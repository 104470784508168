import { storeUser } from "functions/Auth";
import { getUserGender } from "functions/Auth";
import { getUserStatus } from "functions/Auth";
import { storeDriver } from "functions/Driver";
import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";
import SubmitButton from "../components/SubmitButton";
import SubmitButtonInForm from "../components/SubmitButtonInForm";

function UserModalCreate(props) {
  const { t } = useTranslation();
  //Username
  const [userID, setUserID] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [HKID, setHKID] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [name, setName] = React.useState("");
  const [chineseName, setChineseName] = React.useState("");
  const [nickName, setNickName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [passwordPlainText, setPasswordPlainText] = React.useState("");
  const [admissionDate, setAdmissionDate] = React.useState(new Date());
  const [birthday, setBirthday] = React.useState(new Date());


  const [genderChooseList, setGenderChooseList] = React.useState([]);

  // Control
  const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);


  React.useEffect(() => {
    if (props.show) {

      setGenderChooseList(getUserGender());
    }
  }, [genderChooseList]);


  const clearForm = () => {
    setUserID("");
    setPhoneNumber("");
    setHKID("");
    setStatus("");
    setGender("");
    setName("");
    setChineseName("");
    setNickName("");
    setEmail("");
    setPasswordPlainText("");
    setAdmissionDate(new Date());
    setBirthday(new Date());

    setGenderChooseList([]);
    setFinishSubmitBoo(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // data check
    // check phone user name
    if (userID === "") {
      Notifications({
        type: "dataCheck",
        message: "Please input a user name"
      });
      return;
    }
    // check phone user name
    if (passwordPlainText === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a password")
      });
      return;
    }
    // check phone user name
    if (name === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a name")
      });
      return;
    }
    // check phone user name
    if (nickName === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a nickname")
      });
      return;
    }

    let submittedData = {
      userID: userID,
      phoneNumber: phoneNumber,
      HKID: HKID,
      status: "normal",
      gender: gender,
      name: name,
      chineseName: chineseName,
      nickName: nickName,
      email: email,
      passwordPlainText: passwordPlainText,
      admissionDate: admissionDate,
      birthday: birthday
    };

    try {
      let newUserID = await storeUser(submittedData);
      if (newUserID && newUserID !== '') {
        setFinishSubmitBoo(true);
        clearForm();
        props.refreshTable();
        props.onHide();
      }
    } catch (err) {
      Notifications();
    }
  };
  return (
    <>

      <Modal
        isOpen={props.show}
        size="lg"
         aria-labelledby="contained-modal-title-vcenter"
           centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("Username")}${"("}${t("Required")}${")"}`}
                      labelFor="userID"
                      inputName="userID"
                      inputId="userID"
                      inputPlaceholder={t("Username")}
                      value={userID}
                      handleChange={(e) => {
                        setUserID(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <InputTypeTextForm
                      type="password"
                      label={`${t("Password")}${"("}${t("Required")}${")"}`}
                      labelFor="passwordPlainText"
                      inputName="passwordPlainText"
                      inputId="passwordPlainText"
                      inputPlaceholder={t("Password")}
                      value={passwordPlainText}
                      handleChange={(e) => {
                        setPasswordPlainText(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("Name")}${"("}${t("Required")}${")"}`}
                      labelFor="name"
                      inputName="name"
                      inputId="name"
                      inputPlaceholder={t("Name")}
                      value={name}
                      handleChange={(e) => {
                        setName(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <InputTypeTextForm
                      label={t("Chinese Name")}
                      labelFor="chineseName"
                      inputName="chineseName"
                      inputId="chineseName"
                      inputPlaceholder={t("Chinese Name")}
                      value={chineseName}
                      handleChange={(e) => {
                        setChineseName(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("Nickname")}${"("}${t("Required")}${")"}`}
                      labelFor="nickName"
                      inputName="nickName"
                      inputId="nickName"
                      inputPlaceholder={t("Nickname")}
                      value={nickName}
                      handleChange={(e) => {
                        setNickName(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                  <Col>

                  </Col>
                </Row>

                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={t("HKID")}
                      labelFor="HKID"
                      inputName="HKID"
                      inputId="HKID"
                      inputPlaceholder={t("HKID")}
                      value={HKID}
                      handleChange={(e) => {
                        setHKID(e.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <InputTypeSelect2Form
                      useTranslate={true}
                      data={genderChooseList}
                      placeholder={t("--Select Gender--")}
                      value={gender}
                      nameSelect2="gender"
                      idSelect2="gender"
                      label={t("Gender")}
                      handleOnChangeSelect={(e) => setGender(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputTypeGroupTextForm
                      append={true}
                      appendIcon={true}
                      appendIconClass="fa fa-phone"
                      appendText=""
                      label={t("Contact")}
                      labelFor="phoneNumber"
                      inputName="phoneNumber"
                      inputId="phoneNumber"
                      inputPlaceholder={t("Contact")}
                      value={phoneNumber}
                      handleChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <InputTypeGroupTextForm
                      append={true}
                      appendIcon={true}
                      appendIconClass="fa fa-envelope"
                      appendText=""
                      label={t("Email")}
                      labelFor="email"
                      inputName="email"
                      inputId="email"
                      inputPlaceholder={t("Email")}
                      value={email}
                      handleChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputDateTimeGroupForm
                      label={t("Birthday")}
                      labelFor="birthday"
                      inputName="birthday"
                      inputId="birthday"
                      inputPlaceholder={t("Birthday")}
                      value={birthday}
                      onDateChange={(e) => setBirthday(e)}
                      showTimeBoo={false}
                    />
                  </Col>
                  <Col>
                    <InputDateTimeGroupForm
                      label={t("Admission Date")}
                      labelFor="admissionDate"
                      inputName="admissionDate"
                      inputId="admissionDate"
                      inputPlaceholder={t("Admission Date")}
                      value={admissionDate}
                      onDateChange={(e) => setAdmissionDate(e)}
                      showTimeBoo={false}
                    />
                  </Col>
                </Row>
                {/* <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="remark"
                  inputName="remark"
                  inputId="remark"
                  inputPlaceholder={t("Remark")}
                  type="textarea"
                  // value={nickName}
                  // handleChange={(e) => {
                  //   setNickName(e.target.value);
                  // }}
                /> */}
                {/* <InputTypeSelect2Form
                  data={getUserStatus()}
                  placeholder="--Select Status--"
                  value={status}
                  nameSelect2="status"
                  idSelect2="status"
                  label="Status"
                  handleChange={(e) => setStatus(e.target.value)}
                />
                <InputTypeTextForm
                  label="Chinese Name"
                  labelFor="chineseName"
                  inputName="chineseName"
                  inputId="chineseName"
                  inputPlaceholder={t("Chinese Name")} 
                  value={chineseName}
                  handleChange={(e) => {
                    setChineseName(e.target.value);
                  }}
                /> */}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="w-100 d-flex justify-content-between">
              <div>
                <Button
                  color="danger"
                  type="button" // type="button" means the form will not submit
                  onClick={(e) => {
                    e.preventDefault();
                    clearForm();
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
              <div>


                <SubmitButtonInForm
                  finishSubmitBoo={finishSubmitBoo}
                  buttonNormalText={"Add"} />

                <Button color="default" type="button" onClick={props.onHide}>
                  {t("Cancel")}
                </Button>
              </div>
            </Row>

          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default UserModalCreate;
