import { addUserJobMatch } from "functions/UserJobMatch";

import moment from "moment";
import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";
import { getAllUserJobSelect2 } from "functions/UserJob";

function UserModalMatchJob(props) {
    const { t } = useTranslation();

    const [jobArray, setJobArray] = React.useState([]);

    const [userDatas, setUserDatas] = React.useState([]);
    const [userID, setUserID] = React.useState("");
    const [jobToChoose, setJobToChoose] = React.useState("");
    const [jobRemark, setJobRemark] = React.useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userID === "") {
            Notifications({
                type: "dataCheck"
                , message: 'userID is invalid.'
            });
            return;
        }
        if (jobToChoose === "") {
            Notifications({
                type: "dataCheck"
                , message: t('Please choose a job')
            });
            return;
        }

        try {
            await addUserJobMatch(userID, jobToChoose, jobRemark);
            props.refreshTable();
            props.onHide();
        } catch (err) {
            Notifications();
        }
    };

    React.useEffect(() => {
        if (props.show) {
            setJobToChoose("");
            setJobRemark("");
            setUserDatas(props.userDatas);
            setUserID(props.userDatas.userID);
        }
    }, [props.show]);


    React.useEffect(() => {
        const configJobArray = async () => {
            const jobArrayFromAPI = await getAllUserJobSelect2(); 
            setJobArray(jobArrayFromAPI);
        }
        
        if (props.show) {
            configJobArray();
        }
    }, [props.show]);


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t("Choose job")}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col>
                                        <InputTypeSelect2Form
                                            useTranslate={true}
                                            data={jobArray}
                                            placeholder={t("--Select Job--")}
                                            value={jobToChoose}
                                            nameSelect2="job"
                                            idSelect2="job"
                                            label={t("Job")}
                                            handleOnChangeSelect={(e) => setJobToChoose(e.target.value)}
                                        />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            label={t("Job remark")}
                                            labelFor="jobRemark"
                                            inputName="jobRemark"
                                            inputId="jobRemark"
                                            inputPlaceholder={t("Job remark")}
                                            value={jobRemark}
                                            handleChange={(e) => {
                                                setJobRemark(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {t("Confirm")}
                        </Button>
                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default UserModalMatchJob;
