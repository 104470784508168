
import { deleteLocalSetting, getLocalSettingByUserID } from "functions/LocalSetting";

import Notifications from "../components/Notifications";


import moment from "moment";
import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import LocalSettingCard from "./LocalSettingCard";
import LocalSettingCreateModal from "./LocalSettingCreateModal";
import LocalSettingEditModal from "./LocalSettingEditModal";

function LocalSettingModal(props) {
  const { t } = useTranslation();

  const [systemUserData, setSystemUserData] = React.useState({});
  const [systemLocalSettingData, setSystemLocalSettingData] = React.useState([]);

  const [showLocalSettingCreateModal, setShowLocalSettingCreateModal] = React.useState(false);
  const [showLocalSettingModalEdit, setShowLocalSettingModalEdit] = React.useState(false);

  //for the data edit
  const [systemLocalSettingForEdit, setSystemLocalSettingForEdit] = React.useState({});

  const [refreshTable, setRefreshTable] = React.useState(1);

  React.useEffect(() => {
    const getLocalSetting = async (userID) => {
      let localSettingData = await getLocalSettingByUserID(userID);
      setSystemLocalSettingData(localSettingData);
    };
    if (props.userData && Object.values(props.userData).length > 0) {
      getLocalSetting(props.userData.userID);
    };
    setSystemUserData(props.userData);
  }, [props.show, refreshTable]);

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  // Delete Confirmation
  async function deleteConfirmation(data) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: data,
      callback: deleteLocalSettingFcn,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteLocalSettingFcn(data) {
    if (data) {
      await deleteLocalSetting(data?.userID, data?.settingKey);
      refreshTableData();
    }
  }


  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <ModalHeader>
          <div className="mt-4">
            {props.title}
          </div>
        </ModalHeader>
        <ModalBody>


          {/* job match area */}
          <Row className="">
            <Col>
              <Button
                className="mt-1"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setShowLocalSettingCreateModal(true); // open the form
                }}
                size="md"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">{t("Add New")}</span>
              </Button>
            </Col>
          </Row>


          <Row className="mt-4">
            <Col>
              {systemLocalSettingData && Object.values(systemLocalSettingData).length >= 1 &&
                <>
                  {systemLocalSettingData.map((item) => {
                    return <LocalSettingCard
                    key={item.settingKey}
                      systemLocalSettingData={item}
                      systemUserData={systemUserData}
                      useTranslate={true}
                      onClickEdit={(systemLocalSettingData) => {
                        setSystemLocalSettingForEdit(systemLocalSettingData);
                        setShowLocalSettingModalEdit(true);
                        refreshTableData();
                      }}
                      onClickDelete={(systemLocalSettingData) => {
                        deleteConfirmation(systemLocalSettingData);
                      }}
                    />
                  })}
                </>
              }
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>


      <LocalSettingCreateModal
        show={showLocalSettingCreateModal}
        onHide={() => setShowLocalSettingCreateModal(false)}
        userID={systemUserData?.userID}
        refreshTable={() => { refreshTableData(); }}
      />

      <LocalSettingEditModal
        show={showLocalSettingModalEdit}
        onHide={() => setShowLocalSettingModalEdit(false)}
        localSettingData={systemLocalSettingForEdit}
        refreshTable={() => { refreshTableData(); }}
      />


    
    </>
  );
}

export default LocalSettingModal;
