import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { updateAddress } from "functions/DeliveryAddress";
import DeliveryAddressInputFieldWithMap from "../components/DeliveryAddressInputFieldWithMap.js";
import Notifications from "../components/Notifications";

import SpinnerOverlay from "../components/SpinnerOverlay";
// props
/* 
show={modalCreateDeliveryAddressIsOpen}
onHide={(e) => {
  setModalCreateDeliveryAddressIsOpen(false);
}}
title={t("Create new address")}
button={t("Add")}
refreshTable={refreshTableData}
deliveryAddressToClone={selectedDeliveryAddressToCloneData}
 */


function AddressModalEdit(props) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const [addressData, setAddressData] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    const { addressDetailUnitFloor, addressDetailBuilding, addressDetailStreet, addressDetailDistrict, addressDetailMainDistrict, addressRemark, addressGPSLatitude, addressGPSLongtitude } = addressData;

    const deliveryAddressToEdit = props.deliveryAddressToClone;

    const deliveryAddressID = props.deliveryAddressToClone?.deliveryAddressID;

    const handleSubmit = async (e) => {
        e.preventDefault();

        // data check
        if (!deliveryAddressID || deliveryAddressID === "" || deliveryAddressID === undefined) {
            Notifications({
                type: "dataCheck",
                message: t("Cannot find address ID")
            });
            return;
        }

        if (addressDetailMainDistrict === "" || addressDetailDistrict === "" || addressDetailStreet === "" || addressDetailBuilding === "" || addressDetailUnitFloor === "") {
            Notifications({
                type: "dataCheck",
                message: t("Please input an address")
            });
            return;
        }

        let dataSubmit = {
            deliveryAddressID: deliveryAddressID,
            addressDetailUnitFloor: addressDetailUnitFloor,
            addressDetailBuilding: addressDetailBuilding,
            addressDetailStreet: addressDetailStreet,
            addressDetailDistrict: addressDetailDistrict,
            addressDetailMainDistrict: addressDetailMainDistrict,
            addressDetailCity: "香港",
            addressRemark: addressRemark,
            addressGPSLatitude: addressGPSLatitude,
            addressGPSLongtitude: addressGPSLongtitude,
        };

        setIsLoading(true);
        let result = await updateAddress(dataSubmit);
        setIsLoading(false);
        result.code == 200 ? await props.refreshTable() : true;
        props.onHide();
    };

    React.useEffect(() => {
        const clearForm = () => {
            setAddressData("", "", "", "", "", "", 0, 0)
        };

        if (!props.show) {
            clearForm();
        }

    }, [props.show]);

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
             //   aria-labelledby="contained-modal-title-vcenter"
              //  centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.onHide}>
                        <div>{props.title}</div>
                        {props.specialMessage &&
                            <div>{props.specialMessage}</div>
                        }
                    </ModalHeader>
                    <ModalBody className="mt--4">

                        <DeliveryAddressInputFieldWithMap
                            createMode={false}
                            showMap={true}
                            readOnly={false}
                            readOnlyFullAddress={true}
                            submitAddressData={(addressData) => { setAddressData(addressData); }}
                            deliveryAddressToEdit={deliveryAddressToEdit}
                            fieldMapSameRow={true} />
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <Button color="primary" type="submit">
                            {props.button}
                        </Button>
                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default AddressModalEdit;
