import api, { apiDelete, apiGet, apiPost, apiPut, apiPostReporting } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";
import moment from "moment";
const md5 = require('md5');

import { getDateStringWithoutTime } from "functions/Utility";
import { getDateString, getDateDifference } from "./Utility";
import { combineAddress } from "./Delivery";
import { uuid4 } from "@sentry/utils";

export const configQuotationMessage = (customerData, invoiceRemark, invoicesSubitemList, deliveryRecordShoppingCartArray = []
    , quotationMessageIncludeCustomerDataBoo, quotationMessageIncludeSubtotalBoo, quotationMessageIncludeDeliveryRecordShoppingCartBoo) => {

    if (!customerData) return "";
    if (customerData.customerID === "") return "";

    if (!invoicesSubitemList) {
        return "";
    }

    // error 
    if (!Array.isArray(invoicesSubitemList)) return "Wrong array";


    try {
        let messageToGet = "";
        if (invoicesSubitemList.length <= 0) {
            messageToGet = "No invoice subitems";
        } else {
            let messageHead = ""; // Date // Company data
            let messageBody = "";
            let messageFoot = "";

            // messageHead
            const today = new Date();
            messageHead = getDateStringWithoutTime(today, true) + '\n';
            messageHead = messageHead + '永昌隆清拆工程' + '\n';
            messageHead = messageHead + '報價單';


            // messageBody
            let MD5CheckSum = "";
            let goodIDList = invoicesSubitemList.map((item, index) => {
                return item.good.goodID;
            })

            messageBody = messageBody + "貨物資料" + "（" + md5(goodIDList.join("")).substring(0, 6) + "）" + '\n';

            invoicesSubitemList.forEach((item) => {
                let remarkString = (item.invoiceSubitemRemark === "" ? "" : "#" + item.invoiceSubitemRemark + "#");
                messageBody = messageBody + '---' + item.good.goodID + '--' + '\n';
                messageBody = messageBody + item.good.goodDescription + remarkString + '\n';
                let subTotal = item.quantity * item.unitPrice;
                messageBody = messageBody + item.quantity.toString() + '(' + item.good.goodUnit + ')' + ' x ' + '$' + item.unitPrice.toString();
                if (quotationMessageIncludeSubtotalBoo) {
                    messageBody = messageBody + ' = ' + '$' + subTotal.toString() + '\n';
                } else {
                    messageBody = messageBody + '\n';
                }
            })

            messageBody = messageBody + '\n';
            let invoiceAmount = invoicesSubitemList.reduce((total, currentItem) => { return total + (currentItem.quantity * currentItem.unitPrice); }, 0);
            messageBody = messageBody + '總值：$' + invoiceAmount.toString();

            // messageFoot
            if (invoiceRemark) {
                messageFoot = messageFoot + "單據備註：" + '\n';
                messageFoot = messageFoot + invoiceRemark + '\n';
                messageFoot = messageFoot + '\n';
            }

            if (quotationMessageIncludeCustomerDataBoo) {

                messageFoot = messageFoot + "公司資料" + "（" + md5(customerData.customerID).substring(0, 6) + "）" + '\n';
                messageFoot = messageFoot + '客戶編號：' + customerData.customerID + '\n';
                messageFoot = messageFoot + '公司：' + customerData.customerName + '\n';
                messageFoot = messageFoot + '\n';
            }


            // Here is the delivery record shopping cart
            if (quotationMessageIncludeDeliveryRecordShoppingCartBoo) {
                if (deliveryRecordShoppingCartArray && deliveryRecordShoppingCartArray.length >= 1) {
                    messageFoot = messageFoot + "送貨資料";
                    deliveryRecordShoppingCartArray.forEach((item, index) => {
                        try {
                            messageFoot = messageFoot + '\n';
                            messageFoot = messageFoot + '第' + (index + 1).toString() + '車' + '\n';
                            messageFoot = messageFoot + '地址：' + combineAddress(item.DeliveryAddress) + '\n';
                            messageFoot = messageFoot + '預計：' + getDateString(item.proposedDateTime) + '\n';
                            messageFoot = messageFoot + '車牌：' + item.carID + '\n';
                            messageFoot = messageFoot + '司機：' + item.driverID + '\n';
                            messageFoot = messageFoot + '參考：' + item.deliveryAddressID + '\n';

                        } catch (error) {
                            //  console.log(error);
                        }
                    });
                    messageFoot = messageFoot + '\n';
                }

            }
            messageFoot = messageFoot + '報價一個月內生效' + '\n' + '如有問題請 Whatsapp +852 68833248 查詢,謝謝'
            // Calculate the total unpaidAmount
            // Calculate the total AmountToPay
            // let totalUnpaidAmount = invoiceList.reduce((total, currentItem) => { return total + currentItem.unPaidAmountForMessage; }, 0);
            // let totalAmountToPay = invoiceList.reduce((total, currentItem) => { return total + currentItem.amountToPayForMessage; }, 0);

            // if (totalUnpaidAmount == totalAmountToPay) {
            //     messageHead = `客戶：${customerDetail.customerName}` + '\n'
            //         + `共有 ${invoiceList.length} 張單，總值 $${invoiceList.reduce((total, currentItem) => { return total + currentItem.amountToPayForMessage; }, 0)}`
            // } else {
            //     messageHead = `客戶：${customerDetail.customerName}` + '\n'
            //         + `共有 ${invoiceList.length} 張單，付款/總值：$${totalAmountToPay}/$${totalUnpaidAmount}`
            // }


            // let invoiceIDString = invoiceList.map(function (item) {
            //     return item.invoiceID;
            // }).join(",");

            // let invoiceIDStringForSign = invoiceList.map(function (item) {
            //     return item.invoiceID;
            // }).join("");

            // let invoiceAmountDetailArray = invoiceList.map(function (item, index) {
            //     if (item.amountToPayForMessage == item.unPaidAmountForMessage) {
            //         return '第' + (index + 1).toString() + '張' + '：' + item.invoiceID + '（' + '總數：' + '$' + item.unPaidAmountForMessage.toString() + '）';
            //     } else {
            //         return '第' + (index + 1).toString() + '張' + '：' + item.invoiceID + '（' + '付款/總數：' + '$' + item.amountToPayForMessage.toString() + '/' + '$' + item.unPaidAmountForMessage.toString() + '）';
            //     }
            // });

            // // Create a signature
            // let signVerify;
            // signVerify = md5(`${invoiceIDStringForSign}${process.env.REACT_APP_SIGN_HASH}`).substring(0, 6);

            // messageBody = invoiceAmountDetailArray.join('\n');

            // messageBody = messageBody + '\n\n' + `按此連結：` + '\n' + `${baseUrl}/multipleinvoices/pdf/${invoiceIDString}?sign=${signVerify}&listPage=Y&invoicePage=Y&chop=Y`

            // messageFoot = '如單據有更改,連結會自動更新' + '\n' + '有問題請 Whatsapp 查詢,謝謝'

            messageToGet = messageHead + '\n\n' + messageBody + '\n\n' + messageFoot;
        }

        return messageToGet;
    } catch (error) {
        return ""
    }
};

export const getQuotationDataJson = async (message) => {
    try {
        // encodeURI cannot handle special character
        // encodeURIComponent also handle special character
        let response = await apiGet({ url: "quotation?message=" + encodeURIComponent(message), responseType: "json" });
        return response.status == 200 ? response.data.data : [];
    } catch (error) {
        return [];
    }
};

export const getQuotationPDF = async (bodyData) => {
    try {
        // apiPostReporting 
        // Response type: "blob"
        let response = await apiPostReporting({
            url: "quotation/PDF",
            data: bodyData,
            responseType: "",
            enableNotification: false
        });
        return response.status == 200
            ? response.data
            : response.status;
    } catch (error) {
        return null;
    }

};


export const setQuotationToLocalStorage = (customerID = '', customerData = {}, invoiceRemark = '', invoiceSubitemList = [], deliveryRecordList = []) => {
    try {
        // Check all the data
        if (customerID === '' &&
            invoiceRemark === '' &&
            invoiceSubitemList.length === 0 &&
            deliveryRecordList.length === 0) {
            //All data are empty
            // exit
            return;
        }

        let localQuotationID = uuid4();
        let localQuotationRecordDatetime = new Date();

        let originalQuotationList = getQuotationListFromLocalStorage();

        // Check is there anything
        if (!originalQuotationList) {
            // Start a new list
            originalQuotationList = [];
        }

        // The new item
        let quotationObjectToStore = {
            localQuotationID: localQuotationID,
            localQuotationRecordDatetime: localQuotationRecordDatetime,
            customerID: customerID,
            customerData: customerData,
            invoiceRemark: invoiceRemark,
            invoiceSubitemList: invoiceSubitemList,
            deliveryRecordList: deliveryRecordList
        }

        originalQuotationList.push(quotationObjectToStore);

        // Store
        localStorage.setItem("quotationList", JSON.stringify(originalQuotationList));
    } catch (error) {
        console.log(error);
    }
};


export const getQuotationListFromLocalStorage = () => {
    try {
        let listToGet = localStorage.getItem("quotationList");
        // If this object doesnt exist 
        // return null
        if (listToGet) {
            let arrayFromLocalStorage = JSON.parse(listToGet);
            let resultArray = [];
            arrayFromLocalStorage.forEach((item) => {
                if (item && item.localQuotationID && item.localQuotationID !== '') {
                    resultArray.push(item);
                }
            })
            return resultArray;
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
};


export const clearQuotationListFromLocalStorage = () => {
    try {
        localStorage.setItem("quotationList", JSON.stringify([]));
    } catch (error) {

    }
};

export const deleteSingleQuotationListFromLocalStorage = (localQuotationID) => {
    try {
        let resultListToGet = [];
        let originalQuotationList = getQuotationListFromLocalStorage();
        if (originalQuotationList && originalQuotationList.length >= 1) {
            originalQuotationList.forEach((item, index) => {
                if (item && item.localQuotationID && item.localQuotationID !== localQuotationID) {
                    resultListToGet.push(item);
                }
            });
            // Store
            localStorage.setItem("quotationList", JSON.stringify([...resultListToGet]));
        }
    } catch (error) {

    }

};


export const clearOutDatedQuotationListFromLocalStorage = () => {
    try {
        let resultListToGet = [];
        let originalQuotationList = getQuotationListFromLocalStorage();
        if (originalQuotationList && originalQuotationList.length >= 1) {
            originalQuotationList.forEach((item, index) => {
                if (item.localQuotationRecordDatetime && getDateDifference(new Date(item.localQuotationRecordDatetime), new Date(), 'hour') <= 24) {
                    resultListToGet.push(item);
                }
            });
            // Store
            localStorage.setItem("quotationList", JSON.stringify(resultListToGet));

        } else {
            clearQuotationListFromLocalStorage();
        }
    } catch (error) {

    }
};