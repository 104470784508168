import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";

export const filterGoods = (goodsData, search) => {
  let searchLowerCase = search.toLowerCase();
  return goodsData.filter((data) => {
    let goodDescriptionLowerCase = data.goodDescription
      ? data.goodDescription.toLowerCase()
      : "";
    let goodDescriptionTrimmed = goodDescriptionLowerCase.replace(/\-/g, "");
    return (
      (data.goodType &&
        data.goodType.toLowerCase().includes(searchLowerCase)) ||
      (data.goodSubType &&
        data.goodSubType.toLowerCase().includes(searchLowerCase)) ||
      goodDescriptionLowerCase.includes(searchLowerCase) ||
      goodDescriptionTrimmed.includes(searchLowerCase) ||
      (data.goodRemark &&
        data.goodRemark.toLowerCase().includes(searchLowerCase))
    );
  });
};


export const getGoodDatatable = async () => {
  try {
    let response = await apiGet({ url: "goods", responseType: "" });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const getGoodsByID = async (id) => {
  try {
    if (id) {
      let response = await apiGet({ url: "goods/" + id, responseType: "" });
      return response.status == 200 ? response.data.data : [];
    }
  } catch (error) {
    return [];
  }
};

export const getGoodSelect2 = async () => {
  try {
    let response = await apiGet({ url: "goods/", responseType: "" });
    if (response.status == 200) {

      let resultArray = [];
      response.data.data.rows.forEach((obj) => {
        resultArray.push({
          id: obj.goodID,
          text: obj.goodDescription,
          obj: obj
        });
      }
      );
      return resultArray;
    }
  } catch (e) {
    return [];
  }
};

// get invoiceSubitem related

export const getGoodSelect2ByInovoiceID = async (invoiceID) => {
  try {
    let response = await apiGet({ url: "goods/invoiceRelatedGoods/" + invoiceID, responseType: "" });
    if (response.status == 200) {
      let goodListToGet = response.data.data.rows.map(function (item, i) {
        return item.Good;
      });
      goodListToGet.forEach((obj) => renameKeys(obj, "goodID", "id"));
      goodListToGet.forEach((obj) =>
        renameKeys(obj, "goodDescription", "text")
      );
      return goodListToGet ?? [];
    }
  } catch (error) {
    return [];
  }
};

export const createNewGood = async (data) => {
  let response = await apiPost({
    url: "goods/addGood",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data.data.goodID // return the new goodID
    : response.status;
};

export const updateGood = async (data) => {
  let response = await apiPut({
    url: "goods/updateGood",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteGoodByID = async (id) => {
  let response = await apiDelete({
    url: "goods/deleteGood/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export default getGoodDatatable;
