import React, { useState } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { postNewMembershipType } from "functions/Membership.js";

function MembershipTypeCreateModal(props) {
    const { t } = useTranslation();



    // Submit data
    const [submitMembershipTypeDetail, setSubmitMembershipTypeDetail] = React.useState("");
    const [submitMembershipTypeName, setSubmitMembershipTypeName] = React.useState("");

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    //Initial Get Good Data List



    const handleSubmit = async (e) => {
        e.preventDefault();
        let dataToSubmit = {
            membershipTypeName: submitMembershipTypeName,
            membershipTypeDetail: submitMembershipTypeDetail
        };

        let newMemberTypeshipID = await postNewMembershipType(dataToSubmit);

        if (newMemberTypeshipID && newMemberTypeshipID !== '') {
            setFinishSubmitBoo(true);
            clearForm();
            props.refreshTable();
            props.onHide();
        }
    };

    const clearForm = () => {

        setSubmitMembershipTypeName("");
        setSubmitMembershipTypeDetail("");

        setFinishSubmitBoo(false);
    };



    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t("New membership")}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col md="12">
                                <InputTypeTextForm
                                    label={`${t("Membership type")}${"("}${t("Required")}${")"}`}
                                    labelFor="membershipTypeName"
                                    inputName="membershipTypeName"
                                    inputId="membershmembershipTypeNameipType"
                                    inputPlaceholder={t("Membership type")}
                                    value={submitMembershipTypeName}
                                    handleChange={(e) => setSubmitMembershipTypeName(e.target.value)}
                                    required={true}
                                />
                            </Col>
                        
                        </Row>
                        <Row>
                            <Col md="12">
                                <InputTypeTextForm
                                    label={`${t("Membership type detail")}${"("}${t("Required")}${")"}`}
                                    labelFor="membershipTypeDetail"
                                    inputName="membershipTypeDetail"
                                    inputId="membershipTypeDetail"
                                    inputPlaceholder={t("Membership type detail")}
                                    value={submitMembershipTypeDetail}
                                    handleChange={(e) => setSubmitMembershipTypeDetail(e.target.value)}
                                    required={true}
                                />
                            </Col>
                        </Row>


                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>

                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default MembershipTypeCreateModal;
