import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import reactRouterDom, { Link, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";
import { getGoodDatatable } from "functions/Goods";
import GoodModalCreate from "./GoodModalCreate";
import GoodModalEdit from "./GoodModalEdit";
import { deleteGoodByID } from "functions/Goods";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";
import GoodInventoryModalEdit from "./GoodInventoryModalEdit";
import { getInventoryColor } from "functions/GoodInventory";
import GoodInventoryBadge from "../components/GoodInventoryBadge";


function GoodScreen(props) {
  const { t } = useTranslation();
  const pagination = paginationTable();

  const { SearchBar } = Search;

  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  const [goodDataList, setGoodDataList] = React.useState([]);
  const [selectedGoodData, setSelectedGoodData] = React.useState({});
  const [selectedGoodToCloneData, setSelectedGoodToCloneData] = React.useState({});
  const [selectedStoreData, setSelectedStoreData] = React.useState({});
  const [selectedGoodInventoryData, setSelectedGoodInventoryData] = React.useState({});

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

  // Modal
  const [modalCreateGoodIsOpen, setModalCreateGoodIsOpen] = React.useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);
  const [modalEditInventoryIsOpen, setModalEditInventoryIsOpen] = React.useState(false);

  // Call API Customer
  React.useEffect(() => {
    getGoodTable();
  }, [refreshTable]);

  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalCreateGoodIsOpen || modalEditIsOpen);
    lockScrolling(modalCreateGoodIsOpen || modalEditIsOpen);
  }, [modalCreateGoodIsOpen, modalEditIsOpen]);


  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const getGoodTable = async () => {
    setIsLoading(true);
    let goodTable = await getGoodDatatable();
    setGoodDataList(goodTable);
    setIsLoading(false);
  };

  // Clone Confirmation
  async function cloneConfirmation(goodItem) {
    let alert = {
      type: "cloneConfirmation",
      title: "Are you Sure?",
      message: "Ready to clone a good",
      dataToPass: goodItem,
      callback: cloneGoodProcedure,
    };
    Notifications(alert);
  }

  // Clone show form
  async function cloneGoodProcedure(goodItem) {
    setSelectedGoodToCloneData(goodItem)
    setModalCreateGoodIsOpen(true);
  }

  // Delete Confirmation
  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deleteGood,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteGood(id) {
    let deleteResponse = await deleteGoodByID(id);
    deleteResponse.code == 200 ? refreshTableData() : true;
  }

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getGoodTable();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader parentName={t("Products")} icon="fas fa-home" />

      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>

                <ToolkitProvider
                  data={goodDataList}
                  keyField="goodID"
                  columns={
                    [
                      { dataField: "goodType", text: "", hidden: true },
                      { dataField: "goodSubType", text: "", hidden: true },
                      { dataField: "goodDescription", text: "", hidden: true },
                      { dataField: "goodDefaultUnitQuantity", text: "", hidden: true },
                      { dataField: "goodUnit", text: "", hidden: true },
                      { dataField: "goodRemark", text: "", hidden: true },
                      {
                        dataField: "",
                        text: t("Product"),
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          return (
                            <div
                              className="">
                              <>
                                {/* <a
                                    className="table-action"
                                    id="tooltip209424781"
                                    onClick={() => {
                                      setSelectedGoodData(row);
                                      setModalEditIsOpen(true);
                                    }}
                                  >
                                    {" "}
                                    <i className="fas fa-edit fa-lg" />
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209424781"
                                  >
                                    {t("Edit")}
                                  </UncontrolledTooltip> */}
                                <a
                                  className="table-action"
                                  id="tooltip209424444"
                                  onClick={() => {
                                    cloneConfirmation(row);
                                  }}
                                >
                                  {" "}
                                  <i className="fas fa-copy fa-lg" />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip209424444"
                                >
                                  {t("Copy")}
                                </UncontrolledTooltip>
                                <a
                                  className="table-action table-action-delete"
                                  id="tooltip12475020"
                                  onClick={() =>
                                    deleteConfirmation(row.goodID)
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fas fa-trash fa-lg"
                                    data-id={cellContent}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip12475020"
                                >
                                  {t("Delete")}
                                </UncontrolledTooltip>
                              </>
                              <div
                              className="mousePointer"
                                onClick={() => {
                                  setSelectedGoodData(row);
                                  setModalEditIsOpen(true);
                                }}>
                                <div className="tableRowTitle">
                                  {row.goodDescription}
                                </div>
                                <div className="tableRowSecondTitle">
                                  {"$" + row.goodDefaultUnitPrice +
                                    " / " + row.goodDefaultUnitQuantity +
                                    row.goodUnit}
                                  {row.goodDefaultWeight == 0 ? "" : " (" + row.goodDefaultWeight + "kg)"}
                                </div>
                                <div className="tableRowSubSubTitle">
                                  {row.goodType}-{row.goodSubType}-{row.goodNameShortForm}
                                </div>
                                <div className="tableRowRemark">
                                  {row.goodRemark}
                                </div>

                              </div>
                              <div className="mt-1 d-flex">
                                {(row.GoodInventories && Array.isArray(row.GoodInventories) && row.GoodInventories.length >= 1) &&
                                  row.GoodInventories.map((item, index) => {
                                    return (
                                      <GoodInventoryBadge
                                        key={index}
                                        goodInventoryData={item}
                                        goodData={row}
                                        badgeType=""
                                        badgeClassName="statusBadge"
                                        onClickBadge={(goodInventoryData, goodData) => {
                                          setSelectedGoodInventoryData(goodInventoryData);
                                          setSelectedStoreData(goodInventoryData.Store);
                                          setSelectedGoodData(goodData);
                                          setModalEditInventoryIsOpen(true);
                                        }}
                                      />
                                    )
                                  })
                                }
                              </div>
                            </div>
                          );
                        },
                      }
                    ]
                    // : [
                    //   {
                    //     dataField: "goodDescription",
                    //     text: t("Item Name"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodNameShortForm",
                    //     text: t("Short Form"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodUnit",
                    //     formatter: (cellContent, row) => {
                    //       return (
                    //         <>
                    //           {row.goodDefaultUnitPrice +
                    //             " /" +
                    //             row.goodUnit}
                    //         </>
                    //       );
                    //     },
                    //     text: t("Price/Unit"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodDefaultUnitQuantity",
                    //     text: t("Quantity Left"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodRemark",
                    //     text: t("Remark"),
                    //     sort: true,
                    //   },
                    //   {
                    //     isDummyField: true,
                    //     dataField: "Action",
                    //     text: t("Action"),
                    //     formatter: (cellContent, row) => {
                    //       return (
                    //         <>
                    //           <a
                    //             style={{ cursor: "pointer" }}
                    //             className="table-action"
                    //             id="tooltip209424781"
                    //             onClick={() => {
                    //               setSelectedGoodData(row);
                    //               setModalEditIsOpen(true);
                    //             }}
                    //           >
                    //             <i className="fas fa-edit" />
                    //           </a>
                    //           <UncontrolledTooltip
                    //             delay={0}
                    //             target="tooltip209424781"
                    //           >
                    //             {t("Edit")}
                    //           </UncontrolledTooltip>
                    //           <a
                    //             style={{ cursor: "pointer" }}
                    //             className="table-action table-action-delete"
                    //             id="tooltip12475020"
                    //             onClick={() =>
                    //               deleteConfirmation(row.goodID)
                    //             }
                    //           >
                    //             <i
                    //               className="fas fa-trash"
                    //               data-id={cellContent}
                    //             />
                    //           </a>
                    //           <UncontrolledTooltip
                    //             delay={0}
                    //             target="tooltip12475020"
                    //           >
                    //             {t("Delete")}
                    //           </UncontrolledTooltip>
                    //         </>
                    //       );
                    //     },
                    //   },
                    // ]
                  }
                  search
                >
                  {(props) => (
                    <div className="py-2 table-responsive table-sm">
                      <Row className="pb-2">
                        <Col xs={7} sm={7} md={7} lg={7}>
                          <SearchBar
                            className=""
                            placeholder={t("Search")}
                            {...props.searchProps}
                          />
                        </Col>
                        <Col
                          className={"text-right"}
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                        >
                          <Button
                            color="primary"
                            id="tooltip443412080"
                            onClick={() => setModalCreateGoodIsOpen(!modalCreateGoodIsOpen)}
                            size="md"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{t("Add")}</span>
                          </Button>
                          {/* <UncontrolledTooltip
                            delay={1}
                            target="tooltip443412080"
                          >
                            {t("Create")}
                          </UncontrolledTooltip> */}
                        </Col>
                      </Row>

                      <BootstrapTable
                        headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>
      <GoodModalCreate
        show={modalCreateGoodIsOpen}
        onHide={(e) => {
          setModalCreateGoodIsOpen(false);
        }}
        title={t("Create new product")}
        button={t("Add")}
        refreshTable={refreshTableData}
        goodToClone={selectedGoodToCloneData}
      />
      <GoodModalEdit
        show={modalEditIsOpen}
        onHide={() => setModalEditIsOpen(false)}
        title={t("Edit product")}
        button={t("Update")}
        goodData={selectedGoodData}
        refreshTable={refreshTableData}
      />
      <GoodInventoryModalEdit
        show={modalEditInventoryIsOpen}
        onHide={() => setModalEditInventoryIsOpen(false)}
        title={t("Edit good inventory")}
        button={t("Update")}
        goodData={selectedGoodData}
        goodInventoryData={selectedGoodInventoryData}
        storeData={selectedStoreData}
        refreshTable={refreshTableData}
      />

    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default GoodScreen;
