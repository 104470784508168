import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Collapse

} from "reactstrap";
import { useTranslation } from "react-i18next";
import DeliveryStatCard from "views/pages/components/DeliveryStatCard";

function DeliveryRecordHeader(props) {
  const { t } = useTranslation();
  const [expandStat, setExpandStat] = React.useState(false);
  return (
    <>
      <div className="header bg-info">
        <Container fluid>
          <div className="header-body">


{/*             <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <Breadcrumb
                  className="d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem
                    style={{ cursor: "pointer" }}>
                    <a className="h2"
                      onClick={(e) => {
                        e.preventDefault();
                        props.goBackLastPage();
                      }}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a onClick={(e) => e.preventDefault()}>
                      <h6 className="h2 text-white d-inline-block mb-0">
                        {props.name}
                      </h6>{" "}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                     {props. name} 
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row> */}

            <Row>
              <Col md={12} xs={12} sm={12} lg={12} xl={12}>
            
                <DeliveryStatCard
                deliveryListData={props.filteredData}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

DeliveryRecordHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default DeliveryRecordHeader;
