import React, { useState } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form.js";
import { getMembershipTypeSelect2 } from "functions/Membership.js";
import CustomerDisplayCard from "../customer/CustomerDisplayCard.js";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm.js";
import { updateMembership } from "functions/Membership.js";
import InvoiceCard from "../invoice/InvoiceCard.js";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal.js";
import { getSingleInvoice } from "functions/Invoice.js";
import { matchSingleMembershipToSingleInvoiceSubitem } from "functions/Membership.js";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal.js";
import MembershipCreateInvoiceChooseSingleGood from "./MembershipCreateInvoiceChooseSingleGood.js";
import InvoiceAddPaymentModal from "../invoice/InvoiceAddPaymentModal.js";
import { getMembershipByMembershipID } from "functions/Membership.js";
import InvoiceEditModal from "../invoice/InvoiceEditModal.js";
import { deleteInvoiceByID } from "functions/Invoice.js";
import Notifications from "../components/Notifications.js";

function MembershipEditModal(props) {
    const { t } = useTranslation();


    // Other data
    const [invoiceData, setInvoiceData] = React.useState({});



    // Submit data
    const [submitMembershipID, setSubmitMembershipID] = React.useState("");
    const [submitCustomerData, setSubmitCustomerData] = React.useState({});
    const [submitMembershipTypeID, setSubmitMembershipTypeID] = React.useState("");
    const [submitEffectiveDate, setSubmitEffectiveDate] = React.useState(new Date());
    const [submitExpiryDate, setSubmitExpiryDate] = React.useState(new Date());

    // Control
    // in a structure of [{id: xxx, text: yyy, obj: {....}}, {.......}]
    const [membershipTypeChooseList, setMembershipTypeChooseList] = React.useState([]);
    const [membershipTypeChosen, setMembershipTypeChosen] = React.useState({});
    const [goodListToPassInvoiceCreate, setGoodListToPassInvoiceCreate] = React.useState([]);
    const [invoiceSubitemListToPassInvoiceCreate, setInvoiceSubitemListToPassInvoiceCreate] = React.useState([]);

    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);



    const [refreshTableCount, setRefreshTableCount] = React.useState(0);

    // Modal 
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceMessage, setInvoiceSelectedDataForInvoiceMessage] = React.useState({});
    const [modalMembershipCreateInvoiceChooseSingleGood, setModalMembershipCreateInvoiceChooseSingleGood] = React.useState(false);

    const [modalAddPaymentIsOpen, setModalAddPaymentIsOpen] = React.useState(false);
    const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);




    //Initial Get Good Data List
    React.useEffect(() => {
        const initMemberShipTypeChooseListData = async () => {
            let dataList = await getMembershipTypeSelect2();
            // in a structure of [{id: xxx, text: yyy, obj: {....}}, {.......}]
            setMembershipTypeChooseList(dataList);
        };
        if (props.show) {
            initMemberShipTypeChooseListData();
        }
    }, [props.show]);

    React.useEffect(() => {
        updateMemberDataFromDB(submitMembershipID);
    }, [refreshTableCount]);

    React.useEffect(() => {
        if (props.show) {
            if (props.membershipDataToEdit && Object.keys(props.membershipDataToEdit).length >= 1) {
                setSubmitMembershipID(props.membershipDataToEdit.membershipID);
                setSubmitCustomerData(props.membershipDataToEdit.Customer);
                setSubmitMembershipTypeID(props.membershipDataToEdit.membershipTypeID);
                setSubmitEffectiveDate(new Date(props.membershipDataToEdit.effectiveDate));
                setSubmitExpiryDate(new Date(props.membershipDataToEdit.expiryDate));

                setInvoiceData(props.membershipDataToEdit?.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice);

                setFinishSubmitBoo(false);
            }
        }
    }, [props.show, props.membershipDataToEdit]);


    React.useEffect(() => {
        if (submitMembershipTypeID) {
            if (membershipTypeChooseList && Array.isArray(membershipTypeChooseList) && membershipTypeChooseList.length >= 1) {
                let chosenData = membershipTypeChooseList.find((item) => item.id === submitMembershipTypeID);
                if (chosenData && chosenData.obj) {
                    setMembershipTypeChosen(chosenData.obj);
                } else {
                    setMembershipTypeChosen({});
                }
            } else {
                setMembershipTypeChosen({});
            }
        }
    }, [submitMembershipTypeID]);


    React.useEffect(() => {
        // need to filter the list into only one good 

        // Error case
        if (!goodListToPassInvoiceCreate || !Array.isArray(goodListToPassInvoiceCreate) || goodListToPassInvoiceCreate.length <= 0) {
            setInvoiceSubitemListToPassInvoiceCreate([]);
        }
        // only one good, good to go
        if (goodListToPassInvoiceCreate.length == 1) {
            setInvoiceSubitemListToPassInvoiceCreate(changeSingleGoodToInvoiceSubitemList(goodListToPassInvoiceCreate[0]));
        }
        // more than 1 good, need to choose
        if (goodListToPassInvoiceCreate.length > 1) {
            // after the modal come back will do setInvoiceSubitemListToPassInvoiceCreate
            setModalMembershipCreateInvoiceChooseSingleGood(true);
        }

    }, [goodListToPassInvoiceCreate]);


    const refreshTableData = () => {
        setRefreshTableCount(refreshTableCount + 1);
    }

    const updateMemberDataFromDB = async (membershipID) => {
        if (!membershipID || membershipID == '') {
            return;
        }
        let memberDataFromDB = await getMembershipByMembershipID(membershipID);

        if (memberDataFromDB && Object.keys(memberDataFromDB).length >= 1) {
            setSubmitMembershipID(memberDataFromDB.membershipID);
            setSubmitCustomerData(memberDataFromDB.Customer);
            setSubmitMembershipTypeID(memberDataFromDB.membershipTypeID);
            setSubmitEffectiveDate(new Date(memberDataFromDB.effectiveDate));
            setSubmitExpiryDate(new Date(memberDataFromDB.expiryDate));
            setInvoiceData(memberDataFromDB?.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice);

            setFinishSubmitBoo(false);
        }
    };


    // Assumption
    // The invoice has only one subitem
    // Get the invoiceSubitemID and match the invoiceSubitemID to the membershipID
    const matchInvoiceSubitem = async (invoiceID) => {
        if (!invoiceID || invoiceID == '') {
            return;
        }

        let invoiceDataFromdb = await getSingleInvoice(invoiceID);
        if (!invoiceDataFromdb) {
            return;
        }

        let invoiceSubitemArray = invoiceDataFromdb.InvoiceSubitems;
        if (!invoiceSubitemArray || !Array.isArray(invoiceSubitemArray) || invoiceSubitemArray.length != 1) {
            return;
        }

        if (invoiceSubitemArray.length == 1) {
            // Get the only one item
            let invoiceSubitemItem = invoiceSubitemArray[0];
            if (submitMembershipID && invoiceSubitemItem && submitMembershipID != '' && invoiceSubitemItem != '') {
                let result = await matchSingleMembershipToSingleInvoiceSubitem(submitMembershipID, invoiceSubitemItem.subItemID);

                refreshTableData();

            }
        }




    };

    const onChooseMemberShipType = (membershipTypeID) => {
        setSubmitMembershipTypeID(membershipTypeID);
    };

    const getGoodListFromMembershipTypeGoodMatches = (MembershipTypeGoodMatches) => {
        if (MembershipTypeGoodMatches && Array.isArray(MembershipTypeGoodMatches)) {
            if (MembershipTypeGoodMatches.length <= 0) {
                return [];
            } else {
                // construct the list 
                let goodList = MembershipTypeGoodMatches.map((item, index) => {
                    return item.Good ? item.Good : item.good;
                })
                return goodList;
            }
        }
    };

    // Structure
    // quantity: item.quantity,
    // unitPrice: item.unitPrice,
    // invoiceSubitemRemark: item.invoiceSubitemRemark,
    // good: item.Good ? item.Good : item.good
    const changeSingleGoodToInvoiceSubitemList = (singleGoodData) => {
        let invoiceSubitemListToConstruct = [];
        if (singleGoodData && Object.values(singleGoodData).length >= 1) {
            // construct the list 
            // must be a single item
            invoiceSubitemListToConstruct = [{
                quantity: singleGoodData.goodDefaultUnitQuantity,
                unitPrice: singleGoodData.goodDefaultUnitPrice,
                invoiceSubitemRemark: singleGoodData.goodRemark,
                good: singleGoodData
            }];

        }
        return invoiceSubitemListToConstruct;
    };


    function deleteConfirmation(invoiceID) {
        if (!invoiceID || invoiceID == '') {
            return;
        }
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: invoiceID,
            callback: deleteInvoice,
        };
        Notifications(alert);

    }

    // Delete API Call
    async function deleteInvoice(invoiceID) {
        //  setIsLoading(true);
        let deleteResponse = await deleteInvoiceByID(invoiceID);
        if (deleteResponse.code == 200) {
            refreshTableData();
        }
        //   setIsLoading(false);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        // let dataToSubmit = {
        //     customerID: submitCustomerData.customerID,
        //     membershipTypeID: submitMembershipTypeID,
        //     effectiveDate: submitEffectiveDate,
        //     expiryDate: submitExpiryDate
        // };

        let result = await updateMembership(submitMembershipID, submitCustomerData.customerID, submitMembershipTypeID, submitEffectiveDate, submitExpiryDate);

        if (result.code && result.code == 200) {
            setFinishSubmitBoo(true);
            props.refreshTable();
            props.onHide();
        }
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t("Edit membership")}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col md="12">
                                <CustomerDisplayCard
                                    hideCardHeader={true}
                                    customerData={submitCustomerData} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <InputTypeSelect2Form
                                    data={membershipTypeChooseList}
                                    useTranslate={true}
                                    placeholder={t("--Select Item--")}
                                    value={submitMembershipTypeID}
                                    nameSelect2="membershipTypeID"
                                    idSelect2="goodmembershipTypeID"
                                    label={t("Membership type")}
                                    handleOnChangeSelect={(e) => onChooseMemberShipType(e.target.value)}
                                    disabled={invoiceData && Object.values(invoiceData).length != 0} // there is something in invoiceData, disable the selection
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <InputDateTimeGroupForm
                                    label={t("Effective date")}
                                    labelFor="effectiveDate"
                                    inputName="effectiveDate"
                                    inputId="effectiveDate"
                                    inputPlaceholder={t("Effective date")}
                                    value={submitEffectiveDate}
                                    onDateChange={(e) => setSubmitEffectiveDate(e)}
                                    showTimeBoo={false}
                                />
                            </Col>
                            <Col md="6">
                                <InputDateTimeGroupForm
                                    label={t("Expiry date")}
                                    labelFor="expiryDate"
                                    inputName="expiryDate"
                                    inputId="expiryDate"
                                    inputPlaceholder={t("Expiry date")}
                                    value={submitExpiryDate}
                                    onDateChange={(e) => setSubmitExpiryDate(e)}
                                    showTimeBoo={false}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="12">
                                {!invoiceData &&
                                    <Button color="success" type="button" onClick={() => {
                                        setGoodListToPassInvoiceCreate(getGoodListFromMembershipTypeGoodMatches(membershipTypeChosen.MembershipTypeGoodMatches));
                                        setModalInvoiceCreateIsOpen(true);
                                    }}>
                                        {t("Connect invoice")}
                                    </Button>
                                }

                                {invoiceData &&
                                    <Button color="danger" type="button" onClick={() => {

                                        deleteConfirmation(invoiceData.invoiceID);
                                    }}>
                                        {t("Remove invoice")}
                                    </Button>
                                }

                                {invoiceData &&
                                    <Button color="warning" type="button" onClick={() => {
                                        setModalAddPaymentIsOpen(true);
                                    }}>
                                        {t("Pay")}
                                    </Button>
                                }
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md="12">

                                {invoiceData &&

                                    <InvoiceCard
                                        showFullCard={false}
                                        enableCardBgColor={false}
                                        cardBgColor={""}
                                        invoiceData={invoiceData}
                                        setClickViewInvoiceButton={(invoice) => {
                                            setInvoiceSelectedDataForInvoiceMessage(invoice);
                                            setModalInvoiceMessageIsOpen(true);
                                        }}
                                        onClickCard={(invoice) => {
                                            setModalInvoiceEditIsOpen(true);
                                        }}
                                        isSelectedCard={false}
                                        cloneInvoice={(invoice) => {
                                            // setInvoiceSelectedDataForCloneInvoice(invoice);
                                            // setModalInvoiceCreateIsOpen(true);
                                        }}
                                        accessCustomerAction={false}
                                        customerAction={(customer) => {
                                            //  setSelectedCustomerData(customer);
                                            // setModalCustomerActionIsOpen(true);
                                        }}
                                        accessCloneInvoice={false}
                                    />
                                }

                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                {/* <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onDeleteInvoice();
                                    }}
                                >
                                    {t("Delete")}
                                </Button> */}
                            </div>
                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Update"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>

                    </ModalFooter>
                </Form>

            </Modal>

            <InvoiceCreateModal
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    // refreshTableData();
                    matchInvoiceSubitem(returnCreatedInvoiceID);
                }}
                preSelectedCustomer={submitCustomerData}
                preSelectedInvoiceRemark={t('Membership fee')}
                preSelectedInvoiceSubitemList={invoiceSubitemListToPassInvoiceCreate}
                disableDelivery={true}
                disableAddProduct={true}
                disableResetButton={true}
                disableChangeGood={true}
                disableDeleteGood={true}
                allowEmptySubitemList={false}
            />
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={invoiceSelectedDataForInvoiceMessage}
            />

            <MembershipCreateInvoiceChooseSingleGood
                goodList={goodListToPassInvoiceCreate}
                show={modalMembershipCreateInvoiceChooseSingleGood}
                onHide={() => setModalMembershipCreateInvoiceChooseSingleGood(false)}
                title={t("Choose a good")}
                button={t("Confirm")}
                returnSingleGood={(goodToReturn) => {
                    setInvoiceSubitemListToPassInvoiceCreate(changeSingleGoodToInvoiceSubitemList(goodToReturn));
                }}
            />

            <InvoiceAddPaymentModal
                show={modalAddPaymentIsOpen}
                onHide={() => setModalAddPaymentIsOpen(false)}
                title={t("Add Payment")}
                button={t("Save")}
                data={invoiceData}
                refreshTable={() => {
                    refreshTableData();
                }}
            />


            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                    refreshTableData();
                }}
                invoiceData={invoiceData}
                disableDelivery={true}
                disableCloneInvoice={true}
                disableAddProduct={true}
                disableChangeGood={true}
                disableDeleteGood={true}
                disableChangeCustomer={true}
            />


        </>
    );
}

export default MembershipEditModal;
