import * as Sentry from "@sentry/react";
import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";

export const configLocalUserAccessList = async (userID) => {

    let userAccessList;
    let response = await apiGet({
        url: "systemUserAccess/getAccessByUserID/" + userID,
        responseType: "",
    });

    if (response.status == 200) {
        userAccessList = response.data.data.rows;
    } else {
        userAccessList = [];
    }
    localStorage.removeItem("systemUserAccessList");
    localStorage.setItem("systemUserAccessList", JSON.stringify(userAccessList));

}


const getUserAccessList = () => {
    let systemUserAccessList = localStorage.getItem("systemUserAccessList");
    if (systemUserAccessList !== null) {
        return JSON.parse(systemUserAccessList);
    } else {
        return [];
    }
}


export const isAccessible = (accessID) => {

    let isSafeMode = localStorage.getItem('safeMode');
    if (isSafeMode && isSafeMode == 'On') {
        return true;
    }

    let systemUserAccessList = getUserAccessList();
    if (!systemUserAccessList || Object.values(systemUserAccessList).length <= 0) {
        // no any accessID
        // default a no
        return false;
    }
    try {
        let findResult = systemUserAccessList.findIndex((item) => {
           return item.accessID === accessID;
        });

        if (findResult >= 0) {
            // found the accessID
            return true;
        } else if (findResult == -1) {
            // cannot the accessID
            return false;
        } else {
            // other case
            // error
            return false;
        }
    } catch {
        // default prohibit access
        return false;
    }
};


export const getAccessibleTagForSafeMode = (accessID) => {

    let isSafeMode = localStorage.getItem('safeMode');
    if (isSafeMode && isSafeMode == 'On') {
        // safe mode is on
        return '*key:' +  accessID;
    } else {
        // Not in safe mode
        return '';
    }


};