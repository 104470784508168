import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Dropdown
} from "reactstrap";

import { useTranslation } from "react-i18next";
// core components
import Dropzone from "dropzone";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import SpinnerOverlay from "../components/SpinnerOverlay";
import { getRemainingDeliverySubitemsByInvoiceID } from "functions/Delivery";
import renameKeys from "functions/RenameKey";


Dropzone.autoDiscover = false;

// props
// props.invoiceID
// props.preSelectedProduct

function InvoiceRemainingSubitemSelectInput(props) {

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const [selectedSomething, setSelectedSomething] = React.useState(false);

    // Structure
    // [{id: XXX, text: YYY, obj: deliverySubitemShoppingCartItem}, {}, ...]
    const [remainingChooseList, setRemainingChooseList] = React.useState([]);


    const [newDeliverySubitemShoppingCartItem, setNewDeliverySubitemShoppingCartItem] = React.useState({
        localChooseItemID: "",
        deliverySubitemQuantity: 0,
        deliverySubitemRemark: "",
        good: {}
    });




    const onInputChangeWithValue = (localChooseItemID, deliverySubitemQuantity, deliverySubitemRemark, good) => {
        setNewDeliverySubitemShoppingCartItem({
            localChooseItemID: localChooseItemID,
            deliverySubitemQuantity: deliverySubitemQuantity,
            deliverySubitemRemark: deliverySubitemRemark,
            good: good
        });
    };



    const clearInput = () => {
        setNewDeliverySubitemShoppingCartItem({
            localChooseItemID: "",
            deliverySubitemQuantity: 0,
            deliverySubitemRemark: "",
            good: {}
        });
    };

    React.useEffect(() => {
        if (selectedSomething) {
            props.selectItem(deliverySubitemQuantity
                , deliverySubitemRemark
                , good);
            setSelectedSomething(false);
        }
    }, [newDeliverySubitemShoppingCartItem]);

    React.useEffect(() => {
        if (props.preSelectedProduct && props.preSelectedProduct?.good && props.preSelectedProduct?.good?.goodID) {
            matchSelectedGood(props.preSelectedProduct?.good?.goodID);
        } else {
            clearInput();
        }
    }, [props.preSelectedProduct, remainingChooseList]);



    const { localChooseItemID, deliverySubitemQuantity, deliverySubitemRemark, good } = newDeliverySubitemShoppingCartItem;

    const matchSelectedGood = (goodID) => {
        if (remainingChooseList && remainingChooseList.length >= 1) {
            // Get the first item matched the goodID
            let matchedGood = remainingChooseList.find((element) => element.obj.good.goodID === goodID);
            if (matchedGood != undefined) {
                onInputChangeWithValue(matchedGood.obj.localChooseItemID,
                    props.preSelectedProduct?.deliverySubitemQuantity ? props.preSelectedProduct?.deliverySubitemQuantity : matchedGood.obj.deliverySubitemQuantity,
                    props.preSelectedProduct?.deliverySubitemRemark ? props.preSelectedProduct?.deliverySubitemRemark : matchedGood.obj.deliverySubitemRemark,
                    matchedGood.obj.good);

            }
        }
    }

    const initDataGood = async () => {
        let getGoodList = await getRemainingDeliverySubitemsByInvoiceID(props.invoiceID);
        let resultArray = [];
        getGoodList.forEach((item) => {
            resultArray.push({
                id: item.invoiceSubitemID,
                text: item.Good.goodDescription + "(剩 " + item.remainingQuantity + " " + item.Good.goodUnit + ")",
               obj: {
                localChooseItemID: item.invoiceSubitemID,
                deliverySubitemQuantity: item.remainingQuantity,
                deliverySubitemRemark: item.remark,
                good: item.Good
               }
               
            });
        }
        );

        setRemainingChooseList(resultArray);
    };

    React.useEffect(() => {
        initDataGood();
    }, []);


    return (
        <>
            {isLoading ? <SpinnerOverlay /> : ""}
            <InputTypeSelect2
                data={remainingChooseList}
                placeholder={t("--Select Item--")}
                value={localChooseItemID}
                nameSelect2="localChooseItemID"
                idSelect2=""
                label={t("Item Name")}
                handleOnChangeSelect={(e) => {
                    if (e.target.selectedIndex != -1) {
                        setSelectedSomething(true);
                        onInputChangeWithValue(remainingChooseList[e.target.selectedIndex].obj.localChooseItemID,
                            remainingChooseList[e.target.selectedIndex].obj.deliverySubitemQuantity,
                            remainingChooseList[e.target.selectedIndex].obj.deliverySubitemRemark,
                            remainingChooseList[e.target.selectedIndex].obj.good);
                    }

                }}
            />
        </>
    );
}

export default InvoiceRemainingSubitemSelectInput;
