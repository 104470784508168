import { truncate } from "@sentry/utils";

import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey";
import Notifications from "views/pages/components/Notifications";
import { getUserLocalSettingItemValue } from "./LocalSetting";

// export const getStoreSelect2 = async () => {
//     const response = await api.get('/store/getStores');
//     let data = response.data;
//     data.forEach(obj => renameKeys(obj, 'storeID', 'id'));
//     data.forEach(obj => renameKeys(obj, 'storeName', 'text'));
//     return data;
// };

export const getStoreSelect2 = async () => {
  try {
    return api.get("store/getStores").then((response) => {
      try {
        if (response.data.code == 200) {
          response.data.data.rows.forEach((obj) =>
            renameKeys(obj, "storeID", "id")
          );
          response.data.data.rows.forEach((obj) =>
            renameKeys(obj, "storeName", "text")
          );
          return response.data.data.rows;
        } else {
          return Notifications({ type: "error", message: response.data.message });
        }
      } catch (error) {
        return Notifications({ type: "error" });
      }
    });
  } catch (error) {
    return [];
  }
};

export const getAllStore = async () => {
  try {
    let response = await apiGet({ url: "/store/getStores", responseType: "" });
    // response.data.data.rows directly means the array
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const addNewStore = async (data) => {
  let response = await apiPost({
    url: "/store/addStore",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data.data
    : response.status;
};

export const updateStore = async (data) => {
  let response = await apiPut({
    url: "/store/updateStore",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteStoreByStoreID = async (storeID) => {
  let response = await apiDelete({
    url: "/store/deleteStore/" + storeID,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};


export const getStorebyID = async (id) => {
  try {
    const response = await api.get("store/getStore/" + id);
    return response.data.data;
  } catch (error) {
    return [];
  }
};

export const getDefaultStoreID = (storeSelect2Data = []) => {
  try {
    if (storeSelect2Data && Array.isArray(storeSelect2Data) && storeSelect2Data.length == 1) {
      return storeSelect2Data[0].id;
    } else {
      return getUserLocalSettingItemValue("defaultStoreID");
    }
  } catch (error) {
    return '';
  }
};