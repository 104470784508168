import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { createNewGood } from "functions/Goods";
import SubmitButton from "../components/SubmitButton.js";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";

function GoodModalCreate(props) {
  const { t } = useTranslation();
  const [goodStatus, setGoodStatus] = React.useState("normal");
  const [goodPriority, setGoodPriority] = React.useState("");
  const [goodType, setGoodType] = React.useState("");
  const [goodSubType, setGoodSubType] = React.useState("");
  const [goodDescription, setGoodDescription] = React.useState("");
  const [goodDefaultUnitQuantity, setGoodDefaultUnitQuantity] = React.useState(1);
  const [goodUnit, setGoodUnit] = React.useState("");
  const [goodDefaultUnitPrice, setGoodDefaultUnitPrice] = React.useState(0);
  const [goodDefaultWeight, setGoodDefaultWeight] = React.useState(0);
  const [goodIconFileName, setGoodIconFileName] = React.useState("");
  const [goodRemark, setGoodRemark] = React.useState("");
  const [goodNameShortForm, setGoodNameShortForm] = React.useState("");


  // Control
  const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let dataGood = {
      goodStatus: goodStatus,
      goodPriority: goodPriority,
      goodType: goodType,
      goodSubType: goodSubType,
      goodDescription: goodDescription,
      goodDefaultUnitQuantity: goodDefaultUnitQuantity,
      goodUnit: goodUnit,
      goodDefaultUnitPrice: goodDefaultUnitPrice,
      goodDefaultWeight: goodDefaultWeight ? goodDefaultWeight : 0,
      goodIconFileName: goodIconFileName,
      goodRemark: goodRemark,
      goodNameShortForm: goodNameShortForm,
    };

    let goodID = await createNewGood(dataGood);

    if (goodID && goodID !== '') {
      setFinishSubmitBoo(true);
      clearForm();
      props.refreshTable();
      props.onHide();
    }
  };

  const clearForm = () => {
    setGoodStatus("");
    setGoodPriority(0);
    setGoodType("");
    setGoodSubType("");
    setGoodDescription("");
    setGoodDefaultUnitQuantity(1);
    setGoodUnit("");
    setGoodDefaultUnitPrice(0);
    setGoodDefaultWeight(0);
    setGoodIconFileName("");
    setGoodRemark("");
    setGoodNameShortForm("");

    setFinishSubmitBoo(false);
  };

  React.useEffect(() => {
    const setUpGoodToClone = () => {
      setGoodStatus(props.goodToClone.goodStatus);
      setGoodPriority(props.goodToClone.goodPriority);
      setGoodType(props.goodToClone.goodType);
      setGoodSubType(props.goodToClone.goodSubType);
      setGoodDescription(props.goodToClone.goodDescription);
      setGoodDefaultUnitQuantity(props.goodToClone.goodDefaultUnitQuantity);
      setGoodUnit(props.goodToClone.goodUnit);
      setGoodDefaultUnitPrice(props.goodToClone.goodDefaultUnitPrice);
      setGoodDefaultWeight(props.goodToClone.goodDefaultWeight);
      setGoodIconFileName(props.goodToClone.goodIconFileName);
      setGoodRemark(props.goodToClone.goodRemark);
      setGoodNameShortForm(props.goodToClone.goodNameShortForm);
    };

    if (props.show) {
      if (props.goodToClone && props.goodToClone !== undefined && props.goodToClone !== null && Object.values(props.goodToClone).length >= 1) {
        setUpGoodToClone();
      } else {
        clearForm();
      }
    }
  }, [props.goodToClone]);

  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody className="mt--4">
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Item Name")}${"("}${t("Required")}${")"}`}
                  labelFor="goodDescription"
                  inputName="goodDescription"
                  inputId="goodDescription"
                  inputPlaceholder={t("Item Name")}
                  value={goodDescription}
                  handleChange={(e) => setGoodDescription(e.target.value)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Short Form Name")}${"("}${t("Required")}${")"}`}
                  labelFor="goodNameShortForm"
                  inputName="goodNameShortForm"
                  inputId="goodNameShortForm"
                  inputPlaceholder={t("Short Form Name")}
                  value={goodNameShortForm}
                  handleChange={(e) => setGoodNameShortForm(e.target.value)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Type")}${"("}${t("Required")}${")"}`}
                  labelFor="goodType"
                  inputName="goodType"
                  inputId="goodType"
                  inputPlaceholder={t("Type")}
                  value={goodType}
                  handleChange={(e) => setGoodType(e.target.value)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Sub Type")}${"("}${t("Required")}${")"}`}
                  labelFor="goodSubType"
                  inputName="goodSubType"
                  inputId="goodSubType"
                  inputPlaceholder={t("Sub Type")}
                  value={goodSubType}
                  handleChange={(e) => setGoodSubType(e.target.value)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Unit")}${"("}${t("Required")}${")"}`}
                  labelFor="goodUnit"
                  inputName="goodUnit"
                  inputId="goodUnit"
                  inputPlaceholder={t("Unit")}
                  value={goodUnit}
                  handleChange={(e) => setGoodUnit(e.target.value)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Price") + "($)"}${"("}${t("Required")}${")"}`}
                  labelFor="goodDefaultUnitPrice"
                  inputName="goodDefaultUnitPrice"
                  inputId="goodDefaultUnitPrice"
                  inputPlaceholder={t("Price")}
                  value={goodDefaultUnitPrice}
                  handleChange={(e) => setGoodDefaultUnitPrice(e.target.value)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={t("Weight") + "(kg)"}
                  labelFor="goodDefaultWeight"
                  inputName="goodDefaultWeight"
                  inputId="goodDefaultWeight"
                  inputPlaceholder={t("Weight")}
                  value={goodDefaultWeight}
                  handleChange={(e) => setGoodDefaultWeight(e.target.value)}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Default quantity")}${"("}${t("Required")}${")"}`}
                  labelFor="goodDefaultUnitQuantity"
                  inputName="goodDefaultUnitQuantity"
                  inputId="goodDefaultUnitQuantity"
                  inputPlaceholder={t("Default quantity")}
                  value={goodDefaultUnitQuantity}
                  handleChange={(e) =>
                    setGoodDefaultUnitQuantity(e.target.value)
                  }
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="goodRemark"
                  inputName="goodRemark"
                  inputId="goodRemark"
                  inputPlaceholder={t("Remark")}
                  type={"textarea"}
                  value={goodRemark}
                  handleChange={(e) => setGoodRemark(e.target.value)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="mt--4">
            <Row className="w-100 d-flex justify-content-between">
              <div>
                <Button
                  color="danger"
                  type="button" // type="button" means the form will not submit
                  onClick={(e) => {
                    e.preventDefault();
                    clearForm();
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
              <div>
                <SubmitButtonInForm
                  finishSubmitBoo={finishSubmitBoo}
                  buttonNormalText={"Add"} />

                <Button color="default" type="button" onClick={props.onHide}>
                  {t("Cancel")}
                </Button>
              </div>
            </Row>

          </ModalFooter>
        </Form>

      </Modal>
    </>
  );
}

export default GoodModalCreate;
