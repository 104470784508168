import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//deliveryRecordData
//badgeType :string
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
function DeliveryBadge(props) {

    const { t } = useTranslation();
    const deliveryRecord = props.deliveryRecordData;
    const truckWorkerList = props.deliveryRecordData?.DeliveryRecordTruckWorkerMatches;
    const badgeType = props.badgeType;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";


    function textToShow() {
        switch (badgeType) {
            case 'deliveryStatus':
                return `${t(deliveryRecord?.deliveryStatus)}`;
            case 'carID':
                return deliveryRecord?.carID;
            case 'driverID':
                //   return deliveryRecord?.driverID;
                return deliveryRecord?.Driver?.SystemUser?.nickName;
            default:
                return 'N/A';
        }
    };

    function badgeColor() {
        switch (badgeType) {
            case 'deliveryStatus':
                return deliveryRecord?.deliveryStatus === "progress"
                    ? "success"
                    : deliveryRecord?.deliveryStatus === "pending"
                        ? "warning"
                        : deliveryRecord?.deliveryStatus === "取消"
                            ? "danger"
                            : "primary";
            case 'carID':
                return deliveryRecord?.DeliveryRecordSubitems?.length >= 1
                    ? "info"
                    : "primary";
            case 'driverID':
                return deliveryRecord?.DeliveryRecordSubitems?.length >= 1
                    ? "warning"
                    : "primary";
                    
            case 'truckWorkerList':
                return "success";
            default:
                return "primary";
        }
    };
    if (badgeType === 'truckWorkerList') {
        return truckWorkerList && truckWorkerList.map((element, index) => {
            //element is the truckWorker
            return (
                <Badge
                    key={index}
                    color={badgeColor()}
                    className={badgeClassName ? badgeClassName : badgeClassNameDefault}
                >
                    {element?.TruckWorker?.SystemUser?.nickName}
                </Badge>
            );
        });
    } else {
        return (
            <Badge
                color={badgeColor()}
                className={badgeClassName ? badgeClassName : badgeClassNameDefault}
            >
                {textToShow()}
            </Badge>
        );
    }


}

export default DeliveryBadge;
