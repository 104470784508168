import Notifications from "views/pages/components/Notifications";

import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";


export const combineAddress = (addAddressObjToCombine) => {
  if (!addAddressObjToCombine) {
    return "";
  }
  try {
    return addAddressObjToCombine?.addressDetailMainDistrict +
      addAddressObjToCombine?.addressDetailDistrict +
      addAddressObjToCombine?.addressDetailStreet +
      addAddressObjToCombine?.addressDetailBuilding +
      addAddressObjToCombine?.addressDetailUnitFloor;
  } catch (e) {
    return "";
  }
};

export const getAllDeliveryAddress = async () => {
  try {
    const response = await api.get(`/deliveryAddress/getAllAddress`);
    //return response.status == 200 ? response.data.data.rows : [];
    return response.data.data.rows;
  } catch (error) {
    return [];
  }
};

export const addDeliveryAddress = async (data) => {
  let response = await apiPost({
    url: "deliveryAddress/addAddress",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data.data
    : response.status;
};

export const deleteAddressByID = async (deliveryAddressID) => {
  let response = await apiDelete({
    url: "deliveryAddress/deleteAddress/" + deliveryAddressID,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};


export const updateAddress = async (data) => {
  let response = await apiPut({
    url: "deliveryAddress/updateAddress",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

