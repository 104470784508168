import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { addNewStore } from "functions/Store.js";


// props


function StoreCreateModal(props) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [physicalAddress, setPhysicalAddress] = React.useState('');
    const [storeName, setStoreName] = React.useState('');
    const [storeType, setStoreType] = React.useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // data check
        if (physicalAddress === "" || storeName === "" || storeType === "") {
            Notifications({
                type: "dataCheck",
                message: t("Please input all details")
            });
            return;
        }

        let dataSubmit = {
            physicalAddress: physicalAddress,
            storeName: storeName,
            storeType: storeType,
        };
        setIsLoading(true);

        let resultJson = await addNewStore(dataSubmit);
        let storeID = resultJson.storeID;
        if (storeID && storeID !== "") {

            clearForm();
            setFinishSubmitBoo(true);
            props.refreshTable();
            props.onHide();
        }

        setIsLoading(false);
    };

    const clearForm = () => {
        setPhysicalAddress('');
        setStoreName('');
        setStoreType('');

        setFinishSubmitBoo(false);
    };


    const onChangeStoreName = (storeName) => {
        setStoreName(storeName);
    };

    const onChangePhysicalAdress = (physicalAddress) => {
        setPhysicalAddress(physicalAddress);
    };

    const onChangeStoreType = (storeType) => {
        setStoreType(storeType);
    };



    React.useEffect(() => {

    }, [props.show]);


    return (
        <>
            <Modal
                className="modal-dialog"
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.onHide}>
                        <div>{props.title}</div>
                    </ModalHeader>
                    <ModalBody className="mt--4">
                        <InputTypeTextForm
                            label={`${t("Store Name")}${"("}${t("Required")}${")"}`}
                            labelFor="storeName"
                            inputName="storeName"
                            inputId="storeName"
                            inputPlaceholder={t("Store Name")}
                            value={storeName}
                            handleChange={(e) => onChangeStoreName(e.target.value)}
                            required={true}
                        />
                        <InputTypeTextForm
                            label={`${t("Address")}${"("}${t("Required")}${")"}`}
                            labelFor="physicalAddress"
                            inputName="physicalAddress"
                            inputId="physicalAddress"
                            inputPlaceholder={t("Address")}
                            value={physicalAddress}
                            handleChange={(e) => onChangePhysicalAdress(e.target.value)}
                            required={true}
                        />
                        <InputTypeTextForm
                            label={`${t("Store Type")}${"("}${t("Required")}${")"}`}
                            labelFor="storeType"
                            inputName="storeType"
                            inputId="storeType"
                            inputPlaceholder={t("Store Type")}
                            value={storeType}
                            handleChange={(e) => onChangeStoreType(e.target.value)}
                            required={true}
                        />



                    </ModalBody>
                    <ModalFooter className="mt--4">

                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>

                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>


                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default StoreCreateModal;
