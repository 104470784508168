import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Badge
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
  getAllCustomerWithUnpaidInvoice,
  getCustomerDatatable
} from "../../../functions/Customer";
import CustomerModalCreate from "./CustomerModalCreate";
import CustomerActionModal from "./CustomerActionModal";
import { Trans, useTranslation } from "react-i18next";
import { joinArrayToString, addPhoneLink } from "functions/Utility.js";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import CustomerUnpaidInvoiceRecord from "./CustomerUnpaidInvoiceRecord";




function CustomerScreen(props) {
  const { t } = useTranslation();
  const pagination = paginationTable();

  const { SearchBar } = Search;
  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);
  const [customerData, setCustomerData] = React.useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = React.useState([]);

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);
  const [expandChoice, setExpandChoice] = React.useState(false);
  const [useUnpaidInvoiceMode, setUseUnpaidInvoiceMode] = React.useState(false);

  //  modal 
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalActionIsOpen, setModalActionIsOpen] = React.useState(false);
  const [modalCustomerUnpaidInvoiceRecordIsOpen, setModalCustomerUnpaidInvoiceRecordIsOpen] = React.useState(false);


  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalIsOpen || modalActionIsOpen);
    //  lockScrolling( modalIsOpen || modalActionIsOpen);
  }, [modalIsOpen, modalActionIsOpen]);

  const getCustomerTable = async () => {
    setIsLoading(true);
    let customerTable = [];
    if (useUnpaidInvoiceMode) {
      customerTable = await getAllCustomerWithUnpaidInvoice();
    } else {
      customerTable = await getCustomerDatatable();
    }
    setCustomerData(customerTable);
    setIsLoading(false);
  };

  React.useEffect(() => {
    getCustomerTable();
  }, [refreshTable]);

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };


  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getCustomerTable();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader parentName={t("Customer")} icon="fas fa-home" />
      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ToolkitProvider
                  data={customerData} // "row" below means the row of this json
                  keyField="customerID"
                  columns={
                    [
                      // hidden columns are for search
                      { dataField: "customerID", text: "", hidden: true },
                      { dataField: "customerName", text: "", hidden: true },
                      { dataField: "customerDescription", text: "", hidden: true },
                      { dataField: "customerEmail", text: "", hidden: true },
                      { dataField: "customerAddress", text: "", hidden: true },
                      { dataField: "phoneUserNameJoin", text: "", hidden: true, formatter: (cell, row, rowIndex, extraData) => { return `${joinArrayToString(row.CustomerPhones.map((item) => { return item.phoneUserName }), "")}` } },
                      { dataField: "phoneNumberJoin", text: "", hidden: true, formatter: (cell, row, rowIndex, extraData) => { return `${joinArrayToString(row.CustomerPhones.map((item) => { return item.phoneNumber }), "")}` } },
                      {
                        dataField: "",
                        text: t("Name"),
                        sort: true,
                        style: { whiteSpace: 'normal' }, //whiteSpace: 'normal' will break the word if the word is too long
                        formatter: (cell, row, rowIndex, extraData) => {
                          return (
                            <>
                              <a
                                id={`tooltip209424783${row.customerID}`}
                                onClick={() => {
                                  setSelectedCustomerData(row);
                                  setModalActionIsOpen(true);
                                }} >
                                <div className="ml--3 mousePointer">
                                  <div className="tableRowTitle">
                                    {row.customerName}
                                  </div>
                                  <div className="tableRowContent">
                                    {row.customerDescription}
                                  </div>
                                </div>
                              </a>
                              <div className="ml--3">
                                {(row.CustomerPhones && Array.isArray(row.CustomerPhones) && row.CustomerPhones.length >= 1) ?
                                  <div className="tableRowContent">
                                    {addPhoneLink(joinArrayToString(row.CustomerPhones.map((item) => { return `${item.phoneUserName}: ${item.phoneNumber}` }), ", "))}
                                  </div>
                                  :
                                  <div className="tableRowRemark">
                                    {t('Please add a contact')}
                                  </div>
                                }
                              </div>

                              {useUnpaidInvoiceMode &&
                                <div className="ml--3 mt-1">
                                  <Badge className="statusBadge" color="danger"
                                    onClick={() => {
                                      setSelectedCustomerData(row);
                                      setModalCustomerUnpaidInvoiceRecordIsOpen(true);
                                    }}>
                                    <Trans>Unpaid</Trans>:{row?.invoiceCountUnpaid.toString()}<Trans>items</Trans>{` ($${row?.invoiceNetPriceSumUnpaid.toString()})`}
                                  </Badge>
                                  <Badge className="statusBadge" color="warning"
                                    onClick={() => {
                                      setSelectedCustomerData(row);
                                      setModalCustomerUnpaidInvoiceRecordIsOpen(true);
                                    }}>
                                    <Trans>Partial</Trans>:{row?.invoiceCountPartial.toString()}<Trans>items</Trans>{` ($${row?.invoiceNetPriceSumPartial.toString()})`}
                                  </Badge>
                                </div>
                              }
                            </>
                          );
                        },
                      },
                    ]
                  }
                  search={{
                    searchFormatted: true
                  }}
                >
                  {(props) => (
                    <>
                      <Row className="pb-2">
                        <Col className="col-6">
                          <SearchBar
                            className="searchBarText"
                            placeholder={t("Search")}
                            {...props.searchProps}
                            style={{ width: '100%' }}
                          />
                        </Col>
                        <Col className="col-6 text-right">
                          <Button
                            className="mr-1"
                            color="primary"
                            size="md"
                            onClick={() => {
                              setExpandChoice(!expandChoice);
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className={expandChoice ? "fa fa-angle-up" : "fa fa-angle-down"} />
                            </span>
                          </Button>
                          <Button
                            color="primary"
                            id="tooltip443412080"
                            onClick={() => setModalIsOpen(!modalIsOpen)}
                            size="md"
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-plus" />
                            </span>
                            {/* <span className="btn-inner--text">{t("Add")}</span> */}
                          </Button>
                        </Col>
                      </Row>
                      <Row className="pb-2">
                        <Col>
                          {expandChoice &&
                            <Button
                              color={useUnpaidInvoiceMode ? 'success' : 'danger'}
                              id="tooltip443412080"
                              onClick={() => {
                                setUseUnpaidInvoiceMode(!useUnpaidInvoiceMode);
                                refreshTableData();
                              }}
                              size="md"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className={useUnpaidInvoiceMode ? "fas fa-check" : "fas fa-times"} />
                              </span>
                              <span className="btn-inner--icon">
                                <Trans>Show unpaid invoices</Trans>
                              </span>
                            </Button>
                          }
                        </Col>
                      </Row>
                      <div className="py-2 table-responsive table-sm">
                        <BootstrapTable
                          headerClasses={"tableHiddenHeader"}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={false}
                        />
                      </div>
                    </>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <CustomerModalCreate
          show={modalIsOpen}
          onHide={() => setModalIsOpen(false)}
          title={t("Add Customer")}
          button={t("Save")}
          returnNewCustomerCreated={(newCustomerData) => { refreshTableData }}
        />

        <CustomerActionModal
          show={modalActionIsOpen}
          onHide={() => setModalActionIsOpen(false)}
          title={t("Choose Action")}
          button={t("Update")}
          customerData={selectedCustomerData}
          refreshTable={refreshTableData}
        />

        <CustomerUnpaidInvoiceRecord
          show={modalCustomerUnpaidInvoiceRecordIsOpen}
          onHide={() => {
            setModalCustomerUnpaidInvoiceRecordIsOpen(false);
          }}
          customerID={selectedCustomerData.customerID}
          customerData={selectedCustomerData}
        />


      </Container>
    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default CustomerScreen;
