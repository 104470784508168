import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { useTranslation } from "react-i18next";

import { da } from "date-fns/locale";
import PaymentStatCard from "views/pages/components/PaymentStatCard";

function PaymentHeader(props) {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [expandStat, setExpandStat] = React.useState(false);

  return (
    <>
      <div className="header bg-info">
        <Container fluid>
          <div className="header-body">
            {/*             <Row className="align-items-center py-4">
              <Col >
                <Breadcrumb
                  className="d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem
                    style={{ cursor: "pointer" }}>
                    <a className="h2"
                      onClick={(e) => {
                        e.preventDefault();
                        props.goBackLastPage();
                      }}>
                      <i className={props.icon} />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a onClick={(e) => e.preventDefault()}>
                      <h6 className="h2 text-white d-inline-block mb-0">
                        {props.name}
                      </h6>{" "}
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {/* {props. name} 
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <PaymentStatCard
                  paymentListData={props.paymentData}>
                </PaymentStatCard>
              </Col>
            </Row>
          </div>

        </Container>
      </div>
    </>
  );
}

PaymentHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default PaymentHeader;
