import React from "react";
import {
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  Label,
} from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "react-datepicker/dist/react-datepicker.css";

function SearchBar(props) {
  function onSearchChange(e) {
    props.onSearchChange(e.target.value);
  }

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Label for={props.labelFor} className="form-control-label">
            {props.label}
          </Label>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="pl-2 pr-2"
              placeholder={props.inputPlaceholder ?? "搜尋"}
              value={props.searchValue}
              onChange={onSearchChange}
            />
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchBar;
