import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDateString } from "functions/Utility";
import { getInventoryColor } from "functions/GoodInventory";
// props
// goodInventoryData
// goodData
// badgeType :string
// badgeClassName : "statusBadge"/ "statusBadgeSmall"
// useTranlate
// onClickBadge


function GoodInventoryBadge(props) {

    const { t } = useTranslation();
    const [goodInventoryData, setGoodInventoryData] = React.useState({});
    const [goodData, setGoodData] = React.useState({});
    const badgeType = props.badgeType;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";



    React.useEffect(() => {
        if (props.goodInventoryData) {
            setGoodInventoryData(props.goodInventoryData);
            setGoodData(props.goodData);
        }
    }, [props.goodInventoryData, props.goodData]);


    function textToShow() {
        try {
            
            if (!goodInventoryData || Object.values(goodInventoryData).length == 0) {
                return "";
            }
            return goodInventoryData.Store.storeName + ": " + goodInventoryData.currentInventory.toString() + goodData?.goodUnit;
        } catch (error) {
            return "";
        }

    };

    function badgeColor() {
        try {
            if (!goodInventoryData) {
                return "primary";
            } else {
                return getInventoryColor(goodInventoryData.currentInventory, goodInventoryData.minInventory, goodInventoryData.maxInventory);
            }
        } catch (error) {
            return "default";
        }
    };

    return (
        <Badge
        
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
            onClick={() => {
                props.onClickBadge(goodInventoryData, goodData);
            }}
        >
            {textToShow()}
        </Badge>


    );
}

export default GoodInventoryBadge;

