import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Label,
    Col,
    Badge
} from "reactstrap";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import { goToUri, isEmpty, openPDFLink } from "../../../functions/Utility";

function InvoiceMessageModal(props) {
    const { t } = useTranslation();
    const [invoiceURL, setInvoiceURL] = React.useState("");
    const [invoiceMessage, setInvoiceMessage] = React.useState("");

    const [invoiceMessageIncludeDN, setInvoiceMessageIncludeDN] = React.useState(true);
    const [invoiceMessageIncludeRC, setInvoiceMessageIncludeRC] = React.useState(true);
    const [invoiceMessageIncludeCompanyChop, setInvoiceMessageIncludeCompanyChop] = React.useState(true);
  
    const [actionCopyDone, setActionCopyDone] = React.useState(false);

    //props.show means this form is triggered by parent form
    React.useEffect(() => {
        if (props.show) {
            setInvoiceMessage(props?.invoiceData?.invoiceMessage);
            setInvoiceURL(props?.invoiceData?.invoiceURL);
        } else {
            clearForm()
        }
    }, [props.show]);

    const clearForm = () => {
        setInvoiceMessageIncludeDN(true); // IN = invoice 
        setInvoiceMessageIncludeRC(true); // PI = payment ref photo
        setInvoiceMessageIncludeCompanyChop(true);
        setActionCopyDone(false);
    }

    React.useEffect(() => {
        editInvoiceMessage(invoiceMessageIncludeDN, invoiceMessageIncludeRC, invoiceMessageIncludeCompanyChop);
        editInvoiceURL(invoiceMessageIncludeDN, invoiceMessageIncludeRC, invoiceMessageIncludeCompanyChop);
    }, [invoiceMessageIncludeDN, invoiceMessageIncludeRC, invoiceMessageIncludeCompanyChop]);

    const editInvoiceMessage = (DNTrueFalse, RCTrueFalse, chopTrueFalse) => {
        const chopRegex = /chop=[\w]+/g;
        const DNRegex = /DN=[\w]+/g;
        const RCRegex = /RC=[\w]+/g;

        let invoiceMessageToProcess = invoiceMessage;

        let chopMatchResult = invoiceMessageToProcess.match(chopRegex);
        let DNMatchResult = invoiceMessageToProcess.match(DNRegex);
        let RCMatchResult = invoiceMessageToProcess.match(RCRegex);
        if (!chopMatchResult || !DNMatchResult || !RCMatchResult) {
            // Do nothing and leave
            return;
        }
        invoiceMessageToProcess = invoiceMessageToProcess.replace(DNMatchResult[0], `DN=${DNTrueFalse ? 'yes' : 'no'}`)
                                                        .replace(RCMatchResult[0], `RC=${RCTrueFalse ? 'yes' : 'no'}`)
                                                        .replace(chopMatchResult[0], `chop=${chopTrueFalse ? 'yes' : 'no'}`);

       setInvoiceMessage(invoiceMessageToProcess);
    }
    const editInvoiceURL = (DNTrueFalse, RCTrueFalse, chopTrueFalse) => {
        const chopRegex = /chop=[\w]+/g;
        const DNRegex = /DN=[\w]+/g;
        const RCRegex = /RC=[\w]+/g;

        let invoiceURLToProcess = invoiceURL;

        let chopMatchResult = invoiceURLToProcess.match(chopRegex);
        let DNMatchResult = invoiceURLToProcess.match(DNRegex);
        let RCMatchResult = invoiceURLToProcess.match(RCRegex);
        if (!chopMatchResult || !DNMatchResult || !RCMatchResult) {
            // Do nothing and leave
            return;
        }
        invoiceURLToProcess = invoiceURLToProcess.replace(DNMatchResult[0], `DN=${DNTrueFalse ? 'yes' : 'no'}`)
                                                        .replace(RCMatchResult[0], `RC=${RCTrueFalse ? 'yes' : 'no'}`)
                                                        .replace(chopMatchResult[0], `chop=${chopTrueFalse ? 'yes' : 'no'}`);

       setInvoiceURL(invoiceURLToProcess);
    }
    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader >{props.title}</ModalHeader>
                <ModalBody className="mt--4">
                    <Col md={6} lg={6}>
                        <Label className="form-control-label mt-4">
                            {t("Invoice Message")}
                        </Label>
                    </Col>
                    <Col>
                        <InputTypeTextForm
                            label=""
                            labelFor="invoiceMessage"
                            inputName="invoiceMessage"
                            inputId="invoiceMessage"
                            inputPlaceholder={t("Invoice Message")}
                            type="textarea"
                            value={invoiceMessage}
                            handleChange={(e) => { setInvoiceMessage(e.target.value) }}
                        />


                        <div className="d-flex mt-1">
                            <Button
                                className="flex-fill"
                                color={invoiceMessageIncludeDN ? "success" : "danger"}
                                onClick={() => { setInvoiceMessageIncludeDN(!invoiceMessageIncludeDN) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {invoiceMessageIncludeDN ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Delivery Note")}</span>
                            </Button>
                            <Button
                                className="flex-fill"
                                color={invoiceMessageIncludeRC ? "success" : "danger"}
                                onClick={() => { setInvoiceMessageIncludeRC(!invoiceMessageIncludeRC) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {invoiceMessageIncludeRC ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Receipt")}</span>
                            </Button>
                        </div>
                        <div className="d-flex mt-1">
                            
                        <Button
                                className="flex-fill"
                                color={invoiceMessageIncludeCompanyChop ? "success" : "danger"}
                                onClick={() => { setInvoiceMessageIncludeCompanyChop(!invoiceMessageIncludeCompanyChop) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {invoiceMessageIncludeCompanyChop ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Company Chop")}</span>
                            </Button>
                        </div>

                        <div className="d-flex flex-column align-items-right mt-1" >
                            <Button
                                color={actionCopyDone ? "success" : "primary"}
                                onClick={() => {
                                    navigator.clipboard.writeText(invoiceMessage);
                                    setActionCopyDone(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy")}</span>
                            </Button>
                        </div>
                    </Col>
                    <Col
                        className="d-flex flex-column align-items-center"
                    >
                        <QRCode
                            onClick={() =>
                                openPDFLink(invoiceURL, "")
                            }
                            style={{ cursor: "pointer" }}
                            className="mt-5"
                            value={invoiceURL ? invoiceURL : ""}
                            size={200}
                        />
                        <div
                            onClick={() =>{
                                openPDFLink(invoiceURL, "");
                            }
                            }
                            style={{ cursor: "pointer" }}
                            className="text-sm text-underline mt-2"
                        >
                            {t("Click to view")}
                        </div>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default InvoiceMessageModal;
