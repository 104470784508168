import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge
} from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import Notifications from "./Notifications";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";



//props
//invoicesSubitems
//isDriver
function InvoiceSubitemTable(props) {

    const { t } = useTranslation();
    const invoiceSubitemsTable = props.invoiceSubitemsTable;
    const isDriver = props.isDriver;

    async function deleteSubitemConfirmation(id) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: id,
            callback: props.deleteSubitem,
        };
        Notifications(alert);
    }

    return (
        <>
            <ToolkitProvider
                //   data={invoicesSubitemsTable}
                data={invoiceSubitemsTable}
                keyField="localSubitemID"
                columns={
                    [
                        {
                            isDummyField: true,
                            dataField: "good",
                            text: (
                                t("Items")
                            ),
                            sort: true,
                            formatter: (
                                cellContent,
                                row,
                                rowIndex,
                                extraData
                            ) => {
                                return (

                                    <>

                                        <div key={rowIndex}
                                            style={{ cursor: "pointer" }}>
                                            {/* First row */}
                                            <div className="d-flex">
                                                <div className="flex-grow-1"
                                                    onClick={() => {
                                                        props.selectSubitem(row);
                                                    }}>
                                                    <Badge color="primary" className="statusBadge">{(rowIndex + 1).toString()}{"."}</Badge>
                                                    <Badge color="default" className="statusBadge">${row?.unitPrice}/{row?.good?.goodUnit}</Badge>
                                                </div>

                                                <div
                                                    style={{ cursor: "pointer" }}>
                                                    {!props.disableDeleteGood &&
                                                        <a
                                                            className="table-action table-action-delete"
                                                            id={`tooltip12475020${rowIndex}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteSubitemConfirmation(row.localSubitemID);
                                                            }}
                                                        >
                                                            <i
                                                                className="fas fa-trash fa-lg"
                                                                data-id={cellContent}
                                                            />
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                            {/* Second row */}
                                            <div className="tableRowTitle"
                                                onClick={() => {
                                                    props.selectSubitem(row);
                                                }}  >
                                                {row?.quantity}{row?.good?.goodUnit}-{row?.good?.goodDescription}
                                            </div>
                                            {/* Third row */}
                                            <div className="tableRowTitleBigger"
                                                onClick={() => {
                                                    props.selectSubitem(row);
                                                }}  >
                                                ${row?.quantity * row?.unitPrice}
                                            </div>

                                            {/* Forth row */}
                                            {row?.invoiceSubitemRemark && row?.invoiceSubitemRemark != '' &&
                                                <div className="tableRowRemark"
                                                    onClick={() => {
                                                        props.selectSubitem(row);
                                                    }}   >
                                                    #{row?.invoiceSubitemRemark}
                                                </div>
                                            }
                                        </div>


                                    </>
                                );
                            },
                        }
                    ]
                }
                search
            >
                {(props) => (
                    <>
                        <div className="table-responsive pt-2 pb-0">
                            {/* py-2 */}
                            <BootstrapTable
                                headerClasses="tableHiddenHeader" // only hide column when mobile
                                {...props.baseProps}
                                bootstrap4={true}
                                // pagination={paginationTable()}
                                bordered={false}
                                noDataIndication={
                                    <div className="mt-2">
                                        <center>
                                            <h3>{t("Please choose items")}</h3>
                                        </center>
                                    </div>
                                }
                            />
                        </div>

                    </>
                )}
            </ToolkitProvider >
        </>
    );
}

export default InvoiceSubitemTable;
