import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardText,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import moment from "moment";
import Notifications from "../components/Notifications";
import { combineAddress } from "../../../functions/Delivery.js"
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getDateString } from "functions/Utility";

import InvoiceBadge from "../components/InvoiceBadge.js";
import DeliveryBadge from "../components/DeliveryBadge";

// props:

// deliveryTeamData = {
// deliveryTeamID
// deliveryTeamName
// carID
// driverID
// Driver: {driverData}
// Car: {carData}
// DeliveryTeamTruckWorkerMatches
// }

// onClickCard
// isSelectedCard
// styleSkipBottomSpaceBoo

function DeliveryTeamCard(props) {

    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;

    const deliveryTeamData = props.deliveryTeamData;
    const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;


    const styleSkipBottomSpaceBoo = props.styleSkipBottomSpaceBoo;


    const [showFullCard, setShowFullCard] = React.useState(props.showFullCard ? props.showFullCard : true);// default tshow full card
    const [whiteText, setWhiteText] = React.useState("");

    React.useEffect(() => {
        setShowFullCard(props.showFullCard);
    }, [props.showFullCard]);

    React.useEffect(() => {
        setWhiteText(" " + (props.enableCardBgColor ? "cardWhiteText" : ""));
    }, [props.enableCardBgColor]);

    return deliveryTeamData ? (
        <Card className={styleSkipBottomSpaceBoo ? "mb-0" : ""} //naturally will have mb-4
            style={{ cursor: "pointer" }}    >
            <CardHeader className={isSelectedCard ? "bg-gradient-success" : ""}>
                <Row
                    className={whiteText}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClickCard(deliveryTeamData);
                    }}>
                    <Col>
                        <div className="tableRowTitleDouble mt-0">
                            {isSelectedCard &&
                                (<Badge color="primary"><i className="fas fa-check" /></Badge>)
                            }
                        </div>
                        <div className="d-flex">
                            <div className="flex-grow-1 tableRowTitle"
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onClickCard(deliveryTeamData);
                                }}>
                                {deliveryTeamData.deliveryTeamName}
                            </div>
                            {!props.hideControlButton &&
                                <div>
                                    {!props.hideEditButton &&
                                        <a className="table-action"
                                            id="tooltip209424781"
                                            onClick={() => {

                                            }} > {" "}
                                            <i className="fas fa-edit fa-lg" />
                                        </a>
                                    }

                                    {!props.hideCloneButton &&
                                        <a className="table-action"
                                            id="tooltip209424444"
                                            onClick={() => {

                                            }}>{" "}
                                            <i className="fas fa-copy fa-lg" />
                                        </a>
                                    }
                                    {!props.hideDeleteButton &&
                                        <a className="table-action table-action-delete"
                                            id="tooltip12475020"
                                            onClick={() =>
                                                props.onClickDeleteButton(deliveryTeamData.deliveryTeamID)
                                            } > {" "}
                                            <i className="fas fa-trash fa-lg"
                                                data-id={deliveryTeamData.deliveryTeamID}
                                            />
                                        </a>
                                    }
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row
                    className={whiteText}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onClickCard(deliveryTeamData);
                    }}>
                    <Col>

                        <div className="mt-1">
                            <Badge className="statusBadge" color="default">
                                {deliveryTeamData.Driver?.SystemUser.nickName}
                            </Badge>
                            <Badge className="statusBadge" color="warning">
                                {deliveryTeamData.carID}
                            </Badge>
                        </div>
                        <div className="mt-1">
                            {(deliveryTeamData.DeliveryTeamTruckWorkerMatches && Array.isArray(deliveryTeamData.DeliveryTeamTruckWorkerMatches) && deliveryTeamData.DeliveryTeamTruckWorkerMatches.length >= 1) &&
                                deliveryTeamData.DeliveryTeamTruckWorkerMatches.map((item, index) => {
                                    return (
                                        <Badge key={index} className="statusBadge" color="primary">
                                            {item.TruckWorker?.SystemUser?.nickName}

                                        </Badge>
                                    )
                                })
                            }
                        </div>


                    </Col>
                </Row>

            </CardHeader>

            {showFullCard &&
                <CardBody
                    className={isSelectedCard ? "bg-gradient-success" : ""}>


                </CardBody>
            }


            {showFullCard &&
                <CardFooter
                    className={isSelectedCard ? "bg-gradient-success" : ""}>

                </CardFooter>
            }
        </Card>
    ) : (
        <Col xs={12} sm={12} md={12} lg={12}>
            <Card>
                <CardBody>
                    <strong className="text-info h1"><Trans>No Data Found</Trans></strong>
                    <h3 className="text-info"><Trans>Please Check Your Filter</Trans></h3>
                </CardBody>
            </Card>
        </Col>
    );
}

export default DeliveryTeamCard;
