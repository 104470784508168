import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Notifications from "../components/Notifications";


import SpinnerOverlay from "../components/SpinnerOverlay";

import { getAllDeliveryTeam } from "functions/DeliveryTeam";

import DeliveryTeamCard from "./DeliveryTeamCard";

function DeliveryTeamChooseModal(props) {
  const { t } = useTranslation();


  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  const [deliveryTeamList, setDeliveryTeamList] = React.useState([]);

  const [deliveryTeamToReturn, setDeliveryTeamToReturn] = React.useState({});

  // Call API Customer
  React.useEffect(() => {
    if (props.show) {
      getDataFromDB();
    } else {
      setDeliveryTeamToReturn({});
    }
  }, [refreshTable, props.show]);


  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const getDataFromDB = async () => {
    setIsLoading(true);
    let dataFromDB = await getAllDeliveryTeam();
    setDeliveryTeamList(dataFromDB);
    setIsLoading(false);
  };




  const confirmToReturnDeliveryTeam = () => {
    if (deliveryTeamToReturn && Object.values(deliveryTeamToReturn).length >= 1) {
      // a team a is selected
      props.returnSingleDeliveryTeam(deliveryTeamToReturn);
      props.onHide();
    } else {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a delivery team")
      });
      return;
    }

  }

  const checkIsSelectedList = (deliveryTeamID) => {
    try {
      return deliveryTeamToReturn.deliveryTeamID === deliveryTeamID;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
      //  keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader >{props.title}</ModalHeader>
        <ModalBody className="">

          <>
            {deliveryTeamList.map((deliveryTeamItem, i) => (
              <Col
                data-id={deliveryTeamItem.deliveryTeamID}
                key={deliveryTeamItem.deliveryTeamID}
              >
                <DeliveryTeamCard
                  deliveryTeamData={deliveryTeamItem}
                  onClickCard={(deliveryTeamData) => {
                    if (checkIsSelectedList(deliveryTeamData.deliveryTeamID)) {
                      //cancel 
                      setDeliveryTeamToReturn({});
                    } else {
                      setDeliveryTeamToReturn(deliveryTeamData);
                    }
                  }}
                  isSelectedCard={checkIsSelectedList(deliveryTeamItem.deliveryTeamID)}
                  hideControlButton={true}
                  styleSkipBottomSpaceBoo={true }
                />


              </Col>
            ))}
          </>


        </ModalBody>
        <ModalFooter>

          <Row className="w-100 d-flex justify-content-between">
            <div>

            </div>
            <div>
              <Button color="primary" onClick={() => {
                confirmToReturnDeliveryTeam();
              }} type="button">
                {props.button}
              </Button>
              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>

          </Row>

        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeliveryTeamChooseModal;
