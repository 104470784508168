import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Table,
  Button,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Dropdown
} from "reactstrap";

import { useTranslation } from "react-i18next";
// core components
import Dropzone, { optionsForElement } from "dropzone";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import { isAccessible } from "functions/SystemUserAccess";
import SpinnerOverlay from "../components/SpinnerOverlay";
import { getAllCustomerSelect2 } from "functions/Customer";
import { joinArrayToString } from "functions/Utility";

Dropzone.autoDiscover = false;

// props
// props.selectCustomerID
// props.preSelectCustomerID
// props.disabled
// props.showCreateButton
// props.onCreateNewCustomer
// props.reloadMe

function CustomerSelectInput(props) {

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const [reloadMe, setReloadMe] = React.useState(false);

  // In a structure of 
  // [{id: XXX, text: YYY}, {id: HHH, text: KKK}]
  const [customerData, setCustomerData] = React.useState([]);
  const [selectedCustomerID, setSelectedCustomerID] = React.useState("");

  React.useEffect(() => {
    getCustomer();
  }, []);

  React.useEffect(() => {
    if (reloadMe) {
      getCustomer();
      setReloadMe(false);
    }
  }, [reloadMe]);

  React.useEffect(() => {
    setReloadMe(props.reloadMe);
  }, [props.reloadMe]);

  React.useEffect(() => {
    if (props.preSelectCustomerID) {
      setSelectedCustomerID(props.preSelectCustomerID);
    } else {
      setSelectedCustomerID("");
    }
  }, [props.preSelectCustomerID]);

  const getCustomer = async () => {
    setIsLoading(true);
    let customerSelectData = await getAllCustomerSelect2();
    setIsLoading(false);
    setCustomerData(customerSelectData);
  };

  const onSelectCustomerID = (customerID) => {
    setSelectedCustomerID(customerID);
    props.selectCustomerID(customerID);
  };

  const onCreateNewCustomer = () => {
    props.onCreateNewCustomer();
  };


  const matchCustom = (params, data) => { //data is the item in the array // params.term is the search text 
    // params.term undefined means user hasnt input anything
    if (!params.term) {
      return data;
    }
    // If there are no search terms, return all of the data
    if (params.term.trim() === '') {
      return data;
    }
    // Do not display the item if there is no 'text' property
    // if (typeof data.text === 'undefined') {
    //   return null;
    // }

    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object
    let phoneJoin = "";
    try {
      if (data.obj.CustomerPhones && data.obj.CustomerPhones.length >= 1) {
        phoneJoin = joinArrayToString(data.obj.CustomerPhones.map((item) => { return item.phoneNumber }), "");
      }
    } catch (error) {

    }

    // Below are the filter conditions
    if (
      phoneJoin.indexOf(params.term) != -1 ||
      data.text.toUpperCase().replace(' ', '').indexOf(params.term.toUpperCase().replace(' ', '')) != -1
    ) {
      // found
      // Return this item means it will be shown in the list
      return data;
    } else {
      // cannot find 
      // Return `null` if the term should not be displayed
      return null
    }
  }


  return (
    <div className="d-flex">
      {isLoading ? <SpinnerOverlay /> : ""}
      <div className="flex-grow-1 text-truncate">
        <InputTypeSelect2
          data={customerData}
          value={selectedCustomerID}
          placeholder={t("--Select Customer--")}
          nameSelect2="customerID"
          idSelect2=""
          label=""
          handleOnChangeSelect={(e) => {
            if (e.target.selectedIndex != -1) {
              onSelectCustomerID(e.target.value);
            }
          }}
          disabled={props.disabled}
          minimumResultsForSearch={2}
          customSearchFunction={matchCustom}
        />
      </div>
      <div>
        {props.showCreateButton &&

          <Button
            className="ml-1"
            color="success"
            onClick={() => {
              onCreateNewCustomer();
            }}
          ><i className="fas fa-plus" /></Button>

        }
      </div>
    </div>
  );
}

export default CustomerSelectInput;
