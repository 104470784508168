import * as Sentry from "@sentry/react";
import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";

import { configLocalUserAccessList } from './SystemUserAccess'
import { configLocalUserLocalSetting } from './LocalSetting'
import { configLocalUserJobList } from "./UserJob";
import { configLocalMembershipCurrentCompanyList } from "./MembershipCurrentCompany";

export const configLocalUserList = async () => {
  try {

    localStorage.removeItem("userList");
    let userList = await getUserList();
    localStorage.setItem("userList", JSON.stringify(userList));
    return true;
  } catch (error) {
    return false;
  }
};

export const login = async (username, password) => {
  const response = await api
    .post("/auth/login", { userID: username, password: password })
    .catch(function (error) {
      return error.response;
    });
  if (response.data.code === 200) {

    const user = response.data.data;
    localStorage.setItem("token", user.token);
    localStorage.setItem("userID", user.userID);
    localStorage.setItem("name", user.name);
    localStorage.setItem("nickName", user.nickName);

    let loginHistory = JSON.parse(localStorage.getItem("loginHistory")) || {};
    loginHistory[user.userID] = new Date().getTime().toString();
    localStorage.setItem("loginHistory", JSON.stringify(loginHistory));
    localStorage.setItem("userData", JSON.stringify(user));


    //  Sentry set user
    Sentry.setUser({
      id: user.userID,
      username: user.nickName,
    });

    await updateLocalStorage(user.userID);

    return true;
  } else {
    return false;
  }
};

export const updateLocalStorage = async (userID) => {

  if (!userID || userID == '') {
    return;
  }

  // local user list
  await configLocalUserList();

  // user access
  await configLocalUserAccessList(userID);

  // user job 
  await configLocalUserJobList(userID);

  // user local setting
  await configLocalUserLocalSetting(userID);

  // membership current company list
  await configLocalMembershipCurrentCompanyList();
}


export const isLoggedIn = () => {
  return localStorage.getItem("token") !== null;

};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const getUserId = () => {
  return localStorage.getItem("userID");
};

export const getNickname = () => {
  return localStorage.getItem("nickName");
};

export const getRights = () => {
  return localStorage.getItem("rights");
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("name");
  localStorage.removeItem("rights");
  localStorage.removeItem("userID");
  localStorage.removeItem("userList");
  localStorage.removeItem("systemUserLocalSetting");
  localStorage.removeItem("systemUserAccessList");
  localStorage.removeItem("userJobList");
  localStorage.removeItem("membershipCurrentCompanyList");


  //  Sentry set no user
  Sentry.configureScope((scope) => scope.setUser(null));
};

export const getLastLoginList = async () => {

  let loginHistory = JSON.parse(localStorage.getItem("loginHistory")) || {};
  let lastLoginList = [];
  let userList = await getUserList();

  for (const [loginUserId, loginTime] of Object.entries(loginHistory)) {
    let lastLoginUser = userList.find((user) => user.userID === loginUserId);
    lastLoginList.push({
      userID: loginUserId,
      rights: lastLoginUser.rights,
      nickName: lastLoginUser.nickName,
      loginTime: loginTime,
    });
  }
  //  Sort by login time: latest first
  lastLoginList.sort((a, b) => {
    if (a.loginTime > b.loginTime) return -1;
    if (a.loginTime < b.loginTime) return 1;
    return 0;
  });
  return lastLoginList;



};



export const getUserSelect2 = async () => {
  try {
    let response = await apiGet({ url: "auth/users", responseType: "" });
    if (response.status == 200) {
      response.data.data.forEach((obj) => renameKeys(obj, "userID", "id"));
      response.data.data.forEach((obj) => renameKeys(obj, "name", "text"));
      return response.data.data ?? [];
    }
  } catch (error) {
    return [];
  }
};

export const getSystemUserByID = async (id) => {
  try {
    if (id) {
      return api.get("/systemuser/getUserByID/" + id).then((response) => {
        try {

          if (response.data.code == 200) {
            return response.data.data;
          } else {
            return Notifications({
              type: "error",
              message: response.data.message,
            });
          }
        } catch (error) {
          return Notifications({ type: "error" });
        }
      });
    }
  } catch (error) {
    return [];
  }
};

export const getUserList = async () => {
  try {
    let response = await apiGet({
      url: "systemuser/getAllUsers",
      responseType: "",
    });

    return response.status == 200 ? response.data.data : [];
  } catch (error) {
    return [];
  }

};

export const storeUser = async (data) => {
  let response = await apiPost({
    url: "systemuser/addUser",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data.data.userID  // return the new userID
    : response.status;
};

export const updateUser = async (data) => {
  let response = await apiPut({
    url: "systemuser/updateUserInfo",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.status
    : response.status;
};

export const updateUserPassword = async (data) => {
  let response = await apiPut({
    url: "systemuser/updateUserPassword",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteUserByID = async (id) => {
  let response = await apiDelete({
    url: "systemuser/deleteUser/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const getUserStatus = () => {
  return [
    { id: "Active", text: "Active" },
    { id: "Inactive", text: "Inactive" },
  ];
};

export const getUserGender = () => {
  return [
    { id: "Male", text: "Male" },
    { id: "Female", text: "Female" },
    { id: "It", text: "IT" },
  ];
};

