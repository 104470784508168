import { uuid4 } from "@sentry/utils";
import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
} from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import Notifications from "../components/Notifications";

function CustomerModalPhoneEdit(props) {
  const { t } = useTranslation();
  const [newPhone, setNewPhone] = React.useState({
    phoneNumberID: uuid4(),
    phoneUserName: "",
    phoneNumber: "",
    phoneType: "whatsapp",
  });

  const { phoneNumberID, phoneUserName, phoneNumber, phoneType } = newPhone;

  const onInputChange = (e) => {
    setNewPhone({ ...newPhone, [e.target.name]: e.target.value });
  };

  const onEditPhone = (e) => {

    // check phone user name
    if (phoneUserName === "") {
      Notifications({
        type: "dataCheck",
        message: "Please input a user name"
      });
      return;
    }

    // check phone phone number
    if (phoneNumber === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a phone number")
      });
      return;
    }

      props.returnCustomerPhone(
        phoneNumberID,
        phoneUserName,
        phoneNumber,
        phoneType
      );


    setNewPhone({
      phoneNumberID: uuid4(),
      phoneUserName: "",
      phoneNumber: "",
      phoneType: "whatsapp",
    });

    props.onHide();
  };


  React.useEffect(() => {
    if (Object.values(props.customerPhonesData).length > 0) {
      setNewPhone({
        phoneNumberID: props.customerPhonesData.phoneNumberID,
        phoneUserName: props.customerPhonesData.phoneUserName,
        phoneNumber: props.customerPhonesData.phoneNumber,
        phoneType: props.customerPhonesData.phoneType,
      });
    } else {
      setNewPhone({
        phoneNumberID: uuid4(),
        phoneUserName: "",
        phoneNumber: "",
        phoneType: "whatsapp",
      });
    }
  }, [props.show]);

  return (
    <>
      <Modal
        isOpen={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <ModalHeader toggle={props.onHide}><Trans>{props.title}</Trans></ModalHeader>
        <ModalBody>
          <Form>

            <InputTypeTextForm
              label={`${t("Name")}${"("}${t("Required")}${")"}`}
              labelFor="phoneUserName"
              inputName="phoneUserName"
              inputId="phoneUserName"
              inputPlaceholder={t("Name")}
              value={phoneUserName}
              handleChange={(e) => onInputChange(e)}
              required={true}
            />
            <InputTypeTextForm
              label={`${t("Phone Number")}${"("}${t("Required")}${")"}`}
              labelFor="phoneNumber"
              inputName="phoneNumber"
              inputId="phoneNumber"
              inputPlaceholder={t("Phone Number")}
              value={phoneNumber}
              handleChange={(e) => onInputChange(e)}
              required={true}
            />
            <InputTypeSelect2Form
              useTranslate={true}
              data={[
                { id: "main", text: "Main" },
                { id: "company", text: "Company" },
                { id: "whatsapp", text: "Whatsapp" },
                { id: "wechat", text: "Wechat" },
                { id: "telegram", text: "Telegram" },
                { id: "messenger", text: "Messenger" },
                { id: "line", text: "Line" },
              ]}
              value={phoneType}
              nameSelect2="phoneType"
              idSelect2="phoneType"
              label={t("Type")}
              handleOnChangeSelect={(e) => onInputChange(e)}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onEditPhone} type="button">
            {t('Confirm')}
          </Button>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CustomerModalPhoneEdit;
