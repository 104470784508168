import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateGood } from "functions/Goods";
import { updateGoodInventory } from "functions/GoodInventory.js";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess.js";
import { deleteGoodInventory } from "functions/GoodInventory.js";
import Notifications from "../components/Notifications.js";

function GoodInventoryModalEdit(props) {
  const { t } = useTranslation();


  const [goodInventoryData, setGoodInventoryData] = React.useState({
    storeID: "",
    currentInventory: 0,
    minInventory: 0,
    maxInventory: 0,
    goodID: ""
  });

  const [storeData, setStoreData] = React.useState({});
  // "storeID": "9386264E-02CC-4290-ABBC-885F52D061BB",
  // "storeName": "觀塘店",
  // "storeType": "地舖",
  // "physicalAddress": "觀塘祟仁街9號地舖",

  const [goodData, setGoodData] = React.useState({});

  const {
    storeID,
    goodID,
    currentInventory,
    minInventory,
    maxInventory
  } = goodInventoryData;

  const onInputChange = (e) => {
    setGoodInventoryData({ ...goodInventoryData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let update = await updateGoodInventory(storeID, goodID, currentInventory, minInventory, maxInventory);
    update.code == 200 ? await props.refreshTable() : true;
    props.onHide();
  };

  React.useEffect(() => {
    if (props.goodData && Object.values(props.goodData).length > 0) {
      setGoodData(props.goodData);
    } else {
      setGoodData({});
    }

    if (props.storeData && Object.values(props.storeData).length > 0) {
      setStoreData(props.storeData);
    } else {
      setStoreData({});
    }

    if (props.goodInventoryData && Object.values(props.goodInventoryData).length > 0) {
      try {
        setGoodInventoryData({
          storeID: props.goodInventoryData.storeID,
          goodID: props.goodInventoryData.goodID,
          currentInventory: props.goodInventoryData.currentInventory,
          minInventory: props.goodInventoryData.minInventory,
          maxInventory: props.goodInventoryData.maxInventory
        });
      } catch (error) {
        setGoodInventoryData({
          storeID: "",
          goodID: "",
          currentInventory: 0,
          minInventory: 0,
          maxInventory: 0
        });

      }
    } else {
      setGoodInventoryData({
        storeID: "",
        goodID: "",
        currentInventory: 0,
        minInventory: 0,
        maxInventory: 0
      });
    }

  }, [props.show, props.goodData, props.storeData, props.goodInventoryData]);


  // Delete Confirmation
  async function deleteConfirmation(goodID, storeID) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: goodID,
      callback: (goodToReturn) => { deleteGood(goodToReturn, storeID) },
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteGood(goodID, storeID) {
    let deleteResponse = await deleteGoodInventory(goodID, storeID);

    deleteResponse.code == 200 ? closeThisModal() : true;
  }

  async function closeThisModal() {
    props.refreshTable();
    props.onHide();
  }

  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody className="mt--4">
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Inventory")}${"("}${t("Required")}${")"}`}
                  labelFor="currentInventory"
                  inputName="currentInventory"
                  inputId="currentInventory"
                  inputPlaceholder={t("Inventory")}
                  value={currentInventory}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>

            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Min inventory")}${"("}${t("Required")}${")"}`}
                  labelFor="minInventory"
                  inputName="minInventory"
                  inputId="minInventory"
                  inputPlaceholder={t("Min inventory")}
                  value={minInventory}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Max inventory")}${"("}${t("Required")}${")"}`}
                  labelFor="maxInventory"
                  inputName="maxInventory"
                  inputId="maxInventory"
                  inputPlaceholder={t("Max inventory")}
                  value={maxInventory}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={`${t("Item Name")}`}
                  labelFor="goodDescription"
                  inputName="goodDescription"
                  inputId="goodDescription"
                  inputPlaceholder={t("Item Name")}
                  value={goodData.goodDescription ? goodData.goodDescription : ""}
                  handleChange={(e) => { }}
                  readOnly={true}
                />
              </Col>

            </Row>
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={`${t("Store Name")}`}
                  labelFor="storeName"
                  inputName="storeName"
                  inputId="storeName"
                  inputPlaceholder={t("Store Name")}
                  value={storeData.storeName ? storeData.storeName : ""}
                  handleChange={(e) => { }}
                  readOnly={true}
                />
              </Col>

            </Row>
          </ModalBody>
          <ModalFooter className="mt--4">
            <div className="w-100 d-flex justify-content-between">
              <div >
                <Button
                  color="danger"
                  type="button" // type="button" means the form will not submit
                  onClick={(e) => {
                    e.preventDefault();
                    deleteConfirmation(goodID, storeID);

                  }}
                >
                  {t("Delete inventory")}
                  <span>{getAccessibleTagForSafeMode('goodInventoryDeleteInventory')}</span>

                </Button>
              </div>
              <div className="">

                <Button color="primary" type="submit">
                  {props.button}
                </Button>
                <Button color="default" type="button" onClick={props.onHide}>
                  {t("Cancel")}
                </Button>
              </div>

            </div>

          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default GoodInventoryModalEdit;
