
import api, {apiPost, apiDelete, apiPut } from "./Api";
import renameKeys from "./RenameKey";
import Notifications from "views/pages/components/Notifications";



export const addUserJobMatch = async (userID, job, jobRemark) => {
    let response = await apiPost({
        url: "systemuserjob/matchUserJob",
        data: {
            userID: userID,
            job: job,
            jobRemark: jobRemark,
        },
        responseType: "",
      });
      return response.status == 200
        ? response.data
        : response.status;
};

export const updateUserJobMatch = async (userID, job, jobRemark) => {
    let response = await apiPut({
        url: "systemuserjob/updateUserJob",
        data: {
            userID: userID,
            job: job,
            jobRemark: jobRemark,
        },
        responseType: "",
      });
      return response.status == 200
        ? response.data
        : response.status;
};

export const deleteUserJobMatch = async (userID, job) => {
    let response = await apiDelete({
      url: `systemuserjob/unmatchUserJob/${userID}/${job}`,
      responseType: "",
    });
    return response.status == 200
      ? response.data
      : response.status;
  };



