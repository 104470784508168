import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";



export const getAllCustomerSelect2 = async () => {
  try {
    return api.get("/customers/getCustomers").then((response) => {
      try {
        if (response.data.code == 200) {
          let resultArray = [];
          response.data.data.rows.forEach((obj) => {

            resultArray.push({
              id: obj.customerID,
              text: obj.customerName,
              obj: obj
            });
            //  renameKeys(obj, "customerID", "id");
            //    renameKeys(obj, "customerName", "text");
          }
          );
          return resultArray;
        } else {
          return Notifications({ type: "error", message: response.data.message });
        }
      } catch (error) {
        return Notifications({ type: "error" });
      }
    });
  } catch (error) {
    return [];
  }
};

export const getCustomerByID = async (id) => {
  try {
    if (id) {
      return api.get("/customers/getCustomer/" + id).then((response) => {
        try {
          if (response.data.code == 200) {
            return response.data.data;
          } else {
            return Notifications({
              type: "error",
              message: response.data.message,
            });
          }
        } catch (error) {
          return Notifications({ type: "error" });
        }
      });
    }

  } catch (error) {
    return [];
  }
};

export const getCustomerDatatable = async () => {
  try {
    let response = await apiGet({
      url: "customers/getCustomers",
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const getAllCustomerWithUnpaidInvoice = async () => {
  try {
    let response = await apiGet({
      url: "customers/getAllCustomerWithUnpaidInvoice",
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const storeCustomer = async (data) => {
  let response = await apiPost({
    url: "customers/addCustomerWithPhone",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};


// Dont need the phoneNumberID 
// This api will create a uuid in the backend
export const addCustomerPhone = async (phoneUserName, phoneNumber, customerID, phoneType) => {
  let response = await apiPost({
    url: "customers/addCustomerPhone",
    data: {
      phoneUserName: phoneUserName,
      phoneNumber: phoneNumber,
      customerID: customerID,
      phoneType: phoneType
    },
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const updateCustomer = async (data) => {
  let response = await apiPut({
    url: "customers/updateCustomerWithPhone",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteCustomerByID = async (id) => {
  let response = await apiDelete({
    url: "customers/deleteCustomer/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteCustomerPhoneByPhoneNumberID = async (phoneNumberID, customerID) => {
  let response = await apiDelete({
    url: `customers/deleteCustomerPhone/${phoneNumberID}/${customerID}`,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const getPhoneType = (type) => {
  if (type == "whatsapp") {
    return <i className="fab fa-whatsapp"></i>;
  } else if (type == "wechat") {
    return <i className="fab fa-weixin"></i>;
  } else if (type == "telegram") {
    return <i className="fab fa-telegram"></i>;
  } else if (type == "messenger") {
    return <i className="fab fa-facebook-messenger"></i>;
  } else if (type == "line") {
    return <i className="fab fa-line"></i>;
  } else {
    return "";
  }
};

export const updateObjectCustomer = (id, customerObject, updatedCustomer) => {
  if (typeof customerObject === "object" && customerObject !== null) {
    return customerObject.map((data) =>
      data.phoneNumberID === id ? updatedCustomer : data
    );
  }
};

export const deleteObjectCustomer = (id, customerObject) => {
  if (typeof customerObject === "object" && customerObject !== null) {
    return customerObject.filter(function (obj) {
      return obj.phoneNumberID != id;
    });
  }
};
