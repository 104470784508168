import React, { useState } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { createDeliveryTeam } from "functions/DeliveryTeam.js";
import Notifications from "../components/Notifications";
import DropdownControl from "../components/DropdownControl.js";
import { getDriverSelect2 } from "functions/Driver.js";
import { getCarSelect2 } from "functions/Car.js";
import { getAllTruckWorker } from "functions/TruckWorker.js";



function DeliveryTeamCreateModal(props) {
    const { t } = useTranslation();

    // POST data
    const [deliveryTeamName, setDeliveryTeamName] = React.useState("");
    const [driverID, setDriverID] = React.useState("");
    const [carID, setCarID] = React.useState("");
    // truckWorkerIDList = [{truckWorkerID: '1231'}, {truckWorkerID: 'sdsaf'}, ....]
    const [truckWorkerIDList, setTruckWorkerIDList] = React.useState([]);

    // choose data
    const [carChooseData, setCarChooseData] = React.useState([]);
    const [driverChooseData, setDriverChooseData] = React.useState([]);
    //  system user to choose
    const [allTruckWorkerInitialArray, setAllTruckWorkerInitialArray] = React.useState([]);

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    // get the data for the choice
    React.useEffect(() => {
        const getDriver = async () => {
            let driverData = await getDriverSelect2();
            setDriverChooseData(driverData);
        };
        const getCar = async () => {
            let car = await getCarSelect2();
            setCarChooseData(car);
        };
        if (props.show) {
            getDriver();
            getCar();
            getAllTruckWorkerSelect2();
        }
    }, [props.show]);

    const getAllTruckWorkerSelect2 = async () => {
        let truckWorkerData = await getAllTruckWorker();
        setAllTruckWorkerInitialArray(truckWorkerData);
    }



    const isTruckWorkerIDSelected = (truckWorkerID) => {
        if (!truckWorkerIDList || !Array.isArray(truckWorkerIDList) || truckWorkerIDList.length <= 0) {
            // default not selected
            return false;
        }
        return truckWorkerIDList.some((val) => val.truckWorkerID === truckWorkerID);
    }


    const onSelectTruckWorkerID = (truckWorkerID) => {
        if (!truckWorkerIDList || !Array.isArray(truckWorkerIDList)) {
            return;
        }

        let arrayToChange = truckWorkerIDList;
        if (isTruckWorkerIDSelected(truckWorkerID)) {
            // unSelect
            let resultArray = arrayToChange.filter((item) => { return item.truckWorkerID != truckWorkerID });
            setTruckWorkerIDList([...resultArray]);
        } else {
            setTruckWorkerIDList([...arrayToChange, {
                truckWorkerID: truckWorkerID
            }]);
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault();


        // Data check 
        if (!driverID || driverID === '') {
            Notifications({
                type: "dataCheck",
                message: t("Please choose driver")
            });
            return;
        }

        if (!carID || carID === '') {
            Notifications({
                type: "dataCheck",
                message: t("Please choose car")
            });
            return;
        }

        if (!truckWorkerIDList || !Array.isArray(truckWorkerIDList) || truckWorkerIDList.length < 0) {
            Notifications({
                type: "dataCheck",
                message: t("Please choose truck worker")
            });
            return;
        }


        let deliveryTeamIDReturn = await createDeliveryTeam(deliveryTeamName, driverID, carID, truckWorkerIDList);



        if (deliveryTeamIDReturn && deliveryTeamIDReturn !== '') {
            setFinishSubmitBoo(true);
            clearForm();
            props.refreshTable();
            props.onHide();
        }
    };

    const clearForm = () => {
        setDeliveryTeamName("");
        setDriverID("");
        setCarID("");
        setTruckWorkerIDList([]);

        setFinishSubmitBoo(false);
    };


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{props.title}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col md="12">
                                <InputTypeTextForm
                                    label={`${t("Team name")}${"("}${t("Required")}${")"}`}
                                    labelFor="deliveryTeamName"
                                    inputName="deliveryTeamName"
                                    inputId="deliveryTeamName"
                                    inputPlaceholder={t("Team name")}
                                    value={deliveryTeamName}
                                    handleChange={(e) => setDeliveryTeamName(e.target.value)}
                                    required={true}
                                />
                            </Col>

                        </Row>


                        <Row className="mt-2">
                            <Col>
                                <DropdownControl
                                    disabled={false}
                                    defaultColor="primary"
                                    dropdownName={t("Car")}
                                    useTranslate={false}
                                    arrayIDTextFormat={carChooseData}
                                    valueID={carID}
                                    onSelectValueID={async (valueID) => {
                                        // For this button to update the text (value)
                                        setCarID(valueID);

                                        // Approach changed
                                        // Fast mode 
                                        // Just directly update the DB
                                        //     await updateCarIDToServer(valueID);

                                        // Get the data from DB
                                        // Refresh the whole form
                                        //  getDeliveryRecordFromDB();
                                    }}
                                />

                                <DropdownControl
                                    disabled={false}
                                    defaultColor="primary"
                                    dropdownName={t("Driver")}
                                    useTranslate={false}
                                    arrayIDTextFormat={driverChooseData}
                                    valueID={driverID}
                                    onSelectValueID={async (valueID) => {
                                        // For this button to update the text (value)
                                        setDriverID(valueID);

                                        // Approach changed
                                        // Fast mode 
                                        // Just directly update the DB
                                        // await updateDriverIDToServer(valueID);

                                        // Get the data from DB
                                        // Refresh the whole form
                                        // getDeliveryRecordFromDB();
                                    }}
                                />

                            </Col>
                        </Row>


                        <Row className="mt-2">
                            <Col>
                                <div>
                                    {allTruckWorkerInitialArray && Array.isArray(allTruckWorkerInitialArray) && allTruckWorkerInitialArray.length >= 1 &&
                                        allTruckWorkerInitialArray.map((item, index) => {
                                            return <div key={index}>
                                                <Badge
                                                    key={index}
                                                    className="mx-1 my-1 statusBadge"
                                                    color={isTruckWorkerIDSelected(item.truckWorkerID) ? 'default' : 'secondary'}
                                                    onClick={() => {
                                                        onSelectTruckWorkerID(item.truckWorkerID);
                                                    }}>
                                                    {item.SystemUser.nickName}{' '}{'('}{item.truckWorkerID}{')'}{' '}{item.truckWorkerRemark === '' ? '' : '(' + item.truckWorkerRemark + ')'}

                                                </Badge>
                                            </div>

                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>

                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default DeliveryTeamCreateModal;
