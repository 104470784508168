import React from "react";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  div,
  Form,
  Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datetime/css/react-datetime.css";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Checkbox } from "react-inputs-validation";


//props
function InputDateGroup(props) {
  return (
    <div>
      <InputGroup className="input-group-alternative">
        {props.label === "" &&
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
        }
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            {props.label !== "" ?
              <> {props.label}</>
              : <></>}
          </InputGroupText>
        </InputGroupAddon>

        <ReactDatetime
          className="w-70"
          style={{ cursor: "pointer" }}
          inputProps={{
            placeholder: props.inputPlaceholder,
            id: props.inputId,
            name: props.inputName,
            disabled: props.readOnly
          }}
          dateFormat={props.dateFormat ? props.dateFormat : "YYYY-MM-DD ddd"}
          timeFormat={false}
          value={props.value ? props.value : ""}
          onChange={(date) => {
            props.onDateChange(new Date(date));
          }}
          open={props.open}
          closeOnSelect={true}
          initialViewMode='days'
          updateOnView="days"
          input={true}
        />



      </InputGroup>
    </div>
  );
}

export default InputDateGroup;
