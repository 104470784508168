import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Card,
    CardBody,
    CardFooter,
    FormGroup,
    Label,
    Nav,
    NavLink,
    NavItem,
    Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";


import { getAllDeliveryAddress, combineAddress, deleteAddressByID } from "functions/DeliveryAddress";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { paginationTable } from "functions/PaginationTable";
import { isAccessible } from "functions/SystemUserAccess";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { lockScrolling } from "functions/Utility";
import { getAllStore } from "functions/Store";
import { deleteStoreByStoreID } from "functions/Store";
import StoreCreateModal from "./StoreCreateModal";
import StoreEditModal from "./StoreEditModal";



function StoreScreen(props) {
    const { t } = useTranslation();
    const pagination = paginationTable();
    const { SearchBar } = Search;

    const [storeData, setStoreData] = React.useState([]);
    const [selectedStore, setSelectedStore] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(0);

    // GUI
    const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

    //  modal
    const [modalCreateStoreIsOpen, setModalCreateStoreIsOpen] = React.useState(false);
    const [modalEditStoreIsOpen, setModalEditStoreIsOpen] = React.useState(false);



    React.useEffect(() => {
        // Pull to refresh must be disabled if opened some medal
        // Otherwise will lock the screen of the modal
        setPullToRefreshDisable(modalCreateStoreIsOpen || modalEditStoreIsOpen);
        lockScrolling(modalCreateStoreIsOpen || modalEditStoreIsOpen)
    }, [modalCreateStoreIsOpen, modalEditStoreIsOpen]);


    // Call API to get all data
    React.useEffect(() => {
        getData();
    }, [refreshTable]);


    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const getData = async () => {
        setIsLoading(true);
        let storeData = await getAllStore();

        setStoreData(storeData);
        setIsLoading(false);
    };

    // Delete Confirmation
    async function deleteConfirmation(storeID) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: storeID,
            callback: deleteStore,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteStore(storeID) {
        setIsLoading(true);
        let deleteResponse = await deleteStoreByStoreID(storeID);
        setIsLoading(false);
        deleteResponse.code == 200 ? refreshTableData() : true;
    }




    return (isLoggedIn() && isAccessible(props.accessID)) ? (
        <div>
            {isLoading ? <SpinnerOverlay /> : ""}

            <ScreenPullToRefresh
                onRefresh={async () => {
                    await getData();
                }}
                disabled={pullToRefreshDisable}>
                <SimpleHeader parentName={t("")} icon="fas fa-map" />
            </ScreenPullToRefresh>
            <Container style={{ marginTop: "-4rem" }} fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <ToolkitProvider
                                    data={storeData}
                                    keyField="storeID"
                                    columns={
                                        [
                                            { dataField: "storeName", text: "", hidden: true },
                                            { dataField: "storeType", text: "", hidden: true },
                                            { dataField: "physicalAddress", text: "", hidden: true },
                                            {
                                                dataField: "combined",
                                                text: "",
                                                formatter: (cellContent, row, rowIndex, extraData) => {
                                                    return (
                                                        <div
                                                            className="ml--3 mr--3"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {/* First row */}
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1"
                                                                    onClick={() => {
                                                                        setSelectedStore(row);
                                                                        setModalEditStoreIsOpen(true);
                                                                    }}>
                                                                    <div className="tableRowTitle">
                                                                        {row.storeName}{'('}{row.storeType}{')'}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <a
                                                                        className="table-action table-action-delete"
                                                                        id="tooltip12475020"
                                                                        onClick={() =>
                                                                            deleteConfirmation(row.storeID)
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        <i
                                                                            className="fas fa-trash fa-lg"
                                                                            data-id={cellContent}
                                                                        />
                                                                    </a>
                                                                </div>

                                                            </div>
                                                            
                                                            {/* Second row */}
                                                            <div
                                                                onClick={() => {
                                                                    setSelectedStore(row);
                                                                    setModalEditStoreIsOpen(true);
                                                                }}>
                                                                <div className="tableRowRemark">
                                                                    {row.physicalAddress}
                                                                </div>
                                                                <div className="tableRowSubSubTitle">
                                                                    {row.storeID}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            },

                                        ]
                                    }
                                    search
                                >
                                    {(props) => (
                                        <div className="table-responsive table-sm">
                                            <Row className="pb-2">
                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                    <SearchBar
                                                        className=""
                                                        placeholder={t("Search")}
                                                        {...props.searchProps}
                                                    />
                                                </Col>
                                                <Col
                                                    className={"text-right"}
                                                    xs={5}
                                                    sm={5}
                                                    md={5}
                                                    lg={5}
                                                >
                                                    <Button
                                                        color="primary"
                                                        id="tooltip443412080"
                                                        onClick={() => setModalCreateStoreIsOpen(!modalCreateStoreIsOpen)}
                                                        size="md"
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-plus" />
                                                        </span>
                                                        <span className="btn-inner--text">{t("Add")}</span>
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={1}
                                                        target="tooltip443412080"
                                                    >
                                                        {t("Create")}
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </Row>

                                            <BootstrapTable
                                                headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <StoreCreateModal
                show={modalCreateStoreIsOpen}
                onHide={(e) => {
                    setModalCreateStoreIsOpen(false);
                }}
                title={t("Create new store")}
                button={t("Add")}
                refreshTable={refreshTableData}
            />

            <StoreEditModal
                show={modalEditStoreIsOpen}
                onHide={(e) => {
                    setModalEditStoreIsOpen(false);
                }}
                storeData={selectedStore}
                title={t("Update store")}
                button={t("Save")}
                refreshTable={refreshTableData}
            />

        </div>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default StoreScreen;
