import React from "react";
import { Trans } from "react-i18next";
// reactstrap components
import {
    Button
} from "reactstrap";


// props

function SubmitButton(props) {

    const [submitting, setSubmitting] = React.useState(false);
    const [submmited, setSubmitted] = React.useState(false);

    const [buttonDisable, setButtonDisable] = React.useState(false);

    React.useEffect(() => {
        setButtonDisable(submitting || submmited);
    }, [submitting, submmited]);

    const handleSubmitLocal = async (e) => {
        setSubmitting(true);
        setSubmitted(false);
        if (await props.handleSubmit(e)) {
            setSubmitting(false);
            setSubmitted(true);
        } else {
            setSubmitting(false);
            setSubmitted(false);
        };
    };




    return (
        <Button
            disabled={buttonDisable}
            color={submitting ? 'warning' : 'success'}
            onClick={(e) => { handleSubmitLocal(e) }}
        >
            <Trans>{submitting ? 'Loading' : submmited ? 'Finish' : props.buttonNormalText}</Trans>
        </Button >
    );
}

export default SubmitButton;
