import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    Badge,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardTitle
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";

// core components
import Dropzone from "dropzone";
import { getPaymentMethodColor } from "functions/Payment";
import { getDateStringWithoutTime } from "functions/Utility";
import { getPaymentByDateRangeAndPaymentMethod } from "functions/Payment";
import PaymentStatCard from "../components/PaymentStatCard";
import PaymentCard from "../payment/PaymentCard";
import PaymentEditModal from "../payment/PaymentEditModal";
import PaymentMessageModal from "../payment/paymentMessageModal";
Dropzone.autoDiscover = false;


// props.startDate
// props.endDate
// props.paymentMethod

function SalesAccountingPaymentListModal(props) {

    const startDate = props.startDate;
    const endDate = props.endDate;
    const paymentMethod = props.paymentMethod;


    const { t } = useTranslation();
    //Initialize Get Set

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [paymentListData, setPaymentListData] = React.useState([]);


    const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
    const [sortDateSmall, setSortDateSmall] = React.useState(true);

    // for modal
    const [selectedPaymentData, setSelectedPaymentData] = React.useState(null);
    const [modalPaymentEditIsOpen, setModalPaymentEditIsOpen] = React.useState(false);

    const [modalPaymentMessageIsOpen, setModalPaymentMessageIsOpen] = React.useState(false);

    // For filter control show
    const [showFullCard, setShowFullCard] = React.useState(false);

    //Initialize Effect
    React.useEffect(() => {
        const getPaymentListData = async () => {
            let paymentListData = await getPaymentByDateRangeAndPaymentMethod(startDate, endDate, paymentMethod);
            setPaymentListData(paymentListData);
        };

        if (props.show) {
            getPaymentListData();
        }
    }, [props.show, refreshTable]);


    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const sortPrice = () => {
        setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = paymentListData.sort((a, b) => a.paymentPrice - b.paymentPrice);
        } else {
            res = paymentListData.sort((a, b) => b.paymentPrice - a.paymentPrice);
        }
        setPaymentListData(res);
        setSortPriceSmall(!sortPriceSmall);
    };

    const sortDate = () => {
        setSortPriceSmall(true);
        let res = [];
        if (!sortDateSmall) {
            res = paymentListData.sort(
                (a, b) =>
                    new Date(a.paymentRecordDateTime) - new Date(b.paymentRecordDateTime)
            );
        } else {
            res = paymentListData.sort(
                (a, b) =>
                    new Date(b.paymentRecordDateTime) - new Date(a.paymentRecordDateTime)
            );
        }
        setPaymentListData(res);
        setSortDateSmall(!sortDateSmall);
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
               <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
                    <div>
                        <span> {t("Payment")}</span>
                    </div>

                    <div className="mt-1">
                        <Badge color={getPaymentMethodColor(paymentMethod)} className="statusBadge"><Trans>{paymentMethod}</Trans></Badge>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge"><Trans>Start</Trans>:{getDateStringWithoutTime(startDate, true)}</Badge>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge"><Trans>End</Trans>:{getDateStringWithoutTime(endDate, true)}</Badge>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {/* Stat */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <PaymentStatCard
                                paymentListData={paymentListData}>
                            </PaymentStatCard>
                        </Col>

                    </Row>
                    {/* filter Card */}
                    <Row>
                        <Col>
                            <Card className="card-stats">
                                <CardHeader
                                    role="tab">
                                    <Row>
                                        <Col className="col-auto pr-0">
                                            <Button
                                                onClick={() => { setShowFullCard(!showFullCard) }}
                                                className="mt-1"
                                                color="primary">
                                                <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                                            </Button>

                                            <Button onClick={sortPrice} className="mt-1" color="primary">
                                                {t("Price")}{" "}
                                                <i
                                                    className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"
                                                        }`}
                                                />
                                            </Button>

                                            <Button onClick={sortDate} className="mt-1" color="primary">
                                                {t("Date")}{" "}
                                                <i
                                                    className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"
                                                        }`}
                                                />
                                            </Button>
                                        </Col>

                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>

                    {/* Invoice list */}
                    <Row>
                        {paymentListData && paymentListData.length != 0 && paymentListData.map((paymentItem, index) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                data-id={paymentItem.paymentID}
                                key={paymentItem.paymentID}
                            >

                                <PaymentCard
                                    showFullCard={showFullCard}
                                    paymentData={paymentItem}
                                    onClickCard={(selectedPaymentItem) => {
                                        setSelectedPaymentData(selectedPaymentItem);
                                        setModalPaymentEditIsOpen(true);
                                    }}
                                    isSelectedCard={false}
                                    clickPaymentMessage={(paymentData) => {
                                        setSelectedPaymentData(paymentData);
                                        setModalPaymentMessageIsOpen(true);
                                    }}
                                />

                            </Col>
                        ))}
                        {paymentListData && paymentListData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Payment Found!")}
                                        </strong>
                                        <h3 className="text-info">
                                            {t("Please Check Your Filter")} </h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    {/* End of Invoice list */}

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="default"
                        type="button"
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        {t("Back")}
                    </Button>
                </ModalFooter>
            </Modal>

            <PaymentEditModal
                show={modalPaymentEditIsOpen}
                onHide={() => setModalPaymentEditIsOpen(false)}
                title={t("Payment Detail")}
                button={t("Save")}
                refresh={refreshTableData}
                selectedData={selectedPaymentData}
            />
            <PaymentMessageModal
                show={modalPaymentMessageIsOpen}
                onHide={() => setModalPaymentMessageIsOpen(false)}
                title={t("Payment Message")}
                refreshTable={() => { }}
                paymentData={selectedPaymentData}
            />
        </>
    );
}

export default SalesAccountingPaymentListModal;
