import React from "react";
import PullToRefresh from "rmc-pull-to-refresh";
import {Row, Spinner} from "reactstrap";

function ScreenPullToRefresh(props) {
    const {disabled} = props;
    const [isRefreshing, setIsRefreshing] = React.useState(false);

   
  React.useEffect(() => {
    setIsRefreshing(disabled);
  }, [disabled]);
 
// damping means how much can the user pull down
// distanceToRefresh means how long to trigger the refresh

    return (
        disabled ? <div style={{marginTop: "0px"}}>
            {props.children}
        </div> : <PullToRefresh
         color="info"
            direction="down"
            getScrollContainer={() => document.body}
            refreshing={isRefreshing}
            onRefresh={async () => {
                setIsRefreshing(true);
                await props.onRefresh();
                setIsRefreshing(false);
            }}
            indicator={{
                activate: (
                    <Row className="p-2 mb-0" color="info">
                        <i className="fas fa-angle-double-down w-100 text-center text-primary"
                           style={{fontSize: "32px" }}/>
                    </Row>
                ),
                deactivate: (
                    <Row className="p-0 mb-0" color="info">
                        <i className="fas fa-angle-double-down w-100 text-center text-primary"
                           style={{fontSize: "0px"}}/>
                    </Row>
                ),
                release: (
                    <Row className="p-0 mb-0 justify-content-center"  color="info">
                        <Spinner className="text-center" color="primary"/>
                    </Row>
                ),
                finish: (
                    <Row className="p-0 mb-0" color="info">
                        <i className="fas fa-check w-100 text-center text-info"
                           style={{fontSize: "0px"}}/>
                    </Row>
                ),
            }}
            distanceToRefresh={40}
            damping={150}> 
            {props.children}
        </PullToRefresh>
    );
}

export default ScreenPullToRefresh;