import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
    Badge
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateGood } from "functions/Goods";
import { updateStore } from "functions/Store.js";
import { StorageRetryPolicyType } from "@azure/storage-blob";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { getAllUserJob } from "functions/UserJob.js";
import { updateJobArrayToAccessID } from "functions/SystemAccess.js";


// props
// systemAccessData: include accessID / SystemAccessJobMatches


function SystemAccessMatchJobModal(props) {
    const { t } = useTranslation();


    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [accessID, setAccessID] = React.useState('');
    const [systemAccessJobMatchArray, setSystemAccessJobMatchArray] = React.useState([]);
    const [allJobArray, setAllJobArray] = React.useState([]);

    const getAllJobDataLocal = async () => {
        let jobData = await getAllUserJob();
        setAllJobArray(jobData);
    }

    React.useEffect(() => {

        if (props.show) {

            setAccessID(props.systemAccessData.accessID);
            setSystemAccessJobMatchArray(props.systemAccessData.SystemAccessJobMatches);
            getAllJobDataLocal();

            setFinishSubmitBoo(false);
        }
    }, [props.show]);


    const isJobSelected = (job) => {
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray) || systemAccessJobMatchArray.length <= 0) {
            // default not selected
            return false;
        }
        return systemAccessJobMatchArray.some((val) => val.job === job);
    }


    const onSelectJob = (job) => {
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray)) {
            return;
        }

        let arrayToChange = systemAccessJobMatchArray;
        if (isJobSelected(job)) {
            // unSelect
            let resultArray = arrayToChange.filter((item) => { return item.job != job });
            setSystemAccessJobMatchArray([...resultArray]);
        } else {
            setSystemAccessJobMatchArray([...arrayToChange, {
                job: job,
                accessID: accessID
            }]);
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Data check
        if (!accessID || accessID == '') {
            return;
        }

        // Accept the case when array.length = 0 
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray)) {
            return;
        }


        let jobArrayToSubmit = [];
        systemAccessJobMatchArray.forEach((item) => {
            jobArrayToSubmit.push({
                job: item.job
            })
        })

        let dataToSubmit = {
            accessID: accessID,
            jobArray: jobArrayToSubmit
        }

        
        let response = await updateJobArrayToAccessID(dataToSubmit);

        if (response.code == 200) {
            props.refreshTable();
            props.onHide();
        }

    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t('Match Job')}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col>
                                <div className="tableRowTitle">
                                    {accessID}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    {allJobArray && Array.isArray(allJobArray) && allJobArray.length >= 1 &&
                                        allJobArray.map((item, index) => {
                                            return <Badge
                                                key={index}
                                                className="mx-1 my-1 statusBadge"
                                                color={isJobSelected(item.job) ? 'default' : 'secondary'}
                                                onClick={() => {
                                                    onSelectJob(item.job);
                                                }}>
                                                <Trans>{item.job}</Trans>
                                            </Badge>
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Save"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default SystemAccessMatchJobModal;
