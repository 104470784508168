import React from "react";
import {
  Label,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import classnames from "classnames";

function InputTypeGroupTextForm(props) {
  return (
    <FormGroup>
      <Label for={props.labelFor} className="form-control-label">
        {props.label}
      </Label>
      <InputGroup
        className={classnames("input-group-merge", {
          focused: props.value,
        })}
      >
        {props.prepend ? (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {props.prependIcon ? (
                <i className={props.prependIconClass} />
              ) : (
                props.prependText
              )}
            </InputGroupText>
          </InputGroupAddon>
        ) : (
          <></>
        )}
        <Input
          type={props.type ?? "text"}
          rows={props.type == "textarea" ? 5 : 1}
          name={props.inputName}
          id={props.inputId}
          placeholder={props.inputPlaceholder}
          value={props.value}
          onChange={props.handleChange}
          readOnly={props.readOnly ?? false}
        />
        {props.append ? (
          <InputGroupAddon addonType="append">
            <InputGroupText>
              {props.appendIcon ? (
                <i className={props.appendIconClass} />
              ) : (
                props.appendText
              )}
            </InputGroupText>
          </InputGroupAddon>
        ) : (
          <></>
        )}
      </InputGroup>
    </FormGroup>
  );
}

export default InputTypeGroupTextForm;
