import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Col,
  Label,
  Input,
  Row,
  FormGroup,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";
import InputTypeTextForm from "../components/InputTypeTextForm.js";

import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { editPaymentWithMatch } from "functions/Payment.js";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import Notifications from "../components/Notifications.js";
import { getPaymentByID } from "functions/Payment.js";
import { deletePayment, deletePaymentPhoto } from "functions/Payment";
import { addPaymentPhoto } from "functions/Payment";
import { getPaymentPhoto } from "functions/Payment";
import { combineAddress } from "functions/Delivery";
import { getBase64PhotoHeadRemoved } from "functions/Utility";
import PhotoUploadArea from "../components/PhotoUploadArea";


import { isFileImage } from "functions/Utility";
import { getPaymentMethodArray } from "functions/Payment";
import CustomerSelectInput from "../components/CustomerSelectInput";

import InvoiceSearchInvoiceModal from "../invoice/invoiceSearchInvoiceModal.js";


import moment from "moment";
import DropdownControl from "../components/DropdownControl.js";
import SpinnerOverlay from "../components/SpinnerOverlay";
import { isAccessible } from "functions/SystemUserAccess.js";
import InvoiceListToPayMessageArea from "../components/InvoiceListToPayMessageArea.js";
import PaymentMessageModal from "./paymentMessageModal.js";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess.js";

function PaymentEditModal(props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentInvoiceMatchesList, setPaymentInvoiceMatchesList] = useState([]);
  const [invoiceListForMessage, setInvoiceListForMessage] = useState([]);

  const [rerender, setRerender] = useState(false);
  const [selectedCustomerID, setSelectedCustomerID] = useState("");
  const [paymentData, setPaymentData] = useState(null);
  const [paymentRemark, setPaymentRemark] = useState("");
  const [paymentRefNumber, setPaymentRefNumber] = useState("");
  const [paymentMessage, setPaymentMessage] = React.useState("");
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [paymentMethodArray, setPaymentMethodArray] = useState([]);

  // For control
  const [expandFilter, setExpandFilter] = React.useState(false);
  const [modalSearchInvoiceIsOpen, setModalSearchInvoiceIsOpen] = React.useState(false);
  const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
  const [modalPaymentMessageIsOpen, setModalPaymentMessageIsOpen] = React.useState(false);


  const [showAll, setShowAll] = React.useState(false);

  // For Photo
  const [paymentPhoto, setPaymentPhoto] = React.useState({
    photo1ID: "",
    photo1Base64Content: ""
  });
  const { photo1ID, photo1Base64Content } = paymentPhoto;


  // Delete Payment
  async function deleteConfirmationPayment(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deletePaymentFunc,
    };
    Notifications(alert);
  }

  // Delete Payment API Call
  async function deletePaymentFunc(id) {
    setIsLoading(true);
    let deleteResponse = await deletePayment(id);
    setIsLoading(false);
    if (deleteResponse.code == 200) {
      props.refresh();
      props.onHide();
    }
  }


  const onChangePaymentPhoto = (photoIDToUpdate, photoBase64ContentToUpdate) => {
    setPaymentPhoto({
      photo1ID: photoIDToUpdate,
      photo1Base64Content: photoBase64ContentToUpdate
    });
  };

  React.useEffect(() => {

    const getPaymentArrayData = () => {
      let paymentMethodData = getPaymentMethodArray();
      setPaymentMethodArray(paymentMethodData);
    };
    if (props.show) {
      getPaymentArrayData();
    }
    }, [props.show]);


  React.useEffect(() => {
    const getPaymentData = async () => {
      setIsLoading(true);
      let singlePaymentData = await getPaymentByID(props?.selectedData?.paymentID);

      setIsLoading(false);
      setPaymentData(singlePaymentData);

      const arr = singlePaymentData?.PaymentInvoiceMatches;
      if (arr) {
        arr.forEach(function (element) {
          element.price = element.Invoice?.invoiceNetPrice;
          element.paidAmount = element.paidAmount;
        });

        let invoiceList = arr.map(a => a.Invoice);
        configInvoiceListForMessage(invoiceList);
      }
      setPaymentInvoiceMatchesList(arr);
    };
    const getPhoto = async () => {
      setIsLoading(true);
      let res = await getPaymentPhoto(props?.selectedData?.paymentID);
      setIsLoading(false);
      // for (let i = 0; i < res?.data?.rows.length; i += 1) {
      const obj = {
        id: res?.data?.imageID,
        val: res?.data?.imageBase64Content,
      };
      setPaymentPhoto({
        photo1ID: obj.id,
        photo1Base64Content: obj.val
      })
      // }
    };
    if (props.show) {
      getPaymentData();
      getPhoto();
      setSelectedCustomerID(props?.selectedData?.customerID);
      setPaymentMethod(props?.selectedData?.paymentMethod);
      setPaymentRefNumber(props?.selectedData?.paymentRefNumber);
      setPaymentRemark(props?.selectedData?.paymentRemark);

    }
  }, [props.show]);



  const configInvoiceListForMessage = (invoiceListToSet) => {
    if (!Array.isArray(invoiceListToSet)) return;

    invoiceListToSet.forEach(function (element) {
      element.amountToPayForMessage = element.paidAmount;
      element.unPaidAmountForMessage = element.paidAmount;
      element.invoicePrice = element.invoiceNetPrice;
    });

    setInvoiceListForMessage(invoiceListToSet);
  };

  const appendNewInvoiceToInvoiceList = (newInvoiceData) => {
    let originlaList = paymentInvoiceMatchesList;
    const invoiceMatchToAppend = {
      paidAmount: newInvoiceData.unPaidAmount,
      Invoice: newInvoiceData
    };

    // Check if the invoice ID exist in the original list
    if (paymentInvoiceMatchesList.some((item) => item.Invoice.invoiceID === invoiceMatchToAppend.Invoice.invoiceID)) {
      // exist
      Notifications({
        type: "dataCheck",
        message: t("Cannot choose repeated invoices")
      });
      return;
    } else {
      // Do not exist 
      // Go on 
      originlaList.push(invoiceMatchToAppend);
      setPaymentInvoiceMatchesList([...originlaList]);

      let invoiceList = originlaList.map(a => a.Invoice);
      configInvoiceListForMessage(invoiceList);
    }
  }

  // Delete Confirmation
  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: onDeleteInvoiceFromList,
    };
    Notifications(alert);
  }

  const countTotal = () => {
    if (!paymentInvoiceMatchesList) return 0;
    let total = 0;
    for (let i = 0; i < paymentInvoiceMatchesList.length; i += 1) {
      total = total + Number(paymentInvoiceMatchesList[i].paidAmount);
    }
    return total;
  };

  const countPay = () => {
    if (!paymentInvoiceMatchesList) return 0;
    let pay = 0;
    for (let i = 0; i < paymentInvoiceMatchesList.length; i += 1) {
      pay = Number(pay) + Number(paymentInvoiceMatchesList[i].paidAmount);
    }
    return pay;
  };

  const onDeleteInvoiceFromList = (invoiceID) => {
    let arrPayment = paymentInvoiceMatchesList;
    const deletedArray = arrPayment.filter((obj) => obj.invoiceID !== invoiceID);

    setPaymentInvoiceMatchesList(deletedArray);

    let invoiceList = deletedArray.map(a => a.Invoice);
    configInvoiceListForMessage(invoiceList);

    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 10);

  };

  const onChangePay = (e, invoiceID) => {
    let arrPayment = paymentInvoiceMatchesList;
    const objIndex = arrPayment.findIndex((obj) => obj.invoiceID == invoiceID);
    arrPayment[objIndex].paidAmount = e.target.value;
    setPaymentInvoiceMatchesList(arrPayment);

    let invoiceList = arrPayment.map(a => a.Invoice);
    configInvoiceListForMessage(invoiceList);

    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 10);
  };

  const downloadImg = async (photoID, photoBase64Data) => {
    var a = document.createElement("a");
    a.href = "data:image/png;base64," + photoBase64Data;
    a.download = photoID + ".png";
    a.click();
  };

  const uploadImgNowReturnPhotoID = async (imageToUpload, imageRemark, imageOrder) => {
    if (!isFileImage(imageToUpload)) {
      return "";
    } else {
      setIsLoading(true);
      let photoIDFromAPI = await addPaymentPhoto(paymentData?.paymentID, await getBase64PhotoHeadRemoved(imageToUpload));
      setIsLoading(false);
      return photoIDFromAPI;

    }
  };

  const deleteImgNow = async (photoID) => {
    if (photoID === "") return;
    setIsLoading(true);
    await deletePaymentPhoto(photoID);
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let paymentSend = [];
    for (let i = 0; i < paymentInvoiceMatchesList.length; i += 1) {
      const obj = {
        invoiceID: paymentInvoiceMatchesList[i]?.Invoice?.invoiceID,
        paidAmount: paymentInvoiceMatchesList[i].paidAmount,
      };
      paymentSend.push(obj);
    }
    const submittedData = {
      paymentID: paymentData?.paymentID,
      customerID: selectedCustomerID,
      paymentPrice: countPay(),
      paymentStatus: "Paid",
      paymentMethod: paymentMethod,
      paymentRemark: paymentRemark,
      paymentRefNumber: paymentRefNumber,
      invoiceIDs: paymentSend,
    };
    // Important note here
    // Do not allow one payment paying the same invoice
    // First, we need to block this in the front end
    // Then, also block in the backend

    try {
      setIsLoading(true);
      let resultTF = await editPaymentWithMatch(submittedData);
      setIsLoading(false);
      if (resultTF) {
        props.refresh();
        props.onHide();
      }
    } catch (err) {
      Notifications();
    }
  };


  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >

        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader toggle={props.onHide}>
          <div className="mt-1">
            {props.title}
          </div>
          {showAll &&
            <div className="mt-1">
              <Badge color="primary" className="statusBadge ml-1"> {paymentData?.paymentID}</Badge>
            </div>
          }
          <div className="mt-1">
            <Button
              color="primary"
              size="sm"
              onClick={() => { setShowAll(!showAll) }}>
              <i className={`fa fa-angle-${showAll ? "up" : "down"}`} />
            </Button>
            <Button
              className="ml--1"
              color="primary"
              size="sm"
              onClick={() => { setModalPaymentMessageIsOpen(true); }}>
              <Trans>Receipt PDF</Trans>
            </Button>
          </div>
        </ModalHeader>
        <ModalBody className="mt--4">
          <Row md={12} className="mb-4">
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Customer")}</Label>
            </Col>
            <Col md={10}>

              <CustomerSelectInput
                preSelectCustomerID={selectedCustomerID}
                selectCustomerID={(customerID) => {
                  handleOnChangeCustomerID(customerID);
                  setInvoiceDetailEdited(true);
                }}
                disabled={true}
                showCreateButton={false}
                onCreateNewCustomer={() => {

                }} />
            </Col>
          </Row>
          <Row md={12} className="mb-4">
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Payment Price")}</Label>
            </Col>
            <Col className="d-flex justify-content-start" md={10}>
              <Input
                type="text"
                onChange={(e) => { }}
                // value={paymentData?.paymentPrice}
                value={countPay()}
                readOnly={true}
              />
            </Col>
          </Row>
          <Row md={12} className="mb-4">
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Payment Ref No")}</Label>
            </Col>
            <Col className="d-flex justify-content-start" md={10}>
              <Input
                type="text"
                onChange={(e) => setPaymentRefNumber(e.target.value)}
                value={paymentRefNumber}
              />
            </Col>
          </Row>

          <Row md={12} className="mb-4">
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Payment Remark")}</Label>
            </Col>
            <Col className="d-flex justify-content-start" md={10}>
              <Input
                type="textarea"
                onChange={(e) => setPaymentRemark(e.target.value)}
                value={paymentRemark}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <DropdownControl
                disabled={false}
                defaultColor="primary"
                dropdownName={t("Payment method")}
                useTranslate={true}
                // arrayIDTextFormat={[{id: "xxxx", text: "yyy"}]}
                arrayIDTextFormat={paymentMethodArray}
                valueID={paymentMethod}
                onSelectValueID={(valueID) => {
                  setPaymentMethod(valueID);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <PhotoUploadArea
                title={t("Photo Upload")}
                isShow={true}
                photoID={photo1ID}
                photoBase64Data={photo1Base64Content}
                onUploadPhoto={async (photoDataToUpload, imageRemark) => {
                  let returnPhotoID = await uploadImgNowReturnPhotoID(photoDataToUpload, imageRemark, "1");
                  onChangePaymentPhoto(
                    returnPhotoID,
                    await getBase64PhotoHeadRemoved(photoDataToUpload)
                  )
                }}
                onDownloadPhoto={(photoID, photoBase64Content) => {
                  downloadImg(photoID, photoBase64Content);
                }}
                onDeletePhoto={(photoID) => {
                  deleteImgNow(photoID);
                  onChangePaymentPhoto("", "")
                }}
              />

            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>


              <InvoiceListToPayMessageArea
                isSameCustomer={true}
                customerDetail={paymentData?.Customer}
                showReceiptURL={false}
                receiptURL={""}
                invoiceList={invoiceListForMessage}
              />


            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Card className="">
                <CardHeader
                  role="tab"
                  className="">
                  <Row>
                    <Col className="text-right d-flex align-items-begin justify-content-begin"                    >
                      <Button
                        color="primary"
                        id="tooltip443412081"
                        onClick={(e) => {
                          e.preventDefault();
                          setModalSearchInvoiceIsOpen(true);
                        }}
                        size="md"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className={"fa fa-plus"} />
                        </span>
                        <span className="btn-inner--text">
                          {t("Add invoice")}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={11} className="pr-0">
              <div className="mt-4">
                {selectedCustomerID !== "" ? (
                  <>
                    {paymentInvoiceMatchesList?.map((paymentInvoiceMatch, i) => (
                      <div key={i}>
                        <Row className="d-flex px-3 justify-content-between">
                          <Col xs={8}
                            onClick={() => {
                              setSelectedInvoice(paymentInvoiceMatch?.Invoice);
                              setModalInvoiceMessageIsOpen(true);
                            }}>

                            <div>
                              <div className="tableRowTitle"><span className="tableRowSubTitle"><Trans>Invoice total</Trans>: </span>${paymentInvoiceMatch.Invoice?.invoiceNetPrice}</div>
                              <div className="tableRowSecondTitle"><span className="tableRowSubTitle"><Trans>Paid</Trans>: </span>${paymentInvoiceMatch.Invoice?.paidAmount}</div>
                              <div className="tableRowSecondTitle"><span className="tableRowSubTitle"><Trans>Balance</Trans>: </span>:${paymentInvoiceMatch.Invoice?.unPaidAmount} </div>
                              <div className="tableRowSubTitle">{paymentInvoiceMatch.Invoice?.invoiceID} {' #'}{paymentInvoiceMatch.Invoice?.invoiceRemark}</div>
                              <div className="tableRowSubTitle">
                                {moment(
                                  new Date(paymentInvoiceMatch.Invoice?.invoiceRecordDateTime),
                                  moment.ISO_8601
                                ).format("yyyy-MM-DD ddd hh:mm")}
                              </div>

                              <div>
                                <Badge
                                  color={
                                    paymentInvoiceMatch.invoiceStatus === "Paid"
                                      ? "success"
                                      : paymentInvoiceMatch.invoiceStatus === "In Progress"
                                        ? "primary"
                                        : paymentInvoiceMatch.invoiceStatus === "Cancel"
                                          ? "danger"
                                          : "warning"
                                  }
                                  pill
                                >
                                  {`${paymentInvoiceMatch?.Invoice?.PaymentInvoiceMatches?.length}`} {t("payment")}
                                </Badge>

                                <Badge
                                  color={
                                    paymentInvoiceMatch?.Invoice?.invoiceStatus === "Paid"
                                      ? "success"
                                      : paymentInvoiceMatch?.Invoice?.invoiceStatus === "In Progress"
                                        ? "primary"
                                        : paymentInvoiceMatch?.Invoice?.invoiceStatus === "Cancel"
                                          ? "danger"
                                          : "warning"
                                  }
                                  pill
                                >
                                  {t(paymentInvoiceMatch?.Invoice?.invoiceStatus).toUpperCase()}
                                </Badge>
                                <Badge
                                  color={
                                    paymentInvoiceMatch?.Invoice?.DeliveryRecords?.length >= 1
                                      ? "info"
                                      : "primary"
                                  }
                                  pill
                                >
                                  {`${paymentInvoiceMatch?.Invoice?.DeliveryRecords?.length}`} {t("delivery")}
                                </Badge>
                              </div>
                            </div>

                            <hr className="mt-2 mb-1" />
                            <div className="h4 mt-2">
                              {paymentInvoiceMatch.Invoice?.InvoiceSubitems?.map((val_, i) => (
                                <span key={i}>
                                  {val_.Good?.goodDescription} (
                                  {val_.quantity}
                                  {val_.Good?.goodUnit})
                                  {i + 1 < paymentInvoiceMatch?.Invoice?.InvoiceSubitems.length
                                    ? ", "
                                    : ""}
                                </span>
                              ))}
                            </div>

                            <hr className="mt-2 mb-1" />
                            <div className="h4 mt-2">
                              {paymentInvoiceMatch.Invoice?.DeliveryRecords?.map((val_, i) => (
                                <span key={i}>
                                  {combineAddress(val_.DeliveryAddress)}
                                  {i + 1 < paymentInvoiceMatch?.Invoice?.DeliveryRecords?.length
                                    ? ", "
                                    : ""}
                                </span>
                              ))}
                            </div>

                          </Col>
                          <Col className="d-flex justify-content-end" xs={4}>
                            <div>
                              <div
                                style={{ fontSize: "12px" }}
                                className="text-muted mt-2"
                              >
                                {t("Amount")}$:
                              </div>
                              <div className="h2">
                                ${paymentInvoiceMatch.paidAmount}
                              </div>
                              <div
                                style={{ fontSize: "12px" }}
                                className="text-muted mt-2"
                              >
                                {t("Pay")}$:
                              </div>

                              <div className="tableRowTitle">
                                <Input
                                  style={{ width: "100px" }}
                                  className="form-control-lg"
                                  placeholder=""
                                  onChange={(e) => onChangePay(e, paymentInvoiceMatch.invoiceID)}
                                  name="amount"
                                  value={paymentInvoiceMatch.paidAmount}
                                  disabled={false}
                                />
                              </div>
                              <div className="mt-2">

                                <Button
                                  size="sm"
                                  id='tooltip443412089'
                                  color="danger"
                                  onClick={async (e) => { await deleteConfirmation(paymentInvoiceMatch.invoiceID) }}
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-trash" />
                                  </span>
                                  <span className="btn-inner--text">{t("Delete")}</span>
                                </Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <hr className="m-2 p-0" />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center my-5">
                    <Row>
                      <Col>
                        {t("No Data, try search customer")}
                      </Col>
                    </Row>
                  </div>
                )}
                <Row className="mt-4 d-flex px-3 justify-content-between">
                  <Col />
                  <Col className="d-flex justify-content-end">
                    <div className="text-right mx-4">
                      <div className="h4">
                        {t("Total")} $
                      </div>
                      <div className="h4 negativeNumber">
                        {t("Paid")} $
                      </div>
                      <div className="h4">
                        {t("Balance")} $
                      </div>
                    </div>
                    <div>
                      <div className="h4">{countTotal()}</div>
                      <div className="h4 negativeNumber">{countPay()}</div>
                      <div className="h4">{countTotal() - countPay()}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className={isAccessible('paymentEditShowDeletePaymentButton') ? "d-flex justify-content-between" : "d-flex justify-content-end"}>

          {isAccessible('paymentEditShowDeletePaymentButton') &&
            <div>
              <Button color="danger" onClick={() => { deleteConfirmationPayment(paymentData?.paymentID); }} type="button">
                {t("Delete")}
                <span>{getAccessibleTagForSafeMode('paymentEditShowDeletePaymentButton')}</span>
              </Button>
            </div>
          }

          <div>
            <Button color="primary" onClick={handleSubmit} type="button">
              {props.button}
            </Button>
            <Button color="default" type="button" onClick={props.onHide}>
              {t("Cancel")}
            </Button>
          </div>
        </ModalFooter>

        <InvoiceSearchInvoiceModal
          show={modalSearchInvoiceIsOpen}
          onHide={() => { setModalSearchInvoiceIsOpen(false); }}
          selectedCustomerID={selectedCustomerID}
          selectedAnInvoice={(newInvoiceData) => { appendNewInvoiceToInvoiceList(newInvoiceData) }}
          invoiceStatusArray={["Unpaid", "Partial"]}
        />
        <InvoiceMessageModal
          show={modalInvoiceMessageIsOpen}
          onHide={() => setModalInvoiceMessageIsOpen(false)}
          title={t("Invoice Message")}
          refreshTable={() => { }}
          invoiceData={selectedInvoice}
        />

        <PaymentMessageModal
          show={modalPaymentMessageIsOpen}
          onHide={() => setModalPaymentMessageIsOpen(false)}
          title={t("Payment Message")}
          refreshTable={() => { }}
          paymentData={paymentData}
        />
      </Modal>
    </>
  );
}

export default PaymentEditModal;
