import paginationFactory from "react-bootstrap-table2-paginator";


import TranslateItem from "../views/pages/components/TranslateItem"

export const paginationTable = (props) => {
  return paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: false,
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
        <TranslateItem text={"Show"} />{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => onSizePerPageChange(e.target.value)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          }{" "}
          <TranslateItem text={"entries"} />
        </label>
      </div>
    ),
    paginationTotalRenderer: (from, to, size) => (
      <div className="dataTables_length react-bootstrap-table-pagination-total">
        <label>
          <TranslateItem text={"Showing"} /> {from} <TranslateItem text={"to"} /> {to} <TranslateItem text={"entries"} /> {" "} {"("} <TranslateItem text={"Total"} /> {size} <TranslateItem text={"entries"} /> {")"}
        </label>
      </div>
    ),
  })
;
}

export default paginationTable;
