import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";


import { getReportResourceReportTermsArray } from "functions/ReportResource.js";
import { getRefTabData } from "functions/Utility.js";
import { updateSingleReportTerms } from "functions/ReportResource.js";
import SpinnerOverlay from "../components/SpinnerOverlay.js";


function ReportResourceQTNTermsEditModal(props) {
  const { t } = useTranslation();

  // Control
  const [isLoading, setIsLoading] = React.useState(false);

  const [isReportResourceQTNTermsLine1Chnaged, setIsReportResourceQTNTermsLine1Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine2Chnaged, setIsReportResourceQTNTermsLine2Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine3Chnaged, setIsReportResourceQTNTermsLine3Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine4Chnaged, setIsReportResourceQTNTermsLine4Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine5Chnaged, setIsReportResourceQTNTermsLine5Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine6Chnaged, setIsReportResourceQTNTermsLine6Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine7Chnaged, setIsReportResourceQTNTermsLine7Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine8Chnaged, setIsReportResourceQTNTermsLine8Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine9Chnaged, setIsReportResourceQTNTermsLine9Changed] = React.useState(false);
  const [isReportResourceQTNTermsLine10Chnaged, setIsReportResourceQTNTermsLine10Changed] = React.useState(false);

  const [isReportResourceQTNMainColorChnaged, setIsReportResourceQTNMainColorChnaged] = React.useState(false);
  const [isReportResourceQTNBottomInfoLine1Chnaged, setIsReportResourceQTNBottomInfoLine1Chnaged] = React.useState(false);
  const [isReportResourceQTNBottomInfoLine2Chnaged, setIsReportResourceQTNBottomInfoLine2Chnaged] = React.useState(false);


  // Data
  const [reportResourceQTNTermsLine1, setReportResourceQTNTermsLine1] = React.useState('');
  const [reportResourceQTNTermsLine2, setReportResourceQTNTermsLine2] = React.useState('');
  const [reportResourceQTNTermsLine3, setReportResourceQTNTermsLine3] = React.useState('');
  const [reportResourceQTNTermsLine4, setReportResourceQTNTermsLine4] = React.useState('');
  const [reportResourceQTNTermsLine5, setReportResourceQTNTermsLine5] = React.useState('');
  const [reportResourceQTNTermsLine6, setReportResourceQTNTermsLine6] = React.useState('');
  const [reportResourceQTNTermsLine7, setReportResourceQTNTermsLine7] = React.useState('');
  const [reportResourceQTNTermsLine8, setReportResourceQTNTermsLine8] = React.useState('');
  const [reportResourceQTNTermsLine9, setReportResourceQTNTermsLine9] = React.useState('');
  const [reportResourceQTNTermsLine10, setReportResourceQTNTermsLine10] = React.useState('');

  const [reportResourceQTNMainColor, setReportResourceQTNMainColor] = React.useState('');
  const [reportResourceQTNBottomInfoLine1, setReportResourceQTNBottomInfoLine1] = React.useState('');
  const [reportResourceQTNBottomInfoLine2, setReportResourceQTNBottomInfoLine2] = React.useState('');

  // Data process
  // const [refTabSubTypeArray, setRefTabSubTypeArray] = React.useState([]);


  const onSubmitDataToServer = async (refTabID, refTabData) => {
    setIsLoading(true);
    let updateResponse = await updateSingleReportTerms(refTabID, refTabData);
    setIsLoading(false);
    if (updateResponse.code == 200) {
      return true
    } else {
      return false;
    }
  };


  // props 

  React.useEffect(() => {
    const getDataFromServer = async () => {
      let refDataArray = await getReportResourceReportTermsArray('reportResourceQTN');

      if (refDataArray && Array.isArray(refDataArray) && refDataArray.length >= 1) {
        setReportResourceQTNTermsLine1(getRefTabData(refDataArray, 'reportResourceQTNTermsLine1'));
        setReportResourceQTNTermsLine2(getRefTabData(refDataArray, 'reportResourceQTNTermsLine2'));
        setReportResourceQTNTermsLine3(getRefTabData(refDataArray, 'reportResourceQTNTermsLine3'));
        setReportResourceQTNTermsLine4(getRefTabData(refDataArray, 'reportResourceQTNTermsLine4'));
        setReportResourceQTNTermsLine5(getRefTabData(refDataArray, 'reportResourceQTNTermsLine5'));
        setReportResourceQTNTermsLine6(getRefTabData(refDataArray, 'reportResourceQTNTermsLine6'));
        setReportResourceQTNTermsLine7(getRefTabData(refDataArray, 'reportResourceQTNTermsLine7'));
        setReportResourceQTNTermsLine8(getRefTabData(refDataArray, 'reportResourceQTNTermsLine8'));
        setReportResourceQTNTermsLine9(getRefTabData(refDataArray, 'reportResourceQTNTermsLine9'));
        setReportResourceQTNTermsLine10(getRefTabData(refDataArray, 'reportResourceQTNTermsLine10'));

        setReportResourceQTNMainColor(getRefTabData(refDataArray, 'reportResourceQTNMainColor'));
        setReportResourceQTNBottomInfoLine1(getRefTabData(refDataArray, 'reportResourceQTNBottomInfoLine1'));
        setReportResourceQTNBottomInfoLine2(getRefTabData(refDataArray, 'reportResourceQTNBottomInfoLine2'));


        // reset the control here
        setIsReportResourceQTNTermsLine1Changed(false);
        setIsReportResourceQTNTermsLine2Changed(false);
        setIsReportResourceQTNTermsLine3Changed(false);
        setIsReportResourceQTNTermsLine4Changed(false);
        setIsReportResourceQTNTermsLine5Changed(false);
        setIsReportResourceQTNTermsLine6Changed(false);
        setIsReportResourceQTNTermsLine7Changed(false);
        setIsReportResourceQTNTermsLine8Changed(false);
        setIsReportResourceQTNTermsLine9Changed(false);
        setIsReportResourceQTNTermsLine10Changed(false);
        setIsReportResourceQTNMainColorChnaged(false);
        setIsReportResourceQTNBottomInfoLine1Chnaged(false);
        setIsReportResourceQTNBottomInfoLine2Chnaged(false);

        // Data process
        // let mapResult = refDataArray.map((item) => { return item.refTabSubType; });
        // let distinctResult = mapResult.filter((item, index) => mapResult.indexOf(item) === index);
        // setRefTabSubTypeArray(distinctResult);
      }
    }

    if (props.show) {
      getDataFromServer();
    }
  }, [props.show]);


  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader><Trans>Quotation Report Detail</Trans></ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine1")}   (${reportResourceQTNTermsLine1.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine1"
                inputName="reportResourceQTNTermsLine1"
                inputId="reportResourceQTNTermsLine1"
                inputPlaceholder={t("reportResourceQTNTermsLine1")}
                type="textarea"
                value={reportResourceQTNTermsLine1}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine1(e.target.value);
                  setIsReportResourceQTNTermsLine1Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine1Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine1", reportResourceQTNTermsLine1);
                      if (result) {
                        setIsReportResourceQTNTermsLine1Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine2")}   (${reportResourceQTNTermsLine2.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine2"
                inputName="reportResourceQTNTermsLine2"
                inputId="reportResourceQTNTermsLine2"
                inputPlaceholder={t("reportResourceQTNTermsLine2")}
                type="textarea"
                value={reportResourceQTNTermsLine2}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine2(e.target.value);
                  setIsReportResourceQTNTermsLine2Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine2Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine2", reportResourceQTNTermsLine2);
                      if (result) {
                        setIsReportResourceQTNTermsLine2Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine3")}   (${reportResourceQTNTermsLine3.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine3"
                inputName="reportResourceQTNTermsLine3"
                inputId="reportResourceQTNTermsLine3"
                inputPlaceholder={t("reportResourceQTNTermsLine3")}
                type="textarea"
                value={reportResourceQTNTermsLine3}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine3(e.target.value);
                  setIsReportResourceQTNTermsLine3Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine3Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine3", reportResourceQTNTermsLine3);
                      if (result) {
                        setIsReportResourceQTNTermsLine3Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine4")}   (${reportResourceQTNTermsLine4.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine4"
                inputName="reportResourceQTNTermsLine4"
                inputId="reportResourceQTNTermsLine4"
                inputPlaceholder={t("reportResourceQTNTermsLine4")}
                type="textarea"
                value={reportResourceQTNTermsLine4}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine4(e.target.value);
                  setIsReportResourceQTNTermsLine4Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine4Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine4", reportResourceQTNTermsLine4);
                      if (result) {
                        setIsReportResourceQTNTermsLine4Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine5")}   (${reportResourceQTNTermsLine5.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine5"
                inputName="reportResourceQTNTermsLine5"
                inputId="reportResourceQTNTermsLine5"
                inputPlaceholder={t("reportResourceQTNTermsLine5")}
                type="textarea"
                value={reportResourceQTNTermsLine5}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine5(e.target.value);
                  setIsReportResourceQTNTermsLine5Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine5Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine5", reportResourceQTNTermsLine5);
                      if (result) {
                        setIsReportResourceQTNTermsLine5Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine6")}   (${reportResourceQTNTermsLine6.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine6"
                inputName="reportResourceQTNTermsLine6"
                inputId="reportResourceQTNTermsLine6"
                inputPlaceholder={t("reportResourceQTNTermsLine6")}
                type="textarea"
                value={reportResourceQTNTermsLine6}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine6(e.target.value);
                  setIsReportResourceQTNTermsLine6Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine6Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine6", reportResourceQTNTermsLine6);
                      if (result) {
                        setIsReportResourceQTNTermsLine6Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine7")}   (${reportResourceQTNTermsLine7.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine7"
                inputName="reportResourceQTNTermsLine7"
                inputId="reportResourceQTNTermsLine7"
                inputPlaceholder={t("reportResourceQTNTermsLine7")}
                type="textarea"
                value={reportResourceQTNTermsLine7}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine7(e.target.value);
                  setIsReportResourceQTNTermsLine7Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine7Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine7", reportResourceQTNTermsLine7);
                      if (result) {
                        setIsReportResourceQTNTermsLine7Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine8")}   (${reportResourceQTNTermsLine8.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine8"
                inputName="reportResourceQTNTermsLine8"
                inputId="reportResourceQTNTermsLine8"
                inputPlaceholder={t("reportResourceQTNTermsLine8")}
                type="textarea"
                value={reportResourceQTNTermsLine8}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine8(e.target.value);
                  setIsReportResourceQTNTermsLine8Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine8Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine8", reportResourceQTNTermsLine8);
                      if (result) {
                        setIsReportResourceQTNTermsLine8Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine9")}   (${reportResourceQTNTermsLine9.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine9"
                inputName="reportResourceQTNTermsLine9"
                inputId="reportResourceQTNTermsLine9"
                inputPlaceholder={t("reportResourceQTNTermsLine9")}
                type="textarea"
                value={reportResourceQTNTermsLine9}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine9(e.target.value);
                  setIsReportResourceQTNTermsLine9Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine9Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine9", reportResourceQTNTermsLine9);
                      if (result) {
                        setIsReportResourceQTNTermsLine9Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNTermsLine10")}   (${reportResourceQTNTermsLine10.length}${t('words')})`}
                labelFor="reportResourceQTNTermsLine10"
                inputName="reportResourceQTNTermsLine10"
                inputId="reportResourceQTNTermsLine10"
                inputPlaceholder={t("reportResourceQTNTermsLine10")}
                type="textarea"
                value={reportResourceQTNTermsLine10}
                handleChange={(e) => {
                  setReportResourceQTNTermsLine10(e.target.value);
                  setIsReportResourceQTNTermsLine10Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNTermsLine10Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNTermsLine10", reportResourceQTNTermsLine10);
                      if (result) {
                        setIsReportResourceQTNTermsLine10Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={t("reportResourceQTNMainColor")}
                labelFor="reportResourceQTNMainColor"
                inputName="reportResourceQTNMainColor"
                inputId="reportResourceQTNMainColor"
                inputPlaceholder={t("reportResourceQTNMainColor")}
                type="textarea"
                value={reportResourceQTNMainColor}
                handleChange={(e) => {
                  setReportResourceQTNMainColor(e.target.value);
                  setIsReportResourceQTNMainColorChnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNMainColorChnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNMainColor", reportResourceQTNMainColor);
                      if (result) {
                        setIsReportResourceQTNMainColorChnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNBottomInfoLine1")}   (${reportResourceQTNBottomInfoLine1.length}${t('words')})`}
                labelFor="reportResourceQTNBottomInfoLine1"
                inputName="reportResourceQTNBottomInfoLine1"
                inputId="reportResourceQTNBottomInfoLine1"
                inputPlaceholder={t("reportResourceQTNBottomInfoLine1")}
                type="textarea"
                value={reportResourceQTNBottomInfoLine1}
                handleChange={(e) => {
                  setReportResourceQTNBottomInfoLine1(e.target.value);
                  setIsReportResourceQTNBottomInfoLine1Chnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNBottomInfoLine1Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNBottomInfoLine1", reportResourceQTNBottomInfoLine1);
                      if (result) {
                        setIsReportResourceQTNBottomInfoLine1Chnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }



          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceQTNBottomInfoLine2")}   (${reportResourceQTNBottomInfoLine2.length}${t('words')})`}
                labelFor="reportResourceQTNBottomInfoLine2"
                inputName="reportResourceQTNBottomInfoLine2"
                inputId="reportResourceQTNBottomInfoLine2"
                inputPlaceholder={t("reportResourceQTNBottomInfoLine2")}
                type="textarea"
                value={reportResourceQTNBottomInfoLine2}
                handleChange={(e) => {
                  setReportResourceQTNBottomInfoLine2(e.target.value);
                  setIsReportResourceQTNBottomInfoLine2Chnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceQTNBottomInfoLine2Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceQTNBottomInfoLine2", reportResourceQTNBottomInfoLine2);
                      if (result) {
                        setIsReportResourceQTNBottomInfoLine2Chnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }

        </ModalBody>
        <ModalFooter>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ReportResourceQTNTermsEditModal;
