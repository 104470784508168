import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ListGroupItem,
    Label,
    FormGroup,
    Spinner,
    Badge,
} from "reactstrap";
import { isMobile } from "react-device-detect";
import { Link, Redirect, useHistory } from "react-router-dom";
import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import {
    getCustomerDatatable,
    deleteCustomerByID,
    getPhoneType,
} from "../../../functions/Customer";
// import CustomerModal from "./CustomerModalCreate";
import Swal from "sweetalert2";
import Notifications from "../components/Notifications";

import { Trans, useTranslation } from "react-i18next";
import { joinArrayToString, addPhoneLink } from "functions/Utility.js";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";
import { getAllMembershipType } from "functions/Membership";
import { deleteMembershipType } from "functions/Membership";
import MembershipTypeCreateModal from "./MembershipTypeCreateModal";
import { filterEffectiveMembershipList } from "functions/Membership";
import { getCustomerListFromeMembershipList } from "functions/Membership";
import MembershipTypeEditModal from "./MembershipTypeEditModal";



function MembershipTypeScreen(props) {
    const { t } = useTranslation();
    const pagination = paginationTable();

    const { SearchBar } = Search;
    const [isLoading, setIsLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(0);
    const [membershipTypeArrayData, setMembershipTypeArrayData] = React.useState([]);
    const [selectedMembershipTypeData, setSelectedMembershipTypeData] = React.useState([]);

    // GUI
    const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

    //  modal 
    const [modalMembershipTypeCreateIsOpen, setModalMembershipTypeCreateIsOpen] = React.useState(false);
    const [modalMembershipTypeEditIsOpen, setModalMembershipTypeEditIsOpen] = React.useState(false);
    const [selectedMembershipTypeDataToEdit, setSelectedMembershipTypeDataToEdit] = React.useState({});
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalActionIsOpen, setModalActionIsOpen] = React.useState(false);


    React.useEffect(() => {
        // Pull to refresh must be disabled if opened some medal
        // Otherwise will lock the screen of the modal
        setPullToRefreshDisable(modalMembershipTypeCreateIsOpen || modalIsOpen || modalActionIsOpen);
        //  lockScrolling(modalInvoiceCreateIsOpen || modalIsOpen || modalActionIsOpen);
    }, [modalMembershipTypeCreateIsOpen, modalIsOpen, modalActionIsOpen]);

    const getMembershipTypeArrayDataFromDB = async () => {
        setIsLoading(true);
        let dataTableFromDB = await getAllMembershipType();
        setMembershipTypeArrayData(dataTableFromDB);
        setIsLoading(false);
    };

    React.useEffect(() => {
        getMembershipTypeArrayDataFromDB();
    }, [refreshTable]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    // Delete Confirmation
    async function deleteConfirmation(membershipTypeID) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: membershipTypeID,
            callback: deleteMembershipTypeProcess,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteMembershipTypeProcess(id) {
        let deleteResult = await deleteMembershipType(id);
        deleteResult.code == 200 ? refreshTableData() : true;
    }

    return (isLoggedIn() && isAccessible(props.accessID)) ? (
        <>
            {isLoading ? <SpinnerOverlay /> : ""}

            <ScreenPullToRefresh
                onRefresh={async () => {
                    await getMembershipTypeArrayDataFromDB();
                }}
                disabled={pullToRefreshDisable}>
                <SimpleHeader parentName={t("MembershipType")} icon="fas fa-home" />
            </ScreenPullToRefresh>
            <Container style={{ marginTop: "-4rem" }} fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <ToolkitProvider
                                    data={membershipTypeArrayData} // "row" below means the row of this json
                                    keyField="membershipTypeID"
                                    columns={
                                        [
                                            // hidden columns are for search
                                            { dataField: "membershipTypeID", text: "", hidden: true },
                                            { dataField: "membershipTypeName", text: "", hidden: true },
                                            { dataField: "membershipTypeDetail", text: "", hidden: true },
                                            { dataField: "membershipTypeNameTrans", text: "", hidden: true, formatter: (cell, row, rowIndex, extraData) => { return `${t(row.membershipTypeName)}` } },
                                            // { dataField: "customerEmail", text: "", hidden: true },
                                            // { dataField: "customerAddress", text: "", hidden: true },
                                            // { dataField: "phoneUserNameJoin", text: "", hidden: true, formatter: (cell, row, rowIndex, extraData) => { return `${joinArrayToString(row.CustomerPhones.map((item) => { return item.phoneUserName }), "")}` } },
                                            // { dataField: "phoneNumberJoin", text: "", hidden: true, formatter: (cell, row, rowIndex, extraData) => { return `${joinArrayToString(row.CustomerPhones.map((item) => { return item.phoneNumber }), "")}` } },
                                            {
                                                dataField: "",
                                                text: t("Name"),
                                                sort: true,
                                                style: { whiteSpace: 'normal' }, //whiteSpace: 'normal' will break the word if the word is too long
                                                formatter: (cell, row, rowIndex, extraData) => {
                                                    return (
                                                        <>
                                                            <a
                                                                id={`tooltip209424783${row.membershipTypeID}`}
                                                                onClick={() => {
                                                                    setSelectedMembershipTypeDataToEdit(row);
                                                                    setModalMembershipTypeEditIsOpen(true);
                                                                }} >
                                                                <div className="ml--3 mousePointer">



                                                                    <div>
                                                                        {/* Effectvie membership count */}
                                                                        {row.Memberships && Array.isArray(row.Memberships) &&
                                                                            <Badge className="statusBadge" color="primary">
                                                                                {filterEffectiveMembershipList(row.Memberships).length}{t('Eff Mem')}
                                                                            </Badge>
                                                                        }
                                                                        {/* Total customer count */}
                                                                        {row.Memberships && Array.isArray(row.Memberships) &&
                                                                            <Badge className="statusBadge" color="primary">
                                                                                {getCustomerListFromeMembershipList(filterEffectiveMembershipList(row.Memberships)).length}{t('Eff Cus')}
                                                                            </Badge>
                                                                        }
                                                                        {/* Total membership count */}
                                                                        {row.Memberships && Array.isArray(row.Memberships) &&
                                                                            <Badge className="statusBadge" color="warning">
                                                                                {row.Memberships.length}{t('Mem')}
                                                                            </Badge>
                                                                        }
                                                                        {/* Total customer count */}
                                                                        {row.Memberships && Array.isArray(row.Memberships) &&
                                                                            <Badge className="statusBadge" color="warning">
                                                                                {getCustomerListFromeMembershipList(row.Memberships).length}{t('Cus')}
                                                                            </Badge>
                                                                        }

                                                                    </div>

                                                                    <div className="tableRowTitle">
                                                                        <Trans>{row.membershipTypeName}</Trans>
                                                                    </div>
                                                                    <div className="tableRowRemark">
                                                                        {row.membershipTypeDetail}
                                                                    </div>
                                                                </div>
                                                            </a>

                                                            <div className="ml--3">
                                                                <div className="tableRowContent">
                                                                    {joinArrayToString(row.MembershipTypeGoodMatches.map((item) => { return `${item.Good.goodDescription}: $${item.Good.goodDefaultUnitPrice}` }), ", ")}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                },
                                            }, {
                                                isDummyField: true,
                                                dataField: "Action",
                                                text: "",
                                                formatter: (cellContent, row) => {
                                                    return (
                                                        <>
                                                            <a
                                                                className="table-action table-action-delete"
                                                                id="tooltip12475020"
                                                                onClick={() =>
                                                                    deleteConfirmation(row.membershipTypeID)
                                                                }
                                                            >
                                                                <i
                                                                    className="fas fa-trash  fa-lg"
                                                                    data-id={cellContent}
                                                                />
                                                            </a>
                                                        </>
                                                    );
                                                },
                                            },
                                        ]
                                    }
                                    search={{
                                        searchFormatted: true
                                    }}
                                >
                                    {(props) => (
                                        <>
                                            <Row className="pb-2">
                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                    <SearchBar
                                                        className=""
                                                        placeholder={t("Search")}
                                                        {...props.searchProps}
                                                        style={{ width: '100%' }}
                                                    />
                                                </Col>
                                                <Col
                                                    className={"text-right"}
                                                    xs={5}
                                                    sm={5}
                                                    md={5}
                                                    lg={5}
                                                >
                                                    <Button
                                                        color="primary"
                                                        id="tooltip443412080"
                                                        onClick={() => setModalMembershipTypeCreateIsOpen(!modalMembershipTypeCreateIsOpen)}
                                                        size="md"
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-plus" />
                                                        </span>
                                                        <span className="btn-inner--text">{t("Add")}</span>
                                                    </Button>
                                                    {/* <UncontrolledTooltip
                            delay={1}
                            target="tooltip443412080"
                          >
                            {t("Create")}
                          </UncontrolledTooltip> */}
                                                </Col>
                                            </Row>
                                            <div className="py-2 table-responsive table-sm">
                                                <BootstrapTable
                                                    // headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                                                    headerClasses={"tableHiddenHeader"}
                                                    {...props.baseProps}
                                                    bootstrap4={true}
                                                    pagination={pagination}
                                                    bordered={false}
                                                />
                                            </div>
                                        </>
                                    )}
                                </ToolkitProvider>
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>
                {/* <CustomerModalCreate
                    show={modalIsOpen}
                    onHide={() => setModalIsOpen(false)}
                    title={t("Add Customer")}
                    button={t("Save")}
                    returnNewCustomerCreated={(newCustomerData) => { refreshTableData }}
                />

                <CustomerActionModal
                    show={modalActionIsOpen}
                    onHide={() => setModalActionIsOpen(false)}
                    title={t("Choose Action")}
                    button={t("Update")}
                    customerData={selectedMembershipTypeData}
                    refreshTable={refreshTableData}
                />
                <InvoiceCreateModal
                    preSelectedCustomer={selectedMembershipTypeData}
                    show={modalInvoiceCreateIsOpen}
                    onHide={(returnCreatedInvoiceID) => {
                        setModalInvoiceCreateIsOpen(false);
                    }}
                    allowEmptySubitemList={true}
                /> */}


                <MembershipTypeCreateModal
                    show={modalMembershipTypeCreateIsOpen}
                    onHide={(e) => {
                        setModalMembershipTypeCreateIsOpen(false);
                    }}
                    refreshTable={refreshTableData}

                />

                <MembershipTypeEditModal
                    show={modalMembershipTypeEditIsOpen}
                    onHide={(e) => {
                       setModalMembershipTypeEditIsOpen(false);
                    }}
                    refreshTable={refreshTableData}
                    membershipTypeDataToEdit={selectedMembershipTypeDataToEdit}
                />
            </Container>
        </>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default MembershipTypeScreen;
