import React from "react";
import {
    Button,
    Badge,
    Row,
    Col
} from "reactstrap";
import DropdownControl from "../components/DropdownControl";
import { Trans, useTranslation } from "react-i18next";
import { getDriverSelect2 } from "functions/Driver";
import { getTruckWorkerSelect2 } from "functions/TruckWorker";


//props
//DeliveryRecordTruckWorkerMatches
//getChosenTruckWorkerList
function DeliveryTruckWorkerListButtonDropdownGroup(props) {

    const { t } = useTranslation();

    // In fact, this list is actually is a select2 format
    //ie. {id, text, showDeleteButton}
    const [chosenTruckWorkerList, setChosenTruckWorkerList] = React.useState([]);

    const [truckWorkerData, setTruckWorkerData] = React.useState([]);

    const [isInitialDataSync, setIsInitialDataSync] = React.useState(true);

    React.useEffect(() => {
        try {
            if (!isInitialDataSync) {
                props.getChosenTruckWorkerList(chosenTruckWorkerList);
                setIsInitialDataSync(true);
            }
        } catch (error) {

        }
    }, [chosenTruckWorkerList]);


    React.useEffect(() => {
        const getTruckWorkerList = async () => {
            let dataFromDB = await getTruckWorkerSelect2();
            setTruckWorkerData(dataFromDB);
        };
        getTruckWorkerList();
    }, []);

    React.useEffect(() => {
        if (props.DeliveryRecordTruckWorkerMatches) {
            let tempTruckWrokerList = [];
            for (let i = 0; i < props.DeliveryRecordTruckWorkerMatches.length; i += 1) {
                const obj = {
                    id: props.DeliveryRecordTruckWorkerMatches[i]?.truckWorkerID,
                    text: props.DeliveryRecordTruckWorkerMatches[i].TruckWorker?.SystemUser?.nickName,
                    showDeleteButton: false
                };
                tempTruckWrokerList.push(obj);
            }
            setChosenTruckWorkerList(tempTruckWrokerList);
        }

    }, [props.DeliveryRecordTruckWorkerMatches]);


    function addTruckWrokerToChosenList(truckWorkerIDToAdd) {
        //Check if this id already exist in the chosenlist
        if (chosenTruckWorkerList) {
            let existingTruckWorker = chosenTruckWorkerList?.find((item) => item.id === truckWorkerIDToAdd);
            if (existingTruckWorker) {
                // already exist
                return;
            }
        }
        //get the obj from the truckWorkerData
        let selectedTruckWorker = truckWorkerData?.find((item) => item.id === truckWorkerIDToAdd);

        if (selectedTruckWorker) {
            setIsInitialDataSync(false);
            setChosenTruckWorkerList(current => [...current, { id: selectedTruckWorker.id, text: selectedTruckWorker.text, showDeleteButton: false }]);

        }
    };

    function updateTruckWrokerShowDeleteButtonInChosenList(truckWorkerIDToUpdate, showDeleteButtonBoo) {
        // 👇️ passing function to setData method
        setChosenTruckWorkerList(prevState => {
            const newState = prevState.map(obj => {
                // 👇️ if id equals , update showDeleteButton
                if (obj.id === truckWorkerIDToUpdate) {
                    return { ...obj, showDeleteButton: showDeleteButtonBoo };
                }
                // 👇️ otherwise return the object as is
                return obj;
            });
            return newState;
        });
    };

    function deleteTruckWrokerInChosenList(truckWorkerIDToDelete) {
        setIsInitialDataSync(false);
        setChosenTruckWorkerList(current =>
            current.filter(item => {
                // 👇️ remove object that has id equal to 2
                return item.id !== truckWorkerIDToDelete;
            }),
        );

    };

    return (
        <>
            {chosenTruckWorkerList
                && chosenTruckWorkerList.length >= 1
                && chosenTruckWorkerList.map((item, index) => {
                    return (
                        <span key={`list${index}`}>
                            <Button
                                key={`list${index}`}
                                className="ml-0 mr-1"
                                color="default"
                                onClick={(e) => {
                                    e.preventDefault();
                                    updateTruckWrokerShowDeleteButtonInChosenList(item.id, !item.showDeleteButton)
                                }}
                                disabled={props.disabled}
                                >
                                {item.text}
                            </Button>
                            {
                                item.showDeleteButton &&
                                <Button
                                    key={`delete${index}`}
                                    className="ml-0 mr-1"
                                    color="danger"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteTruckWrokerInChosenList(item.id);
                                    }}
                                    disabled={props.disabled}
                                >
                                    <i className="fas fa-trash" />
                                </Button>
                            }
                        </span>

                    )
                })}

            <DropdownControl
                disabled={props.disabled}
                defaultColor="default"
                dropdownName={t("Worker")}
                useTranslate={false}
                allowDelete={true}
                arrayIDTextFormat={truckWorkerData}
                valueID={""}
                onSelectValueID={(valueID) => {
                    addTruckWrokerToChosenList(valueID);
                }}
            />
        </>

    );



}

export default DeliveryTruckWorkerListButtonDropdownGroup;
