import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateGood } from "functions/Goods";

function GoodModalEdit(props) {
  const { t } = useTranslation();
  const [goodData, setGoodData] = React.useState({
    goodID: "",
    goodStatus: "",
    goodPriority: 1,
    goodType: "",
    goodSubType: "",
    goodDescription: "",
    goodDefaultUnitQuantity: 1,
    goodUnit: "",
    goodDefaultUnitPrice: 1,
    goodDefaultWeight: 1,
    goodIconFileName: "",
    goodRemark: "",
    goodNameShortForm: "",
  });

  const {
    goodID,
    goodStatus,
    goodPriority,
    goodType,
    goodSubType,
    goodDescription,
    goodDefaultUnitQuantity,
    goodUnit,
    goodDefaultUnitPrice,
    goodDefaultWeight,
    goodIconFileName,
    goodRemark,
    goodNameShortForm,
  } = goodData;

  const onInputChange = (e) => {
    setGoodData({ ...goodData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let update = await updateGood(goodData);
    update.code == 200 ? await props.refreshTable() : true;
    props.onHide();
  };

  React.useEffect(() => {
    if (Object.values(props.goodData).length > 0) {
      setGoodData({
        goodID: props.goodData.goodID,
        goodStatus: props.goodData.goodStatus,
        goodPriority: props.goodData.goodPriority,
        goodType: props.goodData.goodType,
        goodSubType: props.goodData.goodSubType,
        goodDescription: props.goodData.goodDescription,
        goodDefaultUnitQuantity: props.goodData.goodDefaultUnitQuantity,
        goodUnit: props.goodData.goodUnit,
        goodDefaultUnitPrice: props.goodData.goodDefaultUnitPrice,
        goodDefaultWeight: props.goodData.goodDefaultWeight,
        goodIconFileName: props.goodData.goodIconFileName,
        goodRemark: props.goodData.goodRemark,
        goodNameShortForm: props.goodData.goodNameShortForm,
      });
    }
  }, [props.show]);
  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody className="mt--4">
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Item Name")}${"("}${t("Required")}${")"}`}
                  labelFor="goodDescription"
                  inputName="goodDescription"
                  inputId="goodDescription"
                  inputPlaceholder={t("Item Name")}
                  value={goodDescription}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Short Form Name")}${"("}${t("Required")}${")"}`}
                  labelFor="goodNameShortForm"
                  inputName="goodNameShortForm"
                  inputId="goodNameShortForm"
                  inputPlaceholder={t("Short Form Name")}
                  value={goodNameShortForm}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Type")}${"("}${t("Required")}${")"}`}
                  labelFor="goodType"
                  inputName="goodType"
                  inputId="goodType"
                  inputPlaceholder={t("Type")}
                  value={goodType}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Sub Type")}${"("}${t("Required")}${")"}`}
                  labelFor="goodSubType"
                  inputName="goodSubType"
                  inputId="goodSubType"
                  inputPlaceholder={t("Sub Type")}
                  value={goodSubType}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Unit")}${"("}${t("Required")}${")"}`}
                  labelFor="goodUnit"
                  inputName="goodUnit"
                  inputId="goodUnit"
                  inputPlaceholder={t("Unit")}
                  value={goodUnit}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Price") + "($)"}${"("}${t("Required")}${")"}`}
                  labelFor="goodDefaultUnitPrice"
                  inputName="goodDefaultUnitPrice"
                  inputId="goodDefaultUnitPrice"
                  inputPlaceholder={t("Default Price")}
                  value={goodDefaultUnitPrice}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={t("Weight") + "(kg)"}
                  labelFor="goodDefaultWeight"
                  inputName="goodDefaultWeight"
                  inputId="goodDefaultWeight"
                  inputPlaceholder={t("Weight")}
                  value={goodDefaultWeight}
                  handleChange={(e) => onInputChange(e)}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  type="number"
                  label={`${t("Default quantity")}${"("}${t("Required")}${")"}`}
                  labelFor="goodDefaultUnitQuantity"
                  inputName="goodDefaultUnitQuantity"
                  inputId="goodDefaultUnitQuantity"
                  inputPlaceholder={t("Default Quantity")}
                  value={goodDefaultUnitQuantity}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="goodRemark"
                  inputName="goodRemark"
                  inputId="goodRemark"
                  inputPlaceholder={t("Remark")}
                  type={"textarea"}
                  value={goodRemark}
                  handleChange={(e) => onInputChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={t("Good ID")}
                  labelFor="goodID"
                  inputName="goodID"
                  inputId="goodID"
                  inputPlaceholder={t("Good ID")}
                  value={goodID}
                  handleChange={(e) => onInputChange(e)}
                  readOnly={true}
                />
              </Col>

            </Row>
          </ModalBody>
          <ModalFooter className="mt--4">
            <Button color="primary" type="submit">
              {props.button}
            </Button>
            <Button color="default" type="button" onClick={props.onHide}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default GoodModalEdit;
