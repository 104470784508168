import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  Input,
  Collapse,
  Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";
import { getRights, isLoggedIn } from "../../../functions/Auth";
// core components
import moment from "moment";
import PaymentHeader from "components/Headers/PaymentHeader";

import SimpleHeader from "components/Headers/SimpleHeader";

import PaymentCreateModal from "../payment/PaymentCreateModal";
import PaymentEditModal from "../payment/PaymentEditModal";
import { getAllPayment } from "functions/Payment";
import { getAllSalesStat } from "functions/SalesAccounting";

import PaymentSearchBar from "../components/PaymentSearchBar";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";


import SalesAccountingCard from "./SalesAccountingCard";
import SalesAccountingSearchBar from "../components/SalesAccountingSearchBar";
import CustomerUnpaidInvoiceRecord from "../customer/CustomerUnpaidInvoiceRecord";
import SalesAccountingInvoiceListModal from "./SalesAccountingInvoiceListModal";
import SalesAccountingDeliveryRecordListModal from "./SalesAccountingDeliveryRecordListModal";
import SalesAccountingPaymentListModal from "./SalesAccountingPaymentListModal";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";

function SalesAccountingScreen(props) {
  const { t } = useTranslation();
  const history = useHistory();


  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);


  const [salesAccountingData, setSalesAccountingData] = React.useState([]);
  const [initSalesAccountingData, setInitSalesAccountingData] = React.useState([]);

  const [editModal, setEditModal] = React.useState(false);
  const [selectedSalesAccountingSingleDateData, setSelectedSalesAccountingSingleDateData] = React.useState(null);


  // For filter control show
  const [showFullCard, setShowFullCard] = React.useState(false);
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

  // invoice list modal
  const [modalInvoiceListModalIsOpen, setModalInvoiceListModalIsOpen] = React.useState(false);
  const [modalInvoiceListModalStartDate, setModalInvoiceListModalStartDate] = React.useState(new Date());
  const [modalInvoiceListModalEndDate, setModalInvoiceListModalEndDate] = React.useState(new Date());
  const [modalInvoiceListModalInvoiceStatus, setModalInvoiceListModalInvoiceStatus] = React.useState('');

  // delivery record list modal
  const [modalDeliveryRecordListModalIsOpen, setModalDeliveryRecordListModalIsOpen] = React.useState(false);
  const [modalDeliveryRecordListModalStartDate, setModalDeliveryRecordListModalStartDate] = React.useState(new Date());
  const [modalDeliveryRecordListModalEndDate, setModalDeliveryRecordListModalEndDate] = React.useState(new Date());
  const [modalDeliveryRecordListModalDeliveryStatus, setModalDeliveryRecordListModalDeliveryStatus] = React.useState('');

  // payment list modal
  const [modalPaymentListModalIsOpen, setModalPaymentListModalIsOpen] = React.useState(false);
  const [modalPaymentListModalStartDate, setModalPaymentListModalStartDate] = React.useState(new Date());
  const [modalPaymentListModalEndDate, setModalPaymentListModalEndDate] = React.useState(new Date());
  const [modalPaymentListModalPaymentMethod, setModalPaymentListModalPaymentMethod] = React.useState('');


  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalInvoiceListModalIsOpen || modalDeliveryRecordListModalIsOpen || modalPaymentListModalIsOpen);
    lockScrolling(modalInvoiceListModalIsOpen || modalDeliveryRecordListModalIsOpen || modalPaymentListModalIsOpen);
  }, [modalInvoiceListModalIsOpen, modalDeliveryRecordListModalIsOpen, modalPaymentListModalIsOpen]);

  React.useEffect(() => {
    getSalesAccountingData();
  }, [refreshTable]);

  const getSalesAccountingData = async () => {
    setIsLoading(true);
    let salesAccountingData = await getAllSalesStat();
    setInitSalesAccountingData(salesAccountingData);
    setIsLoading(false);
  };

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      {/* <PaymentHeader
        name={t("Payment List")} // Name of this page
        icon="fas fa-home"
        paymentData={salesAccountingData}
        goBackLastPage={() => { history.goBack() }}
      /> */}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getSalesAccountingData();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader
          parentName={t("Sales Accounting")}
          icon="fas fa-home" />
      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col md={12} xs={12} sm={12} xl={12} lg={12}>

            <SalesAccountingSearchBar
              initSalesAccountingData={initSalesAccountingData}
              returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
              returnFilteredSortedSalesAccountingData={(filteredSortedpaymentData) => { setSalesAccountingData(filteredSortedpaymentData); }}
            >
            </SalesAccountingSearchBar>

            <Row className="mt-4">
              {salesAccountingData && Object.values(salesAccountingData).length >= 1 && salesAccountingData.map((salesAccoutingItem, index) => (
                <Col
                  key={index}
                  style={{ cursor: "pointer" }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  data-id={String(salesAccoutingItem.date)}
                >
                  <SalesAccountingCard
                    showFullCard={showFullCard}
                    salesAccountSingleDateData={salesAccoutingItem}
                    onClickCard={(paymentItem) => {
                      setSelectedSalesAccountingSingleDateData(paymentItem);
                      setEditModal(true);
                    }}
                    onClickInvoiceStatus={(startDate, endDate, invoiceStatus) => {
                      setModalInvoiceListModalStartDate(startDate);
                      setModalInvoiceListModalEndDate(endDate);
                      setModalInvoiceListModalInvoiceStatus(invoiceStatus);
                      setModalInvoiceListModalIsOpen(true);
                    }}
                    onClickDeliveryStatus={(startDate, endDate, deliveryStatus) => {
                      setModalDeliveryRecordListModalStartDate(startDate);
                      setModalDeliveryRecordListModalEndDate(endDate);
                      setModalDeliveryRecordListModalDeliveryStatus(deliveryStatus);
                      setModalDeliveryRecordListModalIsOpen(true);
                    }}
                    onClickPaymentMethod={(startDate, endDate, paymentMethod) => {
                      setModalPaymentListModalStartDate(startDate);
                      setModalPaymentListModalEndDate(endDate);
                      setModalPaymentListModalPaymentMethod(paymentMethod);
                      setModalPaymentListModalIsOpen(true);
                    }}
                    isSelectedCard={false}
                  />

                </Col>
              ))}
              {salesAccountingData.length === 0 && (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <strong className="text-info h1">
                        {t("No Data Found!")}
                      </strong>
                      <h3 className="text-info">
                        {t("Please Check Your Filter")}
                      </h3>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      {/* <PaymentCreateModal
        show={createModal}
        onHide={() => setCreateModal(false)}
        title={t("Add Payment")}
        button={t("Save")}
        refresh={refreshTableData}
      />
      <PaymentEditModal
        show={editModal}
        onHide={() => setEditModal(false)}
        title={t("Payment Detail")}
        button={t("Save")}
        refresh={refreshTableData}
        selectedData={selectedSalesAccountingSingleDateData}
      /> */}

      <SalesAccountingInvoiceListModal
        show={modalInvoiceListModalIsOpen}
        onHide={() => {
          setModalInvoiceListModalIsOpen(false);
        }}
        startDate={modalInvoiceListModalStartDate}
        endDate={modalInvoiceListModalEndDate}
        invoiceStatus={modalInvoiceListModalInvoiceStatus}
      />
      <SalesAccountingDeliveryRecordListModal
        show={modalDeliveryRecordListModalIsOpen}
        onHide={() => {
          setModalDeliveryRecordListModalIsOpen(false);
        }}
        startDate={modalDeliveryRecordListModalStartDate}
        endDate={modalDeliveryRecordListModalEndDate}
        deliveryStatus={modalDeliveryRecordListModalDeliveryStatus}
      />


      <SalesAccountingPaymentListModal
        show={modalPaymentListModalIsOpen}
        onHide={() => {
          setModalPaymentListModalIsOpen(false);
        }}
        startDate={modalPaymentListModalStartDate}
        endDate={modalPaymentListModalEndDate}
        paymentMethod={modalPaymentListModalPaymentMethod}
      />
    </>

  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default SalesAccountingScreen;
