import React from "react";
import { Checkbox } from "react-inputs-validation";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  FormGroup,
  Input,
  Row,
  Container,
} from "reactstrap";
import SearchFilterStatus from "../components/FilterStatus";
import SearchBar from "../components/SearchBar";
import SearchDateRangeBar from "../components/SearchDateRangeBar";
import SearchSortingBar from "../components/SearchSortingBar";
import BillRecordStatusFilter from "../components/BillRecordStatusFilter";
import { getRights } from "../../../functions/Auth";

function DeliveryRecordScreenFilterModal(props) {
  //Initial Const
  const today = new Date();
  const lastMonth = new Date(
    new Date(today.setDate(1)).setMonth(today.getMonth() - 1)
  );
  const initState = {
    scheduled: true,
    onTheWay: true,
    complete: true,
  };
  const columnsToSortTW = [
    {
      columnName: "proposedDateTime",
      name: "日期",
    },
    {
      columnName: "netPrice",
      name: "價錢",
    },
  ];

  const columnsToSort = [
    {
      columnName: "proposedDateTime",
      name: "Date",
    },
    {
      columnName: "netPrice",
      name: "Price",
    },
  ];

  //Get Set Const
  const { isOpen, toggle } = props;
  const [searchString, setSearchString] = React.useState("");
  const [searchStartDate, setSearchStartDate] = React.useState(lastMonth);
  const [searchEndDate, setSearchEndDate] = React.useState(today);
  const [deliveryStatusList, setDeliveryStatusList] = React.useState(initState);
  const data = [
    searchString,
    searchStartDate,
    searchEndDate,
    deliveryStatusList,
  ];

  //send Filter data to Parent
  async function sendFilter(e) {
    props.func(data);
    toggle(e);
  }
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Filter</ModalHeader>
        <ModalBody>
          <SearchDateRangeBar
            onStartDateChange={(date) => {
              setSearchStartDate(date);
            }}
            onEndDateChange={(date) => setSearchEndDate(date)}
            defaultStartDate={searchStartDate}
            defaultEndDate={searchEndDate}
            label="Date Range"
          />
          <SearchFilterStatus
            onFilterChange={(statusList) => setDeliveryStatusList(statusList)}
            label="Delivery Status"
          />
          {getRights() !== "driver" ? (
            <BillRecordStatusFilter
              onFilterChange={(statusList) => setBillStatusList(statusList)}
              label="Invoice Status"
            />
          ) : (
            ""
          )}
          <SearchSortingBar
            columnsToSort={columnsToSort}
            defaultColumn={columnsToSort[0]}
            onSortChange={(columnName, order) => {
              setSortColumnName(columnName);
              setSortOrder(order);
            }}
            label="Order By"
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={sendFilter}>
            Filter
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeliveryRecordScreenFilterModal;
