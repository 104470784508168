import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Notifications from "../components/Notifications";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Label,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";
import InputTypeText from "../components/InputTypeText.js";
// import InputTypeTextForm from "../components/InputTypeTextForm.js";
import CustomerModalPhoneCreate from "./CustomerModalPhoneCreate";
import {
  deleteObjectCustomer,
  storeCustomer,
  updateObjectCustomer,
} from "../../../functions/Customer";
import { useTranslation } from "react-i18next";
import GoodModalCreate from "../good/GoodModalCreate";
import CustomerModalPhoneEdit from "./CustomerModalPhoneEdit";
import SubmitButton from "../components/SubmitButton";


// props
// props.returnNewCustomerCreated(newCustomerData)

function CustomerModalCreate(props) {
  const { t } = useTranslation();

  const [customerName, setCustomerName] = React.useState("");
  const [customerDefaultRole, setCustomerDefaultRole] = React.useState("");
  const [customerDescription, setCustomerDescription] = React.useState("");
  const [customerEmail, setCustomerEmail] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("");
  const [customerPhones, setCustomerPhones] = React.useState([]);

  const [selectedCustomerPhone, setSelectedCustomerPhone] = React.useState({});

  const [modalCreatePhoneIsOpen, setModalCreatePhoneIsOpen] = React.useState(false);
  const [modalEditPhoneIsOpen, setModalEditPhoneIsOpen] = React.useState(false);


  const [deleteSelectedCustomerPhone, setDeleteSelectedCustomerPhone] =
    React.useState("");

  const getPhoneType = (type) => {
    if (type == "whatsapp") {
      return <i className="fab fa-whatsapp"></i>;
    } else if (type == "wechat") {
      return <i className="fab fa-weixin"></i>;
    } else if (type == "telegram") {
      return <i className="fab fa-telegram"></i>;
    } else if (type == "messenger") {
      return <i className="fab fa-facebook-messenger"></i>;
    } else if (type == "line") {
      return <i className="fab fa-line"></i>;
    } else {
      return "";
    }
  };

  const addNewPhone = (
    phoneNumberID,
    phoneUserName,
    phoneNumber,
    phoneType
  ) => {
    setCustomerPhones([...customerPhones, { phoneNumberID, phoneUserName, phoneNumber, phoneType }]);
  };

  const editPhone = (
    phoneNumberID,
    phoneUserName,
    phoneNumber,
    phoneType
  ) => {
    let updated = updateObjectCustomer(phoneNumberID, customerPhones, {
      phoneNumberID,
      phoneUserName,
      phoneNumber,
      phoneType,
    });
    setCustomerPhones(updated);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check customerName
    if (customerName === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a name")
      });
      return;
    }

    let dataCustomer = {
      customerName: customerName,
      customerDefaultRole: "customer",
      customerDescription: customerDescription,
      customerEmail: customerEmail,
      customerAddress: customerAddress,
      customerPhones: customerPhones,
    };
    let responseFromServer = await storeCustomer(dataCustomer);

    if (responseFromServer.code == 200) {
      clearForm();
      props.returnNewCustomerCreated(responseFromServer.data); // newCustomerData
      props.onHide();
    } else {
      // Hold here
    }

  };

  const clearForm = () => {
    setCustomerName("");
    setCustomerDefaultRole("");
    setCustomerDescription("");
    setCustomerEmail("");
    setCustomerAddress("");
    setCustomerPhones([]);
  };

  React.useEffect(() => {
    if (props.show) {

    }
  }, [props.show]);





  React.useEffect(() => {
    const deleteCustomerPhone = () => {
      setCustomerPhones(
        customerPhones.filter(
          (data) => data.phoneNumberID !== deleteSelectedCustomerPhone
        )
      );
      setDeleteSelectedCustomerPhone("");
    };
    if (deleteSelectedCustomerPhone !== "") {
      deleteCustomerPhone();
    }
  }, [deleteSelectedCustomerPhone]);
  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >

        <ModalHeader >{props.title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <InputTypeText
                label={`${t("customerName")}${"("}${t("Required")}${")"}`}
                labelFor="customerName"
                inputName="customerName"
                inputId="customerName"
                inputPlaceholder={t("customerName")}
                value={customerName}
                handleChange={(e) => setCustomerName(e.target.value)}
                required={true}
              />
              {/* <InputTypeText
              label="Default Role"
              labelFor="customerDefaultRole"
              inputName="customerDefaultRole"
              inputId="customerDefaultRole"
              inputPlaceholder={t("Default Role")}
              value={customerDefaultRole} handleChange={(e) => setCustomerDefaultRole(e.target.value)} /> */}
              <InputTypeText
                label={t("Description")}
                labelFor="customerDescription"
                inputName="customerDescription"
                inputId="customerDescription"
                inputPlaceholder={t("Description")}
                value={customerDescription}
                handleChange={(e) => setCustomerDescription(e.target.value)}
              />
              <InputTypeText
                label={t("Email")}
                labelFor="customerEmail"
                inputName="customerEmail"
                inputId="customerEmail"
                inputPlaceholder={t("Email")}
                value={customerEmail}
                handleChange={(e) => setCustomerEmail(e.target.value)}
              />
              <InputTypeText
                label={t("Address")}
                labelFor="customerAddress"
                inputName="customerAddress"
                inputId="customerAddress"
                inputPlaceholder={t("Address")}
                type="textarea"
                value={customerAddress}
                handleChange={(e) => setCustomerAddress(e.target.value)}
              />
              <Row className="mt-2">
                <Col>
                  <Row className="justify-content-between align-items-center mt-2">
                    <div className="col">
                      <Label className="form-control-label">
                        {t("Customer's Contact")}
                      </Label>
                    </div>
                    <Col className="col-auto">

                      <Button
                        color="primary"
                        id="tooltip443412080"
                        onClick={() => {
                          setModalCreatePhoneIsOpen(true);
                        }}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">{t("Add")}</span>
                      </Button>
                    </Col>
                  </Row>
                  <hr className="mt-3 mb-2"></hr>
                  <Row>
                    <Col>
                      <ToolkitProvider
                        data={customerPhones}
                        keyField="phoneNumberID"
                        columns={[
                          {
                            dataField: "phoneUserName",
                            text: t("Person in charges"),
                            sort: true,
                          },
                          {
                            dataField: "phoneNumber",
                            formatter: (cellContent, row) => {
                              return (
                                <>
                                  {getPhoneType(row.phoneType)}
                                  {"  "}
                                  {row.phoneNumber}
                                </>
                              );
                            },
                            text: t("Phone Number"),
                            sort: true,
                          },
                          {
                            isDummyField: true,
                            dataField: "Action",
                            text: t("Action"),
                            formatter: (
                              cellContent,
                              row,
                              rowIndex,
                              formatExtraData
                            ) => {
                              return (
                                <>
                                  <a
                                    className="table-action"
                                    id="tooltip209424781"
                                    onClick={() => {

                                      setSelectedCustomerPhone(row);
                                      setModalEditPhoneIsOpen(true);
                                    }}
                                  >
                                    <i className="fas fa-edit" />
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209424781"
                                  >
                                    {t("Edit")}
                                  </UncontrolledTooltip>
                                  <a
                                    className="table-action table-action-delete"
                                    onClick={(e) => {
                                      setDeleteSelectedCustomerPhone(
                                        row.phoneNumberID
                                      );
                                    }}
                                    type="button"
                                    id="tooltip12475020"
                                  >
                                    <i className="fas fa-trash" />
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip12475020"
                                  >
                                    {t("Delete")}
                                  </UncontrolledTooltip>
                                </>
                              );
                            },
                          },
                        ]}
                        search
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              // pagination={pagination}
                              bordered={true}
                              noDataIndication={
                                <>
                                  <center>
                                    <h4>{t("Phone Number Empty")}</h4>
                                  </center>
                                </>
                              }
                            />
                          </div>
                        )}
                      </ToolkitProvider>

                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="mt--5">

          <Row className="w-100 d-flex justify-content-between">
            <div>
              <Button
                color="danger"
                type="button" // type="button" means the form will not submit
                onClick={(e) => {
                  e.preventDefault();
                  clearForm();
                }}
              >
                {t("Reset")}
              </Button>
            </div>
            <div>
              <SubmitButton
                handleSubmit={(e) => { handleSubmit(e); }}
                buttonNormalText={"Save"}
              />
              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>
          </Row>
        </ModalFooter>

        <CustomerModalPhoneCreate
          title={t('New contact')}
          show={modalCreatePhoneIsOpen}
          onHide={() => setModalCreatePhoneIsOpen(false)}
          returnCustomerPhone={(phoneNumberID,
            phoneUserName,
            phoneNumber,
            phoneType) => { addNewPhone(phoneNumberID, phoneUserName, phoneNumber, phoneType) }}
        />

        <CustomerModalPhoneEdit
          show={modalEditPhoneIsOpen}
          onHide={() => setModalEditPhoneIsOpen(false)}
          title={t('Edit contact')}
          returnCustomerPhone={(phoneNumberID,
            phoneUserName,
            phoneNumber,
            phoneType) => { editPhone(phoneNumberID, phoneUserName, phoneNumber, phoneType) }}
          customerPhonesData={selectedCustomerPhone}
        />


      </Modal>

    </>
  );
}

export default CustomerModalCreate;
