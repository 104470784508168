import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { addNewStore } from "functions/Store.js";
import { addNewSystemAccess } from "functions/SystemAccess.js";
import { addNewJob } from "functions/UserJob.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form.js";
import { getJobTypeArray } from "functions/UserJob.js";


// props

function UserJobCreateModal(props) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [job, setJob] = React.useState('');
    const [jobNature, setJobNature] = React.useState('');
    const [jobType, setJobType] = React.useState('');

    const [jobTypeChooseSelect2Data, setJobTypeChooseSelect2Data] = React.useState([]);


    React.useEffect(() => {
        const getJobTypeArrayFromFcn = async () => {
            let data = await getJobTypeArray();
            setJobTypeChooseSelect2Data(data);
        };

        if (props.show) {
            getJobTypeArrayFromFcn();
        }

    }, [props.show]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        // data check
        if (job === "" || jobNature === "" || jobType === "") {
            Notifications({
                type: "dataCheck",
                message: t("Please input all details")
            });
            return;
        }

        let dataSubmit = {
            job: job,
            jobNature: jobNature,
        };

        setIsLoading(true);

        let resultJson = await addNewJob(dataSubmit);
        let jobFromDB = resultJson.job;
        if (jobFromDB && jobFromDB !== "") {
            clearForm();
            setFinishSubmitBoo(true);
            props.refreshTable();
            props.onHide();
        }

        setIsLoading(false);
    };

    const clearForm = () => {
        setJob('');
        setJobNature('');

        setFinishSubmitBoo(false);
    };

    const onChangeJob = (job) => {
        setJob(job);
    };

    const onChangeJobNature = (jobNature) => {
        setJobNature(jobNature);
    };

    const onChangeJobType = (jobType) => {
        setJobType(jobType);
    };

    return (
        <>
            <Modal
                className="modal-dialog"
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.onHide}>
                        <div>{t("Create new job")}</div>
                    </ModalHeader>
                    <ModalBody className="mt--4">
                        <InputTypeTextForm
                            label={`${t("Job")}${"("}${t("Required")}${")"}`}
                            labelFor="job"
                            inputName="job"
                            inputId="job"
                            inputPlaceholder={t("Job")}
                            value={job}
                            handleChange={(e) => onChangeJob(e.target.value)}
                            required={true}
                        />
                        <InputTypeTextForm
                            label={`${t("Job Nature")}${"("}${t("Required")}${")"}`}
                            labelFor="jobNature"
                            inputName="jobNature"
                            inputId="jobNature"
                            inputPlaceholder={t("Job Nature")}
                            value={jobNature}
                            handleChange={(e) => onChangeJobNature(e.target.value)}
                            required={true}
                        />

                        <InputTypeSelect2Form
                            useTranslate={true}
                            data={jobTypeChooseSelect2Data}
                            placeholder={t("--Job type--")}
                            value={jobType}
                            nameSelect2="Job type"
                            idSelect2="Job type"
                            label={t("Job type")}
                            handleOnChangeSelect={(e) => onChangeJobType(e.target.value)}
                        />


                    </ModalBody>
                    <ModalFooter className="mt--4">

                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>

                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>


                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default UserJobCreateModal;
