import React, { useState } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form.js";
import { getMembershipTypeSelect2 } from "functions/Membership.js";
import CustomerDisplayCard from "../customer/CustomerDisplayCard.js";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm.js";
import { postNewMembership } from "functions/Membership.js";

function MembershipCreateModal(props) {
    const { t } = useTranslation();

    const [customerData, setCustomerData] = React.useState({});

    // in a structure of [{id: xxx, text: yyy, obj: {....}}, {.......}]
    const [membershipTypeChooseList, setMembershipTypeChooseList] = React.useState([]);

    // Submit data
    const [submitMembershipTypeID, setSubmitMembershipTypeID] = React.useState("");
    const [submitEffectiveDate, setSubmitEffectiveDate] = React.useState(new Date());
    const [submitExpiryDate, setSubmitExpiryDate] = React.useState(new Date());

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    //Initial Get Good Data List
    React.useEffect(() => {
        const initMemberShipTypeChooseListData = async () => {
            let dataList = await getMembershipTypeSelect2();
            // in a structure of [{id: xxx, text: yyy, obj: {....}}, {.......}]
            setMembershipTypeChooseList(dataList);
        };
        if (props.show) {
            initMemberShipTypeChooseListData();
        }
    }, [props.show]);


    React.useEffect(() => {
        if (props.customerData && Object.keys(props.customerData).length >= 1) {
            setCustomerData(props.customerData);
        }
    }, [props.customerData]);

    const onChooseMemberShipType = async (membershipTypeID) => {
        setSubmitMembershipTypeID(membershipTypeID);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let dataToSubmit = {
            customerID: customerData.customerID,
            membershipTypeID: submitMembershipTypeID,
            effectiveDate: submitEffectiveDate,
            expiryDate: submitExpiryDate
        };

        let newMembershipID = await postNewMembership(dataToSubmit);

        if (newMembershipID && newMembershipID !== '') {
            setFinishSubmitBoo(true);
            clearForm();
            props.refreshTable();
            props.onHide();
        }
    };

    const clearForm = () => {
        // setCustomerData({});
        setSubmitMembershipTypeID("");
        setSubmitEffectiveDate(new Date());
        setSubmitExpiryDate(new Date());

        setFinishSubmitBoo(false);
    };



    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t("New membership")}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col md="12">
                                <CustomerDisplayCard
                                    hideCardHeader={true}
                                    customerData={props.customerData} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <InputTypeSelect2Form
                                    data={membershipTypeChooseList}
                                    useTranslate={true}
                                    placeholder={t("--Select Item--")}
                                    value={submitMembershipTypeID}
                                    nameSelect2="membershipTypeID"
                                    idSelect2="goodmembershipTypeID"
                                    label={t("Membership type")}
                                    handleOnChangeSelect={(e) => onChooseMemberShipType(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <InputDateTimeGroupForm
                                    label={t("Effective date")}
                                    labelFor="effectiveDate"
                                    inputName="effectiveDate"
                                    inputId="effectiveDate"
                                    inputPlaceholder={t("Effective date")}
                                    value={submitEffectiveDate}
                                    onDateChange={(e) => setSubmitEffectiveDate(e)}
                                    showTimeBoo={false}
                                />
                            </Col>
                            <Col md="6">
                                <InputDateTimeGroupForm
                                    label={t("Expiry date")}
                                    labelFor="expiryDate"
                                    inputName="expiryDate"
                                    inputId="expiryDate"
                                    inputPlaceholder={t("Expiry date")}
                                    value={submitExpiryDate}
                                    onDateChange={(e) => setSubmitExpiryDate(e)}
                                    showTimeBoo={false}
                                />
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>

                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default MembershipCreateModal;
