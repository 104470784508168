import React, { Suspense } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./i18n";

//Service Worker
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.1";
import "assets/css/main.css";

import App from './App';


import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import SpinnerOverlay from "views/pages/components/SpinnerOverlay";


const root = ReactDOM.createRoot(document.getElementById("root"));




root.render(
  <Suspense fallback={<SpinnerOverlay/>}>
    <React.Fragment>
     <App />
      {/* <Sentry.ErrorBoundary fallback="EEOR">
        <BrowserRouter>
          <Switch>
           <Route path="/work/signIn" render={(props) => <IndexView {...props} />} />
            <Route path="/work" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="*" to="/work" />
          </Switch>
        </BrowserRouter>
      </Sentry.ErrorBoundary> */}
    </React.Fragment>
  </Suspense>

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ scope: '/work/' });

//console.log('service worker registered.');
console.log('service worker registered.');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

