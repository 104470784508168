import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import reactRouterDom, { Link, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";
import { getGoodDatatable } from "functions/Goods";
import GoodModalCreate from "../good/GoodModalCreate";
import GoodModalEdit from "../good/GoodModalEdit";
import { deleteGoodByID } from "functions/Goods";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";
import GoodInventoryModalEdit from "../good/GoodInventoryModalEdit";
import { getInventoryColor } from "functions/GoodInventory";
import GoodInventoryBadge from "../components/GoodInventoryBadge";
import { getAllDeliveryTeam } from "functions/DeliveryTeam";
import DeliveryTeamCreateModal from "./DeliveryTeamCreateModal";
import { deleteDeliveryTeam } from "functions/DeliveryTeam";
import DeliveryTeamEditModal from "./DeliveryTeamEditModal";


function DeliveryTeamScreen(props) {
  const { t } = useTranslation();
  const pagination = paginationTable();

  const { SearchBar } = Search;

  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  const [deliveryTeamList, setDeliveryTeamList] = React.useState([]);

  const [selectedDeliveryTeamData, setSelectedDeliveryTeamData] = React.useState({});

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

  // Modal
  const [modalCreateDeliveryTeamIsOpen, setModalCreateDeliveryTeamIsOpen] = React.useState(false);
  const [modalEditDeliveryTeamIsOpen, setModalEditDeliveryTeamIsOpen] = React.useState(false);

  // Call API Customer
  React.useEffect(() => {
    getDataFromDB();
  }, [refreshTable]);

  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalCreateDeliveryTeamIsOpen || modalEditDeliveryTeamIsOpen);
    lockScrolling(modalCreateDeliveryTeamIsOpen || modalEditDeliveryTeamIsOpen);
  }, [modalCreateDeliveryTeamIsOpen, modalEditDeliveryTeamIsOpen]);


  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  const getDataFromDB = async () => {
    setIsLoading(true);
    let dataFromDB = await getAllDeliveryTeam();
    setDeliveryTeamList(dataFromDB);
    setIsLoading(false);
  };

  // Delete Confirmation
  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deleteDeliveryTeamProcess,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function deleteDeliveryTeamProcess(deliveryTeamID) {
    let deleteResponse = await deleteDeliveryTeam(deliveryTeamID);

    deleteResponse.code == 200 ? refreshTableData() : true;
  }


  // config selected delivery team
  async function configSelectedDeliveryTeam(deliveryTeamID, deliveryTeamName, driverID, carID, truckWorkerIDs) {


    // truckWorkerIDs = {
    //   truckWorkerID: 'sdfsf',
    //   truckWorkerID: 'sdfsf',
    //   ...
    // }

    setSelectedDeliveryTeamData({
      deliveryTeamID: deliveryTeamID,
      deliveryTeamName: deliveryTeamName,
      driverID: driverID,
      carID: carID,
      truckWorkerIDs: truckWorkerIDs
    });
  }


  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getDataFromDB();
        }}
        disabled={pullToRefreshDisable}>
        <SimpleHeader parentName={t("Products")} icon="fas fa-home" />

      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-4rem" }} fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <ToolkitProvider
                  data={deliveryTeamList}
                  keyField="deliveryTeamID"
                  columns={
                    [
                      // { dataField: "goodType", text: "", hidden: true },
                      // { dataField: "goodSubType", text: "", hidden: true },
                      // { dataField: "goodDescription", text: "", hidden: true },
                      // { dataField: "goodDefaultUnitQuantity", text: "", hidden: true },
                      { dataField: "deliveryTeamName", text: "", hidden: true },
                      { dataField: "carID", text: "", hidden: true },
                      { dataField: "driverID", text: "", hidden: true },
                      {
                        dataField: "",
                        text: t("Team"),
                        formatter: (cellContent, row, rowIndex, extraData) => {
                          return (
                            <div
                              className="">
                              <>

                                <a
                                  className="table-action table-action-delete"
                                  id="tooltip12475020"
                                  onClick={() =>

                                    deleteConfirmation(row.deliveryTeamID)
                                  }
                                >
                                  {" "}
                                  <i
                                    className="fas fa-trash fa-lg"
                                    data-id={cellContent}
                                  />
                                </a>
                                <UncontrolledTooltip
                                  delay={0}
                                  target="tooltip12475020"
                                >
                                  {t("Delete")}
                                </UncontrolledTooltip>
                              </>
                              <div
                                className="mousePointer"
                                onClick={() => {

                                  let truckWorkerIDList = [];
                                  if (row.DeliveryTeamTruckWorkerMatches && Array.isArray(row.DeliveryTeamTruckWorkerMatches) && row.DeliveryTeamTruckWorkerMatches.length >= 1) {
                                    truckWorkerIDList = row.DeliveryTeamTruckWorkerMatches.map((item, index) => {
                                      return {truckWorkerID: item.truckWorkerID}
                                    })
                                  }

                                  configSelectedDeliveryTeam(
                                    row.deliveryTeamID,
                                    row.deliveryTeamName,
                                    row.driverID,
                                    row.carID,
                                    truckWorkerIDList
                                  );
                                  setModalEditDeliveryTeamIsOpen(true);
                                }}>
                                <div className="tableRowTitle">
                                  {row.deliveryTeamName}
                                </div>
                                <div className="mt-1">
                                  <Badge className="statusBadge" color="default">
                                    {row.Driver?.SystemUser.nickName}
                                  </Badge>
                                  <Badge className="statusBadge" color="warning">
                                    {row.carID}
                                  </Badge>
                                </div>
                                {/* <div className="tableRowSecondTitle">
                                  {"$" + row.goodDefaultUnitPrice +
                                    " / " + row.goodDefaultUnitQuantity +
                                    row.goodUnit}
                                  {row.goodDefaultWeight == 0 ? "" : " (" + row.goodDefaultWeight + "kg)"}
                                </div>
                                <div className="tableRowSubSubTitle">
                                  {row.goodType}-{row.goodSubType}-{row.goodNameShortForm}
                                </div>
                                <div className="tableRowRemark">
                                  {row.goodRemark}
                                </div> */}

                              </div>
                              <div className="mt-1 d-flex">
                                {(row.DeliveryTeamTruckWorkerMatches && Array.isArray(row.DeliveryTeamTruckWorkerMatches) && row.DeliveryTeamTruckWorkerMatches.length >= 1) &&
                                  row.DeliveryTeamTruckWorkerMatches.map((item, index) => {
                                    return (
                                      <Badge className="statusBadge" color="primary">
                                        {item.TruckWorker?.SystemUser?.nickName}

                                      </Badge>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          );
                        },
                      }
                    ]
                    // : [
                    //   {
                    //     dataField: "goodDescription",
                    //     text: t("Item Name"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodNameShortForm",
                    //     text: t("Short Form"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodUnit",
                    //     formatter: (cellContent, row) => {
                    //       return (
                    //         <>
                    //           {row.goodDefaultUnitPrice +
                    //             " /" +
                    //             row.goodUnit}
                    //         </>
                    //       );
                    //     },
                    //     text: t("Price/Unit"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodDefaultUnitQuantity",
                    //     text: t("Quantity Left"),
                    //     sort: true,
                    //   },
                    //   {
                    //     dataField: "goodRemark",
                    //     text: t("Remark"),
                    //     sort: true,
                    //   },
                    //   {
                    //     isDummyField: true,
                    //     dataField: "Action",
                    //     text: t("Action"),
                    //     formatter: (cellContent, row) => {
                    //       return (
                    //         <>
                    //           <a
                    //             style={{ cursor: "pointer" }}
                    //             className="table-action"
                    //             id="tooltip209424781"
                    //             onClick={() => {
                    //               setSelectedGoodData(row);
                    //               setModalEditIsOpen(true);
                    //             }}
                    //           >
                    //             <i className="fas fa-edit" />
                    //           </a>
                    //           <UncontrolledTooltip
                    //             delay={0}
                    //             target="tooltip209424781"
                    //           >
                    //             {t("Edit")}
                    //           </UncontrolledTooltip>
                    //           <a
                    //             style={{ cursor: "pointer" }}
                    //             className="table-action table-action-delete"
                    //             id="tooltip12475020"
                    //             onClick={() =>
                    //               deleteConfirmation(row.goodID)
                    //             }
                    //           >
                    //             <i
                    //               className="fas fa-trash"
                    //               data-id={cellContent}
                    //             />
                    //           </a>
                    //           <UncontrolledTooltip
                    //             delay={0}
                    //             target="tooltip12475020"
                    //           >
                    //             {t("Delete")}
                    //           </UncontrolledTooltip>
                    //         </>
                    //       );
                    //     },
                    //   },
                    // ]
                  }
                  search
                >
                  {(props) => (
                    <div className="py-2 table-responsive table-sm">
                      <Row className="pb-2">
                        <Col xs={7} sm={7} md={7} lg={7}>
                          <SearchBar
                            className=""
                            placeholder={t("Search")}
                            {...props.searchProps}
                          />
                        </Col>
                        <Col
                          className={"text-right"}
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                        >
                          <Button
                            color="primary"
                            id="tooltip443412080"
                            onClick={() => setModalCreateDeliveryTeamIsOpen(!modalCreateDeliveryTeamIsOpen)}
                            size="md"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{t("Add")}</span>
                          </Button>
                          {/* <UncontrolledTooltip
                            delay={1}
                            target="tooltip443412080"
                          >
                            {t("Create")}
                          </UncontrolledTooltip> */}
                        </Col>
                      </Row>

                      <BootstrapTable
                        headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>


      <DeliveryTeamCreateModal
        show={modalCreateDeliveryTeamIsOpen}
        onHide={(e) => {
          setModalCreateDeliveryTeamIsOpen(false);
        }}
        title={t("Create new team")}
        button={t("Add")}
        refreshTable={refreshTableData}
      />


      <DeliveryTeamEditModal
        show={modalEditDeliveryTeamIsOpen}
        onHide={() => setModalEditDeliveryTeamIsOpen(false)}
        title={t("Edit delivery team")}
        button={t("Update")}
        deliveryTeamData={selectedDeliveryTeamData}
        refreshTable={refreshTableData}
      />



    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default DeliveryTeamScreen;
