import React from 'react';
import * as Sentry from "@sentry/react";
import {  BrowserRouter as Router, Route} from "react-router-dom";

import SignIn from "views/Index.js";
import Admin from "layouts/Admin";

 //import './App.css'
import {  isLoggedIn } from "./functions/Auth";
// import ErrorScreen from "./screens/ErrorScreen";


function App() {
  //  Update user list
  React.useEffect(() => {

  }, []);



  //  Rendering
  return (
    <Sentry.ErrorBoundary fallback="Error">
      <Router>
        {isLoggedIn() &&
          <Route render={(props) => <Admin {...props} />} />
        }
        {!isLoggedIn() &&
          <Route render={(props) => <SignIn {...props} />} />
        }
      </Router>
      {/* <Sentry.ErrorBoundary fallback="EEOR">
        <BrowserRouter>
          <Switch>
           <Route path="/work/signIn" render={(props) => <IndexView {...props} />} />
            <Route path="/work" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="*" to="/work" />
          </Switch>
        </BrowserRouter>
      </Sentry.ErrorBoundary> */}
    </Sentry.ErrorBoundary>
  )
}

export default App;

