import { updateUserJobMatch } from "functions/UserJobMatch";

import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";


// props
// userID
// job
// jobRemark

function UserModalMatchJobEdit(props) {
    const { t } = useTranslation();

    const [userID, setUserID] = React.useState("");
    const [job, setJob] = React.useState("");
    const [jobRemark, setJobRemark] = React.useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userID === "") {
            Notifications({
                type: "dataCheck"
                , message: 'userID is invalid.'
            });
            return;
        }
        if (job === "") {
            Notifications({
                type: "dataCheck"
                , message: t('Job is invalid.')
            });
            return;
        }

        try {
            await updateUserJobMatch(userID, job, jobRemark);
            props.refreshTable();
            props.onHide();
        } catch (err) {
            Notifications();
        }
    };

    React.useEffect(() => {
        if (props.show) {
            setUserID(props.userID);
            setJob(props.job);
            setJobRemark(props.jobRemark);
        }
    }, [props.show]);




    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t("Choose job")}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            label={t("Job remark")}
                                            labelFor="jobRemark"
                                            inputName="jobRemark"
                                            inputId="jobRemark"
                                            inputPlaceholder={t("Job remark")}
                                            value={jobRemark}
                                            handleChange={(e) => {
                                                setJobRemark(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {t("Confirm")}
                        </Button>
                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default UserModalMatchJobEdit;
