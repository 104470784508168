import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DeliveryAddressSelectInput from "../components/DeliveryAddressSelectInput";
import { getDeliveryRecordsByAddressID } from "functions/Delivery";
import DeliveryRecordCard from "../../../views/pages/deliveryRecord/DeliveryRecordCard";
import DeliveryRecordScreenFilterModal from "../../../views/pages/deliveryRecord/DeliveryRecordScreenFilterModal";

import CustomerActionModal from "../customer/CustomerActionModal";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal"

import InvoiceMessageModal from "../invoice/InvoiceMessageModal";

import DeliveryRecordEditModal from "../deliveryRecord/DeliveryRecordEditModal";
import DeliveryRecordCreateModal from "../deliveryRecord/DeliveryRecordCreateModal";
import DeliveryRecordMessageModal from "../deliveryRecord/DeliveryRecordMessageModal";
// props
// onHide
// show
// preSelectAddressID
// canChooseAddress

function AddressRelatedDeliveryRecord(props) {
    const { t } = useTranslation();

    const [refreshTable, setRefreshTable] = React.useState(1);

    const [selectedAddressID, setSelectedAddressID] = React.useState("");

    const [selectedAddressData, setSelectedAddressData] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    const { addressDetailUnitFloor, addressDetailBuilding, addressDetailStreet, addressDetailDistrict, addressDetailMainDistrict, addressRemark, addressGPSLatitude, addressGPSLongtitude } = selectedAddressData;

    const [displayDeliveryRecordData, setDisplayDeliveryRecordData] = React.useState([]);




    const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [modalDeliveryRecordEditIsOpen, setModalDeliveryRecordEditIsOpen] = React.useState(false);
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);

    const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});

    // For deliveryRecordCard
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [selectedDeliveryRecodData, onSelectedDeliveryRecodData] = React.useState({});
    const [selectedCustomerData, setSelectedCustomerData] = React.useState({});


    const [modalDeliveryRecordAddIsOpen, setModalDeliveryRecordAddIsOpen] = React.useState(false);
    const [modalDeliveryRecordMessageIsOpen, setModalDeliveryRecordMessageIsOpen] = React.useState(false);

    const [deliveryRecordSelectedDataForClone, setDeliveryRecordSelectedDataForClone] = React.useState({});

    function handleOnChangeDeliveryAddressID(deliveryAddressID) {
        setSelectedAddressID(deliveryAddressID);
    }

    function handleOnChangeDeliveryAddressData(deliveryAddressData) {
        setSelectedAddressData({
            addressDetailUnitFloor: deliveryAddressData?.addressDetailUnitFloor,
            addressDetailBuilding: deliveryAddressData?.addressDetailBuilding,
            addressDetailStreet: deliveryAddressData?.addressDetailStreet,
            addressDetailDistrict: deliveryAddressData?.addressDetailDistrict,
            addressDetailMainDistrict: deliveryAddressData?.addressDetailMainDistrict,
            addressRemark: deliveryAddressData?.addressRemark,
            addressGPSLatitude: deliveryAddressData?.addressGPSLatitude,
            addressGPSLongtitude: deliveryAddressData?.addressGPSLongtitude
        });
    }

    const getDeliveryRecordsByAddressIDFromDB = async (deliveryAddressID) => {
        let deliveryRecord = await getDeliveryRecordsByAddressID(deliveryAddressID);

        


        setDisplayDeliveryRecordData(deliveryRecord);
    }

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    React.useEffect(() => {
        const clearForm = () => {
            setSelectedAddressID("")
        };

        if (!props.show) {
            clearForm();
        }
        if (props.show) {
            if (props.preSelectAddressID) {
                setSelectedAddressID(props.preSelectAddressID);
            }

        }
    }, [props.show]);

    /*     React.useEffect(() => {
            if (props.preSelectAddressID) {
                setSelectedAddressID(props.preSelectAddressID);
            }
        }, [props.preSelectAddressID]); */


    React.useEffect(() => {
        if (selectedAddressID) {
            getDeliveryRecordsByAddressIDFromDB(selectedAddressID);
        }
    }, [selectedAddressID, refreshTable]);


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader toggle={props.onHide}>{t("Delivery record related to this address")}</ModalHeader>
             
             
                <ModalBody className="mt--4">
                    <Row>
                        <Col>
                            <DeliveryAddressSelectInput
                                readOnly={!props.canChooseAddress}
                                preSelectAddressID={selectedAddressID}
                                returnSelectedAddressID={(deliveryAddressID) => {
                                    handleOnChangeDeliveryAddressID(deliveryAddressID);
                                }}
                                returnSelectedAddressData={(deliveryAddressData) => {
                                    handleOnChangeDeliveryAddressData(deliveryAddressData);
                                    // setUseNewAddress(false);
                                    //  setEditAddress(false);
                                }}
                                showLabel={true}
                            />
                        </Col>
                    </Row>

                    <Row>
                        {displayDeliveryRecordData && Object.values(displayDeliveryRecordData).length >= 1 && displayDeliveryRecordData.map((delivery, index) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                data-id={delivery.deliveryID}
                                key={delivery.deliveryID}
                            >
                                <DeliveryRecordCard
                                    showFullCard={false}
                                    deliveryRecordData={delivery}
                                    setClickViewInvoiceButton={(invoice) => {
                                        setSelectedInvoice(invoice);
                                        setModalInvoiceMessageIsOpen(true);
                                    }}
                                    onClickCard={(deliveryRecord) => {
                                        onSelectedDeliveryRecodData(deliveryRecord);
                                        setModalDeliveryRecordEditIsOpen(true);
                                    }}
                                    isSelectedCard={false}
                                    cloneInvoice={(invoice) => {
                                        setInvoiceSelectedDataForInvoiceClone(invoice);
                                        setModalInvoiceCreateIsOpen(true);
                                    }}
                                    customerAction={(customer) => {
                                        setSelectedCustomerData(customer);
                                        setModalCustomerActionIsOpen(true);
                                    }}
                                    cloneDeliveryRecord={(deliveryRecord) => {
                                        setDeliveryRecordSelectedDataForClone(deliveryRecord);
                                        setModalDeliveryRecordAddIsOpen(true);
                                    }}
                                    showDeliveryMessage={(deliveryRecord) => {
                                        onSelectedDeliveryRecodData(deliveryRecord);
                                        setModalDeliveryRecordMessageIsOpen(true);
                                    }}
                                />


                            </Col>
                        ))}
                        {displayDeliveryRecordData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Delivery Record Found!")}
                                        </strong>
                                        <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>


                </ModalBody>
                <ModalFooter className="mt--4">
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>


                <CustomerActionModal
                    show={modalCustomerActionIsOpen}
                    onHide={() => setModalCustomerActionIsOpen(false)}
                    title={t("Choose Action")}
                    button={t("Update")}
                    customerData={selectedCustomerData}
                    refreshTable={refreshTableData}
                />
                <InvoiceMessageModal
                    show={modalInvoiceMessageIsOpen}
                    onHide={() => setModalInvoiceMessageIsOpen(false)}
                    title={t("Invoice Message")}
                    refreshTable={() => { }}
                    invoiceData={selectedInvoice}
                />
                <DeliveryRecordEditModal
                    show={modalDeliveryRecordEditIsOpen}
                    deliveryData={selectedDeliveryRecodData}
                    onHide={() => {
                        refreshTableData();
                        setModalDeliveryRecordEditIsOpen(false);
                    }}
                />
                <InvoiceCreateModal
                    invoiceToClone={invoiceSelectedDataForInvoiceClone}
                    show={modalInvoiceCreateIsOpen}
                    onHide={(returnCreatedInvoiceID) => {
                        setModalInvoiceCreateIsOpen(false);
                        refreshTableData();
                    }}
                    allowEmptySubitemList={true}
                />

                <DeliveryRecordCreateModal
                    show={modalDeliveryRecordAddIsOpen}
                    invoiceData={deliveryRecordSelectedDataForClone.Invoice}
                    deliveryRecordToClone={deliveryRecordSelectedDataForClone}
                    onHide={() => {
                        refreshTableData();
                        setModalDeliveryRecordAddIsOpen(false);
                    }}
                />

                <DeliveryRecordMessageModal
                    show={modalDeliveryRecordMessageIsOpen}
                    onHide={() => setModalDeliveryRecordMessageIsOpen(false)}
                    title={t("Delivery Message")}
                    refreshTable={() => { }}
                    deliveryRecordData={selectedDeliveryRecodData}
                />
            </Modal>
        </>
    );
}

export default AddressRelatedDeliveryRecord;
