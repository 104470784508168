import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardText,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InvoiceBadge from "../components/InvoiceBadge.js";
import PaymentBadge from "../components/PaymentBadge.js";
import { getDateString } from "functions/Utility";


//props:
//paymentData
//onClickCard
//isSelectedCard
function PaymentCard(props) {

    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;
    const payment = props.paymentData;
    const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;

    const [whiteText, setWhiteText] = React.useState("");

    const [showFullCard, setShowFullCard] = React.useState(props.showFullCard ? props.showFullCard : true);// default tshow full card

    React.useEffect(() => {
        setWhiteText(" " + (props.enableCardBgColor ? "cardWhiteText" : ""));
    }, [props.enableCardBgColor]);

    React.useEffect(() => {
        setShowFullCard(props.showFullCard);
    }, [props.showFullCard]);


    return payment ? (

        <Card
            style={{ cursor: "pointer" }}
            className={isSelectedCard ? "bg-gradient-success" : ""}
        >
            <CardHeader className={props.enableCardBgColor ? props.cardBgColor : ""}>
                <div className={"d-flex justify-content-between" + whiteText}>
                    <div className="ml--1">
                        <Button
                            className="mb-1"
                            color="primary"
                            size="sm"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowFullCard(!showFullCard);
                            }}>
                            <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                        </Button>
                        <PaymentBadge
                            paymentData={payment}
                            badgeType="paymentStatus"
                            badgeClassName="statusBadge"
                        />
                        <PaymentBadge
                            paymentData={payment}
                            badgeType="paymentMethod"
                            badgeClassName="statusBadge"
                        />
                    </div>
                    <div className="cardIconButton">
                        <i className="ni ni-credit-card"
                            id={`tooltipRCURL${payment?.paymentID}`}
                            onClick={(e) => {
                                props.clickPaymentMessage(payment);
                            }} />
                        <UncontrolledTooltip
                            delay={1}
                            target={`tooltipRCURL${payment?.paymentID}`}>
                            {t("Payment")}
                        </UncontrolledTooltip>
                    </div>

                </div>
                <Row onClick={() => { props.onClickCard(payment); }}>
                    <Col>
                        <div className={"tableRowTitle" + whiteText}>
                            <span>${payment.paymentPrice}</span>
                            <span>(</span>
                            <span>
                                {payment?.Customer?.customerName === undefined
                                    ? "-"
                                    : payment?.Customer.customerName}
                            </span>
                            <span>)</span>
                        </div>
                        <div className={"font-weight-bold" + whiteText}>
                            {`${getDateString(payment.paymentRecordDateTime, activeLang === "cn")}`}
                        </div>
                        <div className={"tableRowRemark" + whiteText}>
                            {payment.paymentRefNumber !== "" ? ` ${payment.paymentRefNumber}` : ""}{payment.paymentRemark !== "" ? ` | ${payment.paymentRemark}` : ""}
                        </div>
                    </Col>
                </Row>
            </CardHeader>

            {showFullCard &&
                <CardBody
                    className={props.enableCardBgColor ? props.cardBgColor : ""}
                    onClick={() => { props.onClickCard(payment); }}>
                    <Row>
                        <Col>
                            <div
                                style={{ fontSize: "12px" }}
                                className={"text-muted mt--2" + whiteText}
                            >
                                {t("Invoice Number")}:
                            </div>
                            {payment.PaymentInvoiceMatches.map((val, i) => (
                                <div key={i}
                                    className={"d-flex justify-content-between" + whiteText}   >
                                    <div className={"font-weight-bold"}>
                                        {val.invoiceID}{" "}
                                        <InvoiceBadge
                                            invoiceData={val.Invoice}
                                            badgeType="invoiceStatus"
                                            badgeClassName="statusBadgeSmall"
                                        />
                                    </div>
                                    <div className={"font-weight-bold" + whiteText}>
                                        ${val.paidAmount}
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </CardBody>
            }
            {showFullCard &&
                <CardFooter
                    className={props.enableCardBgColor ? props.cardBgColor : ""}
                    onClick={() => { props.onClickCard(payment); }}>
                    <Row>
                        <Col>
                            <div
                                style={{ fontSize: "12px" }}
                                className={"text-muted mt--2" + whiteText}
                            >
                                {t("User")}:
                            </div>
                            <div className={"tableRowSubTitle" + whiteText}>
                                {payment.paymentRecordUser}
                            </div>
                        </Col>
                    </Row>


                </CardFooter>
            }
        </Card>
    ) : (
        <>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardBody>
                        <strong className="text-info h1">
                            {t("No Data Found!")}
                        </strong>
                        <h3 className="text-info">
                            {t("Please Check Your Filter")}
                        </h3>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
}

export default PaymentCard;
