import React from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    Col,
    Row,
    div,
    Collapse
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//invoiceListData
function DeliveryStatCard(props) {

    const [expandStat, setExpandStat] = React.useState(false);

    const { t } = useTranslation();
    const deliveryListData = props.deliveryListData;

    const deliveryListDataPending = deliveryListData.filter((deliveryItem) => deliveryItem.deliveryStatus?.toLowerCase() === "pending");
    const deliveryListDataProgress = deliveryListData.filter((deliveryItem) => deliveryItem.deliveryStatus?.toLowerCase() === "progress");
    const deliveryListDataFinish = deliveryListData.filter((deliveryItem) => deliveryItem.deliveryStatus?.toLowerCase() === "finish");
    const deliveryListDataCancel = deliveryListData.filter((deliveryItem) => deliveryItem.deliveryStatus?.toLowerCase() === "cancel");


    return (

        <Card className="card-stats">

            <CardHeader
                role="tab"
                aria-expanded={expandStat}
            >

                <Row>
                    <div className="col">
                        <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                        >
                            {t("Total")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            {deliveryListData.length}
                        </span>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow"
                            onClick={() => setExpandStat(!expandStat)}>
                            <i className={expandStat ? "fa fa-angle-up" : "fa fa-angle-down"} />

                        </div>
                    </Col>
                </Row>
            </CardHeader>
            <Collapse
                role="tabpanel"
                isOpen={expandStat}
            >
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Pending")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {deliveryListDataPending.length}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <i className="ni ni-watch-time" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>

                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Progress")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {deliveryListDataProgress.length}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="ni ni-delivery-fast" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>

                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Finish")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {deliveryListDataFinish.length}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                <i className="ni ni-check-bold" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>

    );
}

export default DeliveryStatCard;
