import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//paymentListData
//paymentFilterString :string
//hideIfZero
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
//badgeType : "paymentStatus" / "paymentMethod"
function PaymentCountBadge(props) {

    const { t } = useTranslation();
    const paymentList = props.paymentListData;
    const paymentFilterString = props.paymentFilterString;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";


    function textToShow() {
        if (!paymentList || Object.values(paymentList).length === 0) {
            return "";
        }
        let filteredList = paymentList.filter((paymentItem) => {

            if (props.badgeType == "paymentStatus") {
                return paymentItem.paymentStatus.toLowerCase() === paymentFilterString.toLowerCase();
            } else if (props.badgeType == "paymentMethod") {
                return paymentItem.paymentMethod.toLowerCase() === paymentFilterString.toLowerCase();
            } else {
                return "";
            }
        });
        if (!filteredList) {
            return "";
        }
        if (props.hideIfZero && filteredList.length === 0) {
            return "";
        }
        return `${filteredList.length}${" "}${t(paymentFilterString.toLowerCase())}`
    }

    function badgeColor() {
        switch (paymentFilterString) {
            case 'unpaid':
                return "danger";
            case 'paid':
                return "success";
            case 'cancel':
                return "seceondary";
            case 'cash':
                return "info";
            case 'cheque':
                return "success";
            case 'transfer':
                return "warning";
            default:
                return "seceondary";
        }
    };

    return (
        <Badge
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
        >
            {textToShow()}
        </Badge>
    );
}

export default PaymentCountBadge;
