import React from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    Col,
    Row,
    div,
    Collapse
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//invoiceListData
function InvoiceStatCard(props) {

    const [expandStat, setExpandStat] = React.useState(false);

    const { t } = useTranslation();
    const invoiceListData = props.invoiceListData;

    const invoiceListDataUnpaid = invoiceListData.filter((invoiceItem) => invoiceItem.invoiceStatus.toLowerCase() === "unpaid");
    const invoiceListDataPartial = invoiceListData.filter((invoiceItem) => invoiceItem.invoiceStatus.toLowerCase() === "partial");
    const invoiceListDataPaid = invoiceListData.filter((invoiceItem) => invoiceItem.invoiceStatus.toLowerCase() === "paid");
    const invoiceListDataCancel = invoiceListData.filter((invoiceItem) => invoiceItem.invoiceStatus.toLowerCase() === "cancel");

    const totalPrice = invoiceListData.reduce((total, currentValue) => total = total + currentValue.invoiceNetPrice, 0);
    const totalPriceUnpaid = invoiceListDataUnpaid.reduce((total, currentValue) => total = total + currentValue.invoiceNetPrice, 0);
    const totalPricePartial = invoiceListDataPartial.reduce((total, currentValue) => total = total + currentValue.invoiceNetPrice, 0);
    const totalPricePaid = invoiceListDataPaid.reduce((total, currentValue) => total = total + currentValue.invoiceNetPrice, 0);
    const totalPriceCancel = invoiceListDataCancel.reduce((total, currentValue) => total = total + currentValue.invoiceNetPrice, 0);


    return (

        <Card className="card-stats">
            <CardHeader
                role="tab"
                aria-expanded={expandStat}
            >
                <Row>
                    <div className="col">
                        <CardTitle
                            tag="h6"
                            className="text-uppercase text-muted mb-0"
                        >
                            {t("Total")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            {invoiceListData.length} {t("items")} {` ($${totalPrice})`}
                        </span>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-purple text-white rounded-circle shadow mousePointer"
                            onClick={() => setExpandStat(!expandStat)}>
                            <i className={expandStat ? "fa fa-angle-up" : "fa fa-angle-down"} />
                        </div>
                    </Col>
                </Row>

            </CardHeader>

            <Collapse
                role="tabpanel"
                isOpen={expandStat}
            >

                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Unpaid")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {invoiceListDataUnpaid?.length} {t("items")}{" "}{`($${totalPriceUnpaid})`}{" "}{`${(totalPriceUnpaid / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <i className="ni ni-watch-time" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>

                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Partial")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {invoiceListDataPartial?.length} {t("items")}{" "}{`($${totalPricePartial})`}{" "}{`${(totalPricePartial / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                <i className="ni ni-delivery-fast" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Paid")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {invoiceListDataPaid?.length} {t("items")}{" "}{`($${totalPricePaid})`}{" "}{`${(totalPricePaid / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="far fa-check-circle" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Cancel")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {invoiceListDataCancel?.length} {t("items")}{" "}{`($${totalPriceCancel})`}{" "}{`${(totalPriceCancel / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="far fa-times-circle" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>


        </Card>
    );
}

export default InvoiceStatCard;
