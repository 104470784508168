
import api, { apiGet, apiPost, apiDelete, apiPut } from "./Api";
import renameKeys from "./RenameKey";
import Notifications from "views/pages/components/Notifications";



export const configLocalUserLocalSetting = async (userID) => {
  let userLocalSettingData;
  userLocalSettingData = await getLocalSettingByUserID(userID);
  
  localStorage.removeItem("systemUserLocalSetting");
  localStorage.setItem("systemUserLocalSetting", JSON.stringify(userLocalSettingData));
}

export const getUserLocalSetting = () => {
  let userLocalSettingData = localStorage.getItem("systemUserLocalSetting");
  if (userLocalSettingData !== null) {
    return JSON.parse(userLocalSettingData);
  } else {
    return [];
  }
}


export const getUserLocalSettingItemValue = (settingKey) => {
  let userLocalSettingData = getUserLocalSetting();

  if (!userLocalSettingData) return "";
  if (Object.values(userLocalSettingData) <= 0) return "";
  if (!Array.isArray(userLocalSettingData)) return "";
  if (userLocalSettingData.length <= 0) return "";

  let foundItem = userLocalSettingData.find((item) => item.settingKey === settingKey);

  if (!foundItem) return "";
  if (Object.values(foundItem) <= 0) return "";

  return foundItem.settingValue;
}

export const getLocalSettingByUserID = async (userID) => {
  try {
    let response = await apiGet({
      url: `systemLocalSetting/getSettingByUserID/${userID}`,
      responseType: "",
    });
    return response.status == 200
      ? response.data.data.rows
      : response.status;
  } catch (e) {
    return [];
  }
};

// if success return 200
export const addNewLocalSetting = async (userID, settingKey, settingValue, settingDetail) => {
  let response = await apiPost({
    url: "systemLocalSetting/addNewLocalSetting",
    data: {
      userID: userID,
      settingKey: settingKey,
      settingValue: settingValue,
      settingDetail: settingDetail
    },
    responseType: "",
  });


  return response.status == 200
    ? response.status
    : response.status;
};

export const updateLocalSetting = async (userID, settingKey, settingValue, settingDetail) => {
  let response = await apiPut({
    url: "systemLocalSetting/updateLocalSetting",
    data: {
      userID: userID,
      settingKey: settingKey,
      settingValue: settingValue,
      settingDetail: settingDetail
    },
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteLocalSetting = async (userID, settingKey) => {
  let response = await apiDelete({
    url: `systemLocalSetting/deleteLocalSetting/${userID}/${settingKey}`,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};



