import React from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    Col,
    Row,
    div,
    Collapse
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//invoiceListData
function PaymentStatCard(props) {

    const [expandStat, setExpandStat] = React.useState(false);

    const { t } = useTranslation();
    const paymentListData = props.paymentListData;

    const paymentListDataProgress = paymentListData.filter((paymentItem) => paymentItem.paymentStatus.toLowerCase() === "progress");
    const paymentListDataPaid = paymentListData.filter((paymentItem) => paymentItem.paymentStatus.toLowerCase() === "paid");
    const paymentListDataCancel = paymentListData.filter((paymentItem) => paymentItem.paymentStatus.toLowerCase() === "cancel");

    const totalPrice = paymentListData.reduce((total, currentValue) => total = total + currentValue.paymentPrice, 0);
    const totalPriceProgress = paymentListDataProgress.reduce((total, currentValue) => total = total + currentValue.paymentPrice, 0);
    const totalPricePaid = paymentListDataPaid.reduce((total, currentValue) => total = total + currentValue.paymentPrice, 0);
    const totalPriceCancel = paymentListDataCancel.reduce((total, currentValue) => total = total + currentValue.paymentPrice, 0);


    return (

        <Card className="card-stats">
            <CardHeader
                role="tab"
                aria-expanded={expandStat}
            >
                <Row>
                    <div className="col">
                        <CardTitle
                            tag="h6"
                            className="text-uppercase text-muted mb-0"
                        >
                            {t("Total")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                            {paymentListData.length} {t("items")} {` ($${totalPrice})`}
                        </span>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-purple text-white rounded-circle shadow mousePointer"
                            onClick={() => setExpandStat(!expandStat)}>
                            <i className={expandStat ? "fa fa-angle-up" : "fa fa-angle-down"} />
                        </div>
                    </Col>
                </Row>

            </CardHeader>

            <Collapse
                role="tabpanel"
                isOpen={expandStat}
            >

                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Payment in Progress")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {paymentListDataProgress?.length} {t("items")}{" "}{`($${totalPriceProgress})`}{" "}{`${(totalPriceProgress / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                <i className="ni ni-watch-time" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>

                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Paid")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {paymentListDataPaid?.length} {t("items")}{" "}{`($${totalPricePaid})`}{" "}{`${(totalPricePaid / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                <i className="far fa-check-circle" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0"
                            >
                                {t("Cancel")}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                                {paymentListDataCancel?.length} {t("items")}{" "}{`($${totalPriceCancel})`}{" "}{`${(totalPriceCancel / totalPrice * 100).toFixed(1)}%`}
                            </span>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                <i className="far fa-times-circle" />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>


        </Card>
    );
}

export default PaymentStatCard;
