import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { storeCar } from "functions/Car.js";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import Notifications from "../components/Notifications";
import moment from "moment";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { getCarType } from "functions/Car.js";
import { getCarStatus } from "functions/Car.js";

function CarModalCreate(props) {
  const { t } = useTranslation();
  const [carID, setCarID] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [statusID, setStatusID] = React.useState("");
  const [carType, setCarType] = React.useState("");
  const [carTypeID, setCarTypeID] = React.useState("");
  const [carIDRemark, setCarIDRemark] = React.useState("");
  const [maxLoad, setMaxLoad] = React.useState(0);
  const [unitID, setUnitID] = React.useState(-1);
  const [unit, setUnit] = React.useState(0);
  const [insuranceDate, setInsuranceDate] = React.useState(new Date());
  const [licenseDate, setLicenseDate] = React.useState(new Date());


  // Control
  const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

  const statusList = getCarStatus();
  const carTypeList = getCarType();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check 
    if (isNaN(maxLoad)) {
      Notifications({
        type: "dataCheck",
        message: t("Please input a number")
      });
      return;
    }

    if (carID == '') {
      Notifications({
        type: "dataCheck",
        message: t("Please input a carID")
      });
      return;
    }

    if (carTypeID == '') {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a type")
      });
      return;
    }

    if (statusID == '') {
      Notifications({
        type: "dataCheck",
        message: t("Please choose a status")
      });
      return;
    }


    let dataCar = {
      carID: carID,
      status: statusID,
      carType: carTypeID,
      carIDRemark: carIDRemark,
      maxLoad: maxLoad,
      insuranceDate: insuranceDate,
      licenseDate: licenseDate,
    };
    let store = await storeCar(dataCar);
    if (store.code == 200) {
      props.refreshTable()
      setFinishSubmitBoo(true);
      clearForm();
      props.onHide();
    }
  };


  const clearForm = () => {
    setCarID("");
    setStatus("");
    setStatusID("");
    setUnit("");
    setUnitID("");
    setCarType("");
    setCarTypeID("");
    setCarIDRemark("");
    setMaxLoad(0);
    setInsuranceDate(new Date());
    setLicenseDate(new Date());

    setFinishSubmitBoo(false);
  };


  return (
    <>

      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Car ID")}${"("}${t("Required")}${")"}`}
                  labelFor="carID"
                  inputName="carID"
                  inputId="carID"
                  inputPlaceholder={t("Car ID")}
                  value={carID}
                  handleChange={(e) => setCarID(e.target.value)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeSelect2Form

                  useTranslate={true}
                  data={carTypeList}
                  placeholder={t("--Select Car Type--")}
                  value={carTypeID}
                  nameSelect2="carType"
                  idSelect2="carType"
                  label={t("Car Type")}
                  handleOnChangeSelect={(e) => {
                    setCarTypeID(e.params.data.id);
                    setCarType(e.params.data.text);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeSelect2Form
                  useTranslate={true}
                  data={statusList}
                  placeholder={t("--Select Status--")}
                  value={statusID}
                  nameSelect2="status"
                  idSelect2="status"
                  label={t("Status")}
                  handleOnChangeSelect={(e) => {
                    setStatusID(e.params.data.id);
                    setStatus(e.params.data.text);
                  }}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  type={"number"} // must ba a number
                  label={t("Max Load") + "(Kg)"}
                  labelFor="maxLoad"
                  inputName="maxLoad"
                  inputId="maxLoad"
                  inputPlaceholder={t("Max Load") + "(Kg)"}
                  value={maxLoad}
                  handleChange={(e) => setMaxLoad(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputDateTimeGroupForm
                  label={t("Insurance Date")}
                  labelFor="insuranceDate"
                  inputName="insuranceDate"
                  inputId="insuranceDate"
                  inputPlaceholder={t("Insurance Date")}
                  value={insuranceDate}
                  onDateChange={(e) => {
                    setInsuranceDate(e);
                  }}
                  showTimeBoo={false}
                />
              </Col>
              <Col md="6">
                <InputDateTimeGroupForm
                  label={t("License Date")}
                  labelFor="licenseDate"
                  inputName="licenseDate"
                  inputId="licenseDate"
                  inputPlaceholder={t("License Date")}
                  value={licenseDate}
                  onDateChange={(e) => setLicenseDate(e)}
                  showTimeBoo={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="carIDRemark"
                  inputName="carIDRemark"
                  inputId="carIDRemark"
                  inputPlaceholder={t("Remark")}
                  type={"textarea"}
                  value={carIDRemark}
                  handleChange={(e) => setCarIDRemark(e.target.value)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>

            <Row className="w-100 d-flex justify-content-between">
              <div>
                <Button
                  color="danger"
                  type="button" // type="button" means the form will not submit
                  onClick={(e) => {
                    e.preventDefault();
                    clearForm();
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
              <div>
                <SubmitButtonInForm
                  finishSubmitBoo={finishSubmitBoo}
                  buttonNormalText={"Add"} />

                <Button color="default" type="button" onClick={props.onHide}>
                  {t("Cancel")}
                </Button>
              </div>

            </Row>

          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default CarModalCreate;
