import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    Badge,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardTitle
} from "reactstrap";
import QRCode from "react-qr-code";
import { uuid4 } from "@sentry/utils";
import { useTranslation } from "react-i18next";

// core components
import Dropzone from "dropzone";
import InvoiceCard from "../invoice/InvoiceCard";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import CustomerActionModal from "../customer/CustomerActionModal";
import { getInvoiceByCustomerID } from "functions/Invoice";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import InvoiceEditModal from "../invoice/InvoiceEditModal";
import { addDays, getIntegerDecodeFromString, getRandomBgColor } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";

import InvoiceHeader from "components/Headers/InvoiceHeader.js";
import InvoiceStatCard from "../components/InvoiceStatCard";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";
import InvoiceSearchBar from "../components/InvoiceSearchBar";
import moment from "moment";
import InvoiceListToPayMessageArea from "../components/InvoiceListToPayMessageArea";

Dropzone.autoDiscover = false;


function CustomerInvoiceRecord(props) {

    const customerID = props.customerID;


    const { t } = useTranslation();
    //Initialize Get Set

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [filteredSortedInvoiceData, setFilteredSortedInvoiceData] = React.useState([]);


    const [initInvoiceData, setinitInvoiceData] = React.useState([]);


    // for modal 
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceMessage, setInvoiceSelectedDataForInvoiceMessage] = React.useState([]);
    const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceEdit, setInvoiceSelectedDataForInvoiceEdit] = React.useState([]);
    const [invoiceSelectedDataForCloneInvoice, setInvoiceSelectedDataForCloneInvoice] = React.useState(null);

    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);

    //GUI
    const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);
    const [useInvoiceStatusColor, setUseInvoiceStatusColor] = React.useState(false);
    const [useInvoiceDateTimeRangeColor, setUseInvoiceDateTimeRangeColor] = React.useState(false);
    const [useInvoiceIsDeliveryColor, setUseInvoiceIsDeliveryColor] = React.useState(false);

    
    // For filter control show
    const [showFullCard, setShowFullCard] = React.useState(false);


    //Initialize Effect
    React.useEffect(() => {
        const getInvoiceData = async () => {
            let invoiceData = await getInvoiceByCustomerID(customerID); // add invoice Status, so no need to supply invoice status
            setinitInvoiceData(invoiceData);
        };

        if (props.show) {
            getInvoiceData();
        }
    }, [props.show, refreshTable]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };


    const getCardBgColor = (invoiceStatus, invoiceRecordDateString, invoiceIsDeliveryString) => {
        // This control the card Bg color 
        if (enableCardBgColor) {
            // priority 
            // carID color second // Only if these are all true at the same time 
            if (useInvoiceStatusColor) {
                return getRandomBgColor(getIntegerDecodeFromString(invoiceStatus));
            } else if (useInvoiceDateTimeRangeColor) {
                return getRandomBgColor(getIntegerDecodeFromString(invoiceRecordDateString));
            } else if (useInvoiceIsDeliveryColor) {
                return getRandomBgColor(getIntegerDecodeFromString(invoiceIsDeliveryString));
            } else {
                return "";
            }
        }
    }

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
                    <div>
                        <span> {t("Customer Invoice Record")}</span>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge">{props.customerData?.customerName}</Badge>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {/* Stat */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InvoiceStatCard
                                invoiceListData={filteredSortedInvoiceData}
                            />
                        </Col>

                    </Row>

                    {/* filter Card */}
                    <InvoiceSearchBar
                        initInvoiceData={initInvoiceData}
                        changeCardColor={(enableBgColor, useInvoiceStatusColor, useInvoiceDateTimeRangeColor, useInvoiceIsDeliveryColor) => {
                            setEnableCardBgColor(enableBgColor);
                            setUseInvoiceStatusColor(useInvoiceStatusColor);
                            setUseInvoiceDateTimeRangeColor(useInvoiceDateTimeRangeColor);
                            setUseInvoiceIsDeliveryColor(useInvoiceIsDeliveryColor);
                        }}
                        returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
                        returnFilteredSortedInvoiceData={(filteredSortedInvoiceData) => { setFilteredSortedInvoiceData(filteredSortedInvoiceData); }}
                        createInvoice={() => { setModalInvoiceCreateIsOpen(true) }}
                        refreshInitialData={(filterStartDateRequestByUser, filterEndDateRequestByUser, useFilterStartDateRequestByUser, useFilterEndDateRequestByUser) => {
                            // if (moment(filterStartDateRequestByUser).diff(moment(dataRangeStartDate), 'seconds') == 0 &&
                            //     moment(filterEndDateRequestByUser).diff(moment(dataRangeEndDate), 'seconds') == 0 &&
                            //     useFilterStartDateRequestByUser == useFilterStartDate &&
                            //     useFilterEndDateRequestByUser == useFilterEndDate) {
                            //     // values are the same 
                            //     // prevent multiple fetch from the server
                            // } else {
                            //     setUseFilterStartDate(useFilterStartDateRequestByUser);
                            //     setUseFilterEndDate(useFilterEndDateRequestByUser);
                            //     setDataRangeStartDate(moment(filterStartDateRequestByUser).add(dataIntervalInteger * -1, 'days').toDate());
                            //     setDataRangeEndDate(moment(filterEndDateRequestByUser).add(dataIntervalInteger * 1, 'days').toDate());
                            //     refreshTableData();
                            // }
                        }}>
                    </InvoiceSearchBar>

                    <Row className="mt-4">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <InvoiceListToPayMessageArea
                                isSameCustomer={true}
                                customerDetail={props.customerData}
                                showReceiptURL={false}
                                receiptURL={""}
                                invoiceList={filteredSortedInvoiceData}
                            >
                            </InvoiceListToPayMessageArea>
                        </Col>
                    </Row>



                    {/* Invoice list */}
                    <Row className="mt-4">
                        {filteredSortedInvoiceData && filteredSortedInvoiceData.length != 0 && filteredSortedInvoiceData.map((invoiceItem, index) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                data-id={invoiceItem.invoiceID}
                                key={invoiceItem.invoiceID}
                            >
                                <InvoiceCard
                                    showFullCard={showFullCard}
                                    enableCardBgColor={enableCardBgColor}
                                    cardBgColor={getCardBgColor(invoiceItem.invoiceStatus, moment(invoiceItem.invoiceRecordDateTime).format('yyyy-MM-DD').toString(), useInvoiceIsDeliveryColor.toString())}
                                    invoiceData={invoiceItem}
                                    setClickViewInvoiceButton={(invoice) => {
                                        setInvoiceSelectedDataForInvoiceMessage(invoice);
                                        setModalInvoiceMessageIsOpen(true);
                                    }}
                                    onClickCard={(invoice) => {
                                        setInvoiceSelectedDataForInvoiceEdit(invoice);
                                        setModalInvoiceEditIsOpen(true);
                                    }}
                                    isSelectedCard={false}
                                    cloneInvoice={(invoice) => {
                                        setInvoiceSelectedDataForCloneInvoice(invoice);
                                        setModalInvoiceCreateIsOpen(true);
                                    }}
                                    accessCustomerAction={false}
                                    customerAction={(customer) => {
                                        //  setSelectedCustomerData(customer);
                                        // setModalCustomerActionIsOpen(true);
                                    }}
                                    accessCloneInvoice={true}
                                />
                            </Col>
                        ))}
                        {filteredSortedInvoiceData && filteredSortedInvoiceData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Invoice Found!")}
                                        </strong>
                                        <h3 className="text-info">
                                            {t("Please Check Your Filter")} </h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    {/* End of Invoice list */}

                </ModalBody>
                <ModalFooter className="mt--5">
                    <Row className="w-100" md={12}>
                        <Col className="d-flex justify-content-end pr-0" md={12}>
                            <Button
                                color="default"
                                type="button"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                {t("Back")}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>

            {/* <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={customerSelectedDataForCustomerAction}
                refreshTable={() => { setRefreshTable(refreshTable + 1) }}
            /> */}
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={invoiceSelectedDataForInvoiceMessage}
            />
            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                    refreshTableData();
                }}
                invoiceData={invoiceSelectedDataForInvoiceEdit}
            />
            <InvoiceCreateModal
                preSelectedCustomer={props.customerData}
                invoiceToClone={invoiceSelectedDataForCloneInvoice}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    refreshTableData();
                }}
                allowEmptySubitemList={true}
            />
        </>
    );
}

export default CustomerInvoiceRecord;
