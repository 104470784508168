import { updateUser } from "functions/Auth";
import { getUserGender } from "functions/Auth";
import { getSystemUserByID } from "functions/Auth";
import { deleteUserJobMatch } from "functions/UserJobMatch";
import moment from "moment";
import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";
import SystemUserJobMatchCard from "./SystemUserJobMatchCard";
import UserModalMatchJob from "./UserModalMatchJob";
import UserModalMatchJobEdit from "./UserModalMatchJobEdit";


function UserModalEdit(props) {
  const { t } = useTranslation();

  const [systemUserData, setSystemUserData] = React.useState({});
  const [systemUserJobMatchesData, setSystemUserJobMatchesData] = React.useState([]);

  const [showUserModalMatchJob, setShowUserModalMatchJob] = React.useState(false);

  const [showUserModalMatchJobEdit, setShowUserModalMatchJobEdit] = React.useState(false);
  //for the job edit
  const [jobForJobEdit, setJobForJobEdit] = React.useState("");
  const [jobRemarkForJobEdit, setJobRemarkForJobEdit] = React.useState("");



  const [refreshTable, setRefreshTable] = React.useState(1);


  // breakdown data
  const [userDatas, setUserDatas] = React.useState([]);
  const [userID, setUserID] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [HKID, setHKID] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [name, setName] = React.useState("");
  const [chineseName, setChineseName] = React.useState("");
  const [nickName, setNickName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [admissionDate, setAdmissionDate] = React.useState(new Date());
  const [birthday, setBirthday] = React.useState(new Date());
  const handleSubmit = async (e) => {
    e.preventDefault();

    // check phone user name
    if (userID === "") {
      Notifications({
        type: "dataCheck",
        message: "Please input a user name"
      });
      return;
    }
    /*     
        if (passwordPlainText === "") {
          Notifications({
            type: "dataCheck",
            message: t("Please input a password")
          });
          return;
        } */
    // check phone user name
    if (name === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a name")
      });
      return;
    }
    // check phone user name
    if (nickName === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a nickname")
      });
      return;
    }


    let submittedData = {
      userID: userID,
      phoneNumber: phoneNumber,
      HKID: HKID,
      status: "normal",
      gender: gender,
      name: name,
      chineseName: chineseName,
      nickName: nickName,
      email: email,
      admissionDate: admissionDate,
      birthday: birthday
    };
    try {
      let resultCode = await updateUser(submittedData);
      if (resultCode === 200) {
        props.refreshTable();
        props.onHide();
      }
    } catch (err) {
      Notifications();
    }
  };
  React.useEffect(() => {
    const getUser = async (id) => {
      let dataUser = await getSystemUserByID(id);
      setUserDatas(dataUser);
    };
    if (Object.values(props.userData).length > 0) {
      getUser(props.userData.userID);
    }
  }, [props.show, refreshTable]);

  React.useEffect(() => {
    if (userDatas && Object.values(userDatas).length > 0) {
      // set the big data
      setSystemUserData(userDatas);
      console.log(userDatas);
      setSystemUserJobMatchesData(userDatas?.SystemUserJobMatches);

      // set the break down data
      setUserID(userDatas.userID);
      setPhoneNumber(userDatas.phoneNumber);
      setHKID(userDatas.HKID);
      setStatus(userDatas.status);
      setGender(userDatas.gender);
      setName(userDatas.name);
      setChineseName(userDatas.chineseName);
      setNickName(userDatas.nickName);
      setEmail(userDatas.email);
      // setPasswordPlainText(userDatas.passwordPlainText);
      setAdmissionDate(moment(userDatas.admissionDate, "yyyy-MM-D"));
      setBirthday(moment(userDatas.birthday, "yyyy-MM-D"));
    }
  }, [userDatas]);


  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("Name")}${"("}${t("Required")}${")"}`}
                      labelFor="name"
                      inputName="name"
                      inputId="name"
                      inputPlaceholder={t("Name")}
                      value={name}
                      handleChange={(e) => {
                        setName(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <InputTypeTextForm
                      label={t("Chinese Name")}
                      labelFor="chineseName"
                      inputName="chineseName"
                      inputId="chineseName"
                      inputPlaceholder={t("Chinese Name")}
                      value={chineseName}
                      handleChange={(e) => {
                        setChineseName(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("Nickname")}${"("}${t("Required")}${")"}`}
                      labelFor="nickName"
                      inputName="nickName"
                      inputId="nickName"
                      inputPlaceholder={t("Nickname")}
                      value={nickName}
                      handleChange={(e) => {
                        setNickName(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <InputTypeTextForm
                      readOnly={true}
                      label={`${t("userID")}${"("}${t("Required")}${")"}`}
                      labelFor="userID"
                      inputName="userID"
                      inputId="userID"
                      inputPlaceholder={t("userID")}
                      value={userID}
                      handleChange={(e) => {
                        setUserID(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                </Row>


                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={t("HKID")}
                      labelFor="HKID"
                      inputName="HKID"
                      inputId="HKID"
                      inputPlaceholder={t("HKID")}
                      value={HKID}
                      handleChange={(e) => {
                        setHKID(e.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <InputTypeSelect2Form
                      data={getUserGender()}
                      placeholder={t("--Select Gender--")}
                      value={gender}
                      nameSelect2="gender"
                      idSelect2="gender"
                      label={t("Gender")}
                      handleOnChangeSelect={(e) => setGender(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputTypeGroupTextForm
                      append={true}
                      appendIcon={true}
                      appendIconClass="fa fa-phone"
                      appendText=""
                      label={t("Contact")}
                      labelFor="phoneNumber"
                      inputName="phoneNumber"
                      inputId="phoneNumber"
                      inputPlaceholder={t("Contact")}
                      value={phoneNumber}
                      handleChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                    />
                  </Col>
                  <Col>
                    <InputTypeGroupTextForm
                      append={true}
                      appendIcon={true}
                      appendIconClass="fa fa-envelope"
                      appendText=""
                      label={t("Email")}
                      labelFor="email"
                      inputName="email"
                      inputId="email"
                      inputPlaceholder={t("Email")}
                      value={email}
                      handleChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputDateTimeGroupForm
                      label={t("Birthday")}
                      labelFor="birthday"
                      inputName="birthday"
                      inputId="birthday"
                      inputPlaceholder={t("Birthday")}
                      value={birthday}
                      onDateChange={(e) => setBirthday(e)}
                      showTimeBoo={false}
                    />
                  </Col>
                  <Col>
                    <InputDateTimeGroupForm
                      label={t("Admission Date")}
                      labelFor="admissionDate"
                      inputName="admissionDate"
                      inputId="admissionDate"
                      inputPlaceholder={t("Admission Date")}
                      value={admissionDate}
                      onDateChange={(e) => setAdmissionDate(e)}
                      showTimeBoo={false}
                    />
                  </Col>
                </Row>
                {/* <InputTypeTextForm
                  label="Remark"
                  labelFor="remark"
                  inputName="remark"
                  inputId="remark"
                  inputPlaceholder=t("Remark")
                  type="textarea"
                  // value={nickName}
                  // handleChange={(e) => {
                  //   setNickName(e.target.value);
                  // }}
                /> */}
                {/* <InputTypeSelect2Form
                  data={getUserStatus()}
                  placeholder="--Select Status--"
                  value={status}
                  nameSelect2="status"
                  idSelect2="status"
                  label="Status"
                  handleChange={(e) => setStatus(e.target.value)}
                />
                <InputTypeTextForm
                  label="Chinese Name"
                  labelFor="chineseName"
                  inputName="chineseName"
                  inputId="chineseName"
                  inputPlaceholder={t("Chinese Name")}
                  value={chineseName}
                  handleChange={(e) => {
                    setChineseName(e.target.value);
                  }}
                /> */}
              </Col>
            </Row>

            {/* job match area */}
            <Row className="mt-4">
              <Col>
                <Button
                  className="mt-1"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowUserModalMatchJob(true); // open the form to match a job the user
                  }}
                  size="md"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">{t("Add New")}</span>
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                {systemUserJobMatchesData && Object.values(systemUserJobMatchesData).length >= 1 &&
                  <>
                    {systemUserJobMatchesData.map((item) => {
                      return <SystemUserJobMatchCard
                        systemUserJobMatchData={item}
                        systemUserData={systemUserData}
                        useTranslate={true}
                        onClickEdit={(userID, job, jobRemark) => {
                          setJobForJobEdit(job);
                          setJobRemarkForJobEdit(jobRemark);
                          setShowUserModalMatchJobEdit(true);
                          refreshTableData();
                        }}
                        onClickDelete={async (userID, job) => {
                          await deleteUserJobMatch(userID, job);
                          refreshTableData();
                        }}
                      />
                    })}
                  </>
                }
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {props.button}
            </Button>
            <Button color="default" type="button" onClick={props.onHide}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <UserModalMatchJob
        show={showUserModalMatchJob}
        onHide={() => setShowUserModalMatchJob(false)}
        userDatas={userDatas}
        refreshTable={() => { refreshTableData(); }}
      />
      <UserModalMatchJobEdit
        show={showUserModalMatchJobEdit}
        onHide={() => setShowUserModalMatchJobEdit(false)}
        userID={userID}
        job={jobForJobEdit}
        jobRemark={jobRemarkForJobEdit}
        refreshTable={() => { refreshTableData(); }}
      />

    </>
  );
}

export default UserModalEdit;
