import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    Badge,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardTitle
} from "reactstrap";
import QRCode from "react-qr-code";
import { uuid4 } from "@sentry/utils";
import { useTranslation } from "react-i18next";

// core components
import Dropzone from "dropzone";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import PaymentSearchBar from "../components/PaymentSearchBar";
import PaymentEditModal from "../payment/PaymentEditModal";
import PaymentCard from "../payment/PaymentCard";

import { getPaymentByCustomerID } from "functions/Payment";
import PaymentStatCard from "../components/PaymentStatCard";
import PaymentCreateModal from "../payment/PaymentCreateModal";
import SpinnerOverlay from "../components/SpinnerOverlay";
import PaymentMessageModal from "../payment/paymentMessageModal";
import { getRandomBgColor } from "functions/Utility";
import { getIntegerDecodeFromString } from "functions/Utility";
import moment from "moment";

Dropzone.autoDiscover = false;

function CustomerPaymentRecord(props) {

    const history = useHistory();
    const customerID = props.customerID;


    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);
    const [paymentData, setPaymentData] = React.useState([]);
    const [initPaymentData, setInitPaymentData] = React.useState([]);

    const [createModal, setCreateModal] = React.useState(false);
    const [editModal, setEditModal] = React.useState(false);
    const [selectedPaymentData, setSelectedPaymentData] = React.useState(null);

    const [modalPaymentMessageIsOpen, setModalPaymentMessageIsOpen] = React.useState(false);

    // GUI
    const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);
    const [usePaymentStatusColor, setUsePaymentStatusColor] = React.useState(false);
    const [usePaymentRecordDateTimeRangeColor, setUsePaymentRecordDateTimeRangeColor] = React.useState(false);



    //Initialize Get Set
    const [refreshTable, setRefreshTable] = React.useState(0);

    const [showAll, setShowAll] = React.useState(isMobile ? false : true);

    // For filter control show
    const [showFullCard, setShowFullCard] = React.useState(false);

    React.useEffect(() => {
        if (props.show) {
            getPaymentData();
        }
    }, [customerID, refreshTable, props.show]);


    const getPaymentData = async () => {
        if (customerID && customerID !== "") {
            setIsLoading(true);
            let paymentData = await getPaymentByCustomerID(customerID);
            setIsLoading(false);
            setInitPaymentData(paymentData);
        }
    };

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const getCardBgColor = (paymentStatus, paymentRecordDateString) => {
        // This control the card Bg color 
        if (enableCardBgColor) {
            // priority 
            // Driver color first
            // carID color second // Only if these are all true at the same time 
            if (usePaymentStatusColor) {
                return getRandomBgColor(getIntegerDecodeFromString(paymentStatus));
            } else if (usePaymentRecordDateTimeRangeColor) {
                return getRandomBgColor(getIntegerDecodeFromString(paymentRecordDateString));
            } else {
                return "";
            }
        }
    }

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >

                {isLoading ? <SpinnerOverlay /> : ""}

                <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
                    <div>
                        <span> {t("Customer payment record")}</span>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge">{props.customerData?.customerName}</Badge>
                    </div>
                </ModalHeader>
                <ModalBody>

                    {/* Stat */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <PaymentStatCard
                                paymentListData={paymentData}>
                            </PaymentStatCard>

                        </Col>

                    </Row>
                    {/* filter Card */}
                    <Row>
                        <Col>
                            <PaymentSearchBar
                                initPaymentData={initPaymentData}
                                changeCardColor={(enableBgColor, usePaymentStatusColor, usePaymentRecordDateTimeRangeColor) => {
                                    setEnableCardBgColor(enableBgColor);
                                    setUsePaymentStatusColor(usePaymentStatusColor);
                                    setUsePaymentRecordDateTimeRangeColor(usePaymentRecordDateTimeRangeColor);
                                }}
                                returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
                                returnFilteredSortedPaymentData={(filteredSortedpaymentData) => { setPaymentData(filteredSortedpaymentData); }}
                                createPayment={() => { setCreateModal(true) }}
                                refreshInitialData={(filterStartDateRequestByUser, filterEndDateRequestByUser, useFilterStartDateRequestByUser, useFilterEndDateRequestByUser) => {

                                    //     if (moment(filterStartDateRequestByUser).diff(moment(dataRangeStartDate), 'seconds') == 0 &&
                                    //        moment(filterEndDateRequestByUser).diff(moment(dataRangeEndDate), 'seconds') == 0 &&
                                    //         useFilterStartDateRequestByUser == useFilterStartDate &&
                                    //         useFilterEndDateRequestByUser == useFilterEndDate) {
                                    //          // values are the same 
                                    //          // prevent multiple fetch from the server
                                    //      } else {
                                    //         setUseFilterStartDate(useFilterStartDateRequestByUser);
                                    //         setUseFilterEndDate(useFilterEndDateRequestByUser);
                                    //         setDataRangeStartDate(moment(filterStartDateRequestByUser).add(dataIntervalInteger * -1, 'days').toDate());
                                    //        setDataRangeEndDate(moment(filterEndDateRequestByUser).add(dataIntervalInteger * 1, 'days').toDate());
                                    //       refreshTableData();
                                    //    }
                                }}

                            >

                            </PaymentSearchBar>
                        </Col>
                    </Row>

                    {/* Payment list */}
                    <Row className="mt-4">
                        {paymentData && Object.values(paymentData).length >= 1 && paymentData.map((paymentItem, index) => (
                            <Col
                                key={index}
                                style={{ cursor: "pointer" }}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                data-id={paymentItem.paymentID}
                            >

                                <PaymentCard
                                    showFullCard={showFullCard}
                                    cardBgColor={getCardBgColor(paymentItem.paymentStatus, moment(paymentItem.paymentRecordDateTime).format('yyyy-MM-DD').toString())}
                                    enableCardBgColor={enableCardBgColor}
                                    paymentData={paymentItem}
                                    onClickCard={(paymentItem) => {
                                        setSelectedPaymentData(paymentItem);
                                        setEditModal(true);
                                    }}
                                    isSelectedCard={false}
                                    clickPaymentMessage={(paymentData) => {
                                        setSelectedPaymentData(paymentData);
                                        setModalPaymentMessageIsOpen(true);
                                    }}
                                />

                            </Col>
                        ))}
                        {paymentData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Data Found!")}
                                        </strong>
                                        <h3 className="text-info">
                                            {t("Please Check Your Filter")}
                                        </h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    {/* End of Payment list */}

                </ModalBody>


                <ModalFooter className="mt--5">
                    <Row className="w-100" md={12}>
                        <Col className="d-flex justify-content-end pr-0" md={12}>
                            <Button
                                color="default"
                                type="button"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                {t("Back")}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>

            </Modal>

            <PaymentCreateModal
                show={createModal}
                onHide={() => setCreateModal(false)}
                title={t("Add Payment")}
                button={t("Save")}
                refresh={refreshTableData}
                preSelectedCustomer={props.customerData}
            />

            <PaymentEditModal
                show={editModal}
                onHide={() => setEditModal(false)}
                title={t("Payment Detail")}
                button={t("Save")}
                refresh={refreshTableData}
                selectedData={selectedPaymentData}
            />
            <PaymentMessageModal
                show={modalPaymentMessageIsOpen}
                onHide={() => setModalPaymentMessageIsOpen(false)}
                title={t("Payment Message")}
                refreshTable={() => { }}
                paymentData={selectedPaymentData}
            />
        </>
    );
}

export default CustomerPaymentRecord;
