import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";


import { getReportResourceReportTermsArray } from "functions/ReportResource.js";
import { getRefTabData } from "functions/Utility.js";
import { updateSingleReportTerms } from "functions/ReportResource.js";
import SpinnerOverlay from "../components/SpinnerOverlay.js";


function ReportResourceRCTermsEditModal(props) {
    const { t } = useTranslation();

    // Control
    const [isLoading, setIsLoading] = React.useState(false);

    const [isReportResourceRCMainColorChnaged, setIsReportResourceRCMainColorChnaged] = React.useState(false);
    const [isReportResourceRCBottomInfoLine1Chnaged, setIsReportResourceRCBottomInfoLine1Chnaged] = React.useState(false);
    const [isReportResourceRCBottomInfoLine2Chnaged, setIsReportResourceRCBottomInfoLine2Chnaged] = React.useState(false);


    // Data

    const [reportResourceRCMainColor, setReportResourceRCMainColor] = React.useState('');
    const [reportResourceRCBottomInfoLine1, setReportResourceRCBottomInfoLine1] = React.useState('');
    const [reportResourceRCBottomInfoLine2, setReportResourceRCBottomInfoLine2] = React.useState('');

    // Data process
    // const [refTabSubTypeArray, setRefTabSubTypeArray] = React.useState([]);


    const onSubmitDataToServer = async (refTabID, refTabData) => {
        setIsLoading(true);
        let updateResponse = await updateSingleReportTerms(refTabID, refTabData);
        setIsLoading(false);
        if (updateResponse.code == 200) {
            return true
        } else {
            return false;
        }
    };


    // props 

    React.useEffect(() => {
        const getDataFromServer = async () => {
            let refDataArray = await getReportResourceReportTermsArray('reportResourceRC');

            if (refDataArray && Array.isArray(refDataArray) && refDataArray.length >= 1) {
                setReportResourceRCMainColor(getRefTabData(refDataArray, 'reportResourceRCMainColor'));
                setReportResourceRCBottomInfoLine1(getRefTabData(refDataArray, 'reportResourceRCBottomInfoLine1'));
                setReportResourceRCBottomInfoLine2(getRefTabData(refDataArray, 'reportResourceRCBottomInfoLine2'));


                // reset the control here
                setIsReportResourceRCMainColorChnaged(false);
                setIsReportResourceRCBottomInfoLine1Chnaged(false);
                setIsReportResourceRCBottomInfoLine2Chnaged(false);

                // Data process
                // let mapResult = refDataArray.map((item) => { return item.refTabSubType; });
                // let distinctResult = mapResult.filter((item, index) => mapResult.indexOf(item) === index);
                // setRefTabSubTypeArray(distinctResult);
            }
        }

        if (props.show) {
            getDataFromServer();
        }
    }, [props.show]);


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <ModalHeader><Trans>RC Report Detail</Trans></ModalHeader>
                <ModalBody>

                    <Row>
                        <Col >
                            <InputTypeTextForm
                                label={t("reportResourceRCMainColor")}
                                labelFor="reportResourceRCMainColor"
                                inputName="reportResourceRCMainColor"
                                inputId="reportResourceRCMainColor"
                                inputPlaceholder={t("reportResourceRCMainColor")}
                                type="textarea"
                                value={reportResourceRCMainColor}
                                handleChange={(e) => {
                                    setReportResourceRCMainColor(e.target.value);
                                    setIsReportResourceRCMainColorChnaged(true);
                                }}
                            />
                        </Col>
                    </Row>
                    {isReportResourceRCMainColorChnaged &&
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <Button
                                        className="flex-fill"
                                        color="success"
                                        onClick={async (e) => {
                                            let result = await onSubmitDataToServer("reportResourceRCMainColor", reportResourceRCMainColor);
                                            if (result) {
                                                setIsReportResourceRCMainColorChnaged(false);
                                            }
                                        }}
                                    >
                                        <Trans>Save</Trans>
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    }
                    <Row>
                        <Col >
                            <InputTypeTextForm
                                label={`${t("reportResourceRCBottomInfoLine1")}   (${reportResourceRCBottomInfoLine1.length}${t('words')})`}
                                labelFor="reportResourceRCBottomInfoLine1"
                                inputName="reportResourceRCBottomInfoLine1"
                                inputId="reportResourceRCBottomInfoLine1"
                                inputPlaceholder={t("reportResourceRCBottomInfoLine1")}
                                type="textarea"
                                value={reportResourceRCBottomInfoLine1}
                                handleChange={(e) => {
                                    setReportResourceRCBottomInfoLine1(e.target.value);
                                    setIsReportResourceRCBottomInfoLine1Chnaged(true);
                                }}
                            />
                        </Col>
                    </Row>
                    {isReportResourceRCBottomInfoLine1Chnaged &&
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <Button
                                        className="flex-fill"
                                        color="success"
                                        onClick={async (e) => {
                                            let result = await onSubmitDataToServer("reportResourceRCBottomInfoLine1", reportResourceRCBottomInfoLine1);
                                            if (result) {
                                                setIsReportResourceRCBottomInfoLine1Chnaged(false);
                                            }
                                        }}
                                    >
                                        <Trans>Save</Trans>
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    }
                    <Row>
                        <Col >
                            <InputTypeTextForm
                                label={`${t("reportResourceRCBottomInfoLine2")}   (${reportResourceRCBottomInfoLine2.length}${t('words')})`}
                                labelFor="reportResourceRCBottomInfoLine2"
                                inputName="reportResourceRCBottomInfoLine2"
                                inputId="reportResourceRCBottomInfoLine2"
                                inputPlaceholder={t("reportResourceRCBottomInfoLine2")}
                                type="textarea"
                                value={reportResourceRCBottomInfoLine2}
                                handleChange={(e) => {
                                    setReportResourceRCBottomInfoLine2(e.target.value);
                                    setIsReportResourceRCBottomInfoLine2Chnaged(true);
                                }}
                            />
                        </Col>
                    </Row>
                    {isReportResourceRCBottomInfoLine2Chnaged &&
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <Button
                                        className="flex-fill"
                                        color="success"
                                        onClick={async (e) => {
                                            let result = await onSubmitDataToServer("reportResourceRCBottomInfoLine2", reportResourceRCBottomInfoLine2);
                                            if (result) {
                                                setIsReportResourceRCBottomInfoLine2Chnaged(false);
                                            }
                                        }}
                                    >
                                        <Trans>Save</Trans>
                                    </Button>
                                </div>
                            </Col>

                        </Row>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ReportResourceRCTermsEditModal;
