import { truncate } from "@sentry/utils";

import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey";
import Notifications from "views/pages/components/Notifications";




export const getSystemAccessSelect2 = async () => {
    try {
        let response = await apiGet({ url: "/systemAccess/getAllAccess", responseType: "" });
      if (response.status == 200) {
  
        let resultArray = [];
        response.data.data.rows.forEach((obj) => {
          resultArray.push({
            id: obj.accessID,
            text: obj.accessDetail,
            obj: obj
          });
        }
        );
        return resultArray;
      }
    } catch (e) {
      return [];
    }
  };


  export const getSystemAccessTypeSelect2 = async () => {
    try {
        let response = await apiGet({ url: "/systemAccess/getAllAccessType", responseType: "" });
      if (response.status == 200) {
  
        let resultArray = [];
        response.data.data.count.forEach((obj) => {
          resultArray.push({
            id: obj.accessType,
            text: obj.accessType,
            obj: obj // obj.count is the number of count of this type of access
          });
        }
        );
        return resultArray;
      }
    } catch (e) {
      return [];
    }
  };


// SystemAccess doesnt allow update 
// Only add or delete
export const getAllSystemAccess = async () => {
  try {
    let response = await apiGet({ url: "/systemAccess/getAllAccess", responseType: "" });
    // response.data.data.rows directly means the array
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const addNewSystemAccess = async (data) => {
  let response = await apiPost({
    url: "/systemAccess/addNewAccess",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data.data
    : response.status;
};

export const updateJobArrayToAccessID = async (data) => {
  let response = await apiPost({
    url: "/systemAccess/grantJobArrayToSingleAccessID",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data
    : response.status;
};

// export const updateStore = async (data) => {
//   let response = await apiPut({
//     url: "/store/updateStore",
//     data: data,
//     responseType: "",
//   });

//   return response.status == 200
//     ? response.data
//     : response.status;
// };

export const deleteSystemAccessByAccessID = async (accessID) => {
  let response = await apiDelete({
    url: "/systemAccess/deleteAccess/" + accessID,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

