import React from "react";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Card,
    CardHeader,
    CardBody,
    Input,
    Collapse,
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
// core components
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";
import { addDays } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";

//props

// props.initSalesAccountingData
// prpos.returnShowCard
// props.returnFilteredSortedSalesAccountingData

function SalesAccountingSearchBar(props) {
    const { t } = useTranslation();
    const [filteredSortedSalesAccountingData, setFilteredSortedSalesAccountingData] = React.useState([]);
    const [initSalesAccountingData, setInitSalesAccountingData] = React.useState([]);
    const [searchString, setSearchString] = React.useState("");
    const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
    const [sortDateSmall, setSortDateSmall] = React.useState(true);


    // For date search
    const [useStartDate, setUseStartDate] = React.useState(true);
    const [useEndDate, setUseEndDate] = React.useState(true);
    const [searchStartDate, setSearchStartDate] = React.useState(addDays(new Date(), -7));
    const [searchEndDate, setSearchEndDate] = React.useState(new Date());


    // For filter control show
    const [showFilterSearch, setShowFilterSearch] = React.useState(false);
    const [showFilterPaymentDateTimeRange, setShowFilterPaymentDateTimeRange] = React.useState(false);
   const [showFullCard, setShowFullCard] = React.useState(false);

    // For control
    const [expandFilter, setExpandFilter] = React.useState(false);


    const closeAllFilterControl = () => {
        setShowFilterSearch(false);
        setShowFilterPaymentDateTimeRange(false);
    }
    React.useEffect(() => {
        // do an inital sort
        searchDataMain("", "");
    }, [initSalesAccountingData, useStartDate, useEndDate]);


    React.useEffect(() => {
        setInitSalesAccountingData(props.initSalesAccountingData);
    }, [props.initSalesAccountingData]);

    React.useEffect(() => {
        // this is the key return
        props.returnFilteredSortedSalesAccountingData(filteredSortedSalesAccountingData);
   
    }, [filteredSortedSalesAccountingData]);

    React.useEffect(() => {
        // this is the key return
        props.returnShowFullCard(showFullCard);
    }, [showFullCard]);


    const clearFilter = () => {

        setFilteredSortedSalesAccountingData(initSalesAccountingData);
        setSearchString("");
        setSearchStartDate("");
        setSearchEndDate("");
        setSortPriceSmall(true);
        setSortDateSmall(true);
    };

    const searchDataMain = (searchType, searchValue, searchValue2 = null) => {



        const value = searchValue;
        const value2 = searchValue2;
        let res = [];
        let searchString_ = searchString;
        let searchStartDate_ = searchStartDate;
        let searchEndDate_ = searchEndDate;
        if (searchType === "search") {
            setSearchString(value);
            searchString_ = value;
        } else if (searchType === "startDate") {
            setSearchStartDate(value);
            searchStartDate_ = value;
        } else if (searchType === "endDate") {
            setSearchEndDate(value);
            searchEndDate_ = value;
        } else if (searchType === "period") {
            setSearchStartDate(value);
            searchStartDate_ = value;
            setSearchEndDate(value2);
            searchEndDate_ = value2;
        } 
        const filterFuncString = (data) => {
            if (searchString_ !== "") {
                return (
                    data?.Customer?.customerName.includes(searchString_) ||
                    data?.PaymentInvoiceMatches.some((val) =>
                        val.invoiceID.includes(searchString_)
                    )
                );
            } else {
                return true;
            }
        };
        const filterFuncStartDate = (data) => {
            if (searchStartDate_ !== "") {
                const extDate = new Date(data.date);
                const filterDate = new Date(searchStartDate_);
                filterDate.setHours(0, 0, 0, 0);
                return extDate >= filterDate;
            } else {
                return true;
            }
        };
        const filterFuncEndDate = (data) => {
            if (searchEndDate_ !== "") {
                const extDate = new Date(data.date);
                const filterDate = new Date(searchEndDate_);
                filterDate.setHours(23, 59, 59, 999);
                return extDate <= filterDate;
            } else {
                return true;
            }
        };

        setSortPriceSmall(true);
        setSortDateSmall(true);


        if (!initSalesAccountingData || initSalesAccountingData === undefined || Object.values(initSalesAccountingData).length <= 0) {
            return;
        }
        res = initSalesAccountingData.filter(filterFuncString);
        if (useStartDate) {
            res = res.filter(filterFuncStartDate);
        }
        if (useEndDate) {
            res = res.filter(filterFuncEndDate);
        }

        setFilteredSortedSalesAccountingData(res);

    };

    const sortPrice = () => {
        setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = filteredSortedSalesAccountingData.sort((a, b) => a.paymentPrice - b.paymentPrice);
        } else {
            res = filteredSortedSalesAccountingData.sort((a, b) => b.paymentPrice - a.paymentPrice);
        }
        setFilteredSortedSalesAccountingData(res);
        setSortPriceSmall(!sortPriceSmall);
    };

    const sortDate = () => {
        setSortPriceSmall(true);
        let res = [];
        if (!sortDateSmall) {
            res = filteredSortedSalesAccountingData.sort(
                (a, b) =>
                    new Date(a.paymentRecordDateTime) - new Date(b.paymentRecordDateTime)
            );
        } else {
            res = filteredSortedSalesAccountingData.sort(
                (a, b) =>
                    new Date(b.paymentRecordDateTime) - new Date(a.paymentRecordDateTime)
            );
        }
        setFilteredSortedSalesAccountingData(res);
        setSortDateSmall(!sortDateSmall);
    };


    return (
        <Card className="mb--2">

            <CardHeader
                role="tab"
                aria-expanded={expandFilter}>
                <Row>
                    <Col className="col-auto mt-1  pr-0">
                        <Button
                            onClick={() => {
                                if (showFilterSearch && expandFilter) {
                                    setShowFilterSearch(!showFilterSearch);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterSearch && !expandFilter) {
                                    setShowFilterSearch(!showFilterSearch);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterSearch && expandFilter) {
                                    //Close the other

                                    closeAllFilterControl();
                                    setShowFilterSearch(true);
                                    // setExpandFilter(true);
                                }
                            }}
                            className="mt-1"
                            size=""
                            color="primary">
                            <i className={`fa fa-angle-${expandFilter && showFilterSearch ? "up" : "down"}`} />{" "}{t("Search")}
                            {searchString && searchString !== "" && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
                        </Button>

                        <Button
                            onClick={() => {
                                if (showFilterPaymentDateTimeRange && expandFilter) {
                                    setShowFilterPaymentDateTimeRange(!showFilterPaymentDateTimeRange);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterPaymentDateTimeRange && !expandFilter) {
                                    setShowFilterPaymentDateTimeRange(!showFilterPaymentDateTimeRange);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterPaymentDateTimeRange && expandFilter) {
                                    //Close the other
                                    closeAllFilterControl();
                                    setShowFilterPaymentDateTimeRange(true);
                                    // setExpandFilter(true);
                                }
                            }}
                            className="mt-1"
                            color="primary">
                            <i className={`fa fa-angle-${expandFilter && setShowFilterPaymentDateTimeRange ? "up" : "down"}`} />{"  "}{t("Date")}
                            {(useStartDate || useEndDate) && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
                        </Button>
                    </Col>
                    
                    <Col className="col-auto mt-1 pr-0">
                        <Button
                            onClick={() => { setShowFullCard(!showFullCard) }}
                            className="mt-1"
                            color="primary">
                            <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                        </Button>

                        <Button onClick={sortPrice} className="mt-1" color="primary">
                            {t("Price")}{" "}
                            <i className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"}`} />
                        </Button>

                        <Button onClick={sortDate} className="mt-1" color="primary">
                            {t("Date")}{" "}
                            <i className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"}`} />
                        </Button>

                        <Button
                            className="mt-1"
                            color="danger"
                            id="tooltip443412081"
                            onClick={(e) => {
                                e.preventDefault();
                                clearFilter();
                            }}
                            size="md"
                        >
                            <span className="btn-inner--text">
                                {t("Reset Filter")}
                            </span>
                        </Button>
                        <UncontrolledTooltip delay={1} target="tooltip443412081">
                            {t("Reset Filter")}
                        </UncontrolledTooltip>
                    </Col>

                </Row>
            </CardHeader>

            <Collapse
                role="tabpanel"
                isOpen={expandFilter}
            >
                <CardBody>
                    {showFilterSearch &&
                        <Row className="mt-2">
                            <Col
                                className="mt-1"
                                lg={12} md={12} xs={12}
                            >
                                <InputTypeTextForm
                                    label={t("Search")}
                                    labelFor="searchText"
                                    inputName="searchText"
                                    inputId="searchText"
                                    inputPlaceholder={t("Search (Invoice number/Customer/Address/Driver/CarID)")}
                                    value={searchString}
                                    handleChange={(e) => {
                                        e.preventDefault();
                                        searchDataMain("search", e?.target?.value);
                                    }}
                                    readOnly={false}
                                />

                            </Col>
                        </Row>
                    }

                    {showFilterPaymentDateTimeRange &&
                        <>
                            <InputDateGroupCombo
                                labelFor="paymentRecordDateTime"
                                inputName="paymentRecordDateTime"
                                inputId="paymentRecordDateTime"
                                useStartDate={useStartDate}
                                useEndDate={useEndDate}
                                changeUseStartDate={(boo) => { setUseStartDate(boo) }}
                                changeUseEndDate={(boo) => { setUseEndDate(boo) }}
                                startDate={searchStartDate}
                                endDate={searchEndDate}
                                changeStartDate={(date) => { searchDataMain("startDate", date); }}
                                changeEndDate={(date) => { searchDataMain("endDate", date); }}
                                changePeriod={(startDate, endDate) => {
                                    searchDataMain("period", startDate, endDate);
                                }}
                            >
                            </InputDateGroupCombo>
                        </>
                    }
                </CardBody>
            </Collapse>
        </Card>
    );
}

export default SalesAccountingSearchBar;
