
import { addNewLocalSetting } from "functions/LocalSetting";
import moment from "moment";
import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";
import SubmitButtonInForm from "../components/SubmitButtonInForm";




// props
// show
// userID
// refreshTable()
// onHide()

function LocalSettingCreateModal(props) {
    const { t } = useTranslation();

    const [userID, setUserID] = React.useState(localStorage.getItem('userID'));
    const [settingKey, setSettingKey] = React.useState("");
    const [settingValue, setSettingValue] = React.useState("");
    const [settingDetail, setSettingDetail] = React.useState("");


    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userID === "") {
            Notifications({
                type: "dataCheck"
                , message: 'userID is invalid.'
            });
            return;
        }
        if (settingKey === "") {
            Notifications({
                type: "dataCheck"
                , message: 'Please input a setting key.'
            });
            return;
        }
        if (settingValue === "") {
            Notifications({
                type: "dataCheck"
                , message: 'Please input a setting value.'
            });
            return;
        }

        try {
            let resultCode = await addNewLocalSetting(userID, settingKey, settingValue, settingDetail);
            if (resultCode === 200) {
                setFinishSubmitBoo(true);
                clearForm();
                props.refreshTable();
                props.onHide();
            }
        } catch (err) {
            Notifications();
        }
    };

    const clearForm = () => {
        setSettingValue("");
        setSettingKey("");
        setSettingDetail("");
        
        setFinishSubmitBoo(false);
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >
                        <div className="mt-4">
                            {t("Create local setting")}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">

                                <Row>
                                    <Col>

                                        <InputTypeTextForm
                                            type="text"
                                            required={true}
                                            label={`${t("Setting key")}${"("}${t("Required")}${")"}`}
                                            labelFor="settingKey"
                                            inputName="settingKey"
                                            inputId="settingKey"
                                            inputPlaceholder={t("Setting key")}
                                            value={settingKey}
                                            handleChange={(e) => {
                                                setSettingKey(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row> <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            required={true}
                                            label={`${t("Setting value")}${"("}${t("Required")}${")"}`}
                                            labelFor="settingValue"
                                            inputName="settingValue"
                                            inputId="settingValue"
                                            inputPlaceholder={t("Setting value")}
                                            value={settingValue}
                                            handleChange={(e) => {
                                                setSettingValue(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row> <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            label={t("Setting detail")}
                                            labelFor="settingDetail"
                                            inputName="settingDetail"
                                            inputId="settingDetail"
                                            inputPlaceholder={t("Setting detail")}
                                            value={settingDetail}
                                            handleChange={(e) => {
                                                setSettingDetail(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Add"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default LocalSettingCreateModal;
