import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Input,
  Row,
  Col,
  Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import moment from "moment";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import Notifications from "../components/Notifications";
import { storePayment, getPaymentMethodArray } from "functions/Payment.js";
import { getImageType, checkIsNumber, getBase64PhotoHeadRemoved } from "functions/Utility.js";
import { addPaymentPhoto } from "functions/Payment.js";
import { convertFileToBase64 } from "functions/Utility.js";
import PhotoUploadArea from "../components/PhotoUploadArea";
import DropdownControl from "../components/DropdownControl.js";
import SpinnerOverlay from "../components/SpinnerOverlay";

//prop
//data = invoiceData
function InvoiceAddPaymentModal(props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  // Form choices
  const [paymentMethodArray, setPaymentMethodArray] = React.useState([]);

  const [paymentRef, setPaymentRef] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [amountToPay, setAmountToPay] = useState(0);
  const [unPaidAmountFromInvoice, setUnPaidAmountFromInvoice] = useState(0);
  const [paymentRemark, setPaymentRemark] = useState("");

  const [paymentImage, setPaymentImage] = React.useState({
    paymentImageID: "",
    paymentImageBase64Content: ""
  });

  const { paymentImageID, paymentImageBase64Content } = paymentImage;

  const uploadImg = async (paymentID) => {
    setIsLoading(true);
    await addPaymentPhoto(paymentID, paymentImageBase64Content);
    setIsLoading(false);
  };


  const onChangePaymentImage = (photoIDToUpdate, photoBase64ContentToUpdate) => {
    setPaymentImage({
      paymentImageID: photoIDToUpdate,
      paymentImageBase64Content: photoBase64ContentToUpdate
    });
  };


  const clearForm = () => {

    setAmountToPay(props?.data?.unPaidAmount);
    setUnPaidAmountFromInvoice(props?.data?.unPaidAmount);
    setPaymentRef("");
    setPaymentRemark("");
    setPaymentMethod("Cash");
    setPaymentImage({
      paymentImageID: "",
      paymentImageBase64Content: ""
    });

  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Data Validation
    if (!checkIsNumber(amountToPay)) {
      Notifications({ type: "error", message: "Please input a number to paidAmount." });
      return;
    }
    const submittedData = {
      customerID: props.data.Customer?.customerID,
      paymentPrice: amountToPay,
      paymentStatus: "Paid",
      paymentMethod: paymentMethod,
      paymentRemark: paymentRemark,
      paymentRefNumber: paymentRef,
      invoiceIDs: [
        { invoiceID: props.data.invoiceID, paidAmount: amountToPay },
      ],
    };

    try {
      setIsLoading(true);
      const res = await storePayment(submittedData);
      await uploadImg(res[0]?.paymentID);
      setIsLoading(false);
      clearForm();
      props.refreshTable();
      props.onHide();
    } catch (err) {
      Notifications();
    }
  };


  React.useEffect(() => {
    if (props.show) {
      setPaymentMethodArray(getPaymentMethodArray());
    }
  }, [props.show]);

  React.useEffect(() => {
    if (props.show) {
      setAmountToPay(props.data.unPaidAmount);
      setUnPaidAmountFromInvoice(props.data.unPaidAmount);
    }
  }, [props.show]);



  return (
    <>
      <Modal
        isOpen={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader>
          <div className="mt-3 ml-2">
            {props.title}
          </div>
          <Badge color="primary" className="statusBadge ml-1">{props.data?.invoiceID}</Badge>
        </ModalHeader>
        <ModalBody>
          <Row className="d-flex align-items-center mb-2">
            <Col>
              <DropdownControl
                disabled={false}
                defaultColor="primary"
                dropdownName={t("Payment method")}
                useTranslate={true}
                arrayIDTextFormat={paymentMethodArray}
                valueID={paymentMethod}
                onSelectValueID={(valueID) => {
                  setPaymentMethod(valueID);
                }}
              />
            </Col>
          </Row>


          <Row className="d-flex align-items-center mt-2 mb-2">
            <Col className="mt-2" md={4}>{t("Paid Amount")}</Col>
            <Col md={8}>
              <Input
                // defaultValue={amountToPay}
                type="number"
                name="paidAmount"
                placeholder="$0"
                value={amountToPay}
                onChange={(e) => setAmountToPay(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-2 mb-2">
            <Col md={4}>{t("Payment Price")}</Col>
            <Col md={8}>
              <Input
                type="number"
                name="paymentPrice"
                placeholder="$0"
                onChange={(e) => { }}
                value={unPaidAmountFromInvoice}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-2 mb-2">
            <Col md={4}>{t("Payment Ref No")}</Col>
            <Col md={8}>
              <Input
                type="text"
                name="paymentRef"
                value={paymentRef}
                onChange={(e) => setPaymentRef(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>{t("Remark")}</Col>
            <Col md={8}>
              <InputTypeTextForm
                label=""
                labelFor="paymentRemark"
                inputName="paymentRemark"
                inputId="paymentRemark"
                inputPlaceholder={t("Remark")}
                type="textarea"
                value={paymentRemark}
                handleChange={(e) => setPaymentRemark(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>

              <PhotoUploadArea
                title={t("Photo Upload")}
                isShow={true}
                photoID={paymentImageID}
                photoBase64Data={paymentImageBase64Content}
                onUploadPhoto={async (photoDataToUpload) => {
                  //dont upload first
                  // wait for the form submit
                  onChangePaymentImage(
                    "",
                    await getBase64PhotoHeadRemoved(photoDataToUpload)
                  )
                }}
                hideDownloadButton={true}
                onDownloadPhoto={(photoID, photoBase64Content) => {
                  //downloadImg(photoID, photoBase64Content);
                }}
                onDeletePhoto={(photoID) => {
                  onChangePaymentImage("", "")
                }}
              />




            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100 d-flex justify-content-between">
            <div>
              <Button
                color="danger"
                type="button" // type="button" means the form will not submit
                onClick={(e) => {
                  e.preventDefault();
                  clearForm();
                }}
              >
                {t("Reset")}
              </Button>
            </div>
            <div>
              <Button color="primary" onClick={handleSubmit} type="button">
                {props.button}
              </Button>
              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default InvoiceAddPaymentModal;
