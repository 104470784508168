import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Badge,
    Dropdown
} from "reactstrap";

import { useTranslation } from "react-i18next";
// core components
import Dropzone from "dropzone";
import InputTypeSelect2 from "../components/InputTypeSelect2Form";
import { getAllDeliveryAddress } from "functions/DeliveryAddress";
import { combineAddress } from "functions/DeliveryAddress";
import { isAccessible } from "functions/SystemUserAccess";
import SpinnerOverlay from "../components/SpinnerOverlay";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess";

Dropzone.autoDiscover = false;

// props
// props.preSelectAddressID
// props.returnSelectedAddressID
// props.returnSelectedAddressData
// props.showLabel
// props.reloadMe

function DeliveryAddressSelectInput(props) {

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    const [searchAddressDisplayArray, setSearchAddressDisplayArray] = React.useState([]);

    const [searchAddressInitData, setSearchAddressInitData] = React.useState([]);

    const [selectedAddressID, setSelectedAddressID] = React.useState("");

    const [reloadMe, setReloadMe] = React.useState(false);

    const [selectedAddressData, setSelectedAddressData] = React.useState({
        addressDetailUnitFloor: "",
        addressDetailBuilding: "",
        addressDetailStreet: "",
        addressDetailDistrict: "",
        addressDetailMainDistrict: "",
        addressRemark: "",
        addressGPSLatitude: 0,
        addressGPSLongtitude: 0
    });

    const { addressDetailUnitFloor, addressDetailBuilding, addressDetailStreet, addressDetailDistrict, addressDetailMainDistrict, addressRemark, addressGPSLatitude, addressGPSLongtitude } = selectedAddressData;

    const setUpAddressArrayData = async () => {
        setIsLoading(true);
        let res = await getAllDeliveryAddress();
        setIsLoading(false);
        setSearchAddressInitData(res);
        let addressDataForSearch = breakDownArrayDataIntoSelectableText(res);
        setSearchAddressDisplayArray([...addressDataForSearch]);
    };


    const breakDownArrayDataIntoSelectableText = (addressDataArray) => {
        let addressDataForSearch = [];
        for (let i = 0; i < addressDataArray.length; i += 1) {
            const combinedAddress = combineAddress(addressDataArray[i]);

            if (screen.width < 1030) {
                var val_ = combinedAddress.length > 35 ? combinedAddress.slice(0, 32) + "..." : combinedAddress;
            } else {
                var val_ = combinedAddress.length > 65 ? combinedAddress.slice(0, 62) + "..." : combinedAddress;
            }
            const obj = {
                id: addressDataArray[i]?.deliveryAddressID,
                text: val_,
            };
            addressDataForSearch.push(obj);
        }
        return addressDataForSearch;
    }

    async function handleOnChangeDeliveryAddress(e) {
        if (e.target.selectedIndex != -1) {
            const data = searchAddressInitData[e.target.selectedIndex];
            let value = e.target.value;

            setSelectedAddressID(value);
            props.returnSelectedAddressID(value);
            setSelectedAddressData({
                addressDetailUnitFloor: data?.addressDetailUnitFloor,
                addressDetailBuilding: data?.addressDetailBuilding,
                addressDetailStreet: data?.addressDetailStreet,
                addressDetailDistrict: data?.addressDetailDistrict,
                addressDetailMainDistrict: data?.addressDetailMainDistrict,
                addressRemark: data?.addressRemark,
                addressGPSLatitude: data?.addressGPSLatitude,
                addressGPSLongtitude: data?.addressGPSLongtitude
            });
            props.returnSelectedAddressData(data);
        }
    }

    React.useEffect(() => {
        if (reloadMe) {
            setUpAddressArrayData();
            setReloadMe(false);
        }
    }, [reloadMe]);

    React.useEffect(() => {
        setReloadMe(props.reloadMe);
    }, [props.reloadMe]);


    React.useEffect(() => {
        setUpAddressArrayData();
    }, []);

    React.useEffect(() => {
        if (props.additionalAddressDataArray && Array.isArray(props.additionalAddressDataArray) && props.additionalAddressDataArray.length >= 1) {

            // merge these two arrays
            const filteredMap = new Map([...searchAddressInitData, ...props.additionalAddressDataArray].map(obj => [obj.deliveryAddressID, obj]));
            const mergedArray = Array.from(filteredMap.values());

            setSearchAddressInitData(mergedArray);
            let addressDataForSearch = breakDownArrayDataIntoSelectableText(mergedArray);
            setSearchAddressDisplayArray(addressDataForSearch);
        }
    }, [props.additionalAddressDataArray]);

    React.useEffect(() => {
        if (props.preSelectAddressID) {
            if (props.preSelectAddressID !== selectedAddressID) {
                // prevent infinte loop
                // actually react will prevent infinite loop if the state var doesnt change
                setSelectedAddressID(props.preSelectAddressID);
            }
        } else {
            clearInput();
        }
    }, [props.preSelectAddressID]);


    const clearInput = () => {
        setSelectedAddressID("");

    }

    return (
        <>
            {isLoading ? <SpinnerOverlay /> : ""}

            <div className="flex-grow-1 text-truncate">
                <span>{getAccessibleTagForSafeMode('deliveryEditChooseAddress')}</span>
                <InputTypeSelect2
                    disabled={props.readOnly || !isAccessible('deliveryEditChooseAddress')}
                    data={searchAddressDisplayArray}
                    value={selectedAddressID}
                    placeholder={t("--Select Address--")}
                    nameSelect2="deliveryAddressID"
                    // idSelect2="deliveryAddressID"
                    label={props.showLabel ? t("Delivery Address") : ""}
                    handleChange={(e) => {
                        handleOnChangeDeliveryAddress(e);
                    }
                    }
                />
            </div>
        </>
    );
}

export default DeliveryAddressSelectInput;
