import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { storeCar, updateCar } from "functions/Car.js";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import moment from "moment";

function CarModalEdit(props) {
  const { t } = useTranslation();
  const [statusID, setStatusID] = React.useState(1);
  const [carTypeID, setCarTypeID] = React.useState(1);
  const [unitID, setUnitID] = React.useState(1);

  const [carData, setCarData] = React.useState({
    carID: "",
    status: 1,
    carType: 1,
    carIDRemark: "",
    maxLoad: "",
    insuranceDate: moment(),
    licenseDate: moment(),
  });

  const {
    carID,
    status,
    carType,
    carIDRemark,
    maxLoad,
    insuranceDate,
    licenseDate,
  } = carData;

  const onInputChange = (e) => {
    setCarData({ ...carData, [e.target.name]: e.target.value });
  };

  const onChangeStatus = (e) => {
    setStatusID(e.params.data.id);
    setCarData({ ...carData, [e.target.name]: e.params.data.id });
  };

  const onChangeCarType = (e) => {
    setCarTypeID(e.params.data.id);
    setCarData({ ...carData, [e.target.name]: e.params.data.id });
  };

  const onChangeUnit = (e) => {
    setUnitID(e.params.data.id);
    setCarData({ ...carData, [e.target.name]: e.params.data.text });
  };

  const onChangeInsuranceDate = (e) => {
    setCarData({ ...carData, ["insuranceDate"]: e });
  };

  const onChangeLicenseDate = (e) => {
    setCarData({ ...carData, ["licenseDate"]: e });
  };

  const [statusList, setStatusList] = React.useState([
    { id: "normal", text: t("normal") },
    { id: "retire", text: t("retire") },
  ]);
  const [carTypeList, setCarTypeList] = React.useState([
    { id: "light truck", text: t("light truck") },
    { id: "medium truck", text: t("medium truck") },
    { id: "heavy truck", text: t("heavy truck") },
    { id: "van", text: t("van") },
    { id: "other", text: t("other") },
  ]);

  const [unitTypeList, setUnitTypeList] = React.useState([
    { id: 1, text: "gram" },
    { id: 2, text: "kilogram" },
    { id: 3, text: "tonne" },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let update = await updateCar(carData);
    update.code == 200 ? await props.refreshTable() : true;
    props.onHide();
  };

  React.useEffect(() => {
    if (Object.values(props.carData).length > 0) {
      let selectedCarType = carTypeList.filter((data) => {
        if (data.id == props.carData.carType) return true;
      });
      let selectedStatus = statusList.filter((data) => {
        if (data.id == props.carData.status) return true;
      });
      setCarData({
        carID: props.carData.carID,
        status: selectedStatus[0] ? selectedStatus[0].text : 1,
        carType: selectedCarType[0] ? selectedCarType[0].text : 1,
        carIDRemark: props.carData.carIDRemark,
        maxLoad: props.carData.maxLoad,
        insuranceDate: moment(props.carData.insuranceDate, "yyyy-MM-D"),
        licenseDate: moment(props.carData.licenseDate, "yyyy-MM-D"),
      });
      setCarTypeID(selectedCarType[0] ? selectedCarType[0].id : "");
      setStatusID(selectedStatus[0] ? selectedStatus[0].id : "");
    }
  }, [props.show]);
  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="6">
                <InputTypeTextForm
                  label={`${t("Car ID")}${"("}${t("Required")}${")"}`}
                  labelFor="carID"
                  inputName="carID"
                  inputId="carID"
                  inputPlaceholder={t("Car ID")}
                  value={carID}
                  readOnly={true}
                  handleChange={(e) => onInputChange(e)}
                  required={true}
                />
              </Col>
              <Col md="6">
                <InputTypeSelect2Form
                  data={carTypeList}
                  value={carTypeID}
                  nameSelect2="carType"
                  idSelect2="carType"
                  label={t("Car Type")}
                  handleOnChangeSelect={(e) => {
                    onChangeCarType(e);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputTypeSelect2Form
                  data={statusList}
                  value={statusID}
                  nameSelect2="status"
                  idSelect2="status"
                  label={t("Status")}
                  handleOnChangeSelect={(e) => {
                    onChangeStatus(e);
                  }}
                />
              </Col>
              <Col md="6">
                <InputTypeTextForm
                  label={t("Max Load") + "(Kg)"}
                  labelFor="maxLoad"
                  inputName="maxLoad"
                  inputId="maxLoad"
                  inputPlaceholder={t("Max Load")}
                  value={maxLoad}
                  handleChange={(e) => onInputChange(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <InputDateTimeGroupForm
                  label={t("Insurance Date")}
                  labelFor="insuranceDate"
                  inputName="insuranceDate"
                  inputId="insuranceDate"
                  inputPlaceholder={t("Insurance Date")}
                  value={insuranceDate}
                  // onDateChange={(e) => { setInsuranceDate(e) }}
                  onDateChange={(e) => onChangeInsuranceDate(e)}
                  showTimeBoo={false}
                />
              </Col>
              <Col md="6">
                <InputDateTimeGroupForm
                  label={t("License Date")}
                  labelFor="licenseDate"
                  inputName="licenseDate"
                  inputId="licenseDate"
                  inputPlaceholder={t("License Date")}
                  value={licenseDate}
                  // onDateChange={(e) => setLicenseDate(e)}
                  onDateChange={(e) => onChangeLicenseDate(e)}
                  showTimeBoo={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="carIDRemark"
                  inputName="carIDRemark"
                  inputId="carIDRemark"
                  inputPlaceholder={t("Remark")}
                  type={"textarea"}
                  value={carIDRemark}
                  handleChange={(e) => onInputChange(e)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {props.button}
            </Button>
            <Button color="default" type="button" onClick={props.onHide}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default CarModalEdit;
