import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardText,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import moment from "moment";
import Notifications from "../components/Notifications";
import { combineAddress } from "../../../functions/Delivery.js"
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import InvoiceBadge from "../components/InvoiceBadge.js";
import DeliveryBadge from "../components/DeliveryBadge";
import { getDateString } from "functions/Utility";

//props:
//systemLocalSettingData
//systemUserData (without job match)
//isSelectedCard
//useTranslate
//onClickEdit(userID, job, jobRemark)
//onClickDelete(userID, job)

function LocalSettingCard(props) {


    const { t } = useTranslation();
    const systemLocalSettingData = props.systemLocalSettingData;
    const systemUserData = props.systemUserData;
    const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;



    return systemLocalSettingData ? (
        <Card
            style={{ cursor: "pointer" }}
        >
            <CardHeader
                className={isSelectedCard ? "bg-gradient-success" : ""}>
                <Row onClick={(e) => {
                    e.preventDefault();

                }}>
                    <Col xs={12} >
                        <div className="tableRowTitle">
                           {systemLocalSettingData.settingValue}
                        </div>
                        <div className="font-weight-bold tableRowRemark">
                            {props.useTranslate ? `${t(systemLocalSettingData.settingDetail)}` : `${systemLocalSettingData.settingDetail}`}
                        </div>

                    </Col>

                </Row>
            </CardHeader>

            <CardBody
                className={isSelectedCard ? "bg-gradient-success" : ""}>

                <Row>
                    <Col onClick={(e) => {
                        e.preventDefault();
                    }}  >
                        <div className="font-weight-bold">
                            {props.useTranslate ? `${t(systemLocalSettingData.settingKey)}` : `${systemLocalSettingData.settingKey}`}
                        </div>
                        <div className="text-muted">
                            {systemLocalSettingData.userID}
                        </div>
                    </Col>
                </Row>

            </CardBody>



            <CardFooter>
                <Row>
                    <Col onClick={(e) => {
                        e.preventDefault();
                    }}  >
                        <Button
                            size="sm"
                            color="primary"
                            onClick={() => {
                                props.onClickEdit(systemLocalSettingData);
                            }}
                        >
                            <i className="ni ni-settings" />
                            <span>{t("Edit")}</span>
                        </Button>
                        <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                                props.onClickDelete(systemLocalSettingData);
                            }}
                        >
                            <i className="fas fa-trash" />
                            <span>{t("Delete")}</span>
                        </Button>
                    </Col>
                </Row>
            </CardFooter>

        </Card>
    ) : (
        <Col xs={12} sm={12} md={12} lg={12}>
            <Card>
                <CardBody>
                    <strong className="text-info h1">{t("No Data Found!")}</strong>
                    <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                </CardBody>
            </Card>
        </Col>
    );
}

export default LocalSettingCard;
