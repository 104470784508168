import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Col,
    Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";

import GoodCard from "../good/GoodCard.js";

function MembershipCreateInvoiceChooseSingleGood(props) {
    const { t } = useTranslation();

    const [goodListToChoose, setGoodListToChoose] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [goodToReturn, setGoodToReturn] = React.useState({});

    React.useEffect(() => {
        setGoodListToChoose(props.goodList);
    }, [props.goodList]);


    const confirmToReturnGood = () => {
        if (goodToReturn && Object.values(goodToReturn).length >= 1) {
            // a good a is selected
            props.returnSingleGood(goodToReturn);
            props.onHide();
        } else {
            Notifications({
                type: "dataCheck",
                message: t("Please choose a good")
            });
            return;
        }

    }

    const checkIsSelectedList = (goodID) => {
        try {
            return goodToReturn.goodID === goodID;
        } catch (error) {
            return false;
        }
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
            //  keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <ModalHeader >{props.title}</ModalHeader>
                <ModalBody className="">

                    <>
                        {goodListToChoose.map((goodItem, i) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                data-id={goodItem.goodID}
                                key={goodItem.goodID}
                            >
                                <GoodCard
                                    goodData={goodItem}
                                    onClickCard={(goodReturn) => {
                                        if (checkIsSelectedList(goodReturn.goodID)) {
                                            //cancel 
                                            setGoodToReturn({});
                                        } else {
                                            setGoodToReturn(goodReturn);
                                        }
                                    }}
                                    isSelectedCard={checkIsSelectedList(goodItem.goodID)}
                                    hideControlButton={true}
                                />
                            </Col>
                        ))}
                    </>


                </ModalBody>
                <ModalFooter>

                    <Row className="w-100 d-flex justify-content-between">
                        <div>

                        </div>
                        <div>
                            <Button color="primary" onClick={() => {
                                confirmToReturnGood();
                            }} type="button">
                                {props.button}
                            </Button>
                            <Button color="default" type="button" onClick={props.onHide}>
                                {t("Cancel")}
                            </Button>
                        </div>

                    </Row>

                </ModalFooter>
            </Modal>
        </>
    );
}

export default MembershipCreateInvoiceChooseSingleGood;
