import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Col,
  Label,
  Input,
  Row,
  FormGroup,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  UncontrolledTooltip
} from "reactstrap";
import InvoiceCard from "./InvoiceCard";

import InputTypeTextForm from "../components/InputTypeTextForm.js";

import { useTranslation } from "react-i18next";
import { useState } from "react";
import Notifications from "../components/Notifications.js";
import InputTypeText from "../components/InputTypeText";
import { getInvoice, getInvoiceByCustomerID } from "functions/Invoice.js";

import InvoiceMessageModal from "./InvoiceMessageModal";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";


import CustomerSelectInput from "../components/CustomerSelectInput";

// props
// invoiceStatusArray : ["paid", "Cancel"] etc.

function InvoiceSearchInvoiceModal(props) {
  const { t } = useTranslation();

  const invoiceStatusArray = props.invoiceStatusArray;

  const [selectedCustomerID, setSelectedCustomerID] = useState("");
  const [rerender, setRerender] = useState(false);
  const [invoiceListData, setInvoiceListData] = useState([]);

  const [invoiceForInvoiceMessage, setInvoiceForInvoiceMessage] = useState(null);
  const [isSelectedInvoice, setIsSelectedInvoice] = useState(null);
  const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);



  React.useEffect(() => {
    if (props.show) {
      if (props.selectedCustomerID) {
        if (props.selectedCustomerID !== "") {
          setSelectedCustomerID(props.selectedCustomerID);
        }
      }
    }
  }, [props.show]);

  React.useEffect(() => {
    const getInvoiceListWhenSelectCustomer = async () => {
      if (selectedCustomerID !== "") {
        const invoiceList = await getInvoiceByCustomerID(selectedCustomerID, invoiceStatusArray);
        setInvoiceListData(invoiceList);
      }
    };
    getInvoiceListWhenSelectCustomer();
  }, [selectedCustomerID]);


  const searchCustomer = (customerID) => {
    setSelectedCustomerID(customerID);
  };


  const onSelectPayment = (invoiceID, invoicePrice) => {
    let paymentList_ = invoiceToPayList;
    if (!paymentList_.some((val) => val.invoiceID === invoiceID)) {
      const obj = {
        invoiceID,
        invoicePrice: invoicePrice < 0 ? 0 : invoicePrice,
        amountToPay: "",
      };
      paymentList_.push(obj);
      setInvoiceToPayList(paymentList_);
    } else {
      const newList = paymentList_.filter((val) => val.invoiceID !== invoiceID);
      setInvoiceToPayList(newList);
    }
    setRerender(true);
    setTimeout(() => {
      setRerender(false);
    }, 10);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      props.selectedAnInvoice(isSelectedInvoice);
      props.onHide();
    } catch (err) {
      Notifications();
    }
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {rerender && <div />}
        <ModalHeader >{t("Invoice")}</ModalHeader>
        <ModalBody className="mt--4">
          <Row md={12}>
            <Col md={2}>
              <Label className="pr-1 mt-2 h4">{t("Customer")}</Label>
            </Col>
            <Col md={10}>



              <CustomerSelectInput
                preSelectCustomerID={selectedCustomerID}
                selectCustomerID={(customerID) => {
                  searchCustomer(customerID);
                }}
                showCreateButton={false}
                onCreateNewCustomer={() => {

                }} />
            </Col>
          </Row>


          <Row>
            {selectedCustomerID !== "" ? (
              <>
                {invoiceListData.map((invoiceItem, i) => (
                  <Col
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    data-id={invoiceItem.invoiceID}
                    key={invoiceItem.invoiceID}
                  >
                    <InvoiceCard
                      invoiceData={invoiceItem}
                      setClickViewInvoiceButton={(invoice) => {
                        setInvoiceForInvoiceMessage(invoice);
                        setModalInvoiceMessageIsOpen(true);
                      }}
                      onClickCard={(invoice) => {
                        if (isSelectedInvoice?.invoiceID === invoice.invoiceID) {
                          //cancel 
                          setIsSelectedInvoice(null);
                        } else {
                          setIsSelectedInvoice(invoice);
                        }
                      }}
                      isSelectedCard={isSelectedInvoice?.invoiceID === invoiceItem.invoiceID}
                      accessCustomerAction={false}
                      accessCloneInvoice={false}
                    />
                  </Col>
                ))}
              </>
            ) : (
              <div className="text-center my-5">
                {t("No Data, try search customer")}.
              </div>
            )}

          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button color="primary" onClick={handleSubmit} type="button">
            {t("Confirm")}
          </Button>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>

        <InvoiceMessageModal
          show={modalInvoiceMessageIsOpen}
          onHide={() => setModalInvoiceMessageIsOpen(false)}
          title={t("Invoice Message")}
          refreshTable={() => { }}
          invoiceData={invoiceForInvoiceMessage}
        />
      </Modal>
    </>
  );
}

export default InvoiceSearchInvoiceModal;
