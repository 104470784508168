import React from "react";
import { Label, FormGroup, Button } from "reactstrap";
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import "react-datepicker/dist/react-datepicker.css";
import Select2 from "react-select2-wrapper";
import { useTranslation } from "react-i18next";


// props
// useTranlate
// data 
// customSearchFunction(params, data)  // call back function // a custom search function

function InputTypeSelect2Form(props) {
  const { t } = useTranslation();

  const [dataDisplayArray, setDataDisplayArray] = React.useState([]);

  React.useEffect(() => {
    setDataDisplayArray(props.data);
  }, [props.data]);


  
  // dataArray format: 
  //[{id: XXX, text: YYY}, {id: HHH, text: KKK}]
  function convertDataTranslation(dataArray) {
    try {
      if (props.useTranslate) {
        if (!dataArray || Object.values(dataArray).length == 0) {
          return [];
        }
        return dataArray.map(element => {
  
          return { id: element.id, text: t(element.text) };
        });
  
      } else {
        return dataArray;
      }
    } catch (error) {
      return [];
    }
   
  }

  function defaultCustomSearchFunction(params, data) {
    // Return every item with text search

    // params.term undefined means user hasnt input anything
    if (!params.term) {
      return data;
    }
    // If there are no search terms, return all of the data
    if (params.term.trim() === '') {
      return data;
    }

    // Do not display the item if there is no 'text' property
    if (typeof data.text === 'undefined') {
      return null;
    }

    // `params.term` should be the term that is used for searching
    // `data.text` is the text that is displayed for the data object
    // Below are the filter conditions
    if (
      data.text.toUpperCase().replace(' ', '').indexOf(params.term.toUpperCase().replace(' ', '')) != -1
    ) {
      // found
      // Return this item means it will be shown in the list
      return data;
    } else {
      // cannot find 
      // Return `null` if the term should not be displayed
      return null
    }
  }

  function matchCustom(params, data) {
    if (props.customSearchFunction) {
      return props.customSearchFunction(params, data);
    } else {
      return defaultCustomSearchFunction(params, data);
    }
  }

  function optionForControl() {
    // if props.customSearchFunction is undefined, then dont put data into the fucntion
    if (props.customSearchFunction) {
      return {
        placeholder: props.inputPlaceholder ? props.inputPlaceholder : t("Choose an item..."),
        disabled: props.disabled ?? false,
        matcher: matchCustom,
       data: dataDisplayArray ? convertDataTranslation(dataDisplayArray) : []
      }
    } else {
      return {
        placeholder: props.inputPlaceholder ? props.inputPlaceholder : t("Choose an item..."),
        disabled: props.disabled ?? false
      }
    }
  }

  function dataForControl() {
    // if props.customSearchFunction is undefined, then put data into the fucntion
    if (props.customSearchFunction) {
      return [];
    } else {
      return dataDisplayArray ? convertDataTranslation(dataDisplayArray) : [];
    }
  }


  const style = {
    control: base => ({
      ...base,
      // border: 0,
      // // This line disable the blue border
      // boxShadow: "none",
      // width: "10%",
      // height: '500px'
    })
  };


  return (
    <FormGroup className="mb-1 flex-grow-1">
      {props.label !== "" ?
        <Label for={props.labelFor} className="form-control-label">
          {props.label}
        </Label>
        : <></>}
      <Select2
        //  style={{ 
        //   color: "green" ,
        //  width: "75%"
        // }}
 //       disabled={true}
        styles={style}
        className="form-control addressSelector"
       // defaultValue={props.value ? props.value : -1}
        value={props.value ? props.value : -1}
        data={dataForControl()}
        // multiple // multiple selected value
        options={optionForControl()}
        onChange={props.handleChange}
        onSelect={props.handleOnChangeSelect}
        name={props.nameSelect2}
        id={props.idSelect2}
      />
    </FormGroup>
  );
}

export default InputTypeSelect2Form;
