import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Label,
    Col,
    Badge
} from "reactstrap";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { openPDFLink } from "functions/Utility";

function DeliveryRecordMessageModal(props) {
    const { t } = useTranslation();
    const [DNURL, setDNURL] = React.useState("");
    const [deliveryRecordMessage, setDeliveryRecordMessage] = React.useState("");

    const [deliveryRecordMessageIncludeDP, setDeliveryRecordMessageIncludeDP] = React.useState(true); // DP = delivery photo 
    const [deliveryRecordMessageIncludeCompanyChop, setDeliveryRecordMessageIncludeCompanyChop] = React.useState(true);

    const [actionCopyDone, setActionCopyDone] = React.useState(false);

    //props.show means this form is triggered by parent form
    React.useEffect(() => {
        if (props.show) {
            setDeliveryRecordMessage(props?.deliveryRecordData?.deliveryRecordMessage);
            setDNURL(props?.deliveryRecordData?.DNURL);
        } else {
            clearForm()
        }
    }, [props.show]);

    React.useEffect(() => {
        editDeliveryRecordMessage(deliveryRecordMessageIncludeDP, deliveryRecordMessageIncludeCompanyChop);
        editDNURL(deliveryRecordMessageIncludeDP, deliveryRecordMessageIncludeCompanyChop);
    }, [deliveryRecordMessageIncludeDP, deliveryRecordMessageIncludeCompanyChop]);


    const clearForm = () => {
        setDeliveryRecordMessageIncludeDP(true);
        setDeliveryRecordMessageIncludeCompanyChop(true);
        setActionCopyDone(false);
    }

    const editDeliveryRecordMessage = (DPTrueFalse, chopTrueFalse) => {
        const chopRegex = /chop=[\w]+/g;
        const DPRegex = /DP=[\w]+/g;

        try {
            let deliveryRecordMessageToProcess = deliveryRecordMessage;
            let chopMatchResult = deliveryRecordMessageToProcess.match(chopRegex);
            let DPMatchResult = deliveryRecordMessageToProcess.match(DPRegex);
            if (!chopMatchResult || !DPMatchResult) {
                // Do nothing and leave
                return;
            }

            deliveryRecordMessageToProcess = deliveryRecordMessageToProcess.replace(DPMatchResult[0], `DP=${DPTrueFalse ? 'yes' : 'no'}`)
                .replace(chopMatchResult[0], `chop=${chopTrueFalse ? 'yes' : 'no'}`);

            setDeliveryRecordMessage(deliveryRecordMessageToProcess);
        } catch (error) {

        }
    }

    const editDNURL = (DPTrueFalse, chopTrueFalse) => {
        const chopRegex = /chop=[\w]+/g;
        const DPRegex = /DP=[\w]+/g;

        try {
            let deliveryRecordMessageToProcess = DNURL;

            let chopMatchResult = deliveryRecordMessageToProcess.match(chopRegex);
            let DPMatchResult = deliveryRecordMessageToProcess.match(DPRegex);
            if (!chopMatchResult || !DPMatchResult) {
                // Do nothing and leave
                return;
            }
            deliveryRecordMessageToProcess = deliveryRecordMessageToProcess.replace(DPMatchResult[0], `DP=${DPTrueFalse ? 'yes' : 'no'}`)
                .replace(chopMatchResult[0], `chop=${chopTrueFalse ? 'yes' : 'no'}`);
            setDNURL(deliveryRecordMessageToProcess);
        } catch (error) {

        }

    }
    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader >{props.title}</ModalHeader>
                <ModalBody className="mt--4">
                    <Col md={6} lg={6}>
                        <Label className="form-control-label mt-4">
                            {t("Delivery PDF Message")}
                        </Label>
                    </Col>
                    <Col>
                        <InputTypeTextForm
                            label=""
                            labelFor="deliveryRecordMessage"
                            inputName="deliveryRecordMessage"
                            inputId="deliveryRecordMessage"
                            inputPlaceholder={t("Delivery PDF Message")}
                            type="textarea"
                            value={deliveryRecordMessage}
                            handleChange={(e) => { setDeliveryRecordMessage(e.target.value) }}
                        />


                        <div className="d-flex mt-1">
                            <Button
                                className="flex-fill"
                                color={deliveryRecordMessageIncludeCompanyChop ? "success" : "danger"}
                                onClick={() => { setDeliveryRecordMessageIncludeCompanyChop(!deliveryRecordMessageIncludeCompanyChop) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {deliveryRecordMessageIncludeCompanyChop ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Company Chop")}</span>
                            </Button>

                            <Button
                                className="flex-fill"
                                color={deliveryRecordMessageIncludeDP ? "success" : "danger"}
                                onClick={() => { setDeliveryRecordMessageIncludeDP(!deliveryRecordMessageIncludeDP) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {deliveryRecordMessageIncludeDP ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Delivery photo")}</span>
                            </Button>
                        </div>

                        <div className="d-flex flex-column align-items-right mt-1" >
                            <Button
                                color="primary"
                                onClick={() => {
                                    navigator.clipboard.writeText(deliveryRecordMessage);
                                    setActionCopyDone(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy")}</span>
                            </Button>
                        </div>
                    </Col>
                    <Col
                        className="d-flex flex-column align-items-center"
                    >
                        <QRCode
                            onClick={() =>
                                openPDFLink(DNURL)
                            }
                            style={{ cursor: "pointer" }}
                            className="mt-5"
                            value={DNURL ? DNURL : ""}
                            size={200}
                        />
                        <div
                            onClick={() =>
                                openPDFLink(DNURL)
                            }
                            style={{ cursor: "pointer" }}
                            className="text-sm text-underline mt-2"
                        >
                            {t("Click to view")}
                        </div>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default DeliveryRecordMessageModal;
