import React from "react";
import { Row, Label, Col, Input } from "reactstrap";

function InputTypeText(props) {
  if (props.handleChange == "undefined") {
    props.handleChange = async function name(e) {
      e.preventDefault();
    };
  }
  return (
    <Row className="mt-2">
      <Col xs={12} sm={12} md={2} lg={2}>
        <Label for={props.labelFor} className="form-control-label">
          {props.label}
        </Label>
      </Col>
      <Col xs={12} sm={12} md={10} lg={10}>
        <Input
          className={`${props.required ? "requirdInput" : ""}`}
          type={props.type ?? "text"}
          rows={props.type == "textarea" ? 5 : 1}
          name={props.inputName}
          id={props.inputId}
          placeholder={props.inputPlaceholder}
          value={props.value}
          onChange={props.handleChange}
          readOnly={props.readOnly ?? false}
          required={props.required}
        />
      </Col>
    </Row>
  );
}

export default InputTypeText;
