import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useWindowDimensions from "../../../functions/Utility";
import SignatureCanvas from "./SignatureCanvas";
import { useTranslation } from "react-i18next";

function DeliveryRecordSignatureModal(props) {
  const { t } = useTranslation();
  const { isOpen, toggle, onSubmit } = props;
  const { width } = useWindowDimensions();

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("Sign")}</ModalHeader>
      <ModalBody>
        <SignatureCanvas
          canvasWidth={(width >= 576 ? 500 : width - 16) - 70}
          canvasHeight={200}
          strokeColor="#222"
          strokeWidth={3}
          onSubmit={onSubmit}
        />
      </ModalBody>
    </Modal>
  );
}

export default DeliveryRecordSignatureModal;
