import React from "react";
import {
    Badge,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
// props
// arrayIDTextFormat
// defaultColor
// dropdownName
// valueID
// useTranlate : true , false
function DropdownControl(props) {

    const { t } = useTranslation();
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [arrayIDTextFormat, setArrayIDTextFormat] = useState([]);
    // const arrayIDTextFormat = props.arrayIDTextFormat; // [{id: XXX, text: YYY, color:"primary"}, {id: AAA, text: BBB, color:"danger"}]
    const dropdownName = props.dropdownName;
    const defaultColor = props.defaultColor ? props.defaultColor : "secondary";
    const [valueID, setValueID] = React.useState("");
    const [valueText, setValueText] = React.useState("");




    React.useEffect(() => {
        setValueID(props.valueID);
        setArrayIDTextFormat(props.arrayIDTextFormat);
        configValueText(props.valueID, props.arrayIDTextFormat);
    }, [props.valueID, props.arrayIDTextFormat]);

    const configValueText = (valueIDToCheck, arrayIDTextFormat) => {
        try {
            if (!valueIDToCheck) return;
            if (!arrayIDTextFormat) return;
            if (Object.values(arrayIDTextFormat).length <= 0) return;
            const valuTextToFind = arrayIDTextFormat.find(item => item.id === valueIDToCheck);
            setValueText(props.useTranslate ? t(valuTextToFind.text) : valuTextToFind.text);
        } catch {
            return "";
        }
    }
    const getColor = (valueIDToCheck) => {
        try {
            if (!valueIDToCheck) return defaultColor;
            if (!arrayIDTextFormat) return defaultColor;
            if (Object.values(arrayIDTextFormat).length <= 0) return defaultColor;
            const colorToFind = arrayIDTextFormat.find(item => item.id === valueIDToCheck);
            return (colorToFind.color ? colorToFind.color : defaultColor);
        } catch {
            return defaultColor;
        }
    }

    const onClickDropdownItem = (valueID) => {
        // just for show in this control
        setValueID(valueID);
        // call back to do something 
        props.onSelectValueID(valueID);

    }

    return (
        <>

            <UncontrolledDropdown group
>
                <DropdownToggle 
                caret 
                color={getColor(valueID)}
                disabled={props.disabled}
                >
                    {/* {valueID ? valueText :  t(dropdownName)} */}
                    {/* {valueID ? valueText : (props.useTranslate ? t(dropdownName) : dropdownName)} */}
                    {valueID ? valueText : dropdownName}
                </DropdownToggle>
                <DropdownMenu>
                    {/* arrayIDTextFormat = [{id: XXX, text: YYY}, {id: AAA, text: BBB}] */}
                    {arrayIDTextFormat && Object.values(arrayIDTextFormat).length > 0 && arrayIDTextFormat.map((item) => {
                        return <DropdownItem
                            key={item.id}
                            onClick={(e) => {
                                e.preventDefault();
                                onClickDropdownItem(item.id);
                            }}>
                            {props.useTranslate ? t(item.text) : item.text}

                        </DropdownItem>;
                    })}
                </DropdownMenu>
            </UncontrolledDropdown>
           
        </>
    );
}

export default DropdownControl;
