import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  Input,
  Collapse,
  Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { isLoggedIn } from "../../../functions/Auth";
// core components
import PaymentHeader from "components/Headers/PaymentHeader";
import PaymentCreateModal from "./PaymentCreateModal";
import PaymentEditModal from "./PaymentEditModal";
import PaymentCard from "./PaymentCard";
import { getAllPayment } from "functions/Payment";
import PaymentSearchBar from "../components/PaymentSearchBar";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import PaymentMessageModal from "./paymentMessageModal";
import { isAccessible } from "functions/SystemUserAccess";
import { getIntegerDecodeFromString, getRandomBgColor, lockScrolling } from "../../../functions/Utility";
import moment from "moment";
import { getPaymentByDateRangeAndPaymentMethod } from "functions/Payment";

function PaymentScreen(props) {
  const { t } = useTranslation();
  const history = useHistory();


  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);


  const [paymentData, setPaymentData] = React.useState([]);
  const [initpaymentData, setInitPaymentData] = React.useState([]);

  const [selectedPaymentData, setSelectedPaymentData] = React.useState(null);

  // For filter control show
  const [showFullCard, setShowFullCard] = React.useState(false);
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

  // modal 
  const [modalPaymentMessageIsOpen, setModalPaymentMessageIsOpen] = React.useState(false);
  const [modalEditPaymentIsOpen, setModalEditPaymentIsOpen] = React.useState(false);
  const [modalCreateModalIsOpen, setModalCreateModalIsOpen] = React.useState(false);

  // GUI
  const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);
  const [usePaymentStatusColor, setUsePaymentStatusColor] = React.useState(false);
  const [usePaymentRecordDateTimeRangeColor, setUsePaymentRecordDateTimeRangeColor] = React.useState(false);


  // data control
  const dataIntervalInteger = 10;
  const [useFilterStartDate, setUseFilterStartDate] = React.useState(true);
  const [useFilterEndDate, setUseFilterEndDate] = React.useState(true);
  const [dataRangeStartDate, setDataRangeStartDate] = React.useState(moment(new Date()).add(dataIntervalInteger * -1, 'days').toDate()); // default 10 day
  const [dataRangeEndDate, setDataRangeEndDate] = React.useState(moment(new Date()).add(dataIntervalInteger, 'days').toDate()); // default 10 day

  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalPaymentMessageIsOpen || modalEditPaymentIsOpen || modalCreateModalIsOpen);
    lockScrolling(modalPaymentMessageIsOpen || modalEditPaymentIsOpen || modalCreateModalIsOpen);
  }, [modalPaymentMessageIsOpen, modalEditPaymentIsOpen, modalCreateModalIsOpen]);


  React.useEffect(() => {
    getPaymentData();
  }, [refreshTable]);

  const getPaymentData = async () => {
    setIsLoading(true);

    let paymentData = await getAllPayment();

    if (useFilterStartDate && useFilterEndDate) {
      // get data from server using the date option
      if (dataRangeStartDate && dataRangeEndDate) {
        // Get data according to the date range
        paymentData = await getPaymentByDateRangeAndPaymentMethod(dataRangeStartDate, dataRangeEndDate);
      } else {
        // error case
        // dont do anything
      }
    } else {
      // force to get all data from server
      paymentData = await getAllPayment();
    }


    setInitPaymentData(paymentData);
    setIsLoading(false);
  };

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };



  const getCardBgColor = (paymentStatus, paymentRecordDateString) => {
    // This control the card Bg color 
    if (enableCardBgColor) {
      // priority 
      // Driver color first
      // carID color second // Only if these are all true at the same time 
      if (usePaymentStatusColor) {
        return getRandomBgColor(getIntegerDecodeFromString(paymentStatus));
      } else if (usePaymentRecordDateTimeRangeColor) {
        return getRandomBgColor(getIntegerDecodeFromString(paymentRecordDateString));
      } else {
        return "";
      }
    }
  }

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}
      <ScreenPullToRefresh
        onRefresh={async () => {
          await getPaymentData();
        }}
        disabled={pullToRefreshDisable}>
          
        <PaymentHeader
          name={t("Payment List")} // Name of this page
          icon="fas fa-home"
          paymentData={paymentData}
          goBackLastPage={() => { history.goBack() }}
        />

      </ScreenPullToRefresh>
      <Container style={{ marginTop: "-1rem" }} fluid>

        <PaymentSearchBar
          initPaymentData={initpaymentData}
          dataRangeStartDate={dataRangeStartDate}
          dataRangeEndDate={dataRangeEndDate}
          changeCardColor={(enableBgColor, usePaymentStatusColor, usePaymentRecordDateTimeRangeColor) => {
            setEnableCardBgColor(enableBgColor);
            setUsePaymentStatusColor(usePaymentStatusColor);
            setUsePaymentRecordDateTimeRangeColor(usePaymentRecordDateTimeRangeColor);
          }}
          returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
          returnFilteredSortedPaymentData={(filteredSortedpaymentData) => { setPaymentData(filteredSortedpaymentData); }}
          createPayment={() => { setModalCreateModalIsOpen(true) }}
          refreshInitialData={(filterStartDateRequestByUser, filterEndDateRequestByUser, useFilterStartDateRequestByUser, useFilterEndDateRequestByUser) => {

            if (moment(filterStartDateRequestByUser).diff(moment(dataRangeStartDate), 'seconds') == 0 &&
              moment(filterEndDateRequestByUser).diff(moment(dataRangeEndDate), 'seconds') == 0 &&
              useFilterStartDateRequestByUser == useFilterStartDate &&
              useFilterEndDateRequestByUser == useFilterEndDate) {
              // values are the same 
              // prevent multiple fetch from the server
            } else {
              setUseFilterStartDate(useFilterStartDateRequestByUser);
              setUseFilterEndDate(useFilterEndDateRequestByUser);
              setDataRangeStartDate(moment(filterStartDateRequestByUser).add(dataIntervalInteger * -1, 'days').toDate());
              setDataRangeEndDate(moment(filterEndDateRequestByUser).add(dataIntervalInteger * 1, 'days').toDate());
              refreshTableData();
            }
          }}

        >
        </PaymentSearchBar>

        <Row className="mt-4">
          <Col md={12} xs={12} sm={12} xl={12} lg={12}>

            <Row>
              {paymentData && Object.values(paymentData).length >= 1 && paymentData.map((paymentItem, index) => (
                <Col
                  key={index}
                  style={{ cursor: "pointer" }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  data-id={paymentItem.paymentID}
                >

                  <PaymentCard
                    showFullCard={showFullCard}
                    cardBgColor={getCardBgColor(paymentItem.paymentStatus, moment(paymentItem.paymentRecordDateTime).format("yyyy-MM-DD").toString())}
                    enableCardBgColor={enableCardBgColor}
                    paymentData={paymentItem}
                    onClickCard={(paymentItem) => {
                      setSelectedPaymentData(paymentItem);
                      setModalEditPaymentIsOpen(true);
                    }}
                    isSelectedCard={false}
                    clickPaymentMessage={(paymentData) => {
                      setSelectedPaymentData(paymentData);
                      setModalPaymentMessageIsOpen(true);
                    }}
                  />

                </Col>
              ))}
              {paymentData.length === 0 && (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <strong className="text-info h1">
                        {t("No Data Found!")}
                      </strong>
                      <h3 className="text-info">
                        {t("Please Check Your Filter")}
                      </h3>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <PaymentCreateModal
          show={modalCreateModalIsOpen}
          onHide={() => setModalCreateModalIsOpen(false)}
          title={t("Add Payment")}
          button={t("Save")}
          refresh={refreshTableData}
        />
        <PaymentEditModal
          show={modalEditPaymentIsOpen}
          onHide={() => setModalEditPaymentIsOpen(false)}
          title={t("Payment Detail")}
          button={t("Save")}
          refresh={refreshTableData}
          selectedData={selectedPaymentData}
        />
        <PaymentMessageModal
          show={modalPaymentMessageIsOpen}
          onHide={() => setModalPaymentMessageIsOpen(false)}
          title={t("Payment Message")}
          refreshTable={() => { }}
          paymentData={selectedPaymentData}
        />
      </Container>
    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default PaymentScreen;
