import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Card,
    CardBody,
    CardFooter,
    FormGroup,
    Label,
    Nav,
    NavLink,
    NavItem,
    Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";
import AddressModalCreate from "./AddressModalCreate";
import AddressModalEdit from "./AddressModalEdit";

import { getAllDeliveryAddress, combineAddress, deleteAddressByID } from "functions/DeliveryAddress";
import AddressRelatedDeliveryRecord from "./AddressRelatedDeliveryRecord";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { paginationTable } from "functions/PaginationTable";
import { isAccessible } from "functions/SystemUserAccess";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { lockScrolling } from "functions/Utility";



function AddressScreen(props) {
    const { t } = useTranslation();
    const pagination = paginationTable();
    const { SearchBar } = Search;

    const [deliveryAddressData, setDeliveryAddressData] = React.useState([]);
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(0);
    const [selectedDeliveryAddressToCloneData, setSelectedDeliveryAddressToCloneData] = React.useState([]);

    // GUI
    const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

    //  modal
    const [modalCreateDeliveryAddressIsOpen, setModalCreateDeliveryAddressIsOpen] = React.useState(false);
    const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);
    const [modalAddressRelatedDeliveryRecordIsOpen, setModalAddressRelatedDeliveryRecordIsOpen] = React.useState(false);



    React.useEffect(() => {
        // Pull to refresh must be disabled if opened some medal
        // Otherwise will lock the screen of the modal
        setPullToRefreshDisable(modalCreateDeliveryAddressIsOpen || modalEditIsOpen || modalAddressRelatedDeliveryRecordIsOpen);
       lockScrolling(modalCreateDeliveryAddressIsOpen || modalEditIsOpen || modalAddressRelatedDeliveryRecordIsOpen)
    }, [modalCreateDeliveryAddressIsOpen, modalEditIsOpen, modalAddressRelatedDeliveryRecordIsOpen]);


    // Call API to get all data
    React.useEffect(() => {
        getData();
    }, [refreshTable]);


    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const getData = async () => {
        setIsLoading(true);
        let deliveryAddressData = await getAllDeliveryAddress();
        setDeliveryAddressData(deliveryAddressData);
        setIsLoading(false);
    };

    // Delete Confirmation
    async function deleteConfirmation(deliveryAddressID) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: deliveryAddressID,
            callback: deleteDeliveryAddress,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteDeliveryAddress(deliveryAddressID) {
        setIsLoading(true);
        let deleteResponse = await deleteAddressByID(deliveryAddressID);
        setIsLoading(false);
        deleteResponse.code == 200 ? refreshTableData() : true;
    }


    const { ref } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        onPlaceSelected: (place) => { },
        options: {
            types: [],
            componentRestrictions: { country: "HK" }
        },
        defaultValue: "",
        libraries: ["places"],
        language: "zh-HK"
    })


    return (isLoggedIn() && isAccessible(props.accessID)) ? (
        <div>
            {isLoading ? <SpinnerOverlay /> : ""}

            <ScreenPullToRefresh
                onRefresh={async () => {
                    await getData();
                }}
                disabled={pullToRefreshDisable}>
                <SimpleHeader parentName={t("Delivery Address")} icon="fas fa-map" />
            </ScreenPullToRefresh>
            <Container style={{ marginTop: "-4rem" }} fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <ToolkitProvider
                                    data={deliveryAddressData}
                                    keyField="deliveryAddressID"
                                    columns={
                                        [
                                            { dataField: "deliveryAddressID", text: "", hidden: true },
                                            { dataField: "addressDetailUnitFloor", text: "", hidden: true },
                                            { dataField: "addressDetailBuilding", text: "", hidden: true },
                                            { dataField: "addressDetailStreet", text: "", hidden: true },
                                            { dataField: "addressDetailDistrict", text: "", hidden: true },
                                            { dataField: "addressDetailMainDistrict", text: "", hidden: true },
                                            { dataField: "addressDetailCity", text: "", hidden: true },
                                            { dataField: "addressRemark", text: "", hidden: true },
                                            { dataField: "addressGPSLatitude", text: "", hidden: true },
                                            { dataField: "addressGPSLongtitude", text: "", hidden: true },
                                            {
                                                dataField: "combined",
                                                text: "",
                                                // text: (
                                                //     <span className="tableTitle">{t("Address")}</span>
                                                // ),
                                                formatter: (cellContent, row, rowIndex, extraData) => {
                                                    return (
                                                        <div
                                                            className="ml--3 mr--3"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <>
                                                                <a
                                                                    className="table-action"
                                                                    id="tooltip209424781"
                                                                    onClick={() => {
                                                                        setSelectedDeliveryAddress(row);
                                                                        setModalAddressRelatedDeliveryRecordIsOpen(true);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <i className="fas fa-map fa-lg" />
                                                                </a>
                                                                <a
                                                                    className="table-action"
                                                                    id="tooltip209424781"
                                                                    onClick={() => {
                                                                        setSelectedDeliveryAddress(row);
                                                                        setModalEditIsOpen(true);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <i className="fas fa-edit fa-lg" />
                                                                </a>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip209424781"
                                                                >
                                                                    {t("Edit")}
                                                                </UncontrolledTooltip>
                                                                <a
                                                                    className="table-action"
                                                                    id="tooltip209424444"
                                                                    onClick={() => {
                                                                        //setSelectedGoodData(row);
                                                                        //setModalEditIsOpen(true);
                                                                        setSelectedDeliveryAddressToCloneData(row);
                                                                        setModalCreateDeliveryAddressIsOpen(true);
                                                                    }}
                                                                >
                                                                    {" "}
                                                                    <i className="fas fa-copy fa-lg" />
                                                                </a>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip209424444"
                                                                >
                                                                    {t("Copy")}
                                                                </UncontrolledTooltip>
                                                                <a
                                                                    className="table-action table-action-delete"
                                                                    id="tooltip12475020"
                                                                    onClick={() =>
                                                                        deleteConfirmation(row.deliveryAddressID)
                                                                    }
                                                                >
                                                                    {" "}
                                                                    <i
                                                                        className="fas fa-trash fa-lg"
                                                                        data-id={cellContent}
                                                                    />
                                                                </a>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target="tooltip12475020"
                                                                >
                                                                    {t("Delete")}
                                                                </UncontrolledTooltip>
                                                            </>
                                                            <div
                                                                onClick={() => {
                                                                    setSelectedDeliveryAddress(row);
                                                                    setModalAddressRelatedDeliveryRecordIsOpen(true);
                                                                }}>
                                                                <div className="tableRowTitle">
                                                                    {combineAddress(row)}
                                                                </div>
                                                                <div className="tableRowRemark">
                                                                    {row.addressRemark}</div>
                                                                <div className="tableRowSubSubTitle">
                                                                    {row.deliveryAddressID}
                                                                </div>
                                                            </div>



                                                        </div>

                                                    );
                                                },
                                            },

                                        ]
                                    }
                                    search
                                >
                                    {(props) => (
                                        <div className="table-responsive table-sm">
                                            <Row className="pb-2">
                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                    <SearchBar
                                                        className=""
                                                        placeholder={t("Search")}
                                                        {...props.searchProps}
                                                    />
                                                </Col>
                                                <Col
                                                    className={"text-right"}
                                                    xs={5}
                                                    sm={5}
                                                    md={5}
                                                    lg={5}
                                                >
                                                    <Button
                                                        color="primary"
                                                        id="tooltip443412080"
                                                        onClick={() => setModalCreateDeliveryAddressIsOpen(!modalCreateDeliveryAddressIsOpen)}
                                                        size="md"
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-plus" />
                                                        </span>
                                                        <span className="btn-inner--text">{t("Add")}</span>
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={1}
                                                        target="tooltip443412080"
                                                    >
                                                        {t("Create")}
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </Row>

                                            <BootstrapTable
                                                headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddressModalCreate
                show={modalCreateDeliveryAddressIsOpen}
                onHide={(e) => {
                    setModalCreateDeliveryAddressIsOpen(false);
                }}
                title={t("Create new address")}
                button={t("Add")}
                refreshTable={refreshTableData}
                deliveryAddressToClone={selectedDeliveryAddressToCloneData}
                getNewAddressData={(newAddressData) => {
                    // do nothing
                }}

            />

            <AddressModalEdit
                show={modalEditIsOpen}
                onHide={() => setModalEditIsOpen(false)}
                title={t("Edit address")}
                button={t("Update")}
                refreshTable={refreshTableData}
                deliveryAddressToClone={selectedDeliveryAddress}

            />

            <AddressRelatedDeliveryRecord
                show={modalAddressRelatedDeliveryRecordIsOpen}
                onHide={() => setModalAddressRelatedDeliveryRecordIsOpen(false)}
                preSelectAddressID={selectedDeliveryAddress.deliveryAddressID}
                canChooseAddress={false}
            />
        
        </div>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default AddressScreen;
