import React, { useState } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form.js";
import { updateMembershipType } from "functions/Membership.js";
import { getMembershipTypeByMembershipTypeID } from "functions/Membership.js";
import Notifications from "../components/Notifications.js";
import GoodCard from "../good/GoodCard.js";
import { matchMembershipTypeWithGoodIDList } from "functions/Membership.js";
import { getGoodSelect2 } from "functions/Goods.js";

function MembershipTypeEditModal(props) {
    const { t } = useTranslation();


    //Initialize Data
    // in a structure of [{id: xxx, text: yyy, obj: {....}}, {.......}]
    const [goodChooseList, setGoodChooseList] = React.useState([]);
    const [goodSelected, setGoodSelected] = React.useState({});

    // Submit data
    const [submitMembershipTypeName, setSubmitMembershipTypeName] = React.useState("");
    const [submitMembershipTypeDetail, setSubmitMembershipTypeDetail] = React.useState("");
    const [submitMembershipTypeID, setSubmitMembershipTypeID] = React.useState("");

    // list
    const [membershipTypeGoodMatchesList, setMembershipTypeGoodMatchesList] = React.useState([]);
    const [goodFromMatchList, setGoodFromMatchList] = React.useState([]);



    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);
    const [refreshTableCount, setRefreshTableCount] = React.useState(0);
    const [showChooseGoodInput, setShowChooseGoodInput] = React.useState(false);

    // Modal 


    //Initial Get Good Data List
    React.useEffect(() => {
        const initDataGood = async () => {
            let getGoodList = await getGoodSelect2();
            return setGoodChooseList(getGoodList);
        };
        if (props.show) {
            initDataGood();
        }
    }, [props.show]);

    React.useEffect(() => {
        updateMembershipTypeDataFromDB(submitMembershipTypeID);
    }, [refreshTableCount]);

    React.useEffect(() => {
        if (!membershipTypeGoodMatchesList || !Array.isArray(membershipTypeGoodMatchesList) || membershipTypeGoodMatchesList.length < 0) {
            return;
        }

        let goodListToGet = membershipTypeGoodMatchesList.map((item, index) => {
            return item.Good;
        })

        if (!goodListToGet || !Array.isArray(goodListToGet) || goodListToGet.length < 0) {
            return;
        }

        setGoodFromMatchList(goodListToGet);
    }, [membershipTypeGoodMatchesList]);

    React.useEffect(() => {
        if (props.show) {
            if (props.membershipTypeDataToEdit && Object.keys(props.membershipTypeDataToEdit).length >= 1) {

                setSubmitMembershipTypeID(props.membershipTypeDataToEdit?.membershipTypeID);
                setSubmitMembershipTypeName(props.membershipTypeDataToEdit?.membershipTypeName);
                setSubmitMembershipTypeDetail(props.membershipTypeDataToEdit?.membershipTypeDetail);

                setMembershipTypeGoodMatchesList(props.membershipTypeDataToEdit?.MembershipTypeGoodMatches);

                setFinishSubmitBoo(false);
            }
        }
    }, [props.show, props.membershipTypeDataToEdit]);

    const refreshTableData = () => {
        setRefreshTableCount(refreshTableCount + 1);
    }

    const onChooseGood = async (goodID) => {
        if (goodID === "") {

        } else {
            //      let goodFromServer = (await getGoodsByID(goodID)) || [];
            let goodFromServer = (getGoodFromLocalList(goodID)) || [];
            if (goodFromServer) {
                setGoodSelected(goodFromServer);
            }
        }
    };


    const getGoodFromLocalList = (goodID) => {

        if (!goodChooseList || Object.values(goodChooseList).length <= 0) {
            return null;
        }
        let goodArrayToGet = goodChooseList.find((item) => item.id === goodID);

        if (!goodArrayToGet || Object.values(goodArrayToGet).length <= 0) {
            return null;
        }
        return goodArrayToGet.obj;
    }

    // Add API Call
    const onAddGoodCard = async (goodToAdd) => {

        if (!goodToAdd || Object.values(goodToAdd).length <= 0) {
            return;
        }

        if (!goodFromMatchList || !Array.isArray(goodFromMatchList) || goodFromMatchList.length < 0) {
            return;
        }

        let goodListWithNewGood = [...goodFromMatchList, goodToAdd];

        if (!goodListWithNewGood || !Array.isArray(goodListWithNewGood) || goodListWithNewGood.length < 0) {
            return;
        }

        let targetGoodIDList = goodListWithNewGood.map((item, index) => {
            return item.goodID;
        })

        if (!targetGoodIDList || !Array.isArray(targetGoodIDList) || targetGoodIDList.length < 0) {
            return;
        }

        let responseFromServer = await matchMembershipTypeWithGoodIDList(submitMembershipTypeID, targetGoodIDList);

        responseFromServer.code == 200 ? refreshTableData() : true;
    }


    const updateMembershipTypeDataFromDB = async (membershipTypeID) => {
        if (!membershipTypeID || membershipTypeID == '') {
            return;
        }

        let membershipTypeDataFromDB = await getMembershipTypeByMembershipTypeID(membershipTypeID);

        if (membershipTypeDataFromDB && Object.keys(membershipTypeDataFromDB).length >= 1) {
            setSubmitMembershipTypeID(membershipTypeDataFromDB.membershipTypeID);
            setSubmitMembershipTypeName(membershipTypeDataFromDB.membershipTypeName);
            setSubmitMembershipTypeDetail(membershipTypeDataFromDB.membershipTypeDetail);
            setMembershipTypeGoodMatchesList(membershipTypeDataFromDB.MembershipTypeGoodMatches);
            setFinishSubmitBoo(false);
        }
    };


    // Delete Confirmation
    async function deleteConfirmation(goodID) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "This good will be disconnected from this membership type",
            elementID: goodID,
            callback: removeGoodFromListSyncWithDB,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function removeGoodFromListSyncWithDB(goodID) {
        if (!membershipTypeGoodMatchesList || !Array.isArray(membershipTypeGoodMatchesList) || membershipTypeGoodMatchesList.length < 0) {
            return;
        }

        let filteredListWithoutTheGood = membershipTypeGoodMatchesList.filter((item) => {
            return item.goodID != goodID;
        })

        if (!filteredListWithoutTheGood || !Array.isArray(filteredListWithoutTheGood) || filteredListWithoutTheGood.length < 0) {
            return;
        }

        let targetGoodIDList = filteredListWithoutTheGood.map((item, index) => {
            return item.goodID;
        })

        if (!targetGoodIDList || !Array.isArray(targetGoodIDList) || targetGoodIDList.length < 0) {
            return;
        }

        let deleteResponse = await matchMembershipTypeWithGoodIDList(submitMembershipTypeID, targetGoodIDList);

        deleteResponse.code == 200 ? refreshTableData() : true;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        let result = await updateMembershipType(submitMembershipTypeID, submitMembershipTypeName, submitMembershipTypeDetail);
        if (result.code && result.code == 200) {
            setFinishSubmitBoo(true);
            props.refreshTable();
            props.onHide();
        }
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t("Edit membership type")}</ModalHeader>
                    <ModalBody className="mt--4">


                        <Row>
                            <Col md="12">
                                <InputTypeTextForm
                                    label={`${t("Membership type name")}${"("}${t("Required")}${")"}`}
                                    labelFor="membershipTypeName"
                                    inputName="membershipTypeName"
                                    inputId="membershmembershipTypeNameipType"
                                    inputPlaceholder={t("Membership type")}
                                    value={submitMembershipTypeName}
                                    handleChange={(e) => setSubmitMembershipTypeName(e.target.value)}
                                    required={true}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col md="12">
                                <InputTypeTextForm
                                    label={`${t("Membership type detail")}${"("}${t("Required")}${")"}`}
                                    labelFor="membershipTypeDetail"
                                    inputName="membershipTypeDetail"
                                    inputId="membershipTypeDetail"
                                    inputPlaceholder={t("Membership type detail")}
                                    value={submitMembershipTypeDetail}
                                    handleChange={(e) => setSubmitMembershipTypeDetail(e.target.value)}
                                    required={true}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md="12">
                                <Button color="success" type="button" onClick={() => {
                                    setShowChooseGoodInput(!showChooseGoodInput);
                                }}>
                                    {t("Add Good")}
                                </Button>
                            </Col>
                        </Row>

                        {showChooseGoodInput &&
                            <Row className="mt-3">
                                <Col md="12">
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <InputTypeSelect2Form
                                                data={goodChooseList}
                                                placeholder={t("--Select Item--")}
                                                value={goodSelected?.goodID}
                                                nameSelect2="goodID"
                                                idSelect2="goodID"
                                                label={""}
                                                handleOnChangeSelect={(e) => onChooseGood(e.target.value)}
                                            />
                                        </div>
                                        {goodSelected && Object.values(goodSelected).length >= 1 &&
                                            <div>
                                                <Button className="ml-1" color="success"
                                                    onClick={() => {
                                                        onAddGoodCard(goodSelected);
                                                    }} >
                                                    <span className="btn-inner--icon">
                                                        <i className="fas fa-plus" />
                                                    </span>
                                                    {/* <span className="btn-inner--text">{t("Add")}</span> */}
                                                </Button>
                                            </div>
                                        }
                                    </div>

                                </Col>
                            </Row>
                        }


                        <Row className="mt-2">
                            <Col md="12">
                                {goodFromMatchList && Array.isArray(goodFromMatchList) && goodFromMatchList.length >= 1 &&
                                    goodFromMatchList.map((goodItem, i) => (
                                        <div className="mt-2">
                                            <GoodCard
                                                goodData={goodItem}
                                                onClickCard={(goodReturn) => {

                                                }}
                                                onClickDeleteButton={(goodIDToDelete) => {
                                                    deleteConfirmation(goodIDToDelete);
                                                }}
                                                isSelectedCard={false}
                                                styleSkipBottomSpaceBoo={true}
                                                hideControlButton={false}
                                                hideEditButton={true}
                                                hideCloneButton={true}
                                                hideDeleteButton={false}
                                            />

                                        </div>


                                    ))


                                }

                            </Col>


                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                {/* <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onDeleteInvoice();
                                    }}
                                >
                                    {t("Delete")}
                                </Button> */}
                            </div>
                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Update"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>

                    </ModalFooter>
                </Form>

            </Modal>




        </>
    );
}

export default MembershipTypeEditModal;
