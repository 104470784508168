import React from "react";
import { Trans } from "react-i18next";
// reactstrap components
import {
    Button
} from "reactstrap";


// props
// buttonNormalText
// finishSubmitBoo
// buttonDisableFromParent // expicit control to disable

function SubmitButtonInForm(props) {

    const [submitting, setSubmitting] = React.useState(false);
    const [submmited, setSubmitted] = React.useState(false);

    const [buttonDisable, setButtonDisable] = React.useState(false);
    const [buttonDisableFromParent, setButtonDisableFromParent] = React.useState(false);

    React.useEffect(() => {
        setButtonDisable(submitting || submmited);
    }, [submitting, submmited]);

    React.useEffect(() => {
        setButtonDisableFromParent(props.buttonDisableFromParent);
    }, [props.buttonDisableFromParent]);

    React.useEffect(() => {
        if (props.finishSubmitBoo) {
            setSubmitted(true);
            setSubmitting(false);
        } else {
            setSubmitting(false);
            setSubmitted(false);
        }
    }, [props.finishSubmitBoo]);
    

    const startSubmit = (e) => {
        setSubmitting(true);

        // wait for 1.5 sec
        setTimeout(() => {
            setSubmitting(false);
          }, 1500);

    };

    return (
        <Button
            type="submit"
            disabled={buttonDisable || buttonDisableFromParent}
            color={submitting ? 'warning' : 'success'}
            onClick={(e) => { startSubmit(e) }}
        >
            <Trans>{submitting ? 'Loading' : submmited ? 'Finish' : props.buttonNormalText}</Trans>
        </Button >
    );
}

export default SubmitButtonInForm;
