import { truncate } from "@sentry/utils";

import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey";
import Notifications from "views/pages/components/Notifications";






//return a 
export const uploadReportResourceImage = async (imageID, imageType, imageBase64) => {
  try {
    let response = await apiPost({
      url: "/reportResource/uploadReportResourceImage",
      data: {
        imageID: imageID,
        imageType: imageType,
        imageBase64Content: imageBase64,
      },
      responseType: "",
    });
    return response.status == 200
      ? response.data
      : response.status;
  } catch (error) {
    return {};
  }
};

//get a photo from azure
export const getReportResourceImage = async (imageID) => {

  try {
    let response = await apiGet({
      url: "/reportResource/getReportResourceImage/" + imageID,
      responseType: "",
    });
    if (response.status && response.status == 200) {
      return response.data.data
    } else {
      return [];
    }
  } catch (error) {

    return [];
  }

};


// get an array
export const getReportResourceReportTermsArray = async (refTabType) => {

  try {
    let response = await apiGet({
      url: "/reportResource/getReportTerms/" + refTabType,
      responseType: "",
    });
    if (response.status && response.status == 200) {
      return response.data.data
    } else {
      return [];
    }
  } catch (error) {

    return [];
  }

};


// Update single refTab
export const updateSingleReportTerms = async (refTabID, refTabData) => {
  try {
    let response = await apiPut({
      url: "/reportResource/updateSingleReportTerms",
      data: {
        refTabID: refTabID,
        refTabData: refTabData,
      },
      responseType: "",
    });
    return response.status == 200
      ? response.data
      : response.status;
  } catch (error) {
    return {};
  }
};



