import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { paginationTable } from "functions/PaginationTable";
import { isAccessible } from "functions/SystemUserAccess.js";

// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Card,
    CardBody,
    CardFooter,
    FormGroup,
    Label,
    Badge,
    CardHeader,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
    deleteUserByID,
    getNickname,
    getUserId,
    getUserList,
    isLoggedIn,
} from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";
import { joinArrayToString, addPhoneLink } from "functions/Utility.js";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { lockScrolling } from "functions/Utility";

import ReportResourceCompanyLogoEditModal from "./reportResourceCompanyLogoEditModal";
import ReportResourceCompanyChopEditModal from "./repotrResourceCompanyChopEditModal";
import ReportResourceInvoiceTermsEditModal from "./reportResourceInvoiceTermsEditModal";
import ReportResourceDNTermsEditModal from "./reportResourceDNTermsEditModal";
import ReportResourceRCTermsEditModal from "./reportResourceRCTermsEditModal";
import ReportResourceQTNTermsEditModal from "./reportResourceQTNTermsEditModal";
import FrontendResourceBrowserIconEditModal from "./frontendResourceBrowserIconEditModal";
import FrontendResourceLoginLogoEditModal from "./frontendResourceLoginLogoEditModal";
import FrontendResourceSidebarLogoEditModal from "./frontendResourceSidebarLogoEditModal";



function ReportResourceScreen(props) {
    const { t } = useTranslation();
    const pagination = paginationTable();


    const { SearchBar } = Search;

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [userData, setUserData] = React.useState([]);
    const [selectedUserData, setSelectedUserData] = React.useState([]);

    // GUI
    const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

    // Modal Report
    const [modalEditCompanyLogoIsOpen, setModalEditCompanyLogoIsOpen] = React.useState(false);
    const [modalEditCompanyChopIsOpen, setModalEditCompanyChopIsOpen] = React.useState(false);
    const [modalEditInvoiceDetailsIsOpen, setModalEditInvoiceDetailsIsOpen] = React.useState(false);
    const [modalEditDeliveryNoteDetailsIsOpen, setModalEditDeliveryNoteDetailsIsOpen] = React.useState(false);
    const [modalEditReceiptDetailsIsOpen, setModalEditReceiptDetailsIsOpen] = React.useState(false);
    const [modalEditQTNDetailsIsOpen, setModalEditQTNDetailsIsOpen] = React.useState(false);


    // Modal Frontend
    const [modalEditFrontendBrowserIconIsOpen, setModalEditFrontendBrowserIconIsOpen] = React.useState(false);
    const [modalEditFrontendLoginLogoIsOpen, setModalEditFrontendLoginLogoIsOpen] = React.useState(false);
    const [modalEditFrontendSidebarLogoIsOpen, setModalEditFrontendSidebarLogoIsOpen] = React.useState(false);

    React.useEffect(() => {
        // Pull to refresh must be disabled if opened some medal
        // Otherwise will lock the screen of the modal
        setPullToRefreshDisable(modalEditCompanyLogoIsOpen || modalEditCompanyChopIsOpen || modalEditInvoiceDetailsIsOpen || modalEditDeliveryNoteDetailsIsOpen || modalEditReceiptDetailsIsOpen
            || modalEditQTNDetailsIsOpen || modalEditFrontendBrowserIconIsOpen || modalEditFrontendLoginLogoIsOpen || modalEditFrontendSidebarLogoIsOpen);
        //   lockScrolling(modalEditCompanyLogoIsOpen || modalEditCompanyChopIsOpen || modalEditInvoiceDetailsIsOpen || modalEditDeliveryNoteDetailsIsOpen || modalEditReceiptDetailsIsOpen);
    }, [modalEditCompanyLogoIsOpen, modalEditCompanyChopIsOpen, modalEditInvoiceDetailsIsOpen, modalEditDeliveryNoteDetailsIsOpen
        , modalEditReceiptDetailsIsOpen, modalEditQTNDetailsIsOpen, modalEditFrontendBrowserIconIsOpen, modalEditFrontendLoginLogoIsOpen, modalEditFrontendSidebarLogoIsOpen]);



    return (isLoggedIn() && isAccessible(props.accessID)) ? (
        <>
            <ScreenPullToRefresh
                onRefresh={async () => {
                    await getUserTable();
                }}
                disabled={pullToRefreshDisable}>
                <SimpleHeader parentName={t("User")} icon="fas fa-home" />
            </ScreenPullToRefresh>
            <Container style={{ marginTop: "-4rem" }} fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h2><Trans>Report</Trans></h2>
                            </CardHeader>
                            <CardBody>

                                <Button
                                    color="warning"
                                    onClick={() => {
                                        setModalEditCompanyLogoIsOpen(true);
                                    }}
                                ><Trans>Company Logo</Trans></Button>

                                <Button color="warning"
                                    onClick={() => {
                                        setModalEditCompanyChopIsOpen(true);
                                    }}
                                ><Trans>Company Chop</Trans></Button>

                                <Button color="primary"
                                    onClick={() => {
                                        setModalEditInvoiceDetailsIsOpen(true);
                                    }}><Trans>Invoice Details</Trans></Button>

                                <Button color="primary"
                                    onClick={() => {
                                        setModalEditDeliveryNoteDetailsIsOpen(true);
                                    }}><Trans>Delivery Note Details</Trans></Button>

                                <Button color="primary"
                                    onClick={() => {
                                        setModalEditReceiptDetailsIsOpen(true);
                                    }}><Trans>Receipt Details</Trans></Button>

                                <Button color="primary"
                                    onClick={() => {
                                        setModalEditQTNDetailsIsOpen(true);
                                    }}><Trans>Quotation Details</Trans></Button>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <h2><Trans>Interface</Trans></h2>
                            </CardHeader>
                            <CardBody>
                                <Button
                                    color="warning"
                                    onClick={() => {
                                        setModalEditFrontendBrowserIconIsOpen(true);
                                    }}
                                ><Trans>Browser icon</Trans>
                                </Button>
                                <Button
                                    color="warning"
                                    onClick={() => {
                                        setModalEditFrontendLoginLogoIsOpen(true);
                                    }}
                                ><Trans>Login logo</Trans>
                                </Button>
                                <Button
                                    color="warning"
                                    onClick={() => {
                                        setModalEditFrontendSidebarLogoIsOpen(true);
                                    }}
                                ><Trans>Sidebar logo</Trans>
                                </Button>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <ReportResourceCompanyLogoEditModal
                show={modalEditCompanyLogoIsOpen}
                onHide={() => { setModalEditCompanyLogoIsOpen(false) }}
            />

            <ReportResourceCompanyChopEditModal
                show={modalEditCompanyChopIsOpen}
                onHide={() => { setModalEditCompanyChopIsOpen(false) }}
            />


            <ReportResourceInvoiceTermsEditModal
                show={modalEditInvoiceDetailsIsOpen}
                onHide={() => { setModalEditInvoiceDetailsIsOpen(false) }}
            />
            <ReportResourceDNTermsEditModal
                show={modalEditDeliveryNoteDetailsIsOpen}
                onHide={() => { setModalEditDeliveryNoteDetailsIsOpen(false) }}

            />
            <ReportResourceRCTermsEditModal
                show={modalEditReceiptDetailsIsOpen}
                onHide={() => { setModalEditReceiptDetailsIsOpen(false) }}
            />

            <ReportResourceQTNTermsEditModal
                show={modalEditQTNDetailsIsOpen}
                onHide={() => { setModalEditQTNDetailsIsOpen(false) }}
            />

            <FrontendResourceBrowserIconEditModal
                show={modalEditFrontendBrowserIconIsOpen}
                onHide={() => { setModalEditFrontendBrowserIconIsOpen(false) }}
            />

            <FrontendResourceLoginLogoEditModal
                show={modalEditFrontendLoginLogoIsOpen}
                onHide={() => { setModalEditFrontendLoginLogoIsOpen(false) }}
            />

            <FrontendResourceSidebarLogoEditModal
                show={modalEditFrontendSidebarLogoIsOpen}
                onHide={() => { setModalEditFrontendSidebarLogoIsOpen(false) }}
            />
        </>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default ReportResourceScreen;
