import React from "react";
import { Col, Container, Input, Label, Row, FormGroup } from "reactstrap";

function BillRecordStatusFilter(props) {
  const { onFilterChange } = props;
  const initState = {
    Unpaid: true,
    Paid: true,
    Normal: true,
  };
  const [invoiceStatusList, setInvoiceStatusList] = React.useState(initState);

  function onItemClick(itemKey) {
    let statusList = Object.assign({}, invoiceStatusList);
    statusList[itemKey] = !statusList[itemKey]; //  Flip selected checkbox state
    setInvoiceStatusList(statusList);
    //  Transfer status to outer component
    onFilterChange(statusList);
  }

  return (
    <Container>
      <Row className="mt-2">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Label for={props.labelFor} className="form-control-label">
            {props.label}
          </Label>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => onItemClick("Unpaid")}
                checked={invoiceStatusList.Unpaid}
              />{" "}
              Unpaid
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => onItemClick("Paid")}
                checked={invoiceStatusList.Paid}
              />{" "}
              Paid
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input
                type="checkbox"
                onChange={() => onItemClick("Normal")}
                checked={invoiceStatusList.Normal}
              />{" "}
              Normal
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default BillRecordStatusFilter;
