import React from "react";
import {
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Col,
    div,
    Form,
    Button,
    span
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datetime/css/react-datetime.css";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Checkbox } from "react-inputs-validation";
import InputDateGroup from "./InputDateGroup";
import { useTranslation } from "react-i18next";
import { addDays } from "../../../functions/Utility";


//props
// fcn : changeUseStartDate 
// fcn : changeUseEndDate 
// fcn : changeStartDate
// fcn : changeEndDate
// fcn : changePeriod
function InputDateGroupCombo(props) {
    const { t } = useTranslation();

    const labelFor = props.labelFor;
    const inputName = props.inputName;
    const inputId = props.inputId;

    const useStartDate = props.useStartDate;
    const useEndDate = props.useEndDate;

    //const [startDate, setStartDate] = React.useState(props.startDate); //如果咁樣做，當 props.startDate 有變，呢到會唔郁，所以要配合 useEffect
    const startDate = props.startDate;
    const endDate = props.endDate;




    // control
    const [buttonGroupSingleDateSelected, setButtonGroupSingleDateSelected] = React.useState(false);
    const [buttonGroupPeriodSelected, setButtonGroupPeriodSelected] = React.useState(false);

    return (
        <>
            <Row className="mt-2">
                <Col className="ml-3 mt-1 col-auto">
                    <Row>
                        {/*Yesterday Button*/}
                        <Button
                            onClick={() => {
                                props.changePeriod(addDays(startDate, -1), addDays(startDate, -1));
                                setButtonGroupSingleDateSelected(true);
                                setButtonGroupPeriodSelected(false);
                                if (!useStartDate) props.changeUseStartDate(true);
                                if (!useEndDate) props.changeUseEndDate(true);
                            }}
                            className="mt-1"
                            color={buttonGroupSingleDateSelected ? "success" : "secondary"}>
                            <i className="ni ni-bold-left"></i>
                        </Button>
                        {/*Today Button*/}
                        <Button
                            onClick={() => {
                                props.changePeriod(new Date(), new Date());
                                setButtonGroupSingleDateSelected(true);
                                setButtonGroupPeriodSelected(false);
                                if (!useStartDate) props.changeUseStartDate(true);
                                if (!useEndDate) props.changeUseEndDate(true);
                            }}
                            className="mt-1"
                            color={buttonGroupSingleDateSelected ? "success" : "secondary"}>
                            {t("Today")}
                        </Button>
                        {/*Tomorrow Button*/}
                        <Button
                            onClick={() => {
                                props.changePeriod(addDays(startDate, 1), addDays(startDate, 1));
                                setButtonGroupSingleDateSelected(true);
                                setButtonGroupPeriodSelected(false);
                                if (!useStartDate) props.changeUseStartDate(true);
                                if (!useEndDate) props.changeUseEndDate(true);
                            }}
                            className="mt-1"
                            color={buttonGroupSingleDateSelected ? "success" : "secondary"}>
                            <i className="ni ni-bold-right"></i>
                        </Button>
                        {/*This Week Button*/}
                        <Button
                            onClick={() => {
                                props.changePeriod(moment(new Date()).startOf('week').toDate(), moment().endOf('week').toDate());
                                setButtonGroupPeriodSelected(true);
                                setButtonGroupSingleDateSelected(false);
                                if (!useStartDate) props.changeUseStartDate(true);
                                if (!useEndDate) props.changeUseEndDate(true);
                            }}
                            className="mt-1"
                            color={buttonGroupPeriodSelected ? "success" : "secondary"}>
                            {t("This Week")}
                        </Button>
                        {/*Tomorrow Button*/}
                        <Button
                            onClick={() => {
                                props.changePeriod(moment().startOf('month').toDate(), moment().endOf('month').toDate());
                                setButtonGroupPeriodSelected(true);
                                setButtonGroupSingleDateSelected(false);
                                if (!useStartDate) props.changeUseStartDate(true);
                                if (!useEndDate) props.changeUseEndDate(true);
                            }}
                            className="mt-1"
                            color={buttonGroupPeriodSelected ? "success" : "secondary"}>
                            {t("This Month")}
                        </Button>
                    </Row>
                </Col>

            </Row>
            <Row className="mt-2">
                <Col
                    className="mt-1"
                    lg={6} md={6} xs={12}
                >
                    <div className="d-flex">
                        <div className="mt-1">
                            <Button
                                // className={classnames({ active: useStartDate })}
                                color={useStartDate ? "success" : "secondary"}
                                tag="label"
                            >
                                <input
                                    show={false}
                                    style={{ display: 'none' }}
                                    autoComplete="off"
                                    type="checkbox"
                                    value={useStartDate}
                                    onChange={() => { props.changeUseStartDate(!useStartDate) }}
                                />
                                <i className="fas fa-check" />
                            </Button>
                        </div>
                        <div className="flex-grow-1">
                            <InputDateGroup
                                label={t("From")}
                                labelFor={labelFor}
                                inputName={inputName}
                                inputId={inputId}
                                inputPlaceholder={t("Date")}
                                value={startDate}
                                onDateChange={(date) => {
                                    props.changeStartDate(date);
                                }}
                                defaultDate={startDate}
                            />
                        </div>
                    </div>

                </Col>

                <Col
                    className="mt-1"
                    lg={6} md={6} xs={12}
                >
                    <div className="d-flex">
                        <div className="mt-1">
                            <Button
                                // className={classnames({ active: useStartDate })}
                                color={useEndDate ? "success" : "secondary"}
                                tag="label"
                            >
                                <input
                                    show={false}
                                    style={{ display: 'none' }}
                                    autoComplete="off"
                                    type="checkbox"
                                    value={useEndDate}
                                    onChange={() => { props.changeUseEndDate(!useEndDate) }}
                                />
                                <i className="fas fa-check" />
                            </Button>
                        </div>
                        <div className="flex-grow-1">
                            <InputDateGroup
                                label={t("To")}
                                labelFor={labelFor}
                                inputName={inputName}
                                inputId={inputId}
                                inputPlaceholder={t("Date")}
                                value={endDate}
                                onDateChange={(date) => {
                                    props.changeEndDate(date);
                                }}
                                defaultDate={endDate}
                            />
                        </div>
                    </div>

                </Col>
            </Row>
        </>
    );
}

export default InputDateGroupCombo;
