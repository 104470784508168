import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InvoiceScreenFilterModal from "../../views/pages/invoice/InvoiceScreenFilterModal";
import InvoiceStatCard from "../../views/pages/components/InvoiceStatCard.js";

function InvoiceHeader(props) {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  // const [ModalState, setModalState] = React.useState('');
  // async function onModalToggle() {
  //   setModalState('');
  // }
  // const pull_data = (data) => {
  //   sendDataFilter(data);
  // }
  async function sendDataFilter(data) {
    props.filters(data);
  }
  return (
    <>
      <div className="header bg-info">
        <Container fluid>
          <div className="header-body">
{/*             <Row className="align-items-center py-4">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Breadcrumb
                  className="d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem style={{ cursor: "pointer" }}>
                    <a className="h2"
                      onClick={(e) => {
                        e.preventDefault();
                        props.goBackLastPage();
                      }}>
                      <i className={props.icon} />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a onClick={(e) => e.preventDefault()}>
                      <h6 className="h2 text-white d-inline-block mb-0">
                        {props.name}
                      </h6>
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {/* {props. name} 
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>

            </Row> */}

            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <InvoiceStatCard
                  invoiceListData={props.invoiceData}
                />
              </Col>

            </Row>
          </div>
          <InvoiceScreenFilterModal
            show={modalIsOpen}
            onHide={() => setModalIsOpen(false)}
            filters={sendDataFilter}
          />
        </Container>
      </div>
    </>
  );
}

InvoiceHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default InvoiceHeader;
