import React from "react";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Card,
    CardBody,
    CardFooter,
    FormGroup,
    Label,
    Nav,
    NavLink,
    NavItem,
    Input,
    Badge
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link, Redirect, redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Notifications from "../components/Notifications";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { paginationTable } from "functions/PaginationTable";
import { isAccessible } from "functions/SystemUserAccess";
import { lockScrolling } from "functions/Utility";


import { getAllUserJob } from "functions/UserJob";
import { deleteUserJob } from "functions/UserJob";
import UserJobCreateModal from "./UserJobCreateModal";
import UserJobEditModal from "./UserJobEditModal";
import UserJobMatchSystemAccessModal from "./UserJobMatchSystemAccessModal";
import UserJobMatchSystemUserModal from "./UserJobMatchSystemUserModal";



function UserJobScreen(props) {
    const { t } = useTranslation();
    const pagination = paginationTable();
    const { SearchBar } = Search;

    const [jobInitialData, setJobInitialData] = React.useState([]);
    const [selectedJob, setSelectedJob] = React.useState({});


    // GUI
    const [isLoading, setIsLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(0);
    const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);


    //  modal
    const [modalCreateUserJobIsOpen, setModalCreateUserJobIsOpen] = React.useState(false);
    const [modalEditUserJobIsOpen, setModalEditUserJobIsOpen] = React.useState(false);
    const [modalUserJobMatchSystemAccessIsOpen, setModalUserJobMatchSystemAccessIsOpen] = React.useState(false);
    const [modalUserJobMatchSystemUserIsOpen, setModalUserJobMatchSystemUserIsOpen] = React.useState(false);


    React.useEffect(() => {
        // Pull to refresh must be disabled if opened some medal
        // Otherwise will lock the screen of the modal
        setPullToRefreshDisable(modalCreateUserJobIsOpen || modalUserJobMatchSystemAccessIsOpen);
        lockScrolling(modalCreateUserJobIsOpen || modalUserJobMatchSystemAccessIsOpen)
    }, [modalCreateUserJobIsOpen, modalUserJobMatchSystemAccessIsOpen]);


    // Call API to get all data
    React.useEffect(() => {
        getInitialData();
    }, [refreshTable]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const getInitialData = async () => {
        setIsLoading(true);
        let jobData = await getAllUserJob();
        setJobInitialData(jobData);
        setIsLoading(false);
    };


    // Delete Confirmation
    async function deleteConfirmation(job) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: job,
            callback: deleteJob,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteJob(job) {
        setIsLoading(true);
        let deleteResponse = await deleteUserJob(job);
        setIsLoading(false);
        deleteResponse.code == 200 ? refreshTableData() : true;
    }


    return (isLoggedIn() && isAccessible(props.accessID)) ? (
        <div>
            {isLoading ? <SpinnerOverlay /> : ""}

            <ScreenPullToRefresh
                onRefresh={async () => {
                    await getInitialData();
                }}
                disabled={pullToRefreshDisable}>
                <SimpleHeader parentName={""} icon="fas fa-map" />
            </ScreenPullToRefresh>
            <Container style={{ marginTop: "-4rem" }} fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <ToolkitProvider
                                    data={jobInitialData}
                                    keyField="job"
                                    columns={
                                        [
                                            { dataField: "jobNature", text: "", hidden: true },
                                            { dataField: "job", text: "", hidden: true },
                                            {
                                                dataField: "combined",
                                                text: "",
                                                formatter: (cellContent, row, rowIndex, extraData) => {
                                                    return (
                                                        <div
                                                            className="ml--3 mr--3"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {/* First row */}
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1"
                                                                    onClick={() => {
                                                                        setSelectedJob(row);
                                                                        setModalEditUserJobIsOpen(true);
                                                                    }}>
                                                                    <Badge color={row?.jobType === 'fixed' ? "danger" : "default"}className="statusBadge">{t(row?.jobType)}</Badge>
                                                                    <Badge color="primary" className="statusBadge">{row?.SystemAccessJobMatches ? row?.SystemAccessJobMatches.length : ''}{' '}{t('access')}</Badge>
                                                                    <Badge color="primary" className="statusBadge">{row?.SystemUserJobMatches ? row?.SystemUserJobMatches.length : ''}{' '}{t('user')}</Badge>

                                                                </div>
                                                                <div>
                                                                    <a
                                                                        className="table-action"
                                                                        id="tooltip12475020"
                                                                        onClick={() => {
                                                                            setSelectedJob(row);
                                                                            setModalUserJobMatchSystemAccessIsOpen(true);
                                                                        }
                                                                        }  >
                                                                        {" "}
                                                                        <i
                                                                            className="fas fa-key fa-lg"
                                                                            data-id={cellContent}
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        className="table-action"
                                                                        id="tooltip12475021"
                                                                        onClick={() => {
                                                                            setSelectedJob(row);
                                                                            setModalUserJobMatchSystemUserIsOpen(true);
                                                                        }
                                                                        }>
                                                                        {" "}
                                                                        <i
                                                                            className="fas fa-user fa-lg"
                                                                            data-id={cellContent}
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        className="table-action table-action-delete"
                                                                        id="tooltip12475022"
                                                                        onClick={() =>
                                                                            deleteConfirmation(row.job)
                                                                        }  >
                                                                        {" "}
                                                                        <i
                                                                            className="fas fa-trash fa-lg"
                                                                            data-id={cellContent}
                                                                        />
                                                                    </a>
                                                                </div>

                                                            </div>

                                                            {/* Second row */}
                                                            <div
                                                                onClick={() => {
                                                                    setSelectedJob(row);
                                                                    setModalEditUserJobIsOpen(true);
                                                                }}>
                                                                <div className="tableRowTitle">
                                                                    {t(row.job)}
                                                                </div>
                                                                <div className="tableRowRemark">
                                                                    {row.jobNature}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            },

                                        ]
                                    }
                                    search
                                >
                                    {(props) => (
                                        <div className="table-responsive table-sm">
                                            <Row className="pb-2">
                                                <Col xs={7} sm={7} md={7} lg={7}>
                                                    <SearchBar
                                                        className=""
                                                        placeholder={t("Search")}
                                                        {...props.searchProps}
                                                    />

                                                </Col>
                                                <Col
                                                    className={"text-right"}
                                                    xs={5}
                                                    sm={5}
                                                    md={5}
                                                    lg={5}
                                                >
                                                    <Button
                                                        color="primary"
                                                        id="tooltip443412080"
                                                        onClick={() => setModalCreateUserJobIsOpen(!modalCreateUserJobIsOpen)}
                                                        size="md"
                                                    >
                                                        <span className="btn-inner--icon mr-1">
                                                            <i className="fas fa-plus" />
                                                        </span>
                                                        <span className="btn-inner--text">{t("Add")}</span>
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={1}
                                                        target="tooltip443412080"
                                                    >
                                                        {t("Create")}
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </Row>

                                            <BootstrapTable
                                                headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>


            <UserJobCreateModal
                show={modalCreateUserJobIsOpen}
                onHide={() => {
                    setModalCreateUserJobIsOpen(false);
                }}
                refreshTable={refreshTableData}
            />
            <UserJobEditModal
                show={modalEditUserJobIsOpen}
                onHide={() => {
                    setModalEditUserJobIsOpen(false);
                }}
                refreshTable={refreshTableData}
                userJobData={selectedJob}
            />

            <UserJobMatchSystemAccessModal
                show={modalUserJobMatchSystemAccessIsOpen}
                onHide={() => {
                    setModalUserJobMatchSystemAccessIsOpen(false);
                }}
                jobData={selectedJob}
                refreshTable={refreshTableData}
            />

            <UserJobMatchSystemUserModal
                show={modalUserJobMatchSystemUserIsOpen}
                onHide={() => {
                    setModalUserJobMatchSystemUserIsOpen(false);
                }}
                jobData={selectedJob}
                refreshTable={refreshTableData}
            />



        </div>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default UserJobScreen;
