import {  updateUserPassword } from "functions/Auth";
import { getSystemUserByID } from "functions/Auth";
import moment from "moment";
import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";

function UserModalEditPassword(props) {
    const { t } = useTranslation();
    //Username
    const [userDatas, setUserDatas] = React.useState([]);
    const [userID, setUserID] = React.useState("");
    const [passwordPlainText, setPasswordPlainText] = React.useState("");
    const [passwordPlainTextConfirm, setPasswordPlainTextConfirm] = React.useState("");
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (passwordPlainText.replace(/\s+/g, '') === "") {
            Notifications({ type: "error", message: 'Passwords cannot be empty', title: 'Password' });
            return;
        }
        if (passwordPlainText !== passwordPlainTextConfirm) {
            Notifications({ type: "error", message: 'Passwords do not match', title: 'Password' });
            return;
        }

        let submittedData = {
            userID: userID,
            passwordPlainText: passwordPlainText,
        };
        try {
            await updateUserPassword(submittedData);
            props.refreshTable();
            props.onHide();
        } catch (err) {
            Notifications();
        }
    };
    React.useEffect(() => {
        const getUser = async (id) => {
            let dataUser = await getSystemUserByID(id);
            setUserDatas(dataUser);
        };
        if (props.userData && Object.values(props.userData).length > 0) {
            getUser(props.userData.userID);
        }
    }, [props.show]);

    React.useEffect(() => {
        if (userDatas && Object.values(userDatas).length > 0) {
            setUserID(userDatas.userID);
            setPasswordPlainText("");
            setPasswordPlainTextConfirm("");
        }
    }, [userDatas]);

    return (
        <>
            <Modal
                isOpen={props.show}
                 size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                 centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{props.title}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="password"
                                            label={t("Password")}
                                            labelFor="passwordPlainText"
                                            inputName="passwordPlainText"
                                            inputId="passwordPlainText"
                                            inputPlaceholder={t("Password")}
                                            value={passwordPlainText}
                                            handleChange={(e) => {
                                                setPasswordPlainText(e.target.value);
                                            }}
                                        />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="password"
                                            label={t("Confirm password")}
                                            labelFor="passwordPlainTextConfirm"
                                            inputName="passwordPlainTextConfirm"
                                            inputId="passwordPlainTextConfirm"
                                            inputPlaceholder={t("Confirm password")}
                                            value={passwordPlainTextConfirm}
                                            handleChange={(e) => {
                                                setPasswordPlainTextConfirm(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {props.button}
                        </Button>
                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default UserModalEditPassword;
