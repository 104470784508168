import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { paginationTable } from "functions/PaginationTable";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Label,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { isLoggedIn } from "../../../functions/Auth";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { getCarDatatable, deleteCarByID, getCarTypeByID } from "functions/Car";
import Notifications from "../components/Notifications";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { getQuotationListFromLocalStorage } from "functions/Quotation";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";
import { deleteSingleQuotationListFromLocalStorage, clearQuotationListFromLocalStorage } from "functions/Quotation";
import { getDateString } from "functions/Utility";
import { lockScrolling } from "functions/Utility";




function LocalQuotationScreen(props) {
    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;
    const pagination = paginationTable();

    const { SearchBar } = Search;

    const [isLoading, setIsLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(0);

    const [localQuotationList, setLocalQuotationList] = React.useState([]);
    const [selectedLocalQuotationData, setSelectedLocalQuotationData] = React.useState([]);


    // GUI
    const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);

    //  modal
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);

    React.useEffect(() => {
        // Pull to refresh must be disabled if opened some medal
        // Otherwise will lock the screen of the modal
        setPullToRefreshDisable(modalInvoiceCreateIsOpen);
        lockScrolling(modalInvoiceCreateIsOpen);
    }, [modalInvoiceCreateIsOpen]);

    // Call API Customer
    React.useEffect(() => {
        getLocalQuotationData();
    }, [refreshTable]);

    // Delete Confirmation
    async function deleteConfirmation(id) {
        let alert = {
            type: "deleteConfirmation",
            elementID: id,
            callback: deleteSingleLocalQuotation,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteSingleLocalQuotation(id) {
        let deleteResponse = deleteSingleQuotationListFromLocalStorage(id);
        refreshTableData();
    }


    // Delete All Confirmation
    async function deleteAllConfirmation() {
        let alert = {
            type: "deleteConfirmation",
            elementID: '',
            callback: clearAllLocalQuotation,
        };
        Notifications(alert);
    }



    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const getLocalQuotationData = () => {
        setIsLoading(true);
        let dataToGet = getQuotationListFromLocalStorage();
        setLocalQuotationList(dataToGet);
        setIsLoading(false);
    };

    const clearAllLocalQuotation = () => {
        setIsLoading(true);
        clearQuotationListFromLocalStorage();
        refreshTableData();
        setIsLoading(false);
    };

    const configSubitemDIV = (subitemList) => {
        if (subitemList && Array.isArray(subitemList) && subitemList.length >= 1) {
            return subitemList.map((item) => {
                return item.quantity.toString() + item.good.goodUnit + item.good.goodDescription;
            }).join('\n');
        } else {
            return "";
        }
    }


    return (isLoggedIn() && isAccessible(props.accessID)) ? (
        <>
            {isLoading ? <SpinnerOverlay /> : ""}

            <ScreenPullToRefresh
                onRefresh={async () => {
                    getLocalQuotationData();
                }}
                disabled={pullToRefreshDisable}>
                <SimpleHeader parentName={t("Car")} icon="fas fa-home" />
            </ScreenPullToRefresh>
            <Container style={{ marginTop: "-4rem" }} fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <ToolkitProvider
                                    data={localQuotationList}
                                    keyField="localQuotationID"
                                    columns={
                                        [
                                            { dataField: "localQuotationID", text: "localQuotationID", hidden: true },
                                            { dataField: "customerID", text: "customerID", hidden: true },
                                            {
                                                dataField: "display",
                                                text: t("Quotation"),
                                                formatter: (cell, row, rowIndex, extraData) => {
                                                    return (
                                                        <div
                                                            className="ml--3"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedLocalQuotationData(row);
                                                                setModalInvoiceCreateIsOpen(true);
                                                            }}>

                                                            <div className="tableRowTitle">
                                                                {row?.customerData?.customerName}
                                                            </div>
                                                            <div className="tableRowSecondTitle">
                                                                {configSubitemDIV(row?.invoiceSubitemList)}
                                                            </div>
                                                            <div className="tableRowSubTitle">
                                                                {getDateString(row.localQuotationRecordDatetime, activeLang === "cn")}
                                                            </div>
                                                            <div className="tableRowRemark">
                                                                {row.invoiceRemark}
                                                            </div>


                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                isDummyField: true,
                                                dataField: "Action",
                                                text: "",
                                                formatter: (cellContent, row) => {
                                                    return (
                                                        <>
                                                            {/* <a
                                    style={{ cursor: "pointer" }}
                                    className="table-action"
                                    id="tooltip209424781"
                                    onClick={() => {
                                      setSelectedCarData(row);
                                      setModalEditIsOpen(true);
                                    }}
                                  >
                                    <i className="fas fa-edit fa-lg" />
                                  </a>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip209424781"
                                  >
                                    {t("Edit")}
                                  </UncontrolledTooltip> */}
                                                            <a
                                                                style={{ cursor: "pointer" }}
                                                                className="table-action table-action-delete"
                                                                id="tooltip12475020"
                                                                onClick={() =>
                                                                    deleteConfirmation(row.localQuotationID)
                                                                }
                                                            >
                                                                <i
                                                                    className="fas fa-trash fa-lg"
                                                                    data-id={cellContent}
                                                                />
                                                            </a>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                target="tooltip12475020"
                                                            >
                                                                {t("Delete")}
                                                            </UncontrolledTooltip>
                                                        </>
                                                    );
                                                },
                                            },
                                        ]

                                    }
                                    search={{
                                        searchFormatted: false
                                    }}
                                >
                                    {(props) => (
                                        <>
                                            <Row className="pb-2">

                                                    <div className="w-100 d-flex justify-content-between">
                                                        <div className="flex-grow-1">
                                                            <SearchBar
                                                                className="form-control"
                                                                placeholder={t("Search")}
                                                                {...props.searchProps}
                                                            />

                                                        </div>
                                                        <div className="">
                                                            <Button
                                                                color="danger"
                                                                id="tooltip443412080"
                                                                onClick={() => {
                                                                    deleteAllConfirmation();
                                                                }}
                                                            >
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="fas fa-times" />
                                                                </span>
                                                                <span className="">{t("Delete All")}</span>
                                                            </Button>
                                                        </div>
                                                    </div>



                                            </Row>
                                       
                                            <div className="py-2 table-responsive table-sm">
                                                <BootstrapTable
                                                    headerClasses={isMobile ? "tableHiddenHeader" : ""} // only hide column when mobile
                                                    {...props.baseProps}
                                                    bootstrap4={true}
                                                    pagination={pagination}
                                                    bordered={false}
                                                />
                                            </div>
                                        </>
                                    )}
                                </ToolkitProvider>
                            </CardBody>
                            <CardFooter></CardFooter>
                        </Card>
                    </Col>
                </Row>

            </Container>

            <InvoiceCreateModal
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    refreshTableData();
                }}
                preSelectedCustomer={selectedLocalQuotationData.customerData}
                preSelectedInvoiceRemark={selectedLocalQuotationData.invoiceRemark}
                preSelectedInvoiceSubitemList={selectedLocalQuotationData.invoiceSubitemList}
                preSelectedDeliveryRecordList={selectedLocalQuotationData.deliveryRecordList}
                allowEmptySubitemList={true}
            />
        </>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default LocalQuotationScreen;
