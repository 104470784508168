import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Col,
  Row
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeText from "../components/InputTypeText.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import InputTypeGroupTextForm from "../components/InputTypeGroupTextForm.js";
import Notifications from "../components/Notifications";
import {
  getGoodsByID,
  getGoodSelect2ByInovoiceID
} from "../../../functions/Goods";

import renameKeys from "functions/RenameKey";

import {
  getRemainingDeliverySubitemsByInvoiceID
} from "functions/Delivery";

import SpinnerOverlay from "../components/SpinnerOverlay";
import InvoiceRemainingSubitemSelectInput from "../components/InvoiceRemainingSubitemSelectInput.js";

function DeliveryRecordAddProductModal(props) {
  const { t } = useTranslation();


  const [isLoading, setIsLoading] = React.useState(false);
  //Initialize Data
  const [goodSelected, setGoodSelected] = React.useState({});

  //Post Data
  const [newDeliverySubitemShoppingCartItem, setNewDeliverySubitemShoppingCartItem] = React.useState({
    deliverySubitemQuantity: 0,
    deliverySubitemRemark: "",
    good: {}
  });

  const { deliverySubitemQuantity, deliverySubitemRemark, good } = newDeliverySubitemShoppingCartItem;

  const onInputChange = (e) => {
    if (e.target.name == "") {

    } else {
      setNewDeliverySubitemShoppingCartItem({ ...newDeliverySubitemShoppingCartItem, [e.target.name]: e.target.value });
    }
  };

  const onChangeQuantity = (deliverySubitemQuantityToChange) => {
    if (isNaN(parseFloat(deliverySubitemQuantityToChange))) {
      // To improve user experience
      // Allow a empty string
      setNewDeliverySubitemShoppingCartItem({ ...newDeliverySubitemShoppingCartItem, deliverySubitemQuantity: '' });
    } else {
      setNewDeliverySubitemShoppingCartItem({ ...newDeliverySubitemShoppingCartItem, deliverySubitemQuantity: parseFloat(deliverySubitemQuantityToChange) });
    }
  };


  const onInputChangeWithValue = (deliverySubitemQuantity, deliverySubitemRemark, good) => {
    let deliverySubitemQuantityToChange;

    if (isNaN(parseFloat(deliverySubitemQuantity))) {
      deliverySubitemQuantityToChange = 0;
    } else {
      deliverySubitemQuantityToChange = Number(deliverySubitemQuantity);
    }

    setNewDeliverySubitemShoppingCartItem({
      deliverySubitemQuantity: deliverySubitemQuantityToChange,
      deliverySubitemRemark: deliverySubitemRemark,
      good: good
    });
  };

  // add the item to the shopping cart
  const addItemsToParent = (e) => {
    if (!goodSelected) return;

    if (!good) {
      Notifications({ type: "dataCheck", message: t("All input must be filled!") });
      return;
    }

    // check quantity
    if (deliverySubitemQuantity === "" || isNaN(deliverySubitemQuantity) || isNaN(parseFloat(deliverySubitemQuantity))) {
      Notifications({
        type: "dataCheck",
        message: t("Please input quantity")
      });
      return;
    }

    goodSelected["quantity"] = parseFloat(deliverySubitemQuantity);
    goodSelected["deliverySubitemRemark"] = deliverySubitemRemark;
    goodSelected["good"] = good;

    props.addToDeliverySubitemsTable(goodSelected);

    setNewDeliverySubitemShoppingCartItem({
      quantity: 0,
      deliverySubitemRemark: "",
      good: {}
    });
    // CLEAR FORM WHENEVER SUBMIT
    clearForm();
    props.onHide();
  };

  const clearForm = () => {
    // setgoodList([])
    setGoodSelected({});
    setNewDeliverySubitemShoppingCartItem({
      deliverySubitemQuantity: 0,
      deliverySubitemRemark: "",
      good: {}
    });
  };


  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader >{props.title}</ModalHeader>
        <ModalBody className="mt--4">
          <Form>
            <InvoiceRemainingSubitemSelectInput
              preSelectedProduct={{
                good: good
              }}
              invoiceID={props.invoiceData?.invoiceID}
              selectItem={(deliverySubitemQuantity, deliverySubitemRemark, good) => {
                onInputChangeWithValue(deliverySubitemQuantity,
                  deliverySubitemRemark,
                  good)
              }} />

            <InputTypeGroupTextForm
              type="number"
              append={Object.keys(goodSelected).length > 0 ? true : false}
              appendIcon={false}
              appendIconClass=""
              appendText={
                Object.keys(goodSelected).length > 0
                  ? goodSelected.goodUnit
                  : ""
              }
              label={t("DeliverySubitemQuantity")}
              labelFor="deliverySubitemQuantity"
              inputName="deliverySubitemQuantity"
              inputId="deliverySubitemQuantity"
              inputPlaceholder={t("DeliverySubitemQuantity")}
              value={deliverySubitemQuantity}
              handleChange={(e) => onChangeQuantity(e.target.value)}
            // readOnly={false}
            />
            <InputTypeTextForm
              label={t("Invoice Item Remark")}
              labelFor="deliverySubitemRemark"
              inputName="deliverySubitemRemark"
              inputId="deliverySubitemRemark"
              inputPlaceholder={t("Invoice Item Remark")}
              value={deliverySubitemRemark}
              handleChange={(e) => onInputChange(e)}
            />
          </Form>
        </ModalBody>
        <ModalFooter>

          <Row className="w-100 d-flex justify-content-between">
            <div>
              <Button
                color="danger"
                type="button" // type="button" means the form will not submit
                onClick={(e) => {
                  e.preventDefault();
                  clearForm();
                }}
              >
                {t("Reset")}
              </Button>
            </div>
            <div>
              <Button color="primary" onClick={addItemsToParent} type="button">
                {props.button}
              </Button>
              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>
          </Row>

        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeliveryRecordAddProductModal;
