import React from "react";
import { Container, Row, FormGroup, Label, Input, Col } from "reactstrap";

function FilterStatus(props) {
  const { onFilterChange } = props;
  const initState = {
    scheduled: true,
    onTheWay: true,
    complete: true,
  };
  const [deliveryStatusList, setDeliveryStatusList] = React.useState(initState);

  function onItemClick(itemKey) {
    let statusList = Object.assign({}, deliveryStatusList);
    statusList[itemKey] = !statusList[itemKey]; //  Flip selected checkbox state
    setDeliveryStatusList(statusList);
    //  Transfer status to outer component
    onFilterChange(statusList);
  }
  return (
    <>
      <Container>
        <Row className="mt-2">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Label for={props.labelFor} className="form-control-label">
              {props.label}
            </Label>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={() => onItemClick("scheduled")}
                  checked={deliveryStatusList.scheduled}
                />{" "}
                Scheduled
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={() => onItemClick("onTheWay")}
                  checked={deliveryStatusList.onTheWay}
                />{" "}
                Fast Delivery
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  onChange={() => onItemClick("complete")}
                  checked={deliveryStatusList.complete}
                />{" "}
                Finish
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FilterStatus;
