import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardText,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    CardFooter,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import moment from "moment";
import Notifications from "../components/Notifications";
import { combineAddress } from "../../../functions/Delivery.js"
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import InvoiceBadge from "../components/InvoiceBadge.js";
import DeliveryBadge from "../components/DeliveryBadge";
import {
    getCustomerByID,
    getPhoneType,
} from "functions/Customer";
import CustomerModalPhoneCreate from "./CustomerModalPhoneCreate";
import { addCustomerPhone } from "functions/Customer";
import CustomerUnpaidInvoiceRecord from "./CustomerUnpaidInvoiceRecord";

//props:
//customerData
function CustomerDisplayCard(props) {

    const { t } = useTranslation();


    const [refreshCounter, setRefreshCounter] = React.useState(0);

    const [customerData, setCustomerData] = React.useState({});

    const [modalCreatePhoneIsOpen, setModalCreatePhoneIsOpen] = React.useState(false);
    const [modalCustomerUnpaidInvoiceRecordIsOpen, setModalCustomerUnpaidInvoiceRecordIsOpen] = React.useState(false);
    
    React.useEffect(() => {
        if (Object.values(props.customerData).length > 0) {
            setCustomerData(props.customerData);
        } else {
            setCustomerData({});
        }
    }, [props.customerData]);


    React.useEffect( () => {
        async function fetchData() {
            if (customerData && customerData.customerID) {
                let customerDataFromDB = await getCustomerByID(customerData.customerID);
                setCustomerData(customerDataFromDB);
            }
          }
          if (refreshCounter >= 1) {
            fetchData();
          }
    }, [refreshCounter]);


    const addNewPhoneToDB = async (phoneUserName, phoneNumber, phoneType) => {

        if (customerData
            && customerData.customerID
            && phoneUserName
            && phoneNumber
            && phoneType
        ) {
            // Dont need the phoneNumberID 
            // This api will create a uuid in the backend
            await addCustomerPhone(phoneUserName, phoneNumber, customerData.customerID, phoneType);
            setRefreshCounter(refreshCounter + 1);
        }
    };



    return customerData ? (
        <Card>
            {!props.hideCardHeader &&
                <CardHeader className="d-flex justify-content-between align-items-center">
                    {t("Customer Details")}
                </CardHeader>
            }
            <CardBody className="p-1">
                <Row className="text-center">
                    <Col>
                        <Button
                            className="p-3 mb-2 rounded-circle"
                            color="default"
                            onClick={(e) => e.preventDefault()}
                        >
                            <i className="fas fa-user-alt fa-2x"></i>
                        </Button>
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col>
                        <div className="">
                            <h5 className="h3 title">
                                {customerData?.customerName ??
                                    t("Customer Name")}
                            </h5>
                        </div>


                        <div className="mb-1">
                            <Button
                                color="primary"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalCreatePhoneIsOpen(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-plus" />
                                </span>
                                <span className="btn-inner--text">
                                    {t("Phone")}
                                </span>
                            </Button>

                            <Button
                                color="danger"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setModalCustomerUnpaidInvoiceRecordIsOpen(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-money-bill" />
                                </span>
                                <span className="btn-inner--text">
                                    {t("Unpaid")}
                                </span>
                            </Button>
                        </div>

                    </Col>
                </Row>
                {/* 
                <Row className="text-center mt--2">
                    <Col>
                        <p>
                            {customerData.customerAddress ??
                                t("Customer Address")}
                        </p>
                    </Col>
                </Row> */}

                <Row className="justify-content-md-center mt-1">
                    <Col md="auto">
                        <ListGroup flush>
                            {(customerData.CustomerPhones &&
                                Array.isArray(customerData.CustomerPhones) &&
                                customerData.CustomerPhones.length > 0) ? (
                                customerData.CustomerPhones.map(
                                    (value, index) => (
                                        <ListGroupItem
                                            tag="a"
                                            key={
                                                value.phoneNumberID
                                                    ? value.phoneNumberID
                                                    : uuid4()
                                            }
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <span>
                                                        <i className="fa fa-phone"></i>
                                                    </span>
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">
                                                                {
                                                                    value.phoneUserName
                                                                }
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">
                                                        {getPhoneType(
                                                            value.phoneType
                                                        )}
                                                        {"  "}
                                                        {value.phoneNumber}
                                                    </p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                    )
                                )
                            ) : (
                                <div className="mt-2 mb-2 text-center tableRowRemark">
                                    {t('Please add a contact')}
                                </div>
                            )}
                        </ListGroup>
                    </Col>
                </Row>
            </CardBody>

            <CustomerModalPhoneCreate
                title={t('New contact')}
                show={modalCreatePhoneIsOpen}
                onHide={() => setModalCreatePhoneIsOpen(false)}
                returnCustomerPhone={(phoneNumberID,
                    phoneUserName,
                    phoneNumber,
                    phoneType) => { addNewPhoneToDB(phoneUserName, phoneNumber, phoneType) }}
            />
            

            <CustomerUnpaidInvoiceRecord
                show={modalCustomerUnpaidInvoiceRecordIsOpen}
                onHide={() => {
                    setModalCustomerUnpaidInvoiceRecordIsOpen(false);
                }}
                customerID={customerData.customerID}
                customerData={customerData}
            />

        </Card>
    ) : (
        <Col xs={12} sm={12} md={12} lg={12}>
            <Card>
                <CardBody>
                    <strong className="text-info h1">{t("No Data Found!")}</strong>
                    <h3 className="text-info">{t("Please Check Your Filter")}</h3>
                </CardBody>
            </Card>
        </Col>
    );
}

export default CustomerDisplayCard;
