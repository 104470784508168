import React from "react";
import { Row, Col, Label, Button, Badge } from "reactstrap";
import useWindowDimensions from "../../../functions/Utility";
import { getImageType, getDateString } from "../../../functions/Utility";
import SignatureCanvas from "./SignatureCanvas";
import { useTranslation } from "react-i18next";
import Notifications from "../components/Notifications";
import moment from "moment";

// props
// useTranslate
// signatureUploadUser
function DeliveryRecordSignature(props) {
  const { t } = useTranslation();
  const { onSubmit } = props;
  const { width } = useWindowDimensions();
  const signature = props.signature;
  const signatureRecordDateTime = props.signatureRecordDateTime;
  const signatureUploadUser = props.signatureUploadUser;
  const signatureUploadUserNickName = props.signatureUploadUserNickName;

  // Delete Confirmation
  async function deleteConfirmation() {
    if (signature === "") return; // the signature is already empty
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: "",
      callback: callBackToDeleteSignature,
    };
    Notifications(alert);
  }

  // Delete API Call
  async function callBackToDeleteSignature(dummyID) {
    props.onDeleteSignature();
  }

  return (
    <>
      <div
        className="mt-3 mb-3 p-3 d-flex justify-content-between"
        style={{
          backgroundImage: signature
            ? `url(data:image/${getImageType(signature).ext
            };base64,${signature})`
            : "none",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          border: "3px dotted lightgray",
          color: "gray",
          height: "250px",
          maxWidth: 1000 - 70,
        }}
      >
        <div>
          <div className="h5 text-muted">
            {t("Signature")}
          </div>
          <div>
            {signature && <Badge color="info">{signatureUploadUserNickName ? signatureUploadUserNickName : signatureUploadUser}</Badge>}
          </div>
          <div>
            {signature && signatureRecordDateTime && <Badge color="info">{getDateString(signatureRecordDateTime, props.useTranslate)}</Badge>}
          </div>
        </div>


        <div className="d-flex flex-column justify-content-end">
          <div className="mt-1 mb-1">
            <Button
              color="primary"
              onClick={() => { props.onOpenSignature(); }}
            >

              <i className="fas fa-pen-fancy" />

            </Button>

          </div>
          <div className="mt-1 mb-1">
            <Button
              color="danger"
              onClick={() => {
                deleteConfirmation();
              }}
            >
              <i className="fas fa-trash" />
            </Button>

          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryRecordSignature;
