import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
    Badge
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { updateAccessIDArrayToJob } from "functions/UserJob.js";
import { getUserList } from "functions/Auth.js";
import { updateUserIDArrayToJob } from "functions/UserJob.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";


// props
// systemAccessData: include job / SystemAccessJobMatches


function UserJobMatchSystemUserModal(props) {
    const { t } = useTranslation();

    // Control 
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);
    const [isSelectedAllClicked, setIsSelectedAllClicked] = React.useState(true);

    const [job, setJob] = React.useState('');
    const [systemUserJobMatchArray, setSystemUserJobMatchArray] = React.useState([]);

    //  system user to choose
    const [allSystemUserInitialArray, setAllSystemUserInitialArray] = React.useState([]);

    // For array 
    const [jobRemark, setJobRemark] = React.useState('');
    

    React.useEffect(() => {
        if (props.show) {
            setJob(props.jobData.job);
            setSystemUserJobMatchArray(props.jobData.SystemUserJobMatches);

            //GUI
            setIsSelectedAllClicked(true);
            setFinishSubmitBoo(false);
        }
    }, [props.show]);

    React.useEffect(() => {
        getAllSystemUserDataLocal();
    }, [systemUserJobMatchArray]);


    const getAllSystemUserDataLocal = async () => {
        let systemUserData = await getUserList();

        systemUserData.forEach((item) => {
            item.jobRemark = findJobRemarkFromData(item.userID);
        })
        setAllSystemUserInitialArray(systemUserData);
    }


    const findJobRemarkFromData = (userID) => {
        if (!systemUserJobMatchArray || !Array.isArray(systemUserJobMatchArray) || systemUserJobMatchArray.length <= 0) {
            return '';
        }
        let findResult = systemUserJobMatchArray.find((val) => val.userID === userID);
        if (findResult && findResult.jobRemark) {
            return findResult.jobRemark;
        } else {
            return '';
        }
    }


    const isSystemUserIDSelected = (userID) => {
        if (!systemUserJobMatchArray || !Array.isArray(systemUserJobMatchArray) || systemUserJobMatchArray.length <= 0) {
            // default not selected
            return false;
        }
        return systemUserJobMatchArray.some((val) => val.userID === userID);
    }


    const onSelectUserID = (userID) => {
        if (!systemUserJobMatchArray || !Array.isArray(systemUserJobMatchArray)) {
            return;
        }

        let arrayToChange = systemUserJobMatchArray;
        if (isSystemUserIDSelected(userID)) {
            // unSelect
            let resultArray = arrayToChange.filter((item) => { return item.userID != userID });
            setSystemUserJobMatchArray([...resultArray]);
        } else {
            setSystemUserJobMatchArray([...arrayToChange, {
                job: job,
                userID: userID,
                jobRemark: jobRemark
            }]);
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Data check
        if (!job || job == '') {
            return;
        }

        // Accept the case when array.length = 0 
        if (!systemUserJobMatchArray || !Array.isArray(systemUserJobMatchArray)) {
            return;
        }

        let systemUserArrayToSubmit = [];
        systemUserJobMatchArray.forEach((item) => {
            systemUserArrayToSubmit.push({
                userID: item.userID,
                jobRemark: item.jobRemark
            })
        })

        let dataToSubmit = {
            job: job,
            userIDArray: systemUserArrayToSubmit
        }

        let response = await updateUserIDArrayToJob(dataToSubmit);


        if (response.code == 200) {
            props.refreshTable();
            props.onHide();
        }
    };

    const onSelectAllUserID = (selectedAllBoo) => {
        if (!systemUserJobMatchArray || !Array.isArray(systemUserJobMatchArray)) {
            return;
        }
        if (!allSystemUserInitialArray || !Array.isArray(allSystemUserInitialArray) || allSystemUserInitialArray.length <= 0) {
            return;
        }

        if (selectedAllBoo) {
            // Add all
            let arrayToPush = [];
            allSystemUserInitialArray.forEach((item) => {
                // Just to prevent double add
                if (!isSystemUserIDSelected(item.userID)) {
                    arrayToPush.push({
                        job: job,
                        userID: item.userID,
                        jobRemark: jobRemark
                    })
                }
            });
            setSystemUserJobMatchArray([...systemUserJobMatchArray, ...arrayToPush]);
        } else {
            // Remove all the displaying data
            let arrayToPush = systemUserJobMatchArray;
            allSystemUserInitialArray.forEach((item) => {
                if (isSystemUserIDSelected(item.userID)) {
                    // Remove
                    arrayToPush = arrayToPush.filter((element) => { element.userID != item.userID })
                }
            });
            setSystemUserJobMatchArray([...arrayToPush]);
        }

    }

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t('Match system user')}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col>
                                <div className="tableRowTitle">
                                    <Trans>{job}</Trans>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>

                        {/* stats */}
                        <Row className="mb-2">
                            <Col>
                                <Badge color="primary" className="statusBadge">{t('Total')}{': '}{allSystemUserInitialArray ? allSystemUserInitialArray.length : ''}{' '}{t('user')}</Badge>
                                <Badge color="warning" className="statusBadge">{t('Chosen')}{': '}{systemUserJobMatchArray ? systemUserJobMatchArray.length : ''}{' '}{t('user')}</Badge>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col>
                                <Button
                                    color={isSelectedAllClicked ? "default" : "danger"}
                                    onClick={() => {
                                        onSelectAllUserID(isSelectedAllClicked);
                                        setIsSelectedAllClicked(!isSelectedAllClicked);
                                    }}>
                                    <Trans>Select all</Trans>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Job Remark")}`}
                                    labelFor="jobRemark"
                                    inputName="jobRemark"
                                    inputId="jobRemark"
                                    inputPlaceholder={t("Job Remark")}
                                    value={jobRemark}
                                    handleChange={(e) => {
                                        setJobRemark(e.target.value);
                                    }}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    {allSystemUserInitialArray && Array.isArray(allSystemUserInitialArray) && allSystemUserInitialArray.length >= 1 &&
                                        allSystemUserInitialArray.map((item, index) => {
                                            return <div key={index}>
                                                <Badge
                                                    key={index}
                                                    className="mx-1 my-1 statusBadge"
                                                    color={isSystemUserIDSelected(item.userID) ? 'default' : 'secondary'}
                                                    onClick={() => {
                                                        onSelectUserID(item.userID);
                                                    }}>
                                                    {item.nickName}{' '}{'('}{item.userID}{')'}{' '}{item.jobRemark === '' ?  '' : '(' + item.jobRemark + ')' }

                                                </Badge>
                                            </div>

                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Save"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default UserJobMatchSystemUserModal;
