import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";


import { getReportResourceReportTermsArray } from "functions/ReportResource.js";
import { getRefTabData } from "functions/Utility.js";
import { updateSingleReportTerms } from "functions/ReportResource.js";
import SpinnerOverlay from "../components/SpinnerOverlay.js";


function ReportResourceInvoiceTermsEditModal(props) {
  const { t } = useTranslation();

  // Control
  const [isLoading, setIsLoading] = React.useState(false);

  const [isReportResourceInvoiceTermsLine1Chnaged, setIsReportResourceInvoiceTermsLine1Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine2Chnaged, setIsReportResourceInvoiceTermsLine2Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine3Chnaged, setIsReportResourceInvoiceTermsLine3Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine4Chnaged, setIsReportResourceInvoiceTermsLine4Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine5Chnaged, setIsReportResourceInvoiceTermsLine5Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine6Chnaged, setIsReportResourceInvoiceTermsLine6Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine7Chnaged, setIsReportResourceInvoiceTermsLine7Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine8Chnaged, setIsReportResourceInvoiceTermsLine8Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine9Chnaged, setIsReportResourceInvoiceTermsLine9Changed] = React.useState(false);
  const [isReportResourceInvoiceTermsLine10Chnaged, setIsReportResourceInvoiceTermsLine10Changed] = React.useState(false);

  const [isReportResourceInvoiceMainColorChnaged, setIsReportResourceInvoiceMainColorChnaged] = React.useState(false);
  const [isReportResourceInvoiceBottomInfoLine1Chnaged, setIsReportResourceInvoiceBottomInfoLine1Chnaged] = React.useState(false);
  const [isReportResourceInvoiceBottomInfoLine2Chnaged, setIsReportResourceInvoiceBottomInfoLine2Chnaged] = React.useState(false);


  // Data
  const [reportResourceInvoiceTermsLine1, setReportResourceInvoiceTermsLine1] = React.useState('');
  const [reportResourceInvoiceTermsLine2, setReportResourceInvoiceTermsLine2] = React.useState('');
  const [reportResourceInvoiceTermsLine3, setReportResourceInvoiceTermsLine3] = React.useState('');
  const [reportResourceInvoiceTermsLine4, setReportResourceInvoiceTermsLine4] = React.useState('');
  const [reportResourceInvoiceTermsLine5, setReportResourceInvoiceTermsLine5] = React.useState('');
  const [reportResourceInvoiceTermsLine6, setReportResourceInvoiceTermsLine6] = React.useState('');
  const [reportResourceInvoiceTermsLine7, setReportResourceInvoiceTermsLine7] = React.useState('');
  const [reportResourceInvoiceTermsLine8, setReportResourceInvoiceTermsLine8] = React.useState('');
  const [reportResourceInvoiceTermsLine9, setReportResourceInvoiceTermsLine9] = React.useState('');
  const [reportResourceInvoiceTermsLine10, setReportResourceInvoiceTermsLine10] = React.useState('');

  const [reportResourceInvoiceMainColor, setReportResourceInvoiceMainColor] = React.useState('');
  const [reportResourceInvoiceBottomInfoLine1, setReportResourceInvoiceBottomInfoLine1] = React.useState('');
  const [reportResourceInvoiceBottomInfoLine2, setReportResourceInvoiceBottomInfoLine2] = React.useState('');

  // Data process
  // const [refTabSubTypeArray, setRefTabSubTypeArray] = React.useState([]);


  const onSubmitDataToServer = async (refTabID, refTabData) => {
    setIsLoading(true);
    let updateResponse = await updateSingleReportTerms(refTabID, refTabData);
    setIsLoading(false);
    if (updateResponse.code == 200) {
      return true
    } else {
      return false;
    }
  };


  // props 

  React.useEffect(() => {
    const getDataFromServer = async () => {
      let refDataArray = await getReportResourceReportTermsArray('reportResourceInvoice');

      if (refDataArray && Array.isArray(refDataArray) && refDataArray.length >= 1) {
        setReportResourceInvoiceTermsLine1(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine1'));
        setReportResourceInvoiceTermsLine2(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine2'));
        setReportResourceInvoiceTermsLine3(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine3'));
        setReportResourceInvoiceTermsLine4(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine4'));
        setReportResourceInvoiceTermsLine5(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine5'));
        setReportResourceInvoiceTermsLine6(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine6'));
        setReportResourceInvoiceTermsLine7(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine7'));
        setReportResourceInvoiceTermsLine8(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine8'));
        setReportResourceInvoiceTermsLine9(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine9'));
        setReportResourceInvoiceTermsLine10(getRefTabData(refDataArray, 'reportResourceInvoiceTermsLine10'));

        setReportResourceInvoiceMainColor(getRefTabData(refDataArray, 'reportResourceInvoiceMainColor'));
        setReportResourceInvoiceBottomInfoLine1(getRefTabData(refDataArray, 'reportResourceInvoiceBottomInfoLine1'));
        setReportResourceInvoiceBottomInfoLine2(getRefTabData(refDataArray, 'reportResourceInvoiceBottomInfoLine2'));


        // reset the control here
        setIsReportResourceInvoiceTermsLine1Changed(false);
        setIsReportResourceInvoiceTermsLine2Changed(false);
        setIsReportResourceInvoiceTermsLine3Changed(false);
        setIsReportResourceInvoiceTermsLine4Changed(false);
        setIsReportResourceInvoiceTermsLine5Changed(false);
        setIsReportResourceInvoiceTermsLine6Changed(false);
        setIsReportResourceInvoiceTermsLine7Changed(false);
        setIsReportResourceInvoiceTermsLine8Changed(false);
        setIsReportResourceInvoiceTermsLine9Changed(false);
        setIsReportResourceInvoiceTermsLine10Changed(false);
        setIsReportResourceInvoiceMainColorChnaged(false);
        setIsReportResourceInvoiceBottomInfoLine1Chnaged(false);
        setIsReportResourceInvoiceBottomInfoLine2Chnaged(false);

        // Data process
        // let mapResult = refDataArray.map((item) => { return item.refTabSubType; });
        // let distinctResult = mapResult.filter((item, index) => mapResult.indexOf(item) === index);
        // setRefTabSubTypeArray(distinctResult);
      }
    }

    if (props.show) {
      getDataFromServer();
    }
  }, [props.show]);


  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader><Trans>Invoice Report Detail</Trans></ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine1")}   (${reportResourceInvoiceTermsLine1.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine1"
                inputName="reportResourceInvoiceTermsLine1"
                inputId="reportResourceInvoiceTermsLine1"
                inputPlaceholder={t("reportResourceInvoiceTermsLine1")}
                type="textarea"
                value={reportResourceInvoiceTermsLine1}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine1(e.target.value);
                  setIsReportResourceInvoiceTermsLine1Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine1Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine1", reportResourceInvoiceTermsLine1);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine1Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine2")}   (${reportResourceInvoiceTermsLine2.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine2"
                inputName="reportResourceInvoiceTermsLine2"
                inputId="reportResourceInvoiceTermsLine2"
                inputPlaceholder={t("reportResourceInvoiceTermsLine2")}
                type="textarea"
                value={reportResourceInvoiceTermsLine2}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine2(e.target.value);
                  setIsReportResourceInvoiceTermsLine2Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine2Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine2", reportResourceInvoiceTermsLine2);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine2Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine3")}   (${reportResourceInvoiceTermsLine3.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine3"
                inputName="reportResourceInvoiceTermsLine3"
                inputId="reportResourceInvoiceTermsLine3"
                inputPlaceholder={t("reportResourceInvoiceTermsLine3")}
                type="textarea"
                value={reportResourceInvoiceTermsLine3}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine3(e.target.value);
                  setIsReportResourceInvoiceTermsLine3Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine3Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine3", reportResourceInvoiceTermsLine3);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine3Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine4")}   (${reportResourceInvoiceTermsLine4.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine4"
                inputName="reportResourceInvoiceTermsLine4"
                inputId="reportResourceInvoiceTermsLine4"
                inputPlaceholder={t("reportResourceInvoiceTermsLine4")}
                type="textarea"
                value={reportResourceInvoiceTermsLine4}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine4(e.target.value);
                  setIsReportResourceInvoiceTermsLine4Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine4Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine4", reportResourceInvoiceTermsLine4);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine4Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine5")}   (${reportResourceInvoiceTermsLine5.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine5"
                inputName="reportResourceInvoiceTermsLine5"
                inputId="reportResourceInvoiceTermsLine5"
                inputPlaceholder={t("reportResourceInvoiceTermsLine5")}
                type="textarea"
                value={reportResourceInvoiceTermsLine5}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine5(e.target.value);
                  setIsReportResourceInvoiceTermsLine5Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine5Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine5", reportResourceInvoiceTermsLine5);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine5Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine6")}   (${reportResourceInvoiceTermsLine6.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine6"
                inputName="reportResourceInvoiceTermsLine6"
                inputId="reportResourceInvoiceTermsLine6"
                inputPlaceholder={t("reportResourceInvoiceTermsLine6")}
                type="textarea"
                value={reportResourceInvoiceTermsLine6}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine6(e.target.value);
                  setIsReportResourceInvoiceTermsLine6Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine6Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine6", reportResourceInvoiceTermsLine6);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine6Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine7")}   (${reportResourceInvoiceTermsLine7.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine7"
                inputName="reportResourceInvoiceTermsLine7"
                inputId="reportResourceInvoiceTermsLine7"
                inputPlaceholder={t("reportResourceInvoiceTermsLine7")}
                type="textarea"
                value={reportResourceInvoiceTermsLine7}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine7(e.target.value);
                  setIsReportResourceInvoiceTermsLine7Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine7Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine7", reportResourceInvoiceTermsLine7);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine7Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>
            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine8")}   (${reportResourceInvoiceTermsLine8.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine8"
                inputName="reportResourceInvoiceTermsLine8"
                inputId="reportResourceInvoiceTermsLine8"
                inputPlaceholder={t("reportResourceInvoiceTermsLine8")}
                type="textarea"
                value={reportResourceInvoiceTermsLine8}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine8(e.target.value);
                  setIsReportResourceInvoiceTermsLine8Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine8Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine8", reportResourceInvoiceTermsLine8);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine8Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine9")}   (${reportResourceInvoiceTermsLine9.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine9"
                inputName="reportResourceInvoiceTermsLine9"
                inputId="reportResourceInvoiceTermsLine9"
                inputPlaceholder={t("reportResourceInvoiceTermsLine9")}
                type="textarea"
                value={reportResourceInvoiceTermsLine9}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine9(e.target.value);
                  setIsReportResourceInvoiceTermsLine9Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine9Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine9", reportResourceInvoiceTermsLine9);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine9Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceTermsLine10")}   (${reportResourceInvoiceTermsLine10.length}${t('words')})`}
                labelFor="reportResourceInvoiceTermsLine10"
                inputName="reportResourceInvoiceTermsLine10"
                inputId="reportResourceInvoiceTermsLine10"
                inputPlaceholder={t("reportResourceInvoiceTermsLine10")}
                type="textarea"
                value={reportResourceInvoiceTermsLine10}
                handleChange={(e) => {
                  setReportResourceInvoiceTermsLine10(e.target.value);
                  setIsReportResourceInvoiceTermsLine10Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceTermsLine10Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceTermsLine10", reportResourceInvoiceTermsLine10);
                      if (result) {
                        setIsReportResourceInvoiceTermsLine10Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={t("reportResourceInvoiceMainColor")}
                labelFor="reportResourceInvoiceMainColor"
                inputName="reportResourceInvoiceMainColor"
                inputId="reportResourceInvoiceMainColor"
                inputPlaceholder={t("reportResourceInvoiceMainColor")}
                type="textarea"
                value={reportResourceInvoiceMainColor}
                handleChange={(e) => {
                  setReportResourceInvoiceMainColor(e.target.value);
                  setIsReportResourceInvoiceMainColorChnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceMainColorChnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceMainColor", reportResourceInvoiceMainColor);
                      if (result) {
                        setIsReportResourceInvoiceMainColorChnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceBottomInfoLine1")}   (${reportResourceInvoiceBottomInfoLine1.length}${t('words')})`}
                labelFor="reportResourceInvoiceBottomInfoLine1"
                inputName="reportResourceInvoiceBottomInfoLine1"
                inputId="reportResourceInvoiceBottomInfoLine1"
                inputPlaceholder={t("reportResourceInvoiceBottomInfoLine1")}
                type="textarea"
                value={reportResourceInvoiceBottomInfoLine1}
                handleChange={(e) => {
                  setReportResourceInvoiceBottomInfoLine1(e.target.value);
                  setIsReportResourceInvoiceBottomInfoLine1Chnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceBottomInfoLine1Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceBottomInfoLine1", reportResourceInvoiceBottomInfoLine1);
                      if (result) {
                        setIsReportResourceInvoiceBottomInfoLine1Chnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }



          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceInvoiceBottomInfoLine2")}   (${reportResourceInvoiceBottomInfoLine2.length}${t('words')})`}
                labelFor="reportResourceInvoiceBottomInfoLine2"
                inputName="reportResourceInvoiceBottomInfoLine2"
                inputId="reportResourceInvoiceBottomInfoLine2"
                inputPlaceholder={t("reportResourceInvoiceBottomInfoLine2")}
                type="textarea"
                value={reportResourceInvoiceBottomInfoLine2}
                handleChange={(e) => {
                  setReportResourceInvoiceBottomInfoLine2(e.target.value);
                  setIsReportResourceInvoiceBottomInfoLine2Chnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceInvoiceBottomInfoLine2Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceInvoiceBottomInfoLine2", reportResourceInvoiceBottomInfoLine2);
                      if (result) {
                        setIsReportResourceInvoiceBottomInfoLine2Chnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }

        </ModalBody>
        <ModalFooter>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ReportResourceInvoiceTermsEditModal;
