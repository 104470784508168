import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
    Badge
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateGood } from "functions/Goods";
import { updateStore } from "functions/Store.js";
import { StorageRetryPolicyType } from "@azure/storage-blob";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { getAllUserJob } from "functions/UserJob.js";
import { updateJobArrayToAccessID } from "functions/SystemAccess.js";
import { getAllSystemAccess } from "functions/SystemAccess.js";
import { updateAccessIDArrayToJob } from "functions/UserJob.js";
import { getSystemAccessTypeSelect2 } from "functions/SystemAccess.js";
import DropdownControl from "../components/DropdownControl.js";


// props
// systemAccessData: include job / SystemAccessJobMatches


function UserJobMatchSystemAccessModal(props) {
    const { t } = useTranslation();

    // Control 
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);
    const [isSelectedAllClicked, setIsSelectedAllClicked] = React.useState(true);

    const [job, setJob] = React.useState('');
    const [systemAccessJobMatchArray, setSystemAccessJobMatchArray] = React.useState([]);

    //  system access to choose
    const [allSystemAccessInitialArray, setAllSystemAccessInitialArray] = React.useState([]);
    const [allSystemAccessDisplayArray, setAllSystemAccessDisplayArray] = React.useState([]);

    // For type filter
    const [systemAccessTypeSelect2Data, setSystemAccessTypeSelect2Data] = React.useState([]);
    const [selectedAccessType, setSelectedAccessType] = React.useState('');


    React.useEffect(() => {
        let filteredData = filterData(allSystemAccessInitialArray, selectedAccessType);
        setAllSystemAccessDisplayArray(filteredData);

    }, [allSystemAccessInitialArray, selectedAccessType]);

    React.useEffect(() => {
        if (props.show) {
            setJob(props.jobData.job);
            setSystemAccessJobMatchArray(props.jobData.SystemAccessJobMatches);
            getAllSystemAccessDataLocal();

            getAccessTypeSelect2Data();

            //GUI
            setIsSelectedAllClicked(true);
            setFinishSubmitBoo(false);
        }
    }, [props.show]);


    const getAllSystemAccessDataLocal = async () => {
        let systemAccessData = await getAllSystemAccess();
        setAllSystemAccessInitialArray(systemAccessData);
    }

    const isSystemAccessSelected = (accessID) => {
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray) || systemAccessJobMatchArray.length <= 0) {
            // default not selected
            return false;
        }
        return systemAccessJobMatchArray.some((val) => val.accessID === accessID);
    }

    const countSystemAccessSelected = (accessIDArrayToCheck) => {
        if (!accessIDArrayToCheck || !Array.isArray(accessIDArrayToCheck) || accessIDArrayToCheck.length <= 0) {
            return 0;
        }
        let resultCounter = 0;
        accessIDArrayToCheck.forEach((item) => {
            if (isSystemAccessSelected(item.accessID)) {
                resultCounter++;
            }
        })
        return resultCounter;
    }

    const onSelectAccessType = (accessType) => {
        setSelectedAccessType(accessType);

        // For GUI
        setIsSelectedAllClicked(true);
    }

    const onSelectAccessID = (accessID) => {
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray)) {
            return;
        }

        let arrayToChange = systemAccessJobMatchArray;
        if (isSystemAccessSelected(accessID)) {
            // unSelect
            let resultArray = arrayToChange.filter((item) => { return item.accessID != accessID });
            setSystemAccessJobMatchArray([...resultArray]);
        } else {
            setSystemAccessJobMatchArray([...arrayToChange, {
                job: job,
                accessID: accessID,
            }]);
        }
    }

    const onSelectAllAccessIDForFilteredType = (selectedAllBoo) => {
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray)) {
            return;
        }
        if (!allSystemAccessDisplayArray || !Array.isArray(allSystemAccessDisplayArray) || allSystemAccessDisplayArray.length <= 0) {
            return;
        }

        if (selectedAllBoo) {
            // Add all
            let arrayToPush = [];
            allSystemAccessDisplayArray.forEach((item) => {
                // Just to prevent double add
                if (!isSystemAccessSelected(item.accessID)) {
                    arrayToPush.push({
                        job: job,
                        accessID: item.accessID
                    })
                }
            });
            setSystemAccessJobMatchArray([...systemAccessJobMatchArray, ...arrayToPush]);
        } else {
            // Remove all the displaying data
            let arrayToPush = systemAccessJobMatchArray;
            allSystemAccessDisplayArray.forEach((item) => {
                if (isSystemAccessSelected(item.accessID)) {
                    // Remove
                    arrayToPush = arrayToPush.filter((element) => { element.accessID != item.accessID })
                }
            });
            setSystemAccessJobMatchArray([...arrayToPush]);
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Data check
        if (!job || job == '') {
            return;
        }

        // Accept the case when array.length = 0 
        if (!systemAccessJobMatchArray || !Array.isArray(systemAccessJobMatchArray)) {
            return;
        }

        let systemAccessArrayToSubmit = [];
        systemAccessJobMatchArray.forEach((item) => {
            systemAccessArrayToSubmit.push({
                accessID: item.accessID
            })
        })

        let dataToSubmit = {
            job: job,
            accessIDArray: systemAccessArrayToSubmit
        }

        let response = await updateAccessIDArrayToJob(dataToSubmit);

        if (response.code == 200) {
            props.refreshTable();
            props.onHide();
        }
    };

    const getAccessTypeSelect2Data = async () => {
        let systemAccessSelect2Data = await getSystemAccessTypeSelect2();
        setSystemAccessTypeSelect2Data(systemAccessSelect2Data);
    };


    const filterData = (systemAccessArrayToFilter, selectedSystemArraytypeToUse) => {
        if (!systemAccessArrayToFilter || !Array.isArray(systemAccessArrayToFilter)) {
            return [];
        }
        if (!selectedSystemArraytypeToUse || selectedSystemArraytypeToUse == '') {
            return systemAccessArrayToFilter;
        }


        let resultArray = systemAccessArrayToFilter.filter((item) => {
            return item.accessType == selectedSystemArraytypeToUse;
        });
        return resultArray;
    };


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{t('Match system access')}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col>
                                <div className="tableRowTitle">
                                    <Trans>{job}</Trans>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <hr />
                            </Col>
                        </Row>

                        {/* stats */}
                        <Row className="mb-2">
                            <Col>
                                <Badge color="primary" className="statusBadge">{t('Total')}{': '}{allSystemAccessInitialArray ? allSystemAccessInitialArray.length : ''}{' '}{t('access')}</Badge>
                                <Badge color="warning" className="statusBadge">{t('Chosen')}{': '}{systemAccessJobMatchArray ? systemAccessJobMatchArray.length : ''}{' '}{t('access')}</Badge>
                                <Badge color="danger" className="statusBadge">{t('Filter')}{': '}{countSystemAccessSelected(allSystemAccessDisplayArray)}{' '}{t('access')}</Badge>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col>
                                <Button
                                    color={isSelectedAllClicked ? "default" : "danger"}
                                    onClick={() => {
                                        onSelectAllAccessIDForFilteredType(isSelectedAllClicked);
                                        setIsSelectedAllClicked(!isSelectedAllClicked);
                                    }}>
                                    <Trans>Select all</Trans>
                                </Button>

                                <DropdownControl
                                    disabled={false}
                                    defaultColor="primary"
                                    dropdownName={t("Type")}
                                    useTranslate={false}
                                    arrayIDTextFormat={systemAccessTypeSelect2Data}
                                    valueID={selectedAccessType}
                                    onSelectValueID={(valueID) => {



                                        onSelectAccessType(valueID);
                                    }}
                                />
                                {selectedAccessType != '' &&
                                    <Button
                                        className="ml-1"
                                        color="danger"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedAccessType('');
                                        }}
                                    >
                                        <Trans>Reset</Trans>
                                    </Button>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    {allSystemAccessDisplayArray && Array.isArray(allSystemAccessDisplayArray) && allSystemAccessDisplayArray.length >= 1 &&
                                        allSystemAccessDisplayArray.map((item, index) => {
                                            return <Badge
                                                key={index}
                                                className="mx-1 my-1 statusBadge"
                                                color={isSystemAccessSelected(item.accessID) ? 'default' : 'secondary'}
                                                onClick={() => {
                                                    onSelectAccessID(item.accessID);
                                                }}>
                                                <Trans>{item.accessID}</Trans>
                                            </Badge>
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Save"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default UserJobMatchSystemAccessModal;
