
import { updateLocalSetting } from "functions/LocalSetting";
import moment from "moment";
import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm";
import Notifications from "../components/Notifications";




// props
// show
// localSettingData
// refreshTable()
// onHide()

function LocalSettingEditModal(props) {
    const { t } = useTranslation();

    const [userID, setUserID] = React.useState("");
    const [settingKey, setSettingKey] = React.useState("");
    const [settingValue, setSettingValue] = React.useState("");
    const [settingDetail, setSettingDetail] = React.useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userID === "") {
            Notifications({
                type: "dataCheck"
                , message: 'userID is invalid.'
            });
            return;
        }
        if (settingKey === "") {
            Notifications({
                type: "dataCheck"
                , message: t('Please input a setting key.')
            });
            return;
        }
        if (settingValue === "") {
            Notifications({
                type: "dataCheck"
                , message: t('Please input a setting value.')
            });
            return;
        }

        try {
            await updateLocalSetting(userID, settingKey, settingValue, settingDetail);
            props.refreshTable();
            props.onHide();
        } catch (err) {
            Notifications();
        }
    };

    React.useEffect(() => {
        if (props.show && props.localSettingData) {
            // refresh the page
            setUserID(props.localSettingData.userID);
            setSettingValue(props.localSettingData.settingValue);
            setSettingKey(props.localSettingData.settingKey);
            setSettingDetail(props.localSettingData.settingDetail);
        }
    }, [props.show]);



    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >
                        <div className="mt-4">
                            {t("Create local setting")}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="12">

                                <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            label={t("Setting key")}
                                            labelFor="settingKey"
                                            inputName="settingKey"
                                            inputId="settingKey"
                                            inputPlaceholder={t("Setting key")}
                                            value={settingKey}
                                            handleChange={(e) => {
                                                setSettingKey(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row> <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            label={t("Setting value")}
                                            labelFor="settingValue"
                                            inputName="settingValue"
                                            inputId="settingValue"
                                            inputPlaceholder={t("Setting value")}
                                            value={settingValue}
                                            handleChange={(e) => {
                                                setSettingValue(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row> <Row>
                                    <Col>
                                        <InputTypeTextForm
                                            type="text"
                                            label={t("Setting detail")}
                                            labelFor="settingDetail"
                                            inputName="settingDetail"
                                            inputId="settingDetail"
                                            inputPlaceholder={t("Setting detail")}
                                            value={settingDetail}
                                            handleChange={(e) => {
                                                setSettingDetail(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {t("Confirm")}
                        </Button>
                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>

            </Modal>
        </>
    );
}

export default LocalSettingEditModal;
