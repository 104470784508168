import { truncate } from "@sentry/utils";
import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import Notifications from "views/pages/components/Notifications";
import moment from "moment";
import md5 from "md5";



export const getMultipleInvoiceURL = (invoiceList, isShowListPage = true, isShowInvoicePage = true, isShowCompanyChop = true) => {

  const baseUrl =
    process.env.REACT_APP_ENV !== "Production"
      ? process.env.REACT_APP_REPORTING_BASE_URL_DEVELOPMENT
      : process.env.REACT_APP_REPORTING_BASE_URL_PRODUCTION;

  if (!invoiceList || !Array.isArray(invoiceList) || invoiceList.length <= 0) {
    return "";
  }

  try {
    let invoiceIDString = invoiceList.map(function (item) {
      return item.invoiceID;
    }).join(",");

    let invoiceIDStringForSign = invoiceList.map(function (item) {
      return item.invoiceID;
    }).join("");

    // Create a signature
    let signVerify = md5(`${invoiceIDStringForSign}${process.env.REACT_APP_SIGN_HASH}`).substring(0, 6);

    let showListPageString;
    if (isShowListPage) {
      showListPageString = 'Y'
    } else {
      showListPageString = 'N'
    }

    let showInvoicePageString;
    if (isShowInvoicePage) {
      showInvoicePageString = 'Y'
    } else {
      showInvoicePageString = 'N'
    }

    let showCompanyChopString;
    if (isShowCompanyChop) {
      showCompanyChopString = 'Y'
    } else {
      showCompanyChopString = 'N'
    }

    return `${baseUrl}multipleinvoices/pdf/${invoiceIDString}?sign=${signVerify}&listPage=${showListPageString}&invoicePage=${showInvoicePageString}&chop=${showCompanyChopString}`;
  } catch (error) {
    return '';
  }


};



export const filterBillRecords = (
  deliveryData,
  search,
  startDate,
  endDate,
  statusList
) => {
  return deliveryData.filter((data) => {
    let matched;
    let recordDate = new Date(data.recordDateTime).setHours(0, 0, 0, 0);

    matched =
      //  Date Match
      startDate <= recordDate &&
      recordDate <= endDate &&
      //  Status Match
      ((data.billStatus === "未付" && statusList.notPaid) ||
        (data.billStatus === "已付" && statusList.paid));
    if (matched === false) return matched;

    //  Search string filter
    if (
      (data.customerName && data.customerName.includes(search)) ||
      (data.remark && data.remark.includes(search)) ||
      (data.paymentID && data.paymentID.includes(search))
    ) {
      return true;
    }
    return false;
  });
};

export const sortBillRecords = (deliveryData, columnName, order) => {
  if (columnName === "") return deliveryData;

  let sortedData = [...deliveryData]; //  Copy data to another array
  sortedData.sort((a, b) => {
    let compare;
    if (columnName === "recordDateTime") {
      compare = Date.parse(a[columnName]) > Date.parse(b[columnName]) ? 1 : -1;
    } else if (columnName === "netPrice") {
      compare = parseFloat(a[columnName]) > parseFloat(b[columnName]) ? 1 : -1;
    }
    return compare * order; //  order: 1=ascending, -1=descending
  });
  return sortedData;
};

export const getAllBillRecords = async () => {
  try {
    const response = await api.get("/billRecords");
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getAllBillRecordsWithDateRange = async (startDate, endDate) => {
  try {
    const startDateFormat = new Date(startDate).toLocaleDateString("en-US");
    const endDateFormat = new Date(endDate).toLocaleDateString("en-US");
    const response = await api.get(
      `/billRecords?start=${startDateFormat}&end=${endDateFormat}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getBillRecordsFast = async () => {
  try {
    const response = await api.get("/billRecords/fast");
    return response.data;
  } catch (error) {
    return [];
  }
};
export const getBillRecordById = async (paymentID) => {
  try {
    const response = await api.get(`/billRecords/${paymentID}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getBillStatusColor = (status) => {
  if (status === "已付") {
    return "success";
  } else if (status === "未付") {
    return "danger";
  } else {
    return "gray";
  }
  return "";
};

export const getBillStatusIcon = (status) => {
  if (status === "已付") {
    return "fas fa-check";
  } else if (status === "未付") {
    return "fas fa-times";
  } else {
    return "fas fa-times-circle";
  }
  return "";
};

export const isPaid = (status) => {
  if (status === "已付") {
    return true;
  } else {
    return false;
  }
};

export const isPaidColor = (status) => {
  if (status === "已付") {
    return "text-success";
  } else if (status === "未付") {
    return "text-danger";
  } else {
    return "text-gray";
  }
};

export const getBillPaymentImage = async (paymentID) => {
  try {
    const response = await api.get(`/billRecords/${paymentID}/paymentImage`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getBillWhatsappString = (
  paymentID,
  invoiceUrl,
  customerName,
  billRemark
) => {
  let remarkString = "";
  if (customerName === "") {
    remarkString = billRemark;
  } else {
    remarkString = customerName;
  }
  return (
    "單號：" +
    paymentID +
    "（" +
    remarkString +
    "）" +
    "<br />" +
    "<br />" +
    "按此連結：" +
    invoiceUrl +
    "<br />" +
    "<br />" +
    "如單據有更改,連結會自動更新" +
    "<br />" +
    "有問題請whatsapp查詢,謝謝"
  );
};

export const getBillDevileryWhatsappString = (
  paymentID,
  invoiceUrl,
  customerName,
  billRemark,
  deliveryAddress
) => {
  let remarkString = "";
  if (customerName === "") {
    remarkString = billRemark;
  } else {
    remarkString = customerName;
  }
  return (
    "單號：" +
    paymentID +
    "（" +
    remarkString +
    "）" +
    "\n" +
    "送貨地址：" +
    deliveryAddress +
    "\n" +
    "\n" +
    "按此連結：" +
    invoiceUrl +
    "\n" +
    "\n" +
    "如單據有更改,連結會自動更新" +
    "\n" +
    "有問題請whatsapp查詢,謝謝"
  );
};


export const storeInvoice = async (data) => {
  let response = await apiPost({
    url: "invoiceRecords/addInvoice",
    data: data,
    responseType: "",
  });

  // return response.status == 200
  //   ? response.data.data
  //   : response.status;

  return response.status == 200
    ? response.data.data.createInvoiceData.invoiceID //Just return the invoiceID directly
    : response.status;
};


// export const storeInvoice = async (data) => {
//   return api.post("invoiceRecords/addInvoice", data).then((response) => {
//     try {
//       if (response.data.code == 200) {
//         return Notifications({ type: "store", message: response.data.message });
//       } else {
//         return Notifications({ type: "error", message: response.data.message });
//       }
//     } catch (error) {
//       return Notifications({ type: "error" });
//     }
//   });
// };

export const searchDeliveryAddress = async (data) => {
  let response = await apiGet({
    url: `deliveryAddress/searchAddress/${data}`,
    responseType: "",
  });
  return response.status == 200 ? response.data.data.rows : [];
};


export const filterInvoiceRecords = (
  deliveryData,
  startDate,
  endDate,
  statusList
) => {
  return deliveryData.filter((data) => {
    let matched;
    let recordDate = new Date(data.invoiceRecordDateTime).setHours(0, 0, 0, 0);

    matched =
      startDate <= recordDate &&
      recordDate <= endDate &&
      ((data.invoiceStatus.toLowerCase() === "unpaid" && statusList.Unpaid) ||
        (data.invoiceStatus.toLowerCase() === "paid" && statusList.Paid) ||
        (data.invoiceStatus.toLowerCase() === "normal" && statusList.Normal));
    return matched;
  });
};

export const getInvoiceByKeyword = async (param) => {
  try {
    let response = await apiGet({
      url: "invoiceRecords/searchInvoice/" + param,
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const getAllInvoice = async () => {

  try {
    let response = await apiGet({
      url: "invoiceRecords/getAllInvoice",
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }

};

export const getInvoiceByCustomerID = async (customerID, invoiceStatusArray) => {
  try {
    let paramString = '';
    if (invoiceStatusArray && Object.values(invoiceStatusArray).length >= 1) {
      // there is something in invoiceStatusArray
      // e.g. ["paid", "cancel"]
      paramString = "?"
      invoiceStatusArray.forEach((invoiceStatusItem, index) => {
        if (index === 0) {
          paramString += `invoiceStatus=${invoiceStatusItem}`
        } else {
          paramString += `&invoiceStatus=${invoiceStatusItem}`
        }
      });
    }
    let response = await apiGet({
      url: `invoiceRecords/getInvoiceByCustomerID/${customerID}${paramString}`,
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};



export const getSingleInvoice = async (invoiceID) => {
  try {
    let response = await apiGet({
      url: `invoiceRecords/getSingleInvoice/${invoiceID}`,
      responseType: "",
    });
    return response.status == 200 ? response.data.data : [];

  } catch (e) {
    return [];
  }
};


export const getInvoiceByPaymentID = async (paymentID) => {
  try {
    let response = await apiGet({
      url: `invoiceRecords/getInvoiceByPaymentID/${paymentID}`,
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};


export const getInvoiceByDateRange = async (startDate, endDate, invoiceStatus = '') => {
  try {
    let paramClause = '';

    const startDateFormat = moment(startDate).format("yyyy-MM-DD");
    const endDateFormat = moment(endDate).format("yyyy-MM-DD");

    if (invoiceStatus) {
      paramClause = paramClause + 'invoiceStatus' + '=' + invoiceStatus;
    }
    let response = await apiGet({
      url: `invoiceRecords/searchInvoiceDateRange/${startDateFormat}/${endDateFormat}?${paramClause}`,
      responseType: "",
    });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};


export const updateInvoiceFull = async (data) => {

  let response = await apiPut({
    url: "invoiceRecords/updateInvoiceFull",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data
    : response.status;
};

export const updateInvoice = async (data) => {
  let response = await apiPut({
    url: "invoiceRecords/updateInvoice",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const updateInvoiceCancel = async (id) => {
  let response = await apiPut({
    url: "invoiceRecords/cancelInvoice/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteInvoiceByID = async (id) => {
  let response = await apiDelete({
    url: "invoiceRecords/deleteInvoice/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};


// get invoiceSubitem related
export const getInvoiceSubitemsByInovoiceID = async (invoiceID) => {
  try {
    let response = await apiGet({ url: "goods/invoiceRelatedGoods/" + invoiceID, responseType: "" });
    return response.status == 200
      ? response.data
      : response.status;
  } catch (error) {
    return [];
  }

};

// return the updated invoice subitem array
// use localSubitemID to be the ID
export const updateObjectInvoiceSubitems = (
  localSubitemID,
  invoiceSubitemArray,
  invoiceSubitemToUpdate
) => {
  if (typeof invoiceSubitemArray === "object" && invoiceSubitemArray !== null) {
    return invoiceSubitemArray.map((data) =>
      data.localSubitemID === localSubitemID ? invoiceSubitemToUpdate : data
    );
  }
};



export const getInvoiceStatusArray = () => {
  return [
    { id: "Unpaid", text: "Unpaid", color: "danger" },
    { id: "Partial", text: "Partial", color: "primary" },
    { id: "Paid", text: "Paid", color: "success" },
    { id: "Cancel", text: "Cancel", color: "warning" },
  ];
};


export const getInvoiceIsDeliveryArray = () => {
  return [
    { id: "Delivery", text: "Delivery", color: "primary" },
    { id: "NonDelivery", text: "Non-delivery", color: "warning" }
  ];
};

export const isInvoiceDelivery = (singleInvoiceItem) => {
  if (singleInvoiceItem && Object.values(singleInvoiceItem).length >= 1) {
    if (singleInvoiceItem.DeliveryRecords && Array.isArray(singleInvoiceItem.DeliveryRecords) && singleInvoiceItem.DeliveryRecords.length >= 1) {
      // there is some delivery record
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getInvoiceStatusColor = (invoiceStatusToCheck) => {
  const invoiceStatusArray = getInvoiceStatusArray();
  try {
    if (invoiceStatusArray) {
      let result = invoiceStatusArray.filter((item) => {
        return item.id.toLowerCase() == invoiceStatusToCheck.toLowerCase()
      });
      if (result && result.length == 1) {
        return result[0].color;
      } else {
        return "default";
      }
    }
  } catch (error) {
    return "default";
  }
};

export const getDefaultInvoiceStatus = () => {
  return "Unpaid";
};


