import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications.js";

/**
 * API functions
 */
//  Car list

export const getAllCarsFromCache = () => {
  //  Get cached list from local storage
  let carList = localStorage.getItem("carList");
  if (carList !== null) {
    return JSON.parse(carList);
  }
  return [];
};
export const sortCarsByCarID = (carList) => {
  carList.sort((a, b) => {
    if (a.carID > b.carID) return 1;
    if (a.carID < b.carID) return -1;
    return 0;
  });
  return carList;
};

//  Car maintenance type list
export const getAllCarMaintenanceTypes = async () => {
  const response = await api.get("/carMaintenance/types");
  const carMainTypeList = response.data;
  localStorage.setItem("carMainTypeList", JSON.stringify(carMainTypeList));
  return response.data;
};
export const getAllCarMaintenanceTypesFromCache = () => {
  //  Get cached list from local storage
  let carMainTypeList = localStorage.getItem("carMainTypeList");
  if (carMainTypeList !== null) {
    return JSON.parse(carMainTypeList);
  }
  return [];
};

//  Car maintenance records
export const getAllCarMaintenanceRecords = async () => {
  try {
    const response = await api.get("/carMaintenance");
    return response.data;
  } catch (error) {
    return [];
  }
};
export const getCarMaintenanceRecordsFast = async () => {
  try {
    const response = await api.get("/carMaintenance/fast");
    return response.data;
  } catch (error) {
    return [];
  }
};
export const getCarMaintenanceRecordDetail = async (carMainID) => {
  try {
    const response = await api.get(`/carMaintenance/${carMainID}`);
    return response.data;
  } catch (error) {
    return [];
  }
};
export const updateCarMaintenance = async (mainID, updateData) => {
  try {
    const response = await api.put(`/carMaintenance/${mainID}`, updateData);
    return response.data;
  } catch (error) {
    return [];
  }
};
export const createCarMaintenance = async (newData) => {
  try {
    const response = await api.post(`/carMaintenance`, newData);
    return response.data;
  } catch (error) {
    return [];
  }
};
export const deleteCarMaintenance = async (mainID) => {
  try {
    const response = await api.delete(`/carMaintenance/${mainID}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

/**
 * Frontend functions
 */
export const filterCarMaintenance = (
  carMainData,
  search,
  startDate,
  endDate
) => {
  return carMainData.filter((data) => {
    let searchMatched;
    searchMatched =
      (data.carID && data.carID.toString().includes(search)) ||
      (data.mainType && data.mainType.includes(search)) ||
      (data.mainDetail && data.mainDetail.includes(search)) ||
      (data.mainFee && data.mainFee.toString().includes(search));

    let dateMatched, finishDate;
    finishDate = new Date(data.mainDate).setHours(0, 0, 0, 0);
    dateMatched = finishDate >= startDate && finishDate <= endDate;

    return searchMatched && dateMatched;
  });
};
export const sortCarMaintenance = (carMainData, columnName, order) => {
  if (columnName === "") return carMainData;

  let sortedData = [...carMainData]; //  Copy data to another array
  sortedData.sort((a, b) => {
    let compare;
    if (columnName === "mainDate") {
      compare = Date.parse(a[columnName]) > Date.parse(b[columnName]) ? 1 : -1;
    } else if (columnName === "mainFee") {
      compare = parseFloat(a[columnName]) > parseFloat(b[columnName]) ? 1 : -1;
    }
    return compare * order; //  order: 1=ascending, -1=descending
  });
  return sortedData;
};
export const getCarMaintenanceStatusColor = (status) => {
  if (status === "完成") {
    return "success";
  } else if (status === "排期") {
    return "info";
  }
  return "";
};

export const getCarSelect2 = async () => {
  try {
    return api.get("/cars/getCars").then((response) => {
      try {
        if (response.data.code == 200) {
          response.data.data.rows.forEach((obj) =>
            renameKeys(obj, "carID", "id")
          );
          response.data.data.rows.forEach((obj) =>
            renameKeys(obj, "carID", "text")
          );
          return response.data.data.rows;
        } else {
          return Notifications({ type: "error", message: response.data.message });
        }
      } catch (error) {
        return Notifications({ type: "error" });
      }
    });
  } catch (error) {
    return [];
  }
};

//New
export const getAllCars = async () => {
  try {
    let response = await apiGet({ url: "cars/getCars", responseType: "" });
    return response.status == 200 ? response.data.data : [];
  } catch (error) {
    return [];
  }
};

export const getCarDatatable = async () => {
  try {
    let response = await apiGet({ url: "cars/getCars", responseType: "" });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const storeCar = async (data) => {
  let response = await apiPost({
    url: "cars/addcar",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const updateCar = async (data) => {
  let response = await apiPut({
    url: "cars/updateCar",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteCarByID = async (id) => {
  let response = await apiDelete({
    url: "cars/deleteCar/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const getCarStatus = () => {
  return [
    { id: "normal", text: "normal" },
    { id: "retire", text: "retire" },
  ];
};

export const getCarType = () => {
  return [
    { id: "light truck", text: "light truck" },
    { id: "medium truck", text: "medium truck" },
    { id: "heavy truck", text: "heavy truck" },
    { id: "van", text: "van" },
    { id: "other", text: "other" },
  ];
};

export const getCarTypeByID = (id) => {
  try {
    return getCarType().filter((data) => {
      if (data.id == id) {
        return data;
      }
    })[0];
  } catch (e) {
    return { id: "", text: "" }
  }
};

export const getDefaultCarID = (carChooseSelect2Data = []) => {
  try {
    if (carChooseSelect2Data && Array.isArray(carChooseSelect2Data) && carChooseSelect2Data.length == 1) {
      return carChooseSelect2Data[0].id;
    } else {
      return "TBC";
    }
  } catch (error) {
    return '';
  }
};