import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";


export const getInventoryColor = (currentInventory, minInventory, maxInventory) => {
    try {
        if (minInventory > maxInventory) {
            // error 
            return "default";
        } 
        if (currentInventory <= minInventory ) {
            return "danger";
        } 
        if (minInventory <= currentInventory && currentInventory <= maxInventory) {
            return "success";
        } 
        if (maxInventory <= currentInventory) {
            return "primary";
        } 

    } catch (error) {
        return "default";
    }

};


export const getGoodDatatable = async () => {
    try {
        let response = await apiGet({ url: "goods/goodInventory/getGoodInventory", responseType: "" });
        return response.status == 200 ? response.data.data.rows : [];
    } catch (error) {
        return [];
    }
};

export const getGoodInventoryByGoodID = async (goodID) => {
    try {
        if (goodID) {
            let response = await apiGet({ url: "goods/" + goodID, param: { goodID: goodID }, responseType: "" });
            return response.status == 200 ? response.data.data : [];
        }
    } catch (error) {
        return [];
    }
};



// get invoiceSubitem related

export const getGoodSelect2ByInovoiceID = async (invoiceID) => {
    try {
        let response = await apiGet({ url: "goods/invoiceRelatedGoods/" + invoiceID, responseType: "" });
        if (response.status == 200) {
            let goodListToGet = response.data.data.rows.map(function (item, i) {
                return item.Good;
            });
            goodListToGet.forEach((obj) => renameKeys(obj, "goodID", "id"));
            goodListToGet.forEach((obj) =>
                renameKeys(obj, "goodDescription", "text")
            );
            return goodListToGet ?? [];
        }
    } catch (error) {
        return [];
    }
};

export const createNewGood = async (data) => {
    let response = await apiPost({
        url: "goods/addGood",
        data: data,
        responseType: "",
    });

    return response.status == 200
        ? response.data.data.goodID // return the new goodID
        : response.status;
};

export const updateGoodInventory = async (storeID, goodID, currentInventory, minInventory, maxInventory) => {
    let response = await apiPut({
        url: "goods/goodInventory/updateInventory",
        data: {
            storeID: storeID,
            goodID: goodID,
            currentInventory: currentInventory,
            minInventory: minInventory,
            maxInventory: maxInventory
        },
        responseType: "",
    });
    return response.status == 200
        ? response.data
        : response.status;
};

export const deleteGoodByID = async (id) => {
    let response = await apiDelete({
        url: "goods/deleteGood/" + id,
        responseType: "",
    });
    return response.status == 200
        ? response.data
        : response.status;
};

export const deleteGoodInventory = async (goodID, storeID) => {
    let response = await apiDelete({
        url: `goods/goodInventory/deleteGoodInventory`,
        // url: `goods/goodInventory/deleteGoodInventory?goodID=${goodID}&storeID=${storeID}`,
        param: {
            params: {
                storeID: storeID,
                goodID: goodID
            }
        },
        responseType: "",
    });
    return response.status == 200
        ? response.data
        : response.status;
};

export default getGoodDatatable;
