import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateGood } from "functions/Goods";
import { updateStore } from "functions/Store.js";
import { StorageRetryPolicyType } from "@azure/storage-blob";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";

function StoreEditModal(props) {
    const { t } = useTranslation();


    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [storeID, setStoreID] = React.useState('');
    const [physicalAddress, setPhysicalAddress] = React.useState('');
    const [storeName, setStoreName] = React.useState('');
    const [storeType, setStoreType] = React.useState('');


    React.useEffect(() => {
        if (Object.values(props.storeData).length > 0) {
            setStoreID(props.storeData.storeID);
            setPhysicalAddress(props.storeData.physicalAddress);
            setStoreName(props.storeData.storeName);
            setStoreType(props.storeData.storeType);

            setFinishSubmitBoo(false);
        }
    }, [props.show]);



    const onChangeStoreName = (storeName) => {
        setStoreName(storeName);
    };

    const onChangePhysicalAdress = (physicalAddress) => {
        setPhysicalAddress(physicalAddress);
    };

    const onChangeStoreType = (storeType) => {
        setStoreType(storeType);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let dataToSubmit = {
            storeID: storeID,
            physicalAddress: physicalAddress,
            storeName: storeName,
            storeType: storeType
        }

        let update = await updateStore(dataToSubmit);
        if (update.code == 200) {
            props.refreshTable();
            props.onHide();
        }

    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{props.title}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Store Name")}${"("}${t("Required")}${")"}`}
                                    labelFor="storeName"
                                    inputName="storeName"
                                    inputId="storeName"
                                    inputPlaceholder={t("Store Name")}
                                    value={storeName}
                                    handleChange={(e) => onChangeStoreName(e.target.value)}
                                    required={true}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Address")}${"("}${t("Required")}${")"}`}
                                    labelFor="physicalAddress"
                                    inputName="physicalAddress"
                                    inputId="physicalAddress"
                                    inputPlaceholder={t("Address")}
                                    value={physicalAddress}
                                    handleChange={(e) => onChangePhysicalAdress(e.target.value)}
                                    required={true}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Store type")}${"("}${t("Required")}${")"}`}
                                    labelFor="storeType"
                                    inputName="storeType"
                                    inputId="storeType"
                                    inputPlaceholder={t("Store type")}
                                    value={storeType}
                                    handleChange={(e) => onChangeStoreType(e.target.value)}
                                    required={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Store ID")}`}
                                    labelFor="storeID"
                                    inputName="storeID"
                                    inputId="storeID"
                                    inputPlaceholder={t("Store ID")}
                                    value={storeID}
                                    handleChange={(e) => {}}
                                   disabled={true}
                                />
                            </Col>
                        </Row>


                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Save"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default StoreEditModal;
