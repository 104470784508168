import React from "react";

// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import TranslateItem from "views/pages/components/TranslateItem";

function AuthHeader({ title, lead, isMobiles }) {
  return (
    <>
      <div className="header bg-gradient-primary py-6 py-lg-6 pt-lg-6">
        <Container>
          <div className="header-body text-center mb-7">
            <Row className="justify-content-center">
              {/* {isMobiles ? (
                <img
                  alt="Main Logo"
                  src="/loginLogo.png"
                  className="avatar rounded-circle"
                />
              ) : (
                <img
                  alt="Main Logo"
                  src="/loginLogo.png"
                  className="avatar-xl"
                />
              )} */}
              {isMobiles ? (
                <img
                  alt="Main Logo"
                  src={`${process.env.REACT_APP_FRONTEND_IMAGE_BASE_URL}/loginLogo`}
                  className="avatar rounded-circle"
                />
              ) : (
                <img
                  alt="Main Logo"
                  src={`${process.env.REACT_APP_FRONTEND_IMAGE_BASE_URL}/loginLogo`}
                  className="avatar-xl"
                />
              )}


            </Row>
            <Row className="justify-content-center">
              <Col className="px-5" lg="6" md="8" xl="5">

                {title ? <h1 className="text-white"><TranslateItem text={title} /></h1> : null}
                {lead ? <p className="text-lead text-white"><TranslateItem text={lead} /></p> : null}
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            {/* <polygon className="fill-default" points="2560 0 2560 100 0 100" /> */}
          </svg>
        </div>
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
  isMobiles: PropTypes.bool,
};

export default AuthHeader;
