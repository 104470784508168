import imageType from "image-type";
import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";
import moment from "moment";

import { isMobile } from "react-device-detect";

export const joinArrayToString = (arrayToJoin, separator) => {

  if (!arrayToJoin) return "";

  if (!Array.isArray(arrayToJoin)) return "";

  if (Object.values(arrayToJoin).length <= 0) return "";
  try {
    return arrayToJoin.join(separator);
  } catch (e) {
    return "";
  }
}

export const getChineseDateFormat = (dateToConvert, includeWeekDay = true) => {

  function tryConvertWeek(textToConvert) {
    switch (textToConvert) {
      case 'MON':
        return "星期一";
      case "TUE":
        return "星期二";
      case "WED":
        return "星期三";
      case "THU":
        return "星期四";
      case "FRI":
        return "星期五";
      case "SAT":
        return "星期六";
      case "SUN":
        return "星期日";
      default:
        return '';
    }
  };

  try {
    if (includeWeekDay) {
      return moment(dateToConvert).format("YYYY年MM月DD日") + " " + tryConvertWeek(moment(dateToConvert).format("ddd").toUpperCase());
    } else {
      return moment(dateToConvert).format("YYYY年MM月DD日");
    }
  } catch (e) {
    return "";
  }
};



export const checkIsNumber = (varToCheck) => {
  return !isNaN(varToCheck);
};


export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};


export const openPDFLink = (uri, name = "") => {
  if (isMobile) {
    try {
      goToUri(uri, name);
    } catch (error) {

    }
  } else {
    // computer
    // Use window open is fine
    try {
      window.open(uri);
    } catch (error) {

    }
  }
};

export const goToUri = (uri, name) => {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.target = "_blank";
  link.rel = "noreferrer";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

//this including the base 64 encoding
export const resizeFile = (file) => {
  if (isFileImage(file)) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1000, //in pixel
        1000, //in pixel
        "JPEG", // compressed format: PNG / JPEG
        50, // 50% quality
        0, //rotaion = 0
        (uri) => {
          resolve(uri); // a uri encoded in base 64
        },
        "base64" // blob
      );
    });
  } else {
    return;
  }
}

export const isFileImage = (file) => {
  return file && file['type'].split('/')[0] === 'image';
}

export const getBase64PhotoHeadRemoved = async (photoToProcess, resizeBoo = true) => {

  if (!isFileImage(photoToProcess)) {
    return "";
  }

  if (resizeBoo) {
    //resize file will auto encoded to base 64
    let imageToUploadResized = await resizeFile(photoToProcess);
    // resize file will auto convert to jpg
    let imageBase64 = imageToUploadResized;
    let imageBase64HeaderRemoved = imageBase64.split(',')[1];
    return imageBase64HeaderRemoved;
  } else {
    let imageBase64 = await convertFileToBase64(photoToProcess);
    // The file type remains
    // If PNG, then keeps PNG
    let imageBase64HeaderRemoved = imageBase64.split(',')[1];
    return imageBase64HeaderRemoved;
  }
};

export const getImageType = (fileBase64) => {
  return fileBase64 ? imageType(Buffer.from(fileBase64, "base64")) : null;
};

export const imageBase64ToFile = (fileBase64, name) => {
  const imageDataArray = Buffer.from(fileBase64, "base64");
  return new File([imageDataArray.buffer], name, {
    type: imageType(imageDataArray).mime,
  });
};

export const addPhoneLink = (text) => {
  if (!text) return "";
  if (text === "") return "";
  const matchArray = text.match(/[0-9]{8}|([0-9]{4}[ -][0-9]{4})/g);
  if (matchArray === null) return text;

  let textWithoutNumber = text;
  let textArray;
  let dialLinkArray = [];
  matchArray.forEach((number) => {
    let dialNumber, dialLink;
    dialNumber = number.replace(" ", "-");
    dialLink = (
      <a href={`tel:+852-${dialNumber}`} key={dialNumber}>
        {number}
      </a>
    );
    dialLinkArray.push(dialLink);
    textWithoutNumber = textWithoutNumber.replace(number, "|");
  });
  textArray = textWithoutNumber
    .split("|")
    .map((text, index) => <span key={`text-${index}`}>{text}</span>);

  let componentArray = [];
  for (let i = 0; i < Math.max(textArray.length, dialLinkArray.length); i++) {
    if (textArray[i]) {
      componentArray.push(textArray[i]);
    }
    if (dialLinkArray[i]) {
      componentArray.push(dialLinkArray[i]);
    }
  }
  return componentArray;
};

//  Screen height and width
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export const getDateStringFromString = (text) => {
  const DateToUse = new Date(text);
  return (
    DateToUse.toLocaleDateString() +
    " " +
    DateToUse.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );
};

export function calculateArraySum(wieghtArray) {
  let sum = 0;

  /*loop over array and add each item to sum
   */
  for (const item of wieghtArray) {
    sum += item;
  }
  // return the result
  return sum;
}

export function addDays(date, daysToAdd) {
  const newDateToUse = new Date(); //today
  newDateToUse.setTime(date.getTime()); //align the time
  newDateToUse.setDate(date.getDate() + daysToAdd);
  return newDateToUse;
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function getDateString(dateStringFromAPI, chineseBoo) {
  try {
    if (chineseBoo) {
      return new Date(dateStringFromAPI).toLocaleDateString('zh-HK', {
        year: 'numeric'
        , month: 'short'
        , day: 'numeric'
        , hour: 'numeric'
        , minute: 'numeric'
        , second: 'numeric'
        , weekday: "long"
      })
    } else {
      return moment(dateStringFromAPI).format("yyyy-MM-DD ddd HH:mm")
    }
  } catch {
    return ""
  }
}

export function getDateStringWithoutTime(dateStringFromAPI, chineseBoo) {
  try {
    if (chineseBoo) {
      return new Date(dateStringFromAPI).toLocaleDateString('zh-HK', {
        year: 'numeric'
        , month: 'short'
        , day: 'numeric'
        , weekday: "long"
      })
    } else {
      return moment(dateStringFromAPI).format("yyyy-MM-DD ddd")
    }
  } catch {
    return ""
  }
}


export function getRandomBgColor(integer) {
  // light = neutral = white = seceondary
  try {
    const colorType = 25;
    switch (integer % colorType) {
      case 0:
        return "bg-primary";
      case 1:
        return "bg-translucent-primary";
      case 2:
        return "bg-info";
      case 3:
        return "bg-translucent-info";
      case 4:
        return "bg-warning";
      case 5:
        return "bg-translucent-warning";
      case 6:
        return "bg-success";
      case 7:
        return "bg-translucent-success";
      case 8:
        return "bg-danger";
      case 9:
        return "bg-translucent-danger";
      case 10:
        return "bg-default";
      case 11:
        return "bg-translucent-default";
      case 12:
        return "bg-blue";
      case 13:
        return "bg-indigo";
      case 14:
        return "bg-red";
      case 15:
        return "bg-purple";
      case 16:
        return "bg-pink";
      case 17:
        return "bg-orange";
      case 18:
        return "bg-yellow";
      case 19:
        return "bg-green";
      case 20:
        return "bg-teal";
      case 21:
        return "bg-cyan";
      case 22:
        return "bg-gray";
      case 23:
        return "bg-maroon";
      case 24:
        return "bg-translucent-light";
      default:
        return "bg-translucent-darker";
    }
  } catch {
    return "bg-translucent-dark"; // also darker // error
  }
}

// return "bg-gradient-primary";
// return "bg-gradient-info";
// return "bg-gradient-warning";
// return "bg-gradient-default";
// return "bg-gradient-blue";
// return "bg-gradient-indigo";
// return "bg-gradient-red";
// return "bg-gradient-purple";
// return "bg-gradient-pink";
// return "bg-gradient-orange";
// return "bg-gradient-yellow";
// return "bg-gradient-green";
// return "bg-gradient-teal";
// return "bg-gradient-cyan";
// return "bg-gradient-gray";
// return "bg-gradient-maroon";


export function getIntegerDecodeFromString(stringToDecode) {
  try {
    const stringToSum = Array.from(stringToDecode).reduce((a, x) => a += x.codePointAt(0), 0);
    return stringToSum;
  } catch (error) {
    return 0;
  }
}



export function getDateDifference(dateSmaller, dateBigger, compareTerm) {
  try {
    let a = moment(dateSmaller);
    let b = moment(dateBigger);

    return b.diff(a, compareTerm); // in second, minute, hour, day, ....
  } catch (error) {
    return 0;
  }
}



// export function lockScrolling(lockUnlockBoo) {
//   try {
//     if (lockUnlockBoo) {
//       document.body.style.overflow = 'hidden';
//     } else {
//       document.body.style.overflow = 'auto';
//     }
//   } catch (error) {
//     document.body.style.overflow = 'auto';
//   }
// }


export function lockScrolling(lockUnlockBoo) {
  lockScrollingfix(lockUnlockBoo);
}

function lockScrollingbackup(lockUnlockBoo) {
  try {
    if (lockUnlockBoo) {
      document.documentElement.style.setProperty('position', 'fixed');
      document.documentElement.style.setProperty('width', '100%');
      document.documentElement.style.setProperty('height', '100%');
      document.documentElement.style.setProperty('overflow', 'auto');

      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
      document.body.style.position = 'fixed';
      document.body.style.left = 0;
      document.body.style.top = 0;
      document.body.style.right = 0;
      document.body.style.bottom = 0;
    } else {
      unlockAllScrolling()
    }
  } catch (error) {
    unlockAllScrolling()

  }
}


function lockScrollingfix(lockUnlockBoo) {
  try {
    if (lockUnlockBoo) {
      scrollYpxGlobal = window.scrollY;

      document.documentElement.style.setProperty('position', 'fixed');
      document.documentElement.style.setProperty('width', '100%');
      document.documentElement.style.setProperty('height', '100%');
       document.documentElement.style.setProperty('overflow', 'hidden');

      document.body.style.overflow = 'auto';
      document.body.style.touchAction = 'none';
      document.body.style.position = 'fixed';
      document.body.style.left = 0;
      document.body.style.top = 0;
      document.body.style.right = 0;
      document.body.style.bottom = 0;

    } else {
      unlockAllScrolling()
    }
  } catch (error) {
    scrollYpxGlobal = 0;
    unlockAllScrolling()

  }
}

let scrollYpxGlobal = 0;


function unlockAllScrolling() {
  try {

    document.documentElement.style.setProperty('position', '');
    document.documentElement.style.setProperty('width', '');
    document.documentElement.style.setProperty('height', '');
    document.documentElement.style.setProperty('overflow', '');

    document.body.style.overflow = '';
    document.body.style.touchAction = '';
    document.body.style.position = '';
    document.body.style.left = '';
    document.body.style.top = '';
    document.body.style.right = '';
    document.body.style.bottom = '';

    
    window.scrollTo(0, scrollYpxGlobal);
  } catch (error) {

  }
}

export function getRefTabData (refTabArray, refTabIDToFind) {
  try {
      if (!refTabArray || !Array.isArray(refTabArray)) {
          return "";
      }

      let itemFound = refTabArray.find((item) => {return item.refTabID === refTabIDToFind});

      if (!itemFound) {
          return "";
      } else {
          return itemFound.refTabData;
      }
  } catch {
      return ""
  }
}



