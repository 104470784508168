import axios from "axios";
import { getToken, isLoggedIn } from "./Auth";
import Notifications from "views/pages/components/Notifications.js";
import getDescendantProp from "./getDescendantProp";

const baseUrl =
  process.env.REACT_APP_ENV !== "Production"
    ? process.env.REACT_APP_API_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_API_BASE_URL_PRODUCTION;

let baseUrlReporting = process.env.REACT_APP_ENV !== "Production"
  ? process.env.REACT_APP_REPORTING_BASE_URL_DEVELOPMENT
  : process.env.REACT_APP_REPORTING_BASE_URL_PRODUCTION;

const api = axios.create({
  baseURL: baseUrl,

  timeout: 10000,
  headers: {
    "Content-Type": "application/json"
    , "Access-Control-Allow-Origin": "*"
  },
  responseType: "json",
  transformRequest: [
    (data, headers) => {
      if (isLoggedIn()) {
        headers["Authorization"] = `Bearer ${getToken()}`;
      }
      return JSON.stringify(data);
    },
  ],
});

const apiReporting = axios.create({
  baseURL: baseUrlReporting,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json"
  },
  responseType: "blob", //20240218 important // blob means file
  transformRequest: [
    (data, headers) => {
      // if (isLoggedIn()) {
      //   headers["Authorization"] = `Bearer ${getToken()}`;
      // }
      return JSON.stringify(data);
    },
  ],
});

export const apiGet = async (prop) => {
  let { url, param, responseType } = prop;
  return await api
    .get(url, param ? param : undefined)
    .then(function (response) {
      return getDescendantProp(response);
    })
    .catch(function (error) {
      let message = '', title = '';
      try {
        message = process.env.REACT_APP_DEBUG
          ? error.response.data.message ||
          error.response.message ||
          error.response.statusText
          : undefined;
        title = process.env.REACT_APP_DEBUG
          ? error.response.data.message ||
          error.response.message ||
          error.response.status
          : undefined;
      } catch (error) {

      }
      Notifications({ type: "error", message: message, title: title });
      return error.response;
    });
};

export const apiPost = async (prop) => {
  let { url, data, responseType } = prop;
  return await api
    .post(url, data)
    .then(function (response) {
      Notifications({
        type: "store",
        message: response.data.message || response.message,
      });
      return getDescendantProp(response);
    })
    .catch(function (error) {
      let message = process.env.REACT_APP_DEBUG
        ? error.response.data.message ||
        error.response.message ||
        error.response.statusText
        : undefined;
      let title = process.env.REACT_APP_DEBUG
        ? error.response.data.status || "" + " " + error.response.status
        : undefined;
      message =
        title == 404 && process.env.REACT_APP_DEBUG
          ? "Request for this Url:" + url + " Not Responding!"
          : message;
      Notifications({ type: "error", message: message, title: title });
      return error.response;
    });
};

export const apiPut = async (prop) => {
  let { url, data, responseType } = prop;
  return await api
    .put(url, data)
    .then(function (response) {
      Notifications({
        type: "store",
        message: response.data.message || response.message,
      });
      return getDescendantProp(response);
    })
    .catch(function (error) {
      let message = process.env.REACT_APP_DEBUG
        ? error.response.data.message ||
        error.response.message ||
        error.response.statusText
        : undefined;
      let title = process.env.REACT_APP_DEBUG
        ? error.response.data.status || "" + " " + error.response.status
        : undefined;
      message =
        title == 404 && process.env.REACT_APP_DEBUG
          ? "Request for this Url:" + url + " Not Responding!"
          : message;
      Notifications({ type: "error", message: message, title: title });
      return error.response;
    });
};

export const apiDelete = async (prop) => {
  let { url, param, responseType } = prop;
  // param : {params : {goodID: XXX, storeID: YYY }}
  return await api
    .delete(url, param)
    .then(function (response) {
      Notifications({
        type: "delete",
        message: response.data.message || response.message,
      });
      return getDescendantProp(response);
    })
    .catch(function (error) {
      let message = process.env.REACT_APP_DEBUG
        ? error.response.data.message ||
        error.response.message ||
        error.response.statusText
        : undefined;
      let title = process.env.REACT_APP_DEBUG
        ? error.response.data.message ||
        error.response.message ||
        error.response.status
        : undefined;
      message =
        title == 404 && process.env.REACT_APP_DEBUG
          ? "Request for this Url:" + url + " Not Responding!"
          : message;
      Notifications({ type: "error", message: message, title: title });
      return error.response;
    });
};


export const apiPostReporting = async (prop) => {
  let { url, data, responseType, enableNotification } = prop;

  return await apiReporting
    .post(url, data)
    .then(function (response) {
      if (enableNotification) {
        Notifications({
          type: "store",
          message: response.data.message || response.message,
        });
      }
      return getDescendantProp(response);
    })
    .catch(function (error) {
      let message = process.env.REACT_APP_DEBUG
        ? error.response.data.message ||
        error.response.message ||
        error.response.statusText
        : undefined;
      let title = process.env.REACT_APP_DEBUG
        ? error.response.data.status || "" + " " + error.response.status
        : undefined;
      message =
        title == 404 && process.env.REACT_APP_DEBUG
          ? "Request for this Url:" + url + " Not Responding!"
          : message;
      Notifications({ type: "error", message: message, title: title });
      return error.response;
    });
};


export default api;
