import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Label,
    Col,
    Badge
} from "reactstrap";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { configQuotationMessage } from "functions/Quotation.js";
import { getQuotationDataJson } from "functions/Quotation.js";
import { getQuotationPDF } from "functions/Quotation.js";

import { goToUri, isEmpty, openPDFLink } from "../../../functions/Utility";

// customerData
// invoiceRemark
// invoiceSubitemList
function QuotationMessageModal(props) {
    const { t } = useTranslation();
    const [quotationMessage, setQuotationMessage] = React.useState("");

    const [quotationMessageIncludeCustomerData, setQuotationMessageIncludeCustomerData] = React.useState(true);
    const [quotationMessageIncludeSubtotal, setQuotationMessageIncludeSubtotal] = React.useState(true);
    const [quotationMessageIncludeDeliveryRecordShoppingCartBoo, setQuotationMessageIncludeDeliveryRecordShoppingCartBoo] = React.useState(true);

    const [actionCopyDone, setActionCopyDone] = React.useState(false);
    const [actionDownloadDone, setActionDownloadDone] = React.useState(false);
    const [actionDownloadInprogressDone, setActionDownloadInProgressDone] = React.useState(false);


    const [customerData, setCustomerData] = React.useState({});
    const [invoiceRemark, setInvoiceRemark] = React.useState("");
    const [invoicesSubitemList, setInvoicesSubitemList] = React.useState([]);
    const [deliveryRecordShoppingCartList, setDeliveryRecordShoppingCartList] = React.useState([]);

    //props.show means this form is triggered by parent form
    React.useEffect(() => {
        if (props.show) {

            // Set Customer
            setCustomerData(props.customerData);
            // invoice detail
            setInvoiceRemark(props.invoiceRemark);
            // invoiceSubitem
            setInvoicesSubitemList(props.invoiceSubitemList);
            // deliveryRecordShoppingCart
            setDeliveryRecordShoppingCartList(props.deliveryRecordShoppingCartList);
        } else {
            clearForm()
        }
    }, [props.show]);

    React.useEffect(() => {

        let configMessage = configQuotationMessage(customerData, invoiceRemark, invoicesSubitemList, deliveryRecordShoppingCartList
            , quotationMessageIncludeCustomerData, quotationMessageIncludeSubtotal, quotationMessageIncludeDeliveryRecordShoppingCartBoo);

        setQuotationMessage(configMessage);
    }, [customerData, invoiceRemark, invoicesSubitemList, deliveryRecordShoppingCartList, quotationMessageIncludeCustomerData, quotationMessageIncludeSubtotal, quotationMessageIncludeDeliveryRecordShoppingCartBoo]);

    const clearForm = () => {
        setCustomerData({});
        setInvoiceRemark("");
        setInvoicesSubitemList([]);
        setDeliveryRecordShoppingCartList([]);
        setActionCopyDone(false);
        setActionDownloadDone(false);
    }

    const setPDFLink = async () => {
        let resultJSON = await getQuotationDataJson(quotationMessage);
        let resultPDF = await getQuotationPDF(resultJSON);
        let customerName = customerData.customerName;
        openPDFLink(URL.createObjectURL(resultPDF), t('Quotation') + '-' + customerName);
    }

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader >{props.title}</ModalHeader>
                <ModalBody className="mt--4">
                    
                    <Col md={6} lg={6}>
                        <Label className="form-control-label mt-4">
                            {t("Invoice Message")}
                        </Label>
                    </Col>
                    <Col>
                        <InputTypeTextForm
                            label=""
                            labelFor="invoiceMessage"
                            inputName="invoiceMessage"
                            inputId="invoiceMessage"
                            inputPlaceholder={t("Quotation message")}
                            type="textarea"
                            value={quotationMessage}
                            handleChange={(e) => { setQuotationMessage(e.target.value) }}
                        />


                        <div className="d-flex mt-1">
                            <Button
                                className="flex-fill"
                                color={quotationMessageIncludeCustomerData ? "success" : "danger"}
                                onClick={() => { setQuotationMessageIncludeCustomerData(!quotationMessageIncludeCustomerData) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {quotationMessageIncludeCustomerData ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Customer info")}</span>
                            </Button>
                            <Button
                                className="flex-fill"
                                color={quotationMessageIncludeSubtotal ? "success" : "danger"}
                                onClick={() => { setQuotationMessageIncludeSubtotal(!quotationMessageIncludeSubtotal) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {quotationMessageIncludeSubtotal ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Subtotal")}</span>
                            </Button>
                        </div>

                        <div className="d-flex flex-column align-items-right mt-1" >
                            <Button
                                color={actionCopyDone ? "success" : "primary"}
                                onClick={() => {
                                    navigator.clipboard.writeText(quotationMessage);
                                    setActionCopyDone(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy")}</span>
                            </Button>
                        </div>
                        <div className="d-flex flex-column align-items-right mt-1" >
                            <Button
                                color={actionDownloadDone ? "success" : (actionDownloadInprogressDone ? "warning" : "primary")}
                                onClick={async () => {
                                    setActionDownloadInProgressDone(true);
                                    await setPDFLink();
                                    setActionDownloadInProgressDone(false);
                                    setActionDownloadDone(true);
                                }} >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionDownloadDone ? t("Downloaded PDF") : (actionDownloadInprogressDone ? t("Download in progress") : t("Download PDF"))}</span>
                            </Button>
                        </div>
                    </Col>

                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default QuotationMessageModal;
