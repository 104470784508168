import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Card,
  CardHeader,
  CardBody,
  Input,
  Collapse,
  Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
// core components
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";
import { addDays } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";

import { getInvoiceStatusArray } from "functions/Invoice.js";
import { getMembershipTypeNameArray } from "functions/Membership.js";
//props

// props.initMembershipListData
// prpos.returnShowCard
// props.returnFilteredSortedMembershipListData
// prpos.createMembership


function MembershipSearchBar(props) {
  const { t } = useTranslation();


  const [refreshTable, setRefreshTable] = React.useState(0);

  const [initMembershipListData, setInitMembershipListData] = React.useState([]);
  const [filteredSortedMembershipListData, setFilteredSortedMembershipListData] = React.useState([]);


  // Filter
  const [useSortPrice, setUseSortPrice] = React.useState(false);
  const [useSortDate, setUseSortDate] = React.useState(false);
  const [useSortInvoiceStatus, setUseSortInvoiceStatus] = React.useState(false);
  const [useSortMembershipTypeName, setUseSortMembershipTypeName] = React.useState(false);
  const [useSortInvoiceSubitemConnection, setUseSortInvoiceSubitemConnection] = React.useState(false);


  // Filter Sort
  const [sortPriceSmall, setSortPriceSmall] = React.useState(false);
  const [sortDateSmall, setSortDateSmall] = React.useState(true);
  const [sortInvoiceStatusSmall, setSortInvoiceStatusSmall] = React.useState(false);
  const [sortMembershipTypeNameSmall, setSortMembershipTypeNameSmall] = React.useState(false);
  const [sortInvoiceSubitemConnectionSmall, setSortInvoiceSubitemConnectionSmall] = React.useState(false);

  // For filter invoiceStatusArray
  // In a structure [{id:"paid",text:"paid",isChecked:true/false}]
  const [invoiceStatusChooseData, setInvoiceStatusChooseData] = React.useState([]);
  const [invoiceSubitemConnectionChooseData, setInvoiceSubitemConnectionChooseData] = React.useState([]);

  // For filter invoiceStatusArray
  // In a structure [{id:"paid",text:"paid",isChecked:true/false}]
  const [membershipTypeNameChooseData, setMembershipTypeNameChooseData] = React.useState([]);

  // GUI
  const [showFullCard, setShowFullCard] = React.useState(false);
  const [showSortingButton, setShowSortingButton] = React.useState(false);

  // For date search
  const [useStartDate, setUseStartDate] = React.useState(true);
  const [useEndDate, setUseEndDate] = React.useState(true);
  const [searchStartDate, setSearchStartDate] = React.useState(new Date());
  const [searchEndDate, setSearchEndDate] = React.useState(new Date());

  // For filter control show
  const [showFilterInvoiceDateTimeRange, setShowFilterInvoiceDateTimeRange] = React.useState(false);
  const [showFilterInvoiceStatusArray, setShowFilterInvoiceStatusArray] = React.useState(false);
  const [showFilterInvoiceSubitemConnectionArray, setShowFilterInvoiceSubitemConnectionArray] = React.useState(false);
  const [showFilterMembershipTypeName, setShowFilterMembershipTypeName] = React.useState(false);


  // For control
  const [expandFilter, setExpandFilter] = React.useState(false);

  React.useEffect(() => {
    const getInvoiceStatusArrayFromFunction = async () => {
      let invoiceStatusArray = getInvoiceStatusArray();
      // Add a value isChecked
      if (invoiceStatusArray && Object.values(invoiceStatusArray).length >= 1) {
        invoiceStatusArray.forEach((item, index) => {
          item.isChecked = true; // default a true
        })
        setInvoiceStatusChooseData(invoiceStatusArray);
      } else {
        setInvoiceStatusChooseData([]);
      }
    };

    const getMembershipTypeNameArrayFromFunction = async () => {
      let MembershipTymeNameArray = await getMembershipTypeNameArray();
      // Add a value isChecked
      if (MembershipTymeNameArray && Object.values(MembershipTymeNameArray).length >= 1) {
        MembershipTymeNameArray.forEach((item, index) => {
          item.isChecked = true; // default a true
        })
        setMembershipTypeNameChooseData(MembershipTymeNameArray);
      } else {
        setMembershipTypeNameChooseData([]);
      }
    };

    const configInvoiceSubitemConnectionArray = async () => {
      let targetChooseArray = [
        {
          id: "connected"
          , text: "Connected"
          , isChecked: true
        }, {
          id: "not connected"
          , text: "Not connected"
          , isChecked: true
        },
      ];
      setInvoiceSubitemConnectionChooseData(targetChooseArray);
    };

    // Only do once
    getInvoiceStatusArrayFromFunction();
    // Only do once
    getMembershipTypeNameArrayFromFunction();
    // Only do once
    configInvoiceSubitemConnectionArray();
  }, []);


  // showFilterDriver , showFilterCarID ... etc. are multually exclusive
  // Process in same useEffect function
  React.useEffect(() => {
    const enableBgColor = showFilterInvoiceStatusArray || showFilterInvoiceDateTimeRange || showFilterMembershipTypeName || showFilterInvoiceSubitemConnectionArray;
    props.changeCardColor(enableBgColor, showFilterInvoiceStatusArray, showFilterInvoiceDateTimeRange, showFilterMembershipTypeName, showFilterInvoiceSubitemConnectionArray);
  }, [showFilterInvoiceStatusArray, showFilterInvoiceDateTimeRange, showFilterMembershipTypeName, showFilterInvoiceSubitemConnectionArray]);


  React.useEffect(() => {
    // do an inital sort
    searchDataMain("", "");
  }, [initMembershipListData, refreshTable]);


  React.useEffect(() => {
    setInitMembershipListData(props.initMembershipListData);
  }, [props.initMembershipListData]);

  React.useEffect(() => {
    // this is the key return
    props.returnFilteredSortedMembershipListData(filteredSortedMembershipListData);
  }, [filteredSortedMembershipListData]);

  React.useEffect(() => {
    // this is the key return
    props.returnShowFullCard(showFullCard);
  }, [showFullCard]);


  const countInvoiceStatusOn = () => {
    let counter = 0;
    if (invoiceStatusChooseData && invoiceStatusChooseData.length >= 1 && Object.values(invoiceStatusChooseData).length >= 1) {
      invoiceStatusChooseData.forEach((item) => {
        if (item.isChecked) {
          counter++;
        }
      })
    }
    return counter;
  }

  const countMembershipTypeNameOn = () => {
    let counter = 0;
    if (membershipTypeNameChooseData && membershipTypeNameChooseData.length >= 1 && Object.values(membershipTypeNameChooseData).length >= 1) {
      membershipTypeNameChooseData.forEach((item) => {
        if (item.isChecked) {
          counter++;
        }
      })
    }
    return counter;
  }


  const countInvoiceSubitemConnectionOn = () => {
    let counter = 0;

    if (invoiceSubitemConnectionChooseData && invoiceSubitemConnectionChooseData.length >= 1 && Object.values(invoiceSubitemConnectionChooseData).length >= 1) {
      invoiceSubitemConnectionChooseData.forEach((item) => {
        if (item.isChecked) {
          counter++;
        }
      })
    }
    return counter;
  }

  const closeAllFilterControl = () => {

    setShowFilterInvoiceDateTimeRange(false);
    setShowFilterInvoiceStatusArray(false);
    setShowFilterMembershipTypeName(false);
    setShowFilterInvoiceSubitemConnectionArray(false);
  }


  const resetFilter = () => {

    setSearchStartDate(new Date());
    setSearchEndDate(new Date());

    resetAllUseSort();
    resetAllSortOrder();

    try {
      let tempData_4 = invoiceStatusChooseData;
      tempData_4.forEach(item => item.isChecked = true);
      setInvoiceStatusChooseData([...tempData_4]);
    } catch (error) {
    }

    setRefreshTable(refreshTable + 1);
  };

  const filterFuncInvoiceStatusArray = (data) => {

    try {
      // If the invoice subitem is not connected
      // return true, later will filter it
      if (!data?.MembershipInvoiceSubitemMatch) {
        return true;
      }

      // Cross check with invoiceStatusArray
      return invoiceStatusChooseData.findIndex((item) => { return (item.id === data?.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus) && (item.isChecked) }) != -1;
    } catch (error) {
      return true;
    }
  };

  const filterFuncInvoiceSubitemConnectionArray = (data) => {

    try {
      if (data?.MembershipInvoiceSubitemMatch) {
        return invoiceSubitemConnectionChooseData.findIndex((item) => { return ((item.id === 'connected') && (item.isChecked)) }) != -1;
      }
      if (!data?.MembershipInvoiceSubitemMatch) {
        return invoiceSubitemConnectionChooseData.findIndex((item) => { return ((item.id === 'not connected') && (item.isChecked)) }) != -1;
      }
    } catch (error) {
      return true;
    }
  };

  const filterFuncMembershipTypeNameArray = (membershipItemData) => {

    try {
      // Cross check with membershipTypeName
      return membershipTypeNameChooseData.findIndex((item) => {
        return (item.text == membershipItemData?.MembershipType?.membershipTypeName) && (item.isChecked);
      }) != -1;
    } catch (error) {
      return true;
    }
  };

  const searchDataMain = (searchType, searchValue, searchValue2 = null) => {
    const value = searchValue;
    const value2 = searchValue2;
    let res = [];
    let searchStartDate_ = searchStartDate;
    let searchEndDate_ = searchEndDate;
    if (searchType === "startDate") {
      setSearchStartDate(value);
      searchStartDate_ = value;
    } else if (searchType === "endDate") {
      setSearchEndDate(value);
      searchEndDate_ = value;
    } else if (searchType === "period") {
      setSearchStartDate(value);
      searchStartDate_ = value;
      setSearchEndDate(value2);
      searchEndDate_ = value2;
    }

    const filterFuncStartDate = (data) => {
      if (searchStartDate_ !== "") {
        const extDate = new Date(data.effectiveDate);
        const filterDate = new Date(searchStartDate_);
        filterDate.setHours(0, 0, 0);
        return extDate >= filterDate;
      } else {
        return true;
      }
    };
    const filterFuncEndDate = (data) => {
      if (searchEndDate_ !== "") {
        const extDate = new Date(data.effectiveDate);
        const filterDate = new Date(searchEndDate_);
        filterDate.setHours(23, 59, 59);
        return extDate <= filterDate;
      } else {
        return true;
      }
    };

    res = initMembershipListData;

    res = res.filter(filterFuncInvoiceStatusArray);

    res = res.filter(filterFuncMembershipTypeNameArray);

    res = res.filter(filterFuncInvoiceSubitemConnectionArray);

    if (useStartDate) {
      res = res.filter(filterFuncStartDate);
    }
    if (useEndDate) {
      res = res.filter(filterFuncEndDate);
    }

    if (useSortPrice) {
      res = sortPriceWithOutput(res);
    } else if (useSortDate) {
      res = sortDateWithOutput(res);
    } else if (useSortInvoiceStatus) {
      res = sortInvoiceStatusWithOutput(res);
    } else if (useSortMembershipTypeName) {
      res = sortMemberTypeNameWithOutput(res);
    } else if (useSortInvoiceSubitemConnection) {
      res = sortInvoiceSubitemConnectionWithOutput(res);
    }

    setFilteredSortedMembershipListData(res);
  };


  const sortPrice = (refreshBoo) => {
    resetAllUseSort();
    setUseSortPrice(true);
    setSortPriceSmall(!sortPriceSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  const sortDate = (refreshBoo) => {
    resetAllUseSort();
    setUseSortDate(true);
    setSortDateSmall(!sortDateSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  const sortInvoiceStatus = (refreshBoo) => {
    resetAllUseSort();
    setUseSortInvoiceStatus(true);
    setSortInvoiceStatusSmall(!sortInvoiceStatusSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  const sortInvoiceSubitemConnection = (refreshBoo) => {
    resetAllUseSort();
    setUseSortInvoiceSubitemConnection(true);
    setSortInvoiceSubitemConnectionSmall(!sortInvoiceSubitemConnectionSmall);
    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  const sortMembershipTypeName = (refreshBoo) => {
    resetAllUseSort();
    setUseSortMembershipTypeName(true);
    setSortMembershipTypeNameSmall(sortMembershipTypeNameSmall);

    if (refreshBoo) {
      setRefreshTable(refreshTable + 1);
    }
  };

  // take in data, sort and return
  const sortInvoiceStatusWithOutput = (dataToSort) => {

    if (!dataToSort) {
      return []; //return a empty array
    }
    let res = [];
    const smallBigControl = sortInvoiceStatusSmall ? 1 : -1;
    res = dataToSort.sort(
      (a, b) => {
        try {
          const nameA = a.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus.toUpperCase(); // ignore upper and lowercase
          const nameB = b.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1 * smallBigControl;
          }
          if (nameA > nameB) {
            return 1 * smallBigControl;
          }
          // names must be equal
          return 0;
        } catch (error) {
          return 0;
        }
      }
    );
    return res;
  };

  // take in data, sort and return
  const sortMemberTypeNameWithOutput = (dataToSort) => {
    if (!dataToSort) {
      return []; //return a empty array
    }
    let res = [];
    const smallBigControl = sortMembershipTypeNameSmall ? 1 : -1;
    res = dataToSort.sort(
      (a, b) => {
        const nameA = a.membershipTypeName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.membershipTypeName.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1 * smallBigControl;
        }
        if (nameA > nameB) {
          return 1 * smallBigControl;
        }
        // names must be equal
        return 0;
      }
    );
    return res;
  };

  // take in data, sort and return
  const sortInvoiceSubitemConnectionWithOutput = (dataToSort) => {

    if (!dataToSort) {
      return []; //return a empty array
    }
    let res = [];
    const smallBigControl = sortInvoiceSubitemConnectionSmall ? 1 : -1;
    res = dataToSort.sort(
      (a, b) => {
        try {
          const invoiceSubitemConnectedBoo = a.MembershipInvoiceSubitemMatch; 
          if (invoiceSubitemConnectedBoo ) {
            return -1 * smallBigControl;
          }
          if (!invoiceSubitemConnectedBoo) {
            return 1 * smallBigControl;
          }

          return 0;
        } catch (error) {
          return 0;
        }
      }
    );
    return res;
  };

  const resetAllUseSort = () => {
    setUseSortPrice(false);
    setUseSortDate(false);
    setUseSortInvoiceStatus(false);
    setUseSortMembershipTypeName(false);
    setUseSortInvoiceSubitemConnection(false);
  }

  const resetAllSortOrder = () => {
    setSortDateSmall(false);
    setSortPriceSmall(false);
    setSortInvoiceStatusSmall(false);
    setSortMembershipTypeNameSmall(false);
  }

  // take in data, sort and return
  const sortDateWithOutput = (invoiceDataToSort) => {
    if (!invoiceDataToSort) {
      return []; //return a empty array
    }

    // setSortPriceSmall(true);
    let res = [];
    if (!sortDateSmall) {
      res = invoiceDataToSort.sort(
        (a, b) => {
          return new Date(a.invoiceRecordDateTime) - new Date(b.invoiceRecordDateTime)
        }
      );
    } else {
      res = invoiceDataToSort.sort(
        (a, b) => {
          return new Date(b.invoiceRecordDateTime) - new Date(a.invoiceRecordDateTime)
        });
    }
    return res;
  };

  // take in data, sort and return
  const sortPriceWithOutput = (invoiceDataToSort) => {
    if (!invoiceDataToSort) {
      return []; //return a empty array
    }

    //setSortDateSmall(true);
    let res = [];
    if (!sortPriceSmall) {
      res = invoiceDataToSort.sort(
        (a, b) =>
          a.invoiceNetPrice - b.invoiceNetPrice
      );
    } else {
      res = invoiceDataToSort.sort(
        (a, b) =>
          b.invoiceNetPrice - a.invoiceNetPrice
      );
    }

    return res;
  };

  return (
    <Card className="mb--2">
      <CardHeader
        role="tab"
        aria-expanded={expandFilter}>
        <Row>
          <Col className="col-auto mt-1 pr-0">
            {/* <Button
              className="mt-1"
              onClick={() => {
                if (showFilterSearch && expandFilter) {
                  setShowFilterSearch(!showFilterSearch);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterSearch && !expandFilter) {
                  setShowFilterSearch(!showFilterSearch);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterSearch && expandFilter) {
                  //Close the other

                  closeAllFilterControl();
                  setShowFilterSearch(true);
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterSearch ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterSearch ? "up" : "down"}`} />{" "}{t("Search")}
              {searchString && searchString !== "" && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
            </Button> */}

            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterInvoiceDateTimeRange && expandFilter) {
                  setShowFilterInvoiceDateTimeRange(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceDateTimeRange && !expandFilter) {
                  setShowFilterInvoiceDateTimeRange(true);
                  sortDate(true); // auto follow a sort
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceDateTimeRange && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterInvoiceDateTimeRange(true);
                  sortDate(true); // auto follow a sort
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterInvoiceDateTimeRange ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && setShowFilterInvoiceDateTimeRange ? "up" : "down"}`} />{"  "}{t("Date")}
              {(useStartDate || useEndDate) && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
            </Button>



            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterInvoiceStatusArray && expandFilter) {
                  setShowFilterInvoiceStatusArray(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceStatusArray && !expandFilter) {
                  setShowFilterInvoiceStatusArray(true);
                  sortInvoiceStatus(true); // auto follow a sort
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceStatusArray && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterInvoiceStatusArray(true);
                  sortInvoiceStatus(true); // auto follow a sort
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterInvoiceStatusArray ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterInvoiceStatusArray ? "up" : "down"}`} />{t("Invoice")}
              {/* {(countInvoiceStatusOn() != 0) || " "} */}
              {countInvoiceStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countInvoiceStatusOn()}</Badge>}

            </Button>
            {/* ********************************************* */}
            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterInvoiceSubitemConnectionArray && expandFilter) {
                  setShowFilterInvoiceSubitemConnectionArray(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceSubitemConnectionArray && !expandFilter) {
                  setShowFilterInvoiceSubitemConnectionArray(true);
                  sortInvoiceSubitemConnection(true); // auto follow a sort

                  setExpandFilter(!expandFilter);
                }
                if (!showFilterInvoiceSubitemConnectionArray && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterInvoiceSubitemConnectionArray(true);
                  sortInvoiceSubitemConnection(true); // auto follow a sort
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterInvoiceSubitemConnectionArray ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterInvoiceSubitemConnectionArray ? "up" : "down"}`} />{t("Invoice connection")}
              {/* {(countInvoiceStatusOn() != 0) || " "} */}
              {countInvoiceSubitemConnectionOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countInvoiceSubitemConnectionOn()}</Badge>}

            </Button>

            <Button
              className="mt-1"
              onClick={() => {
                if (showFilterMembershipTypeName && expandFilter) {
                  setShowFilterMembershipTypeName(false);
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterMembershipTypeName && !expandFilter) {
                  setShowFilterMembershipTypeName(true);
                  sortMembershipTypeName(true); // auto follow a sort
                  setExpandFilter(!expandFilter);
                }
                if (!showFilterMembershipTypeName && expandFilter) {
                  //Close the other
                  closeAllFilterControl();
                  setShowFilterMembershipTypeName(true);
                  sortMembershipTypeName(true); // auto follow a sort  
                  // setExpandFilter(true);
                }
              }}
              color={expandFilter && showFilterMembershipTypeName ? "default" : "primary"}>
              <i className={`fa fa-angle-${expandFilter && showFilterMembershipTypeName ? "up" : "down"}`} />{t("Type")}
              {countMembershipTypeNameOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countMembershipTypeNameOn()}</Badge>}

            </Button>
          </Col>

          <Col className="col-auto mt-1 pr-0">
            <Button
              onClick={() => { setShowFullCard(!showFullCard) }}
              className="mt-1"
              color={"info"}>
              <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
            </Button>


            <Button
              className="mt-1"
              onClick={() => { setShowSortingButton(!showSortingButton) }}
              color="info">
              <i className={`fas fa-sort-numeric-${showSortingButton ? "up" : "down"}`} />{t("Sort")}
            </Button>

            <Button
              className="mt-1"
              color="danger"
              id="tooltip443412081"
              onClick={(e) => {
                e.preventDefault();
                resetFilter();
              }}
              size="md"
            >
              <span className="btn-inner--text">
                {t("Reset Filter")}
              </span>
            </Button>
            <UncontrolledTooltip delay={1} target="tooltip443412081">
              {t("Reset Filter")}
            </UncontrolledTooltip>

          </Col>
          <Col className="col-auto mt-1">
            <Button
              className="mt-1"
              color="success"
              id="tooltip443412089"
              onClick={(e) => {
                props.createMembership();
              }}
              size="md"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">{t("Add")}</span>
            </Button>
            <UncontrolledTooltip delay={1} target="tooltip443412089">
              {t("Add")}
            </UncontrolledTooltip>

          </Col>
        </Row>
        {showSortingButton &&
          <Row>
            <Col className="col-auto mt-1 pr-0" >
              <Button
                className="mt-1"
                onClick={() => sortInvoiceStatus(true)}
                color={useSortInvoiceStatus ? "default" : "info"}>
                <i className={`fas fa-sort-numeric-${sortInvoiceStatusSmall ? "up" : "down"}`} />{t("Invoice")}
              </Button>

              <Button
                className="mt-1"
                onClick={() => sortPrice(true)}
                color={useSortPrice ? "default" : "info"}>
                <i className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"}`} />{t("Price")}
              </Button>

              <Button
                className="mt-1"
                onClick={() => sortDate(true)}
                color={useSortDate ? "default" : "info"}>
                <i className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"}`} />{t("Date")}
              </Button>


            </Col>
          </Row>
        }


      </CardHeader>
      <Collapse
        role="tabpanel"
        isOpen={expandFilter}
      >
        <CardBody>

          {showFilterInvoiceDateTimeRange &&
            <>
              <Row>
                <Col>
                  <InputDateGroupCombo
                    labelFor="invoiceRecordDateTime"
                    inputName="invoiceRecordDateTime"
                    inputId="invoiceRecordDateTime"
                    useStartDate={useStartDate}
                    useEndDate={useEndDate}
                    changeUseStartDate={(boo) => { setUseStartDate(boo); setRefreshTable(refreshTable + 1); }}
                    changeUseEndDate={(boo) => { setUseEndDate(boo); setRefreshTable(refreshTable + 1); }}
                    startDate={searchStartDate}
                    endDate={searchEndDate}
                    changeStartDate={(date) => { searchDataMain("startDate", date); }}
                    changeEndDate={(date) => { searchDataMain("endDate", date); }}
                    changePeriod={(startDate, endDate) => {
                      searchDataMain("period", startDate, endDate);
                    }}
                  >
                  </InputDateGroupCombo>
                </Col>
              </Row>
            </>
          }

          {showFilterInvoiceStatusArray &&
            <Row className="mt-2 ml-1">
              {invoiceStatusChooseData.map((invoiceStatusItem, index) => (
                <FilterBadge
                  key={index}
                  textToShow={t(invoiceStatusItem.text)}
                  isChecked={invoiceStatusItem.isChecked}
                  setIsChecked={(isChecked) => {
                    let selectedItemIndex = invoiceStatusChooseData.findIndex((item) => { return item.id === invoiceStatusItem.id });
                    if (selectedItemIndex != -1) {
                      try {
                        invoiceStatusChooseData[selectedItemIndex].isChecked = isChecked;
                        setInvoiceStatusChooseData([...invoiceStatusChooseData]);
                      } catch (error) {
                      }
                    }
                    searchDataMain("", "");
                  }}
                />
              )
              )
              }
            </Row>
          }

          {showFilterInvoiceSubitemConnectionArray &&
            <Row className="mt-2 ml-1">
              {invoiceSubitemConnectionChooseData.map((invoiceSubitemConnectionItem, index) => (
                <FilterBadge
                  key={index}
                  textToShow={t(invoiceSubitemConnectionItem.text)}
                  isChecked={invoiceSubitemConnectionItem.isChecked}
                  setIsChecked={(isChecked) => {
                    let selectedItemIndex = invoiceSubitemConnectionChooseData.findIndex((item) => { return item.id === invoiceSubitemConnectionItem.id });
                    if (selectedItemIndex != -1) {
                      try {
                        invoiceSubitemConnectionChooseData[selectedItemIndex].isChecked = isChecked;
                        setInvoiceSubitemConnectionChooseData([...invoiceSubitemConnectionChooseData]);
                      } catch (error) {
                      }
                    }
                    searchDataMain("", "");
                  }}
                />
              )
              )
              }
            </Row>
          }


          {showFilterMembershipTypeName &&
            <Row className="mt-2 ml-1">
              {membershipTypeNameChooseData.map((membershipTypeNameItem, index) => (
                <FilterBadge
                  key={index}
                  textToShow={t(membershipTypeNameItem.text)}
                  isChecked={membershipTypeNameItem.isChecked}
                  setIsChecked={(isChecked) => {
                    let selectedItemIndex = membershipTypeNameChooseData.findIndex((item) => { return item.id === membershipTypeNameItem.id });
                    if (selectedItemIndex != -1) {
                      try {
                        membershipTypeNameChooseData[selectedItemIndex].isChecked = isChecked;
                        setMembershipTypeNameChooseData([...membershipTypeNameChooseData]);
                      } catch (error) {
                      }
                    }
                    searchDataMain("", "");
                  }}
                />
              )
              )
              }
            </Row>
          }

        </CardBody>
      </Collapse>
    </Card>
  );
}

export default MembershipSearchBar;
