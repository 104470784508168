import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDateString } from "functions/Utility";
//props
//membershipData
//badgeType :string
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
// useTranlate


function MembershipBadge(props) {

    const { t } = useTranslation();
    const membershipData = props.membershipData;
    const badgeType = props.badgeType;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";


    function textToShow() {
        if (!membershipData || Object.values(membershipData).length == 0) {
            return "";
        }

        switch (badgeType) {
            case 'invoiceStatus':
                return `${t(membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus).toUpperCase()}`;
            case 'invoiceSubitemConnection':
                return `${t(membershipData.MembershipInvoiceSubitemMatch ? "Connected" : "Not connected")}`;
            case 'membershipTypeName':
                return `${membershipData.MembershipType?.membershipTypeName}`;
            case 'customerName':
                return `${membershipData.PaymentInvoiceMatches?.length} ` + t("payment");
            case 'effectiveDate':
                return `${membershipData.effectiveDate}`;
            case 'expiryDate':
                return `${membershipData.expiryDate}`;
            case 'price':
                return `${t(membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.unitPrice.toString())}`;
            default:
                return 'N/A';
        }
    };

    function badgeColor() {

        if (!membershipData) {
            return "primary";
        }
        switch (badgeType) {
            case 'invoiceStatus':
                return membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus ?
                    membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus === "Paid"
                        ? "success"
                        : membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus === "In Progress"
                            ? "primary"
                            : membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus === "Cancel"
                                ? "warning"
                                : "danger"
                    : "default";
            case 'invoiceSubitemConnection':
                return membershipData.MembershipInvoiceSubitemMatch ? "success" : "danger";
            case 'deliveryCount':
                return membershipData.DeliveryRecords?.length >= 1
                    ? "info"
                    : "primary";
            case 'paymentCount':
                return membershipData.PaymentInvoiceMatches?.length >= 1
                    ? "success"
                    : "warning"
            default:
                return "primary";
        }
    };

    return (
        <Badge
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
        >
            {textToShow()}
        </Badge>
    );
}

export default MembershipBadge;
