import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";


import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";
import InvoiceRemainingSubitemSelectInput from "../components/InvoiceRemainingSubitemSelectInput.js";

import { uuid4 } from "@sentry/utils";

function DeliveryRecordEditProductModal(props) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  //Initialize Data
  const [goodSelected, setGoodSelected] = React.useState([]);

  //Post Data
  const [subitemToEdit, setSubitemToEdit] = React.useState({
    localDeliverySubitemID: uuid4(),
    deliverySubitemQuantity: 0,
    deliverySubitemRemark: "",
    good: {},
  });

  const { localDeliverySubitemID, deliverySubitemQuantity, deliverySubitemRemark, good } = subitemToEdit;

  const onInputChangeWithValue = (deliverySubitemQuantity, deliverySubitemRemark, good) => {
    let deliverySubitemQuantityToChange;

    if (isNaN(parseFloat(deliverySubitemQuantity))) {
      deliverySubitemQuantityToChange = 0;
    } else {
      deliverySubitemQuantityToChange = parseFloat(deliverySubitemQuantity);
    }

    setSubitemToEdit({
      deliverySubitemQuantity: deliverySubitemQuantityToChange,
      deliverySubitemRemark: deliverySubitemRemark,
      good: good
    });
  };

  const onChangeQuantity = (deliverySubitemQuantityToChange) => {
    if (isNaN(parseFloat(deliverySubitemQuantityToChange))) {
      // To improve user experience
      // Allow a empty string ''
       setSubitemToEdit({ ...subitemToEdit, deliverySubitemQuantity: '' });
    } else {
      setSubitemToEdit({ ...subitemToEdit, deliverySubitemQuantity: parseFloat(deliverySubitemQuantityToChange) });
    }
  };


  const onInputChange = (e) => {
    if (e.target.value == "" && e.target.name == "goodID") {
    } else {
      setSubitemToEdit({ ...subitemToEdit, [e.target.name]: e.target.value });

    }
  };

  React.useEffect(() => {
    if (props.show) {
      if (Object.values(props.selectedSubitem).length > 0) {

        setSubitemToEdit({
          localDeliverySubitemID: props.selectedSubitem.localDeliverySubitemID,
          deliverySubitemQuantity: props.selectedSubitem.deliverySubitemQuantity,
          deliverySubitemRemark: props.selectedSubitem.deliverySubitemRemark,
          good: props.selectedSubitem.good
        });
      } else {
        setSubitemToEdit({
          localDeliverySubitemID: uuid4(),
          deliverySubitemQuantity: 0,
          deliverySubitemRemark: "",
          good: {}
        });
      }
    }

  }, [props.show]);

  const editItemToParent = (e) => {


    // check quantity
    if (deliverySubitemQuantity === "" || isNaN(deliverySubitemQuantity) || isNaN(parseFloat(deliverySubitemQuantity))) {
      Notifications({
        type: "dataCheck",
        message: t("Please input a quantity")
      });
      return;
    }



    props.addToDeliverySubitemsTable(
      {
        localDeliverySubitemID: localDeliverySubitemID,
        deliverySubitemQuantity: parseFloat(deliverySubitemQuantity),
        deliverySubitemRemark: deliverySubitemRemark,
        good: good
      }
    );

    setSubitemToEdit({
      localDeliverySubitemID: uuid4(),
      deliverySubitemQuantity: 0,
      deliverySubitemRemark: "",
      good: {}
    });

    props.onHide();
  };

  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader >{props.title}</ModalHeader>
        <ModalBody>
          <Form>
            <InvoiceRemainingSubitemSelectInput
              invoiceID={props.invoiceData?.invoiceID}
              selectItem={(deliverySubitemQuantity, deliverySubitemRemark, good) => {
                onInputChangeWithValue(
                  deliverySubitemQuantity,
                  deliverySubitemRemark,
                  good)
              }}
              preSelectedProduct={props.selectedSubitem} />

            <InputTypeTextForm
              type="number"
              label={`${t("Quantity")}${"("}${t("Required")}${")"}`}
              labelFor="deliverySubitemQuantity"
              inputName="deliverySubitemQuantity"
              inputId="deliverySubitemQuantity"
              inputPlaceholder={t("Quantity")}
              value={deliverySubitemQuantity}
              handleChange={(e) => onChangeQuantity(e.target.value)}
              required={true}
            />
            <InputTypeTextForm
              label={t("Delivery Subitem Remark")}
              labelFor="deliverySubitemRemark"
              inputName="deliverySubitemRemark"
              inputId="deliverySubitemRemark"
              inputPlaceholder={t("Invoice Item Remark")}
              value={deliverySubitemRemark}
              handleChange={(e) => onInputChange(e)}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => { editItemToParent(e) }} type="button">
            {props.button}
          </Button>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeliveryRecordEditProductModal;
