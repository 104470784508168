/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useHistory } from "react-router-dom";
import { isLoggedIn, login } from "../functions/Auth";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";


import { useTranslation, Trans } from "react-i18next";
// core components
import AuthHeader from "components/Headers/AuthHeaderLogin.js";
import { isMobile } from "react-device-detect";
import NotificationAlert from "react-notification-alert";
import SpinnerOverlay from "./pages/components/SpinnerOverlay";


// import AppPage from '../../../AppPage.js';

function Login() {


  const { t, i18n } = useTranslation();

  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  let history = useHistory();
  const [loginUsername, setLoginUsername] = React.useState(false);
  const [loginPassword, setLoginPassword] = React.useState(false);



  const [isLoading, setIsLoading] = React.useState(false);

  const notificationAlertRef = React.useRef(null);
  const notify = (type) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            <Trans>Invalid Login Credentials</Trans>
          </span>
          <span data-notify="message">
            <Trans>Please enter a correct username and password</Trans>
          </span>
        </div>
      ),
      type: type,
      icon: "fa fa-exclamation",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };


  const alreadyLogin = () => {
    // move to calendar
    // history.push("/work/calendar");
    // // window.location.reload();
    window.location.reload();
  }

  async function onLoginClick() {

    setIsLoading(true);
    let loginTestBoo = await login(loginUsername, loginPassword);
    setIsLoading(false);

    if (loginTestBoo) {
      // login is successful

      alreadyLogin();

    } else {
      notify("danger");
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      await onLoginClick();
    }
  };


  React.useEffect(() => {
    const activeLang = i18n.language;
    // set default language to "cn"
    // for ref, usually the default is zh-HK, change to cn
    if (activeLang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("cn");
    }
  }, []);


  return isLoggedIn() ? (
    alreadyLogin()
  ) : (
    <>
    {isLoading ? <SpinnerOverlay /> : ""}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader
        title="Welcome"
        lead={process.env.REACT_APP_COMPANY_NAME ? process.env.REACT_APP_COMPANY_NAME : t('Good to see you again')}
        isMobiles={isMobile}
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>
                    <Trans>Sign In</Trans>
                  </small>
                </div>
                <Form role="form" onSubmit={onLoginClick}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-circle-08" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("Username")}
                        type="username"
                        name="username"
                        onChange={(e) => setLoginUsername(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t("Password")}
                        type="password"
                        name="password"
                        onChange={(e) => setLoginPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={onLoginClick}
                    >
                      <Trans>Sign In</Trans>
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
