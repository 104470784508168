import React from "react";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Card,
    CardHeader,
    CardBody,
    Input,
    Collapse,
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
// core components
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";
import { addDays } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";
import { getPaymentStatusArray } from "functions/Payment.js";

//props

// props.initpaymentData
// prpos.returnShowCard
// props.returnFilteredSortedPaymentData

function PaymentSearchBar(props) {
    const { t } = useTranslation();

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [filteredSortedpaymentData, setFilteredSortedPaymentData] = React.useState([]);
    const [initPaymentData, setinitPaymentData] = React.useState([]);
    const [searchString, setSearchString] = React.useState("");

    // For filter invoiceStatusArray
    // In a structure [{id:"paid",text:"paid",isChecked:true/false}]
    const [paymentStatusChooseData, setPaymentStatusChooseData] = React.useState([]);

    // For date search
    const [useFilterStartDate, setFilterUseStartDate] = React.useState(true);
    const [useFilterEndDate, setFilterUseEndDate] = React.useState(true);
    const [filterStartDate, setFilterStartDate] = React.useState(new Date());
    const [filterEndDate, setFilterEndDate] = React.useState(new Date());

    // For data communication with the screen
    const [dataRangeStartDate, setDataRangeStartDate] = React.useState(new Date());
    const [dataRangeEndDate, setDataRangeEndDate] = React.useState(new Date());

    // GUI
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [showSortingButton, setShowSortingButton] = React.useState(false);

    // Sorting
    const [useSortPrice, setUseSortPrice] = React.useState(false);
    const [useSortDate, setUseSortDate] = React.useState(false);
    const [useSortPaymentStatus, setUseSortPaymentStatus] = React.useState(false);
    const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
    const [sortDateSmall, setSortDateSmall] = React.useState(true);
    const [sortPaymentStatusSmall, setSortPaymentStatusSmall] = React.useState(false);

    // For filter control show
    const [showFilterSearch, setShowFilterSearch] = React.useState(false);
    const [showFilterPaymentDateTimeRange, setShowFilterPaymentDateTimeRange] = React.useState(false);
    const [showFilterPaymentStatus, setShowFilterPaymentStatus] = React.useState(false);
    const [showFilterPaymentStatusArray, setShowFilterPaymentStatusArray] = React.useState(false);

    // For control
    const [expandFilter, setExpandFilter] = React.useState(false);


    React.useEffect(() => {
        const getPaymentStatusArrayFromFunction = async () => {
            let paymentStatusArray = getPaymentStatusArray();


            // Add a value isChecked
            if (paymentStatusArray && Object.values(paymentStatusArray).length >= 1) {
                paymentStatusArray.forEach((item, index) => {
                    item.isChecked = true; // default a true
                })
                setPaymentStatusChooseData(paymentStatusArray);
            } else {
                setPaymentStatusChooseData([]);
            }
        };
        // Only do once
        getPaymentStatusArrayFromFunction();
    }, []);


    const countPaymentStatusOn = () => {
        let counter = 0;
        if (paymentStatusChooseData && paymentStatusChooseData.length >= 1 && Object.values(paymentStatusChooseData).length >= 1) {
            paymentStatusChooseData.forEach((item) => {
                if (item.isChecked) {
                    counter++;
                }
            })
        }
        return counter;
    }

    // showFilterDriver , showFilterCarID ... etc. are multually exclusive
    // Process in same useEffect function
    React.useEffect(() => {
        const enableBgColor = showFilterPaymentStatusArray || showFilterPaymentDateTimeRange;
        props.changeCardColor(enableBgColor, showFilterPaymentStatusArray, showFilterPaymentDateTimeRange);
    }, [showFilterPaymentStatusArray, showFilterPaymentDateTimeRange]);


    React.useEffect(() => {
        // do an inital sort
        searchDataMain("", "");
    }, [initPaymentData, useFilterStartDate, useFilterEndDate, refreshTable]);


    React.useEffect(() => {
        setinitPaymentData(props.initPaymentData);
    }, [props.initPaymentData]);

    React.useEffect(() => {
        // this is the key return
        props.returnFilteredSortedPaymentData(filteredSortedpaymentData);
    }, [filteredSortedpaymentData]);

    React.useEffect(() => {
        // this is the key return
        props.returnShowFullCard(showFullCard);
    }, [showFullCard]);


    React.useEffect(() => {
        if (!useFilterStartDate || !useFilterEndDate) {
          // any one off 
          // must trigger reload the initial data from the server
          // will ignore the date range in the server
          // The Screen will handle the logic
          props.refreshInitialData(filterStartDate, filterEndDate, useFilterStartDate, useFilterEndDate);
        }
        if (useFilterStartDate && useFilterEndDate) {

          if (filterStartDate > filterEndDate) {
            // error case
            return;
          }
          if (dataRangeStartDate > dataRangeEndDate) {
            // error case
            return;
          }
          // a logic to compare calendarDate and dataDate
          if (dataRangeStartDate <= filterStartDate && filterEndDate <= dataRangeEndDate) {
            // save, go on
            // just do not trigger the data fetching from server
            return;
          }
          if (dataRangeStartDate > filterStartDate || filterEndDate > dataRangeEndDate) {
            // extend the data date range 
            // The Screen will handle the logic
            props.refreshInitialData(filterStartDate, filterEndDate, useFilterStartDate, useFilterEndDate);
          }
        }
      }, [filterStartDate, filterEndDate, useFilterStartDate, useFilterEndDate]);
    
    
      React.useEffect(() => {
        setDataRangeStartDate(props.dataRangeStartDate);
        setDataRangeEndDate(props.dataRangeEndDate);
      }, [props.dataRangeStartDate, props.dataRangeEndDate]);

      
    const closeAllFilterControl = () => {
        setShowFilterSearch(false);
        setShowFilterPaymentDateTimeRange(false);
        setShowFilterPaymentStatus(false);
        setShowFilterPaymentStatusArray(false);
    }

    const resetFilter = () => {
        setSearchString("");

        setFilterUseStartDate(true);
        setFilterUseEndDate(true);
        setFilterStartDate(new Date());
        setFilterEndDate(new Date());

        resetAllUseSort();
        resetAllSortOrder();

        try {
            let tempData_4 = paymentStatusChooseData;
            tempData_4.forEach(item => item.isChecked = true);
            setPaymentStatusChooseData([...tempData_4]);
        } catch (error) {
        }

        setRefreshTable(refreshTable + 1);
    };

    const searchDataMain = (searchType, searchValue, searchValue2 = null) => {

        const value = searchValue;
        const value2 = searchValue2;
        let res = [];
        let searchString_ = searchString;
        let searchStartDate_ = filterStartDate;
        let searchEndDate_ = filterEndDate;
        if (searchType === "search") {
            setSearchString(value);
            searchString_ = value;
        } else if (searchType === "startDate") {
            setFilterStartDate(value);
            searchStartDate_ = value;
        } else if (searchType === "endDate") {
            setFilterEndDate(value);
            searchEndDate_ = value;
        } else if (searchType === "period") {
            setFilterStartDate(value);
            searchStartDate_ = value;
            setFilterEndDate(value2);
            searchEndDate_ = value2;
        }

        const filterFuncString = (data) => {
            if (searchString_ !== "") {
                return (
                    data?.Customer?.customerName.includes(searchString_) ||
                    data?.PaymentInvoiceMatches.some((val) =>
                        val.invoiceID.includes(searchString_)
                    )
                );
            } else {
                return true;
            }
        };
        const filterFuncStartDate = (data) => {
            if (searchStartDate_ !== "") {
                const extDate = new Date(data.paymentRecordDateTime);
                const filterDate = new Date(searchStartDate_);
                filterDate.setHours(0, 0, 0, 0);
                return extDate >= filterDate;
            } else {
                return true;
            }
        };
        const filterFuncEndDate = (data) => {
            if (searchEndDate_ !== "") {
                const extDate = new Date(data.paymentRecordDateTime);
                const filterDate = new Date(searchEndDate_);
                filterDate.setHours(23, 59, 59, 999);
                return extDate <= filterDate;
            } else {
                return true;
            }
        };

        const filterFuncInvoiceStatusArray = (data) => {
            try {
                // Cross check with invoiceStatusArray
                return paymentStatusChooseData.findIndex((item) => { return (item.id === data?.paymentStatus) && (item.isChecked) }) != -1;
            } catch (error) {
                return true;
            }
        };


        if (!initPaymentData || initPaymentData === undefined || Object.values(initPaymentData).length <= 0) {
            return;
        }
        res = initPaymentData.filter(filterFuncString);
        if (useFilterStartDate) {
            res = res.filter(filterFuncStartDate);
        }
        if (useFilterEndDate) {
            res = res.filter(filterFuncEndDate);
        }

        res = res.filter(filterFuncInvoiceStatusArray);

        if (useSortPrice) {
            res = sortPriceWithOutput(res);
        } else if (useSortDate) {
            res = sortDateWithOutput(res);
        } else if (useSortPaymentStatus) {
            res = sortPaymentStatusWithOutput(res);
        }

        setFilteredSortedPaymentData(res);
    };

    const sortPriceWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        //setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = dataToSort.sort(
                (a, b) => a.paymentPrice - b.paymentPrice
            );
        } else {
            res = dataToSort.sort(
                (a, b) => b.paymentPrice - a.paymentPrice
            );
        }
        return res;
    };

    const sortDateWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        //setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = dataToSort.sort(
                (a, b) => new Date(a.paymentRecordDateTime) - new Date(b.paymentRecordDateTime)
            );
        } else {
            res = dataToSort.sort(
                (a, b) => new Date(b.paymentRecordDateTime) - new Date(a.paymentRecordDateTime)
            );
        }
        return res;
    };

    // take in data, sort and return
    const sortPaymentStatusWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        let res = [];
        const smallBigControl = sortPaymentStatusSmall ? 1 : -1;
        res = dataToSort.sort(
            (a, b) => {
                const nameA = a.paymentStatus.toUpperCase(); // ignore upper and lowercase
                const nameB = b.paymentStatus.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1 * smallBigControl;
                }
                if (nameA > nameB) {
                    return 1 * smallBigControl;
                }
                // names must be equal
                return 0;
            }
        );
        return res;
    };


    const sortPrice = (refreshBoo) => {
        resetAllUseSort();
        setUseSortPrice(true);
        setSortPriceSmall(!sortPriceSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    const sortDate = (refreshBoo) => {
        resetAllUseSort();
        setUseSortDate(true);
        setSortDateSmall(!sortDateSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    const sortPayemntStatus = (refreshBoo) => {
        resetAllUseSort();
        setUseSortPaymentStatus(true);
        setSortPaymentStatusSmall(!sortPaymentStatusSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    const resetAllUseSort = () => {
        setUseSortPrice(false);
        setUseSortDate(false);
        setUseSortPaymentStatus(false);
    }

    return (
        <Card className="card-stats mb--2">
            <CardHeader
                role="tab"
                aria-expanded={expandFilter}>
                <Row>
                    <Col className="col-auto mt-1 pr-0">
                        <Button
                            onClick={() => {
                                if (showFilterSearch && expandFilter) {
                                    setShowFilterSearch(false);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterSearch && !expandFilter) {
                                    setShowFilterSearch(true);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterSearch && expandFilter) {
                                    //Close the other

                                    closeAllFilterControl();
                                    setShowFilterSearch(true);
                                    // setExpandFilter(true);
                                }
                            }}
                            className="mt-1"
                            size=""
                            color={expandFilter && showFilterSearch ? "default" : "primary"}>
                            <i className={`fa fa-angle-${expandFilter && showFilterSearch ? "up" : "down"}`} />{" "}{t("Search")}
                            {searchString && searchString !== "" && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
                        </Button>

                        <Button
                            onClick={() => {
                                if (showFilterPaymentDateTimeRange && expandFilter) {
                                    setShowFilterPaymentDateTimeRange(false);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterPaymentDateTimeRange && !expandFilter) {
                                    setShowFilterPaymentDateTimeRange(true);
                                    sortDate(true); // auto follow a sort
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterPaymentDateTimeRange && expandFilter) {
                                    //Close the other
                                    closeAllFilterControl();
                                    setShowFilterPaymentDateTimeRange(true);
                                    sortDate(true); // auto follow a sort
                                    // setExpandFilter(true);
                                }
                            }}
                            className="mt-1"
                            color={expandFilter && showFilterPaymentDateTimeRange ? "default" : "primary"}>
                            <i className={`fa fa-angle-${expandFilter && setShowFilterPaymentDateTimeRange ? "up" : "down"}`} />{"  "}{t("Date")}
                            {(useFilterStartDate || useFilterEndDate) && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
                        </Button>


                        <Button
                            onClick={() => {
                                if (showFilterPaymentStatusArray && expandFilter) {
                                    setShowFilterPaymentStatusArray(false);
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterPaymentStatusArray && !expandFilter) {
                                    setShowFilterPaymentStatusArray(true);
                                    sortPayemntStatus(true); // auto follow a sort
                                    setExpandFilter(!expandFilter);
                                }
                                if (!showFilterPaymentStatusArray && expandFilter) {
                                    //Close the other
                                    closeAllFilterControl();
                                    setShowFilterPaymentStatusArray(true);
                                    sortPayemntStatus(true); // auto follow a sort
                                    // setExpandFilter(true);
                                }
                            }}
                            className="mt-1"
                            color={expandFilter && showFilterPaymentStatusArray ? "default" : "primary"}>
                            <i className={`fa fa-angle-${expandFilter && showFilterPaymentStatusArray ? "up" : "down"}`} />{countPaymentStatusOn() != 0 || " "}{t("Invoice")}
                            {countPaymentStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countPaymentStatusOn()}</Badge>}
                        </Button>
                    </Col>

                    <Col className="col-auto mt-1 pr-0">
                        <Button
                            onClick={() => { setShowFullCard(!showFullCard) }}
                            className="mt-1"
                            color="primary">
                            <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                        </Button>

                        <Button
                            className="mt-1"
                            onClick={() => { setShowSortingButton(!showSortingButton) }}
                            color="info">
                            <i className={`fas fa-sort-numeric-${showSortingButton ? "up" : "down"}`} />{t("Sort")}
                        </Button>

                        <Button
                            className="mt-1"
                            color="danger"
                            id="tooltip443412081"
                            onClick={(e) => {
                                e.preventDefault();
                                resetFilter();
                            }}
                            size="md"
                        >
                            <span className="btn-inner--text">
                                {t("Reset Filter")}
                            </span>
                        </Button>
                        <UncontrolledTooltip delay={1} target="tooltip443412081">
                            {t("Reset Filter")}
                        </UncontrolledTooltip>
                    </Col>

                    <Col className="col-auto mt-1 pr-0">
                        <Button
                            className="mt-1"
                            color="success"
                            id="tooltip443412080"
                            onClick={() => { props.createPayment(); }}
                            size="md"
                        >
                            <span className="btn-inner--icon">
                                <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{t("Add New")}</span>
                        </Button>
                        <UncontrolledTooltip delay={1} target="tooltip443412080">
                            {t("Create")}
                        </UncontrolledTooltip>
                    </Col>

                </Row>
                {showSortingButton &&
                    <Row>
                        <Col className="col-auto mt-1 pr-0" >
                            <Button
                                className="mt-1"
                                onClick={() => sortPrice(true)}
                                color={useSortPrice ? "default" : "info"}>
                                <i className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"}`} /> {t("Price")}
                            </Button>
                            <Button
                                className="mt-1"
                                onClick={() => sortDate(true)}
                                color={useSortDate ? "default" : "info"}>
                                <i className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"}`} />{t("Date")}
                            </Button>
                            <Button
                                className="mt-1"
                                onClick={() => sortPayemntStatus(true)}
                                color={useSortPaymentStatus ? "default" : "info"}>
                                <i className={`fas fa-sort-numeric-${sortPaymentStatusSmall ? "up" : "down"}`} />{t("Invoice")}
                            </Button>
                        </Col>
                    </Row>
                }
            </CardHeader>

            <Collapse
                role="tabpanel"
                isOpen={expandFilter}
            >
                <CardBody>
                    {showFilterSearch &&
                        <Row className="mt-2">
                            <Col
                                className="mt-1"
                                lg={12} md={12} xs={12}
                            >
                                <InputTypeTextForm
                                    label={t("Search")}
                                    labelFor="searchText"
                                    inputName="searchText"
                                    inputId="searchText"
                                    inputPlaceholder={t("Search (Invoice number/Customer/Address/Driver/CarID)")}
                                    value={searchString}
                                    handleChange={(e) => {
                                        e.preventDefault();
                                        searchDataMain("search", e?.target?.value);
                                    }}
                                    readOnly={false}
                                />

                            </Col>
                        </Row>
                    }

                    {showFilterPaymentDateTimeRange &&
                        <>
                            <InputDateGroupCombo
                                labelFor="paymentRecordDateTime"
                                inputName="paymentRecordDateTime"
                                inputId="paymentRecordDateTime"
                                useStartDate={useFilterStartDate}
                                useEndDate={useFilterEndDate}
                                changeUseStartDate={(boo) => { setFilterUseStartDate(boo) }}
                                changeUseEndDate={(boo) => { setFilterUseEndDate(boo) }}
                                startDate={filterStartDate}
                                endDate={filterEndDate}
                                changeStartDate={(date) => { searchDataMain("startDate", date); }}
                                changeEndDate={(date) => { searchDataMain("endDate", date); }}
                                changePeriod={(startDate, endDate) => {
                                    searchDataMain("period", startDate, endDate);
                                }}
                            >
                            </InputDateGroupCombo>
                        </>
                    }

                    {showFilterPaymentStatusArray &&
                        <Row className="mt-2 ml-1">
                            {paymentStatusChooseData.map((invoiceStatusItem, i) => (
                                <FilterBadge
                                    textToShow={t(invoiceStatusItem.text)}
                                    isChecked={invoiceStatusItem.isChecked}
                                    setIsChecked={(isChecked) => {
                                        let selectedItemIndex = paymentStatusChooseData.findIndex((item) => { return item.id === invoiceStatusItem.id });
                                        if (selectedItemIndex != -1) {
                                            try {
                                                paymentStatusChooseData[selectedItemIndex].isChecked = isChecked;
                                                setPaymentStatusChooseData([...paymentStatusChooseData]);
                                            } catch (error) {
                                            }
                                        }
                                        searchDataMain("", "");
                                    }}
                                />
                            )
                            )
                            }
                        </Row>
                    }
                </CardBody>
            </Collapse>
        </Card>
    );
}

export default PaymentSearchBar;
