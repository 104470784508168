



import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { Button, Row, Col } from "reactstrap";

import { combineAddress } from "functions/Delivery";
import { Trans } from "react-i18next";
import { goToUri } from "../../../functions/Utility";

function DeliveryRecordNewGoogleMap(props) {
    const { addressData, onLocationSelected } = props;

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);
    Geocode.setLanguage("zh-HK");
    Geocode.setRegion("HK");

    //address data is from outside 

    //尖沙咀鐘樓 //default
    const initLatitude = 22.2945247;
    const initLongitude = 114.169805;


    const [mapLat, setMapLat] = React.useState(addressData.addressGPSLatitude ? addressData.addressGPSLatitude : initLatitude);
    const [mapLong, setMapLong] = React.useState(addressData.addressGPSLongtitude ? addressData.addressGPSLongtitude : initLongitude);


    const submitLocation = (newLag, newLong) => {
        // For local
        // Techically, the addressData will change to update the mapLng map Long
        // setMapLat(newLag);
        // setMapLong(newLong);
        // For parent addressData
        onLocationSelected(newLag, newLong);
    }


    //addressData will come late coz it takes time from db
    React.useEffect(() => {
        mapMainLogic();
    }, [addressData]);


    const mapMainLogic = async () => {
        //2 cases 
        // 1. there is a valid lag and long in the address data
        // use the data in addressData
        if (addressData.addressGPSLatitude
            && addressData.addressGPSLongtitude
            && addressData.addressGPSLatitude !== 0
            && addressData.addressGPSLongtitude !== 0) {
            setMapLat(addressData.addressGPSLatitude);
            setMapLong(addressData.addressGPSLongtitude);
            return;
        }

        // 2. lag and long in the address data are NOT valid
        // use Geocode to do search
        if (!addressData.addressGPSLatitude
            || !addressData.addressGPSLongtitude
            || addressData.addressGPSLatitude === 0
            || addressData.addressGPSLongtitude === 0) {
            let addressInText = combineAddress(addressData);

            if (addressInText == "") {
                // Not a valid address
                // Do not do search 
            } else {
                // It is a valid address
                // do search 
                // Geocoding API
                const response = await Geocode.fromAddress(combineAddress(addressData));
                if (response.results.length > 0) {
                    const { lat, lng } = response.results[0].geometry.location;
                    setMapLat(lat);
                    setMapLong(lng);
                    // important here
                    // only return when geocode succeeded
                    return;
                } else {
                    // Sentry.captureException(
                    //     new Error("Cannot get location from Google Geocoding API")
                    // );
                }
            }
        }



    }

    const updatePinPointToCurrentPosition = () => {
        if (navigator.geolocation) {
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0,
            };
            // setIsLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    //setCurrentPosition(position.coords);
                    //  if (updateMap) {
                    submitLocation(position.coords.latitude, position.coords.longitude);
                    //  }
                    // setIsLoading(false);
                },
                (error) => {
                    //   let errorMessage;
                    //   setIsLoading(false);
                    //   switch (error.code) {
                    //     case error.PERMISSION_DENIED:
                    //       errorMessage = (
                    //         <div>
                    //           <div>請允許位置權限</div>
                    //           <div>{getSettingsGuide()}</div>
                    //         </div>
                    //       );
                    //       break;
                    //     case error.POSITION_UNAVAILABLE:
                    //       errorMessage = "取不到目前定位";
                    //       break;
                    //     case error.TIMEOUT:
                    //       errorMessage = "請求時間太長，請重新嘗試";
                    //       break;
                    //     default:
                    //       errorMessage = "未知錯誤";
                    //   }
                    //   setToastMessage(errorMessage);
                    //   Sentry.captureException(new Error(error.message));
                },
                options
            );
        } else {
            //   setToastMessage("您的裝置不支援GPS定位");
        }
    };




    const containerStyle = {
        position: "absolute",
        width: "100%",
        height: "100%"
    };

    const center = {
        lat: mapLat,
        lng: mapLong
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        region: "HK",
        language: "zh-HK",
        // libraries: ["places"]
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])



    // const [mapLat, setMapLat] = React.useState(addressData.addressGPSLatitude ? addressData.addressGPSLatitude : initLatitude);

    return isLoaded ? (
        <>
            <Row>
                <Col>
                    <div className=" ml-4 mr-4 mb-10 square d-flex justify-content-center align-content-center overflow-hidden min-vh-100">

  

                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={17}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >

                                <Marker
                                    position={{ lat: mapLat, lng: mapLong }}
                                    draggable={true}
                                    onDragEnd={(e) => {
                                        submitLocation(e.latLng.lat(), e.latLng.lng());
                                    }} />
                                { /* Child components, such as markers, info windows, etc. */}
                            </GoogleMap>

                    </div>
                </Col>

            </Row>
            <Row className='mt-2'>
                <Col>
                    <Button
                        className='mt-2 mb-2'
                        onClick={(e) => {
                            // use device current position
                            updatePinPointToCurrentPosition();
                        }}
                        color='primary'
                    >
                        <span className="btn-inner--icon mr-1">
                            <i className="fas fa-map-marker-alt " />
                        </span>
                        <span className="btn-inner--text">
                            <Trans>Current location</Trans>
                        </span>
                    </Button>
                    <Button color="success"
                        style={{
                            backgroundColor: "#25B366",
                            borderColor: "#25B366"
                        }}
                        onClick={() => goToUri(`whatsapp://send?text=https%3A%2F%2Fwww.google.com%2Fmaps%3Fq%3D${mapLat}%2C${mapLong}%26z%3D17`)}>
                        <i className="fab fa-whatsapp" /> <Trans>Share</Trans>
                    </Button>
                    <Button color="info"
                        onClick={() => goToUri(`https://www.google.com/maps/dir/?api=1&destination=${mapLat},${mapLong}&travelmode=driving`)}>
                        <i className="fas fa-directions" /> <Trans>Routing</Trans>
                    </Button>
                </Col>

            </Row>

        </>


    ) : <></>
}

export default DeliveryRecordNewGoogleMap