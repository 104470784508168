import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Label,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import Notifications from "../components/Notifications.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import CustomerModalPhoneEdit from "./CustomerModalPhoneEdit";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";

import {
  updateCustomer,
  updateObjectCustomer,
} from "../../../functions/Customer";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import CustomerModalPhoneCreate from "./CustomerModalPhoneCreate.js";

function CustomerModalEdit(props) {
  const { t } = useTranslation();


  const [modalCreatePhoneIsOpen, setModalCreatePhoneIsOpen] = React.useState(false);


  const [customerID, setCustomerID] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [customerDefaultRole, setCustomerDefaultRole] = React.useState("");
  const [customerDescription, setCustomerDescription] = React.useState("");
  const [customerEmail, setCustomerEmail] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("");
  const [customerPriority, setCustomerPriority] = React.useState("");
  const [customerPhones, setCustomerPhones] = React.useState([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("Save");
  const [modalButtonText, setModalButtonText] = React.useState("Add Customer");
  const [selectedCustomerPhones, setSelectedCustomerPhones] = React.useState(
    []
  );
  const [deleteSelectedCustomerPhone, setDeleteSelectedCustomerPhone] =
    React.useState("");
  const history = useHistory();

  const getPhoneType = (type) => {
    if (type == "whatsapp") {
      return <i className="fab fa-whatsapp"></i>;
    } else if (type == "wechat") {
      return <i className="fab fa-weixin"></i>;
    } else if (type == "telegram") {
      return <i className="fab fa-telegram"></i>;
    } else if (type == "messenger") {
      return <i className="fab fa-facebook-messenger"></i>;
    } else if (type == "line") {
      return <i className="fab fa-line"></i>;
    } else {
      return "";
    }
  };

  const addNewPhone = (
    phoneNumberID,
    phoneUserName,
    phoneNumber,
    phoneType
  ) => {
    let newCustomerPhoneList = [
      ...customerPhones,
      { phoneNumberID, phoneUserName, phoneNumber, phoneType },
    ];
    setCustomerPhones(newCustomerPhoneList);
  };

  const editCustomerPhone = (
    phoneNumberID,
    phoneUserName,
    phoneNumber,
    phoneType
  ) => {

    let updated = updateObjectCustomer(phoneNumberID, customerPhones, {
      phoneNumberID,
      phoneUserName,
      phoneNumber,
      phoneType,
    });
    setCustomerPhones(updated);

  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // check customerName
    if (customerName === "") {
      Notifications({
        type: "dataCheck",
        message: t("Please input a name")
      });
      return;
    }

    let dataCustomer = {
      customerID: customerID,
      customerName: customerName,
      customerDefaultRole: customerDefaultRole,
      customerDescription: customerDescription,
      customerEmail: customerEmail,
      customerAddress: customerAddress,
      customerPriority: customerPriority,
      customerPhones: customerPhones,
    };

    let update = await updateCustomer(dataCustomer);

    update.code == 200 ? props.refreshTable() : true;
    props.onHide();
  };

  React.useEffect(() => {
    if (Object.values(props.customerData).length > 0) {
      setCustomerID(props.customerData.customerID);
      setCustomerName(props.customerData.customerName);
      setCustomerDefaultRole(props.customerData.customerDefaultRole);
      setCustomerDescription(props.customerData.customerDescription);
      setCustomerEmail(props.customerData.customerEmail);
      setCustomerAddress(props.customerData.customerAddress);
      setCustomerPhones(props.customerData.CustomerPhones);
      setCustomerPriority(props.customerData.customerPriority);
    }
  }, [props.show]);

  React.useEffect(() => {
    const deleteCustomerPhone = () => {
      setCustomerPhones(
        customerPhones.filter(
          (data) => data.phoneNumberID !== deleteSelectedCustomerPhone
        )
      );
      setDeleteSelectedCustomerPhone("");
    };
    if (deleteSelectedCustomerPhone !== "") {
      deleteCustomerPhone();
    }
  }, [deleteSelectedCustomerPhone]);

  return (
    <>
      <Modal
        className="modal-dialog-centered"

        isOpen={props.show}
        //   size="lg"
        //  aria-labelledby="contained-modal-title-vcenter"
        //    centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader ><Trans>{props.title}</Trans></ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>

                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("Name")}${"("}${t("Required")}${")"}`}
                      labelFor="customerName"
                      inputName="customerName"
                      inputId="customerName"
                      inputPlaceholder={t("Name")}
                      value={customerName}
                      handleChange={(e) => {
                        setCustomerName(e.target.value);
                      }}
                      required={true}
                    />
                  </Col>
                  <Col>
                    <InputTypeSelect2Form
                      useTranslate={true}
                      data={[
                        { id: "customer", text: "Customer" },
                        { id: "supplier", text: "Supplier" },
                      ]}
                      value={customerDefaultRole}
                      nameSelect2="customerDefaultRole"
                      idSelect2="customerDefaultRole"
                      label={t("Default Role")}
                      handleOnChangeSelect={(e) => setCustomerDefaultRole(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={t("Description")}
                      labelFor="customerDescription"
                      inputName="customerDescription"
                      inputId="customerDescription"
                      inputPlaceholder={t("Description")}
                      value={customerDescription}
                      handleChange={(e) => setCustomerDescription(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <InputTypeTextForm
                      label={t("Email")}
                      labelFor="customerEmail"
                      inputName="customerEmail"
                      inputId="customerEmail"
                      inputPlaceholder={t("Email")}
                      value={customerEmail}
                      handleChange={(e) => setCustomerEmail(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <InputTypeTextForm
                      label={t("Address")}
                      labelFor="customerAddress"
                      inputName="customerAddress"
                      inputId="customerAddress"
                      inputPlaceholder={t("Address")}
                      type="textarea"
                      value={customerAddress}
                      handleChange={(e) => setCustomerAddress(e.target.value)}
                    />

                  </Col>
                  <Col>
                    <InputTypeTextForm
                      label={`${t("CustomerID")}${"("}${t("Required")}${")"}`}
                      labelFor="CustomerID"
                      inputName="CustomerID"
                      inputId="CustomerID"
                      inputPlaceholder={t("CustomerID")}
                      value={customerID}
                      readOnly={true}
                      required={true}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Row className="justify-content-between align-items-center mt-2">
                      <div className="col">
                        <Label className="form-control-label">
                          {t("Customer's Contact")}
                        </Label>
                      </div>
                      <Col className="col-auto">

                        <Button
                          color="primary"
                          id="tooltip443412080"
                          onClick={() => {
                            setModalCreatePhoneIsOpen(true);
                          }}
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">{t("Add")}</span>
                        </Button>
                      </Col>
                    </Row>
                    <hr className="mt-3 mb-2"></hr>
                    <Row>
                      <Col>
                        <ToolkitProvider
                          data={customerPhones}
                          keyField="phoneNumberID"
                          columns={[
                            {
                              dataField: "phoneUserName",
                              text: t("Person in charges"),
                              sort: true,
                              style: { whiteSpace: 'normal' } //whiteSpace: 'normal' will break the word if the word is too long
                            },
                            {
                              dataField: "phoneNumber",
                              formatter: (cellContent, row) => {
                                return (
                                  <>
                                    {getPhoneType(row.phoneType)}
                                    {"  "}
                                    {row.phoneNumber}
                                  </>
                                );
                              },
                              text: t("Phone Number"),
                              sort: true,
                            },
                            {
                              isDummyField: true,
                              dataField: "Action",
                              text: t("Action"),
                              formatter: (cellContent, row) => {
                                return (
                                  <>
                                    <a
                                      className="table-action"
                                      id="tooltip209424781"
                                      onClick={() => {
                                        setModalButtonText("Update");
                                        setModalTitle("Update contact");
                                        setSelectedCustomerPhones(row);
                                        setModalIsOpen(true);
                                      }}
                                    >
                                      <i className="fas fa-edit fa-lg" />
                                    </a>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target="tooltip209424781"
                                    >
                                      {t("Edit")}
                                    </UncontrolledTooltip>
                                    <a
                                      className="table-action table-action-delete"
                                      id="tooltip12475020"
                                      onClick={(e) => {
                                        setDeleteSelectedCustomerPhone(
                                          row.phoneNumberID
                                        );
                                      }}
                                    >
                                      <i
                                        className="fas fa-trash fa-lg"
                                        data-id={cellContent}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target="tooltip12475020"
                                    >
                                      {t("Delete")}
                                    </UncontrolledTooltip>
                                  </>
                                );
                              },
                            },
                          ]}
                          search
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                // pagination={pagination}
                                bordered={true}
                                noDataIndication={
                                  <>
                                    <center>
                                      <h4>{t("Phone Number Empty")}</h4>
                                    </center>
                                  </>
                                }
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                        {/* <ListGroup flush>
                      <>
                        {(customerPhones) && (customerPhones.length > 0) ?
                          customerPhones.map((value, index) => (
                            <ListGroupItem
                              className="list-group-item-action"
                              href={"tel:" + value.phoneNumber}
                              // onClick={(e) => { e.preventDefault(); deletePhoneConfirmation(value.phoneNumberID) }}
                              onClick={(e) => { e.preventDefault(); }}
                              tag="a"
                              key={value.phoneNumberID ? value.phoneNumberID : uuid4()}
                            >
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <span>
                                    <i className="fa fa-phone"></i>
                                  </span>
                                </Col>
                                <div className="col ml--2">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h4 className="mb-0 text-sm">{value.phoneUserName}</h4>
                                    </div>
                                    <div className="text-right">
                                      <Button size="sm" color="primary" onClick={(e) => { e.preventDefault(); setSelectedCustomerPhones(value); setModalIsOpen(true) }}>
                                        <i className="fas fa-pencil-alt" />
                                      </Button>
                                      <Button size="sm" color="danger" onClick={(e) => { e.preventDefault(); deletePhoneConfirmation(value.phoneNumberID) }}>
                                        <i className="fas fa-trash" />
                                      </Button>
                                    </div>
                                  </div>
                                  <p className="text-sm mb-0">
                                    {getPhoneType(value.phoneType)}
                                    {'  '}
                                    {value.phoneNumber}
                                  </p>
                                </div>
                              </Row>
                            </ListGroupItem>
                          )) : <Card>
                            <CardBody className="mb-0">
                              <h3 className="text-info">No records found.</h3>
                            </CardBody>
                          </Card>
                        }
                      </>
                    </ListGroup> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="mt--5">
            <Row className="w-100" md={12}>
              <Col className="d-flex justify-content-end pr-0" md={12}>

                <Button color="success" type="submit">
                  {props.button}
                </Button>
                <Button color="default" type="button" onClick={props.onHide}>
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Form>
      </Modal>

      <CustomerModalPhoneEdit
        show={modalIsOpen}
        onHide={() => setModalIsOpen(false)}

        title={t('Edit contact')}
        returnCustomerPhone={(phoneNumberID,
          phoneUserName,
          phoneNumber,
          phoneType) => { editCustomerPhone(phoneNumberID, phoneUserName, phoneNumber, phoneType) }}
        customerPhonesData={selectedCustomerPhones}
      />

      <CustomerModalPhoneCreate
        title={t('New contact')}
        show={modalCreatePhoneIsOpen}
        onHide={() => setModalCreatePhoneIsOpen(false)}
        returnCustomerPhone={(phoneNumberID,
          phoneUserName,
          phoneNumber,
          phoneType) => { addNewPhone(phoneNumberID, phoneUserName, phoneNumber, phoneType) }}
      />
    </>
  );
}

export default CustomerModalEdit;
