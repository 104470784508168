import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import Notifications from "../components/Notifications";
import SpinnerOverlay from "../components/SpinnerOverlay";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { addNewStore } from "functions/Store.js";
import { addNewSystemAccess } from "functions/SystemAccess.js";


// props


function SystemAccessCreateModal(props) {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [accessID, setAccessID] = React.useState('');
    const [accessType, setAccessType] = React.useState('');
    const [accessDetail, setAccessDetail] = React.useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // data check
        if (accessID === "" || accessType === "" || accessDetail === "") {
            Notifications({
                type: "dataCheck",
                message: t("Please input all details")
            });
            return;
        }

        let dataSubmit = {
            accessID: accessID,
            accessType: accessType,
            accessDetail: accessDetail,
        };
        setIsLoading(true);
        setFinishSubmitBoo(true);

        let resultJson = await addNewSystemAccess(dataSubmit);

        let accessIDFromDB = resultJson.accessID;
        if (accessIDFromDB && accessIDFromDB !== "") {

            clearForm();
            props.refreshTable();
            props.onHide();
        }

        setIsLoading(false);
    };

    const clearForm = () => {
        setAccessID('');
        setAccessType('');
        setAccessDetail('');

        setFinishSubmitBoo(false);
    };

    const onChangeAccessID = (accessID) => {
        setAccessID(accessID);
    };

    const onChangeAccessType = (accessType) => {
        setAccessType(accessType);
    };

    const onChangeAccessDetail = (accessDetail) => {
        setAccessDetail(accessDetail);
    };



    return (
        <>
            <Modal
                className="modal-dialog"
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                {isLoading ? <SpinnerOverlay /> : ""}
                <Form onSubmit={handleSubmit}>
                    <ModalHeader toggle={props.onHide}>
                        <div>{props.title}</div>
                    </ModalHeader>
                    <ModalBody className="mt--4">
                        <InputTypeTextForm
                            label={`${t("Access ID")}${"("}${t("Required")}${")"}`}
                            labelFor="accessID"
                            inputName="accessID"
                            inputId="accessID"
                            inputPlaceholder={t("Access ID")}
                            value={accessID}
                            handleChange={(e) => onChangeAccessID(e.target.value)}
                            required={true}
                        />
                        <InputTypeTextForm
                            label={`${t("Access Type")}${"("}${t("Required")}${")"}`}
                            labelFor="accessType"
                            inputName="accessType"
                            inputId="accessType"
                            inputPlaceholder={t("Access Type")}
                            value={accessType}
                            handleChange={(e) => onChangeAccessType(e.target.value)}
                            required={true}
                        />
                        <InputTypeTextForm
                            label={`${t("Access Detail")}${"("}${t("Required")}${")"}`}
                            labelFor="accessDetail"
                            inputName="accessDetail"
                            inputId="accessDetail"
                            inputPlaceholder={t("Access Detail")}
                            value={accessDetail}
                            handleChange={(e) => onChangeAccessDetail(e.target.value)}
                            required={true}
                        />



                    </ModalBody>
                    <ModalFooter className="mt--4">

                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                <Button
                                    color="danger"
                                    type="button" // type="button" means the form will not submit
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearForm();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>

                            <div>
                                <SubmitButtonInForm
                                    finishSubmitBoo={finishSubmitBoo}
                                    buttonNormalText={"Add"} />

                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </div>
                        </Row>


                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default SystemAccessCreateModal;
