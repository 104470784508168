import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    Badge,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardTitle
} from "reactstrap";
import QRCode from "react-qr-code";
import { uuid4 } from "@sentry/utils";
import { useTranslation } from "react-i18next";

// core components
import Dropzone from "dropzone";
import Notifications from "../components/Notifications";
import InvoiceCard from "../invoice/InvoiceCard";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import CustomerActionModal from "../customer/CustomerActionModal";
import { getInvoiceByCustomerID } from "functions/Invoice";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import InvoiceEditModal from "../invoice/InvoiceEditModal";
import { addDays, getIntegerDecodeFromString, getRandomBgColor } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";

import InvoiceHeader from "components/Headers/InvoiceHeader.js";
import InvoiceStatCard from "../components/InvoiceStatCard";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";
import InvoiceSearchBar from "../components/InvoiceSearchBar";
import moment from "moment";
import { getMembershipByCustomerID } from "functions/Membership";
import MembershipSearchBar from "../components/MembershipSearchbar";
import MembershipCard from "../membership/MembershipCard";
import MembershipCreateModal from "../membership/MembershipCreateModal";
import { deleteMembership } from "functions/Membership";
import MembershipEditModal from "../membership/MembershipEditModal";

Dropzone.autoDiscover = false;


function CustomerMembershipModal(props) {

    const customerID = props.customerID;
    const customerData = props.customerData;


    const { t } = useTranslation();
    //Initialize Get Set

    // const [isLoading, setIsLoading] = React.useState(false);

    const [refreshTable, setRefreshTable] = React.useState(0);



    const [displayMembershipListData, setDisplayMembershipListData] = React.useState([]);
    const [initMembershipListData, setInitMembershipListData] = React.useState([]);

    // for modal 
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceMessage, setInvoiceSelectedDataForInvoiceMessage] = React.useState([]);


    const [membershipSelectedDataForMembershipEdit, setMembershipSelectedDataForMembershipEdit] = React.useState({});

    const [modalMembershipCreateIsOpen, setModalMembershipCreateIsOpen] = React.useState(false);
    const [modalMembershipEditIsOpen, setModalMembershipEditIsOpen] = React.useState(false);

    //GUI
    const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);
    const [useInvoiceStatusColor, setUseInvoiceStatusColor] = React.useState(false);
    const [useInvoiceDateTimeRangeColor, setUseInvoiceDateTimeRangeColor] = React.useState(false);
    const [useMembershipTypeColor, setUseMembershipTypeColor] = React.useState(false);
    const [useInvoiceSubitemConnectionColor, setUseInvoiceSubitemConnectionColor] = React.useState(false);


    // For filter control show
    const [showFullCard, setShowFullCard] = React.useState(false);


    //Initialize Effect
    React.useEffect(() => {
        const getCustomerMembershipData = async () => {
            let membershipData = await getMembershipByCustomerID(customerID);
            setInitMembershipListData(membershipData);
        };
        if (props.show) {
            getCustomerMembershipData();
        }
    }, [props.show, refreshTable]);

    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const getCardBgColor = (invoiceStatus, invoiceRecordDateString, membershipType, invoiceSubitemConnectedBoo) => {
        // This control the card Bg color 
        if (enableCardBgColor) {
            // priority 
            // carID color second // Only if these are all true at the same time 
            if (useInvoiceStatusColor) {
                return getRandomBgColor(getIntegerDecodeFromString(invoiceStatus));
            } else if (useInvoiceDateTimeRangeColor) {
                return getRandomBgColor(getIntegerDecodeFromString(invoiceRecordDateString));
            } else if (useMembershipTypeColor) {
                return getRandomBgColor(getIntegerDecodeFromString(membershipType));
            } else if (useInvoiceSubitemConnectionColor) {
                return getRandomBgColor(getIntegerDecodeFromString(invoiceSubitemConnectedBoo ? 'yes' : 'no'));
            } else {
                return "";
            }
        }
    }

    // Delete Confirmation
    async function deleteConfirmation(membershipID) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: membershipID,
            callback: deleteMembershipProcess,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteMembershipProcess(membershipID) {
        //   setIsLoading(true);
        let deleteResponse = await deleteMembership(membershipID);

        //    setIsLoading(false);
        deleteResponse.code == 200 ? refreshTableData() : true;
        //  refreshTableData();
    }


    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
                    <div>
                        <span> {t("Customer Membership")}</span>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge">{props.customerData?.customerName}</Badge>
                    </div>
                </ModalHeader>
                <ModalBody>

                    {/* filter Card */}

                    <MembershipSearchBar
                        initMembershipListData={initMembershipListData}
                        changeCardColor={(enableBgColor, useInvoiceStatusColor, useInvoiceDateTimeRangeColor, useMembershipTypeColor, useInvoiceSubitemConnectionColor) => {
                            setEnableCardBgColor(enableBgColor);
                            setUseInvoiceStatusColor(useInvoiceStatusColor);
                            setUseInvoiceDateTimeRangeColor(useInvoiceDateTimeRangeColor);
                            setUseMembershipTypeColor(useMembershipTypeColor);
                            setUseInvoiceSubitemConnectionColor(useInvoiceSubitemConnectionColor);
                        }}
                        returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
                        returnFilteredSortedMembershipListData={(filteredSortedMembershipListData) => {
                            setDisplayMembershipListData(filteredSortedMembershipListData);
                        }}
                        createMembership={() => { setModalMembershipCreateIsOpen(true) }} >
                    </MembershipSearchBar>

                    {/* Membership list */}
                    <Row className="mt-4">
                        {displayMembershipListData && displayMembershipListData.length != 0 && displayMembershipListData.map((membershipItem, index) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                data-id={membershipItem.membershipID}
                                key={membershipItem.membershipID}
                            >

                                <MembershipCard
                                    showFullCard={showFullCard}
                                    enableCardBgColor={enableCardBgColor}
                                    cardBgColor={getCardBgColor(membershipItem.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice?.invoiceStatus, moment(membershipItem.effectiveDate).format('yyyy-MM-DD').toString(), membershipItem.membershipTypeID, membershipItem.MembershipInvoiceSubitemMatch)}
                                    membershipData={membershipItem}
                                    setClickViewInvoiceButton={(invoice) => {
                                        if (invoice) {
                                            setInvoiceSelectedDataForInvoiceMessage(invoice);
                                            setModalInvoiceMessageIsOpen(true);
                                        }
                                    }}
                                    onClickCard={(membership) => {
                                        setMembershipSelectedDataForMembershipEdit(membership);
                                        setModalMembershipEditIsOpen(true);
                                    }}
                                    isSelectedCard={false}
                                    deleteThisMembership={(membershipID) => {
                                        deleteConfirmation(membershipID);
                                    }}
                                />
                            </Col>
                        ))}
                        {displayMembershipListData && displayMembershipListData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Invoice Found!")}
                                        </strong>
                                        <h3 className="text-info">
                                            {t("Please Check Your Filter")} </h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    {/* End of Invoice list */}

                </ModalBody>
                <ModalFooter className="mt--5">
                    <Row className="w-100" md={12}>
                        <Col className="d-flex justify-content-end pr-0" md={12}>
                            <Button
                                color="default"
                                type="button"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                {t("Back")}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>

            {/* <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={customerSelectedDataForCustomerAction}
                refreshTable={() => { setRefreshTable(refreshTable + 1) }}
            /> */}
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={invoiceSelectedDataForInvoiceMessage}
            />


            <MembershipCreateModal
                show={modalMembershipCreateIsOpen}
                onHide={(e) => {
                    setModalMembershipCreateIsOpen(false);
                }}
                refreshTable={refreshTableData}
                customerData={customerData}
            />
            
            <MembershipEditModal
                show={modalMembershipEditIsOpen}
                onHide={(e) => {
                    refreshTableData();
                    setModalMembershipEditIsOpen(false);
                }}
                customerData={customerData}
                membershipDataToEdit={membershipSelectedDataForMembershipEdit}
            />

        </>
    );
}

export default CustomerMembershipModal;
