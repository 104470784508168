import React, { useEffect } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Label,
    Input,
    Row,
    Col,
    UncontrolledTooltip,
    CardBody,
    Card
} from "reactstrap";
import InputTypeText from "../components/InputTypeText.js";
// import InputTypeTextForm from "../components/InputTypeTextForm.js";
import {
    updateCustomer,
    deleteCustomerByID,
    updateObjectCustomer,
} from "../../../functions/Customer";
import { useTranslation } from "react-i18next";

import Notifications from "../components/Notifications";

import CustomerModalEdit from "./CustomerModalEdit";
import CustomerDisplayCard from "./CustomerDisplayCard.js";
import CustomerInvoiceRecord from "./CustomerInvoiceRecord.js";
import CustomerUnpaidInvoiceRecord from "./CustomerUnpaidInvoiceRecord.js";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";
import CustomerPaymentRecord from "./CustomerPaymentRecord.js";
import { isAccessible } from "functions/SystemUserAccess.js";
import CustomerMembershipModal from "./CustomerMembershipModal.js";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess.js";

function CustomerActionModal(props) {
    const { t } = useTranslation();


    // Modal
    const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);
    const [modalCustomerInvoiceRecordIsOpen, setModalCustomerInvoiceRecordIsOpen] = React.useState(false);
    const [modalCustomerUnpaidInvoiceRecordIsOpen, setModalCustomerUnpaidInvoiceRecordIsOpen] = React.useState(false);
    const [modalCustomerPaymentRecordIsOpen, setModalCustomerPaymentRecordIsOpen] = React.useState(false);
    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);
    const [modalCustomerMembershipIsOpen, setModalCustomerMembershipIsOpen] = React.useState(false);

    // Data
    const [customerID, setCustomerID] = React.useState("");



    // Delete Confirmation
    async function deleteConfirmation(id) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: id,
            callback: deleteCustomer,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function deleteCustomer(id) {
        let deleteCustomer = await deleteCustomerByID(id);
        if (deleteCustomer.code == 200) {
            props.refreshTable();
            props.onHide();
        }
    }

    React.useEffect(() => {
        if (Object.values(props.customerData).length > 0) {
            setCustomerID(props.customerData.customerID);
        }
    }, [props.show]);


    return (
        <>
            <Modal
                className="modal-dialog-centered"
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form >
                    <ModalHeader >{props.title}</ModalHeader>
                    <ModalBody className="mb-5">

                        <Row className="p-1">
                            <Col md={12} sm="12">
                                <CustomerDisplayCard
                                    customerData={props.customerData} />
                            </Col>
                        </Row>
                        <Row className="p-1">
                            <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card>
                                    <CardBody>
                                        <Button
                                            className="mt-1"
                                            color="success"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalInvoiceCreateIsOpen(true);
                                            }}
                                        >
                                            {t("Create Invoice")}
                                        </Button>
                                        <Button
                                            className="mt-1"
                                            color="primary"
                                            id={`tooltip209424789${customerID}`}
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalCustomerInvoiceRecordIsOpen(true);
                                            }}>
                                            {t("Invoice Record")}
                                        </Button>
                                        <Button
                                            className="mt-1"
                                            color="danger"
                                            id={`tooltip2094247777${customerID}`}
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalCustomerUnpaidInvoiceRecordIsOpen(true);
                                            }}>
                                            {t("Unpaid Invoice Record")}
                                        </Button>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Button
                                            className="mt-1"
                                            color="primary"
                                            id={`tooltip209424781${customerID}`}
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalEditIsOpen(true);
                                            }}>
                                            {t("Customer info")}
                                        </Button>
                                        <Button
                                            className="mt-1"
                                            color="danger"
                                            id={`tooltip209424782${customerID}`}
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                deleteConfirmation(customerID)
                                            }}>
                                            {t("Delete Customer")}
                                        </Button>

                                        {isAccessible('customerActionAccessMembership') &&
                                            <Button
                                                className="mt-1"
                                                color="primary"
                                                id={`tooltip209424444${customerID}`}
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setModalCustomerMembershipIsOpen(true);
                                                }}>
                                                {t("Membership")}{getAccessibleTagForSafeMode('customerActionAccessMembership')}
                                            </Button>
                                        }
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <Button
                                            className="mt-1"
                                            color="primary"
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setModalCustomerPaymentRecordIsOpen(true);
                                            }}>
                                            {t("Payment Record")}
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--5">
                        <Row className="w-100" md={12}>
                            <Col className="d-flex justify-content-end pr-0" md={12}>
                                <Button color="default" type="button" onClick={props.onHide}>
                                    {t("Cancel")}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </Form>
            </Modal>
            <CustomerModalEdit
                show={modalEditIsOpen}
                onHide={() => {
                    setModalEditIsOpen(false);
                    props.onHide;
                }}
                title={"Edit Customer"}
                button={t("Update")}
                customerData={props.customerData}
                refreshTable={() => { props.refreshTable(); props.onHide(); }}
            />
            <CustomerInvoiceRecord
                show={modalCustomerInvoiceRecordIsOpen}
                onHide={() => {
                    setModalCustomerInvoiceRecordIsOpen(false);
                }}
                customerID={customerID}
                customerData={props.customerData}
            />
            <CustomerUnpaidInvoiceRecord
                show={modalCustomerUnpaidInvoiceRecordIsOpen}
                onHide={() => {

                    setModalCustomerUnpaidInvoiceRecordIsOpen(false);
                }}
                customerID={customerID}
                customerData={props.customerData}
            />
            <CustomerPaymentRecord
                show={modalCustomerPaymentRecordIsOpen}
                onHide={() => {
                    setModalCustomerPaymentRecordIsOpen(false);
                }}
                customerID={customerID}
                customerData={props.customerData}
            />

            <InvoiceCreateModal
                preSelectedCustomer={props.customerData}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                }}
                allowEmptySubitemList={true}
            />


            <CustomerMembershipModal
                show={modalCustomerMembershipIsOpen}
                onHide={() => {
                    setModalCustomerMembershipIsOpen(false);
                }}
                customerID={customerID}
                customerData={props.customerData}
            />
        </>
    );
}

export default CustomerActionModal;
