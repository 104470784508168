import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";

export const getEffectiveMembershipCurrentCompany = async () => {
  try {
    return api.get("/membershipCurrentCompany/getEffectiveMembership").then((response) => {
      try {
        if (response.data.code == 200) {
          return response.data.data.rows;
        } else {
          return Notifications({ type: "error", message: response.data.message });
        }
      } catch (error) {
        return Notifications({ type: "error" });
      }
    });
  } catch (error) {
    return [];
  }
};


export const configLocalMembershipCurrentCompanyList = async () => {

    let dataList;
    let response = await apiGet({
        url: "membershipCurrentCompany/getEffectiveMembership",
        responseType: "",
    });
  
    if (response.status == 200) {
        dataList = response.data.data.rows;
    } else {
        dataList = [];
    }
    
    localStorage.removeItem("membershipCurrentCompanyList");
    localStorage.setItem("membershipCurrentCompanyList", JSON.stringify(dataList));
  
  }
  