import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Label,
    Col,
    Badge
} from "reactstrap";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import {
    getGoodsByID
} from "../../../functions/Goods";

import { getQuotationDataJson } from "functions/Quotation.js";
import { getQuotationPDF } from "functions/Quotation.js";
import Notifications from "../components/Notifications.js";
import { openPDFLink } from "functions/Utility.js";
import { PDFDocument } from 'pdf-lib'
import { getCustomerByID } from "functions/Customer.js";
import { configQuotationMessage } from "functions/Quotation.js";

// customerData
// invoiceRemark
// invoiceSubitemList

function UseQuotationModal(props) {

    const { t } = useTranslation();
    const [quotationMessage, setQuotationMessage] = React.useState("");


    const [actionCreateInvoiceDone, setActionCreateInvoiceDone] = React.useState(false);
    const [actionDownloadDone, setActionDownloadDone] = React.useState(false);
    const [actionDownloadInprogressDone, setActionDownloadInProgressDone] = React.useState(false);

    // Return data
    const [customerDataForReturn, setCustomerDataForReturn] = React.useState({});
    const [invoiceRemarkForReturn, setInvoiceRemarkForReturn] = React.useState("");
    const [invoiceSubitemListForReturn, setInvoiceSubitemListForReturn] = React.useState([]);

    const hiddenFileInput3 = React.useRef(null);

    //props.show means this form is triggered by parent form
    React.useEffect(() => {
        if (props.show) {
            // Set Customer
            setCustomerDataForReturn(props.customerData);
            // invoice detail
            setInvoiceRemarkForReturn(props.invoiceRemark);
            // invoiceSubitem
            setInvoiceSubitemListForReturn(props.invoiceSubitemList);
        } else {
            clearForm()
        }
    }, [props.show]);

    React.useEffect(() => {

    }, [customerDataForReturn, invoiceRemarkForReturn, invoiceSubitemListForReturn]);

    const clearForm = () => {
        setCustomerDataForReturn({});
        setInvoiceRemarkForReturn("");
        setInvoiceSubitemListForReturn([]);
        setActionCreateInvoiceDone(false);
        setActionDownloadDone(false);
    }


    const createInvoice = async () => {
        let resultJSON = await getQuotationDataJson(quotationMessage);
        await createInvoiceFromJson(resultJSON);
    }

    const createInvoiceFromJson = async (resultJSON) => {

        if (!resultJSON || Object.values(resultJSON).length <= 0) {
            Notifications({
                type: "dataCheck",
                message: t("Message is not correct")
            });
            return false;
        } else {

            let customerID = resultJSON.qtn_customerID;
            let invoiceRemark = resultJSON.qtn_remark;
            let goodListFromServer = resultJSON.qtn_good_list;
            let invoiceSubitemList = [];

            let deliveryRecordFromServer = resultJSON.qtn_delivery_address_list;
            let deliveryRecordList = [];
            // Goods and subitems
            try {
                if (goodListFromServer && goodListFromServer.length >= 1) {

                    for (let i = 0; i < goodListFromServer.length; i++) {
                        let item = goodListFromServer[i];
                        let good = (await getGoodsByID(item.qtn_goodID)) || [];
                        invoiceSubitemList.push({
                            quantity: item.qtn_good_quantity,
                            unitPrice: item.qtn_good_unitPrice,
                            invoiceSubitemRemark: item.qtn_good_bill_remark,
                            good: good
                        })
                    }
                }
            } catch (error) {
                //  console.log(error);
            }

            // Delivery record
            try {
                if (deliveryRecordFromServer && deliveryRecordFromServer.length >= 1) {

                    for (let i = 0; i < deliveryRecordFromServer.length; i++) {
                        let item = deliveryRecordFromServer[i];
                        deliveryRecordList.push({
                            deliveryAddressID: item.qtn_each_delivery_address_ID,
                            carID: item.qtn_each_delivery_carID,
                            driverID: item.qtn_each_delivery_driverID,
                            proposedDateTime: item.qtn_each_delivery_proposedDateTime
                        })
                    }
                }
            } catch (error) {
                //  console.log(error);
            }



            // Process the data
            props.getBackData(customerID, invoiceRemark, invoiceSubitemList, deliveryRecordList);
            props.onHide();
        }
    }

    const configQuotationMessageFromPDF = async (resultJSON) => {

        if (!resultJSON || Object.values(resultJSON).length <= 0) {
            Notifications({
                type: "dataCheck",
                message: t("Message is not correct")
            });
            return false;
        } else {
            let customerID = resultJSON.qtn_customerID;
            let invoiceRemark = resultJSON.qtn_remark;
            let goodListFromServer = resultJSON.qtn_good_list;
            let invoiceSubitemList = [];
            try {
                if (goodListFromServer && goodListFromServer.length >= 1) {

                    for (let i = 0; i < goodListFromServer.length; i++) {
                        let item = goodListFromServer[i];
                        let good = (await getGoodsByID(item.qtn_goodID)) || [];
                        invoiceSubitemList.push({
                            quantity: item.qtn_good_quantity,
                            unitPrice: item.qtn_good_unitPrice,
                            invoiceSubitemRemark: item.qtn_good_bill_remark,
                            good: good
                        })
                    }
                }
            } catch (error) {
                // console.log(error);
            }
            let customerData = await getCustomerByID(customerID);
            let configMessage = configQuotationMessage(customerData, invoiceRemark, invoiceSubitemList, [], true, true, true);
            setQuotationMessage(configMessage);
        }
    }

    const openQuotationPDF = async () => {
        if (!quotationMessage) {
            Notifications({
                type: "dataCheck",
                message: t("Empty message")
            });
            return false;
        }
        let resultJSON = await getQuotationDataJson(quotationMessage);
        if (!resultJSON || Object.values(resultJSON).length <= 0) {
            Notifications({
                type: "dataCheck",
                message: t("Message is not correct")
            });
            return false;
        } else {
            let resultPDF = await getQuotationPDF(resultJSON);
            openPDFLink(URL.createObjectURL(resultPDF));
            return true;
        }
    }


    const handleClickUpload = (event) => {
        // if (photoID !== undefined && photoID !== "") {
        //     Notifications({
        //         type: "advice",
        //         message: t('Cannot upload image. Plesae delete first.')
        //     });
        // } else {
        hiddenFileInput3.current.click();
        // }
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader >{props.title}</ModalHeader>
                <ModalBody className="mt--4">
                    <Col md={6} lg={6}>
                        <Label className="form-control-label mt-4">
                            {t("Quotation Message")}
                        </Label>
                    </Col>
                    <Col>
                        <InputTypeTextForm
                            label=""
                            labelFor="invoiceMessage"
                            inputName="invoiceMessage"
                            inputId="invoiceMessage"
                            inputPlaceholder={t("Input quotation message")}
                            type="textarea"
                            value={quotationMessage}
                            handleChange={(e) => { setQuotationMessage(e.target.value) }}
                        />


                        <div className="d-flex mt-1">

                            <Button
                                className="flex-fill"
                                color={actionCreateInvoiceDone ? "success" : "primary"}
                                onClick={async () => {
                                    await createInvoice();
                                    setActionCreateInvoiceDone(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionCreateInvoiceDone ? t("Invoice created") : t("Create invoice")}</span>
                            </Button>
                            <Button
                                className="flex-fill"
                                color={"danger"}
                                onClick={() => { setQuotationMessage(""); }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-times" />
                                </span>
                                <span className="btn-inner--text">{t("Clear")}</span>
                            </Button>
                        </div>


                        <div className="d-flex mt-1" >

                            <Button
                                className="flex-fill"
                                color={"primary"}
                                onClick={(e) => {
                                    handleClickUpload(e);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-upload" />
                                </span>
                                <span className="btn-inner--text">{t("Upload PDF")}</span>
                            </Button>
                            <input
                                // the input is hidden, so the Button click action will trigger input.current.click
                                multiple="multiple"
                                type="file"
                                ref={hiddenFileInput3}
                                onChange={async (e) => {
                                    const existingPdfBytes = await fetch(URL.createObjectURL(e.target?.files[0])).then(res => res.arrayBuffer())
                                    const pdfDoc = await PDFDocument.load(existingPdfBytes, {
                                        updateMetadata: false
                                    })
                                    configQuotationMessageFromPDF(JSON.parse(pdfDoc.getSubject())); // The json is inside the subject
                                }
                                }
                                style={{ display: "none" }}
                            />

                            <Button
                                className="flex-fill"
                                color={actionDownloadDone ? "success" : (actionDownloadInprogressDone ? "warning" : "primary")}
                                onClick={async () => {
                                    setActionDownloadInProgressDone(true);
                                    if (await openQuotationPDF()) {
                                        setActionDownloadDone(true);
                                    }
                                    setActionDownloadInProgressDone(false);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-download" />
                                </span>
                                <span className="btn-inner--text">{actionDownloadDone ? t("Downloaded PDF") : (actionDownloadInprogressDone ? t("Download in progress") : t("Download PDF"))}</span>
                            </Button>
                        </div>
                    </Col>

                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default UseQuotationModal;
