import React from "react";
import { Label, FormGroup, Input, Row, Col, Button } from "reactstrap";
import classnames from "classnames";

// props.showCopyButton
function InputTypeTextForm(props) {

  const defaultRowHeight = 21;



  const [localTextStorage, setLocalTextStorage] = React.useState("");
  const [isTextCopied, setIsTextCopied] = React.useState(false);




  React.useEffect(() => {
    setLocalTextStorage(props.value);
  }, [props.value]);

  const onChnageHeight = (event) => {
    if (event) {
      if (props.type == "textarea") {
        const target = event.target ? event.target : event;
        target.style.height = "auto"; // Then the input will resize to normal
        target.style.height = `${target.scrollHeight}px`;
      }
    }
  };

  return (
    <FormGroup className="mb-1">
      <Row>
        <Col>
          {props.label !== "" ?
            <Label for={props.labelFor} className="form-control-label">
              {props.label}
            </Label>
            : <></>}
          <div className="d-flex">
            <div className="flex-grow-1">
              <Input
                className={classnames(`${props.required ? "requirdInput" : ""}`, `remarkTextArea`)}
                type={props.type ?? "text"}
                rows={props.type == "textarea" ? 3 : 1} // 21 is the default row height of the input
                name={props.inputName}
                id={props.inputId}
                placeholder={props.inputPlaceholder}
                value={props.value}
                // defaultValue={props.defaultValue ? props.defaultValue : ""}
                onClick={(e) => {
                  onChnageHeight(e);
                }}
                onChange={(e) => {
                  props.handleChange(e);
                  onChnageHeight(e);
                  setLocalTextStorage(e.target.value);
                }}
                readOnly={props.readOnly ?? false}
                required={props.required}
              />
            </div>
            {props.showCopyButton &&
              <div>
                <Button
                  color={isTextCopied ? "success" : "primary"}
                  onClick={() => {
                    navigator.clipboard.writeText(localTextStorage);
                    setIsTextCopied(true);
                  }}>
                  <i className="ni ni-single-copy-04" />
                </Button>
              </div>
            }
          </div>
        </Col>
      </Row>
    </FormGroup>
  );
}

export default InputTypeTextForm;
