import React from "react";

import { Spinner } from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "react-datepicker/dist/react-datepicker.css";

function SpinnerOverlay() {
  return (
    <div
      style={{
        backgroundColor: "rgba(204, 204, 204, 0.5)",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1000,
      }}
    >
      <Spinner
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-16px",
          marginLeft: "-16px",
        }}
        color="primary"
      />
    </div>
  );
}

export default SpinnerOverlay;
