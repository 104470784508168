import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";


import { getReportResourceReportTermsArray } from "functions/ReportResource.js";
import { getRefTabData } from "functions/Utility.js";
import { updateSingleReportTerms } from "functions/ReportResource.js";
import SpinnerOverlay from "../components/SpinnerOverlay.js";


function ReportResourceDNTermsEditModal(props) {
  const { t } = useTranslation();

  // Control
  const [isLoading, setIsLoading] = React.useState(false);

  const [isReportResourceDNTermsLine1Chnaged, setIsReportResourceDNTermsLine1Changed] = React.useState(false);
  const [isReportResourceDNTermsLine2Chnaged, setIsReportResourceDNTermsLine2Changed] = React.useState(false);
  const [isReportResourceDNTermsLine3Chnaged, setIsReportResourceDNTermsLine3Changed] = React.useState(false);
  const [isReportResourceDNTermsLine4Chnaged, setIsReportResourceDNTermsLine4Changed] = React.useState(false);

  const [isReportResourceDNMainColorChnaged, setIsReportResourceDNMainColorChnaged] = React.useState(false);
  const [isReportResourceDNBottomInfoLine1Chnaged, setIsReportResourceDNBottomInfoLine1Chnaged] = React.useState(false);
  const [isReportResourceDNBottomInfoLine2Chnaged, setIsReportResourceDNBottomInfoLine2Chnaged] = React.useState(false);


  // Data
  const [reportResourceDNTermsLine1, setReportResourceDNTermsLine1] = React.useState('');
  const [reportResourceDNTermsLine2, setReportResourceDNTermsLine2] = React.useState('');
  const [reportResourceDNTermsLine3, setReportResourceDNTermsLine3] = React.useState('');
  const [reportResourceDNTermsLine4, setReportResourceDNTermsLine4] = React.useState('');


  const [reportResourceDNMainColor, setReportResourceDNMainColor] = React.useState('');
  const [reportResourceDNBottomInfoLine1, setReportResourceDNBottomInfoLine1] = React.useState('');
  const [reportResourceDNBottomInfoLine2, setReportResourceDNBottomInfoLine2] = React.useState('');

  // Data process
  // const [refTabSubTypeArray, setRefTabSubTypeArray] = React.useState([]);


  const onSubmitDataToServer = async (refTabID, refTabData) => {
    setIsLoading(true);
    let updateResponse = await updateSingleReportTerms(refTabID, refTabData);
    setIsLoading(false);
    if (updateResponse.code == 200) {
      return true
    } else {
      return false;
    }
  };


  // props 

  React.useEffect(() => {
    const getDataFromServer = async () => {
      let refDataArray = await getReportResourceReportTermsArray('reportResourceDN');

      if (refDataArray && Array.isArray(refDataArray) && refDataArray.length >= 1) {
        setReportResourceDNTermsLine1(getRefTabData(refDataArray, 'reportResourceDNTermsLine1'));
        setReportResourceDNTermsLine2(getRefTabData(refDataArray, 'reportResourceDNTermsLine2'));
        setReportResourceDNTermsLine3(getRefTabData(refDataArray, 'reportResourceDNTermsLine3'));
        setReportResourceDNTermsLine4(getRefTabData(refDataArray, 'reportResourceDNTermsLine4'));

        setReportResourceDNMainColor(getRefTabData(refDataArray, 'reportResourceDNMainColor'));
        setReportResourceDNBottomInfoLine1(getRefTabData(refDataArray, 'reportResourceDNBottomInfoLine1'));
        setReportResourceDNBottomInfoLine2(getRefTabData(refDataArray, 'reportResourceDNBottomInfoLine2'));


        // reset the control here
        setIsReportResourceDNTermsLine1Changed(false);
        setIsReportResourceDNTermsLine2Changed(false);
        setIsReportResourceDNTermsLine3Changed(false);
        setIsReportResourceDNTermsLine4Changed(false);
        setIsReportResourceDNMainColorChnaged(false);
        setIsReportResourceDNBottomInfoLine1Chnaged(false);
        setIsReportResourceDNBottomInfoLine2Chnaged(false);

        // Data process
        // let mapResult = refDataArray.map((item) => { return item.refTabSubType; });
        // let distinctResult = mapResult.filter((item, index) => mapResult.indexOf(item) === index);
        // setRefTabSubTypeArray(distinctResult);
      }
    }

    if (props.show) {
      getDataFromServer();
    }
  }, [props.show]);


  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        {isLoading ? <SpinnerOverlay /> : ""}
        <ModalHeader><Trans>DN Report Detail</Trans></ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <InputTypeTextForm
                label={`${t("reportResourceDNTermsLine1")}   (${reportResourceDNTermsLine1.length}${t('words')})`}
                labelFor="reportResourceDNTermsLine1"
                inputName="reportResourceDNTermsLine1"
                inputId="reportResourceDNTermsLine1"
                inputPlaceholder={t("reportResourceDNTermsLine1")}
                type="textarea"
                value={reportResourceDNTermsLine1}
                handleChange={(e) => {
                  setReportResourceDNTermsLine1(e.target.value);
                  setIsReportResourceDNTermsLine1Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNTermsLine1Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNTermsLine1", reportResourceDNTermsLine1);
                      if (result) {
                        setIsReportResourceDNTermsLine1Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceDNTermsLine2")}   (${reportResourceDNTermsLine2.length}${t('words')})`}
                labelFor="reportResourceDNTermsLine2"
                inputName="reportResourceDNTermsLine2"
                inputId="reportResourceDNTermsLine2"
                inputPlaceholder={t("reportResourceDNTermsLine2")}
                type="textarea"
                value={reportResourceDNTermsLine2}
                handleChange={(e) => {
                  setReportResourceDNTermsLine2(e.target.value);
                  setIsReportResourceDNTermsLine2Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNTermsLine2Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNTermsLine2", reportResourceDNTermsLine2);
                      if (result) {
                        setIsReportResourceDNTermsLine2Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceDNTermsLine3")}   (${reportResourceDNTermsLine3.length}${t('words')})`}
                labelFor="reportResourceDNTermsLine3"
                inputName="reportResourceDNTermsLine3"
                inputId="reportResourceDNTermsLine3"
                inputPlaceholder={t("reportResourceDNTermsLine3")}
                type="textarea"
                value={reportResourceDNTermsLine3}
                handleChange={(e) => {
                  setReportResourceDNTermsLine3(e.target.value);
                  setIsReportResourceDNTermsLine3Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNTermsLine3Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNTermsLine3", reportResourceDNTermsLine3);
                      if (result) {
                        setIsReportResourceDNTermsLine3Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceDNTermsLine4")}   (${reportResourceDNTermsLine4.length}${t('words')})`}
                labelFor="reportResourceDNTermsLine4"
                inputName="reportResourceDNTermsLine4"
                inputId="reportResourceDNTermsLine4"
                inputPlaceholder={t("reportResourceDNTermsLine4")}
                type="textarea"
                value={reportResourceDNTermsLine4}
                handleChange={(e) => {
                  setReportResourceDNTermsLine4(e.target.value);
                  setIsReportResourceDNTermsLine4Changed(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNTermsLine4Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNTermsLine4", reportResourceDNTermsLine4);
                      if (result) {
                        setIsReportResourceDNTermsLine4Changed(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
         
          <Row>
            <Col >
              <InputTypeTextForm
                label={t("reportResourceDNMainColor")}
                labelFor="reportResourceDNMainColor"
                inputName="reportResourceDNMainColor"
                inputId="reportResourceDNMainColor"
                inputPlaceholder={t("reportResourceDNMainColor")}
                type="textarea"
                value={reportResourceDNMainColor}
                handleChange={(e) => {
                  setReportResourceDNMainColor(e.target.value);
                  setIsReportResourceDNMainColorChnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNMainColorChnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNMainColor", reportResourceDNMainColor);
                      if (result) {
                        setIsReportResourceDNMainColorChnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceDNBottomInfoLine1")}   (${reportResourceDNBottomInfoLine1.length}${t('words')})`}
                labelFor="reportResourceDNBottomInfoLine1"
                inputName="reportResourceDNBottomInfoLine1"
                inputId="reportResourceDNBottomInfoLine1"
                inputPlaceholder={t("reportResourceDNBottomInfoLine1")}
                type="textarea"
                value={reportResourceDNBottomInfoLine1}
                handleChange={(e) => {
                  setReportResourceDNBottomInfoLine1(e.target.value);
                  setIsReportResourceDNBottomInfoLine1Chnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNBottomInfoLine1Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNBottomInfoLine1", reportResourceDNBottomInfoLine1);
                      if (result) {
                        setIsReportResourceDNBottomInfoLine1Chnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }
          <Row>
            <Col >
              <InputTypeTextForm
                label={`${t("reportResourceDNBottomInfoLine2")}   (${reportResourceDNBottomInfoLine2.length}${t('words')})`}
                labelFor="reportResourceDNBottomInfoLine2"
                inputName="reportResourceDNBottomInfoLine2"
                inputId="reportResourceDNBottomInfoLine2"
                inputPlaceholder={t("reportResourceDNBottomInfoLine2")}
                type="textarea"
                value={reportResourceDNBottomInfoLine2}
                handleChange={(e) => {
                  setReportResourceDNBottomInfoLine2(e.target.value);
                  setIsReportResourceDNBottomInfoLine2Chnaged(true);
                }}
              />
            </Col>
          </Row>
          {isReportResourceDNBottomInfoLine2Chnaged &&
            <Row>
              <Col>
                <div className="d-flex">
                  <Button
                    className="flex-fill"
                    color="success"
                    onClick={async (e) => {
                      let result = await onSubmitDataToServer("reportResourceDNBottomInfoLine2", reportResourceDNBottomInfoLine2);
                      if (result) {
                        setIsReportResourceDNBottomInfoLine2Chnaged(false);
                      }
                    }}
                  >
                    <Trans>Save</Trans>
                  </Button>
                </div>
              </Col>

            </Row>
          }

        </ModalBody>
        <ModalFooter>
          <Button color="default" type="button" onClick={props.onHide}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ReportResourceDNTermsEditModal;
