import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateDriver } from "functions/Driver.js";
import {  getUserSelect2 } from "functions/Auth";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";

function DriverModalEdit(props) {
  const { t } = useTranslation();
  const [userID, setUserID] = React.useState(-1);
  const [nickName, setNickName] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [jobRemark, setJobRemark] = React.useState("");

  async function handleChangeUser(e) {
    if (e.target.selectedIndex != -1) {
      let value = e.target.value;
      let text = e.target.options[e.target.selectedIndex].text;
      setUserID(value);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let dataDriver = {
      driverID: userID,
      jobRemark: jobRemark,
    };
    let update = await updateDriver(dataDriver);
    update.code == 200 ? await props.refreshTable() : true;
    props.onHide();
  };

  React.useEffect(() => {
    const getUserList = async () => {
      let getUser = await getUserSelect2();
      setUserList(getUser);
    };
    if (Object.values(props.driverData).length > 0) {
      setUserID(props.driverData.driverID);
      setJobRemark(props.driverData.driverRemark);
      setNickName(props.driverData.SystemUser.nickName);
      // setPhoneNumber(props.driverData.SystemUser.phoneNumber);
    }
    if (props.show) {
      getUserList();
    }
  }, [props.show]);



  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader
            toggle={props.onHide}>{props.title}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <InputTypeSelect2Form
                  data={userList}
                  placeholder="--Select Name--"
                  value={userID}
                  nameSelect2="userID"
                  idSelect2="userID"
                  disabled={true}
                  label={t("Name")}
                />
                <InputTypeTextForm
                  label={`${t("Nickname")}${"("}${t("Required")}${")"}`}
                  labelFor="nickname"
                  inputName="nickname"
                  inputId="nickname"
                  inputPlaceholder={t("Nickname")}
                  readOnly={true}
                  value={nickName}
                  required={true}
                />
                <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="jobRemark"
                  inputName="jobRemark"
                  inputId="jobRemark"
                  inputPlaceholder={t("Remark")}
                  type="textarea"
                  value={jobRemark}
                  handleChange={(e) => setJobRemark(e.target.value)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {props.button}
            </Button>
            <Button color="default" type="button" onClick={props.onHide}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default DriverModalEdit;
