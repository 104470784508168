import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { updateGood } from "functions/Goods";
import { updateStore } from "functions/Store.js";
import { StorageRetryPolicyType } from "@azure/storage-blob";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";
import { updateUserJob } from "functions/UserJob.js";
import { getJobTypeArray } from "functions/UserJob.js";
import DropdownControl from "../components/DropdownControl.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form.js";

function UserJobEditModal(props) {
    const { t } = useTranslation();

    // Control
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [job, setJob] = React.useState('');
    const [jobNature, setJobNature] = React.useState('');
    const [jobType, setJobType] = React.useState('');

    const [jobTypeChooseSelect2Data, setJobTypeChooseSelect2Data] = React.useState([]);

    React.useEffect(() => {


        const getJobTypeArrayFromFcn = async () => {
            let data = await getJobTypeArray();
            setJobTypeChooseSelect2Data(data);
        };

        if (props.show) {
            if (Object.values(props.userJobData).length > 0) {
                setJob(props.userJobData.job);
                setJobNature(props.userJobData.jobNature);
                setJobType(props.userJobData.jobType);
                setFinishSubmitBoo(false);
            }
            getJobTypeArrayFromFcn();
        }

    }, [props.show]);


    const onChangeJobNature = (jobNature) => {
        setJobNature(jobNature);
    };


    const onChangeJobType = (jobType) => {
        setJobType(jobType);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        let dataToSubmit = {
            job: job,
            jobNature: jobNature,
            jobType: jobType
        }

        let update = await updateUserJob(dataToSubmit);
        if (update.code == 200) {
            props.refreshTable();
            props.onHide();
        }

    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >{props.title}</ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Job")}${"("}${t("Required")}${")"}`}
                                    labelFor="job"
                                    inputName="job"
                                    inputId="job"
                                    inputPlaceholder={t("Job")}
                                    value={job}
                                    handleChange={(e) => { }}
                                    required={true}
                                    readOnly={true}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col >
                                <InputTypeTextForm
                                    label={`${t("Job Nature")}${"("}${t("Required")}${")"}`}
                                    labelFor="jobNature"
                                    inputName="jobNature"
                                    inputId="jobNature"
                                    inputPlaceholder={t("Job Nature")}
                                    value={jobNature}
                                    handleChange={(e) => onChangeJobNature(e.target.value)}
                                    required={true}
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col >
                                <InputTypeSelect2Form
                                    useTranslate={true}
                                    data={jobTypeChooseSelect2Data}
                                    placeholder={t("--Job type--")}
                                    value={jobType}
                                    nameSelect2="Job type"
                                    idSelect2="Job type"
                                    label={t("Job type")}
                                    handleOnChangeSelect={(e) => onChangeJobType(e.target.value)}
                                />
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter className="mt--4">
                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Save"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default UserJobEditModal;
