/*!

=========================================================
* Argon Dashboard PRO React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

function TimelineHeader(props) {
  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
{/*             <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <Breadcrumb
                  className="d-md-inline-block ml-lg-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem
                    style={{ cursor: "pointer" }}>
                    <a className="h2"
                      onClick={(e) => {
                        e.preventDefault();
                        props.goBackLastPage();
                      }}>
                      <i className={props.icon} />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a onClick={(e) => e.preventDefault()}>
                      <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                        {props.name}
                      </h6>
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem aria-current="page" className="active">
                    {props.childName}
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row> */}
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
