import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";
import { getIntegerDecodeFromString, getRandomBgColor } from "./Utility";

// **************************************************
// FUNCTION

export const filterEffectiveMembershipList = (membershipListToFilter) => {
  if (!membershipListToFilter || !Array.isArray(membershipListToFilter)) {
    return [];
  }
  try {
    let filteredList = [];
    filteredList = membershipListToFilter.filter((item) => {
      let todayDate = new Date();
      let effectiveDate = new Date(item.effectiveDate);
      let expiryDate = new Date(item.expiryDate);
      if (effectiveDate <= todayDate && todayDate <= expiryDate) {
        return true;
      } else {
        // do not return
        return false;
      }
    });

    return filteredList;
  } catch (error) {
    return [];
  }
};


export const getCustomerListFromeMembershipList = (membershipListToFilter) => {
  if (!membershipListToFilter || !Array.isArray(membershipListToFilter)) {
    return [];
  }
  try {
    let customerList = [];
    customerList = membershipListToFilter.map((item, index) => {
      return item.Customer ? item.Customer : item.customer;
    });

    return customerList;
  } catch (error) {
    return [];
  }
};


// **************************************************
// GET

export const getMembershipByCustomerID = async (customerID) => {
  try {
    let response = await apiGet({ url: `membership/getMembershipByCustomerID/${customerID}`, responseType: "" });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const getMembershipByMembershipID = async (membershipID) => {
  try {
    let response = await apiGet({ url: `membership/getMembershipByMembershipID/${membershipID}`, responseType: "" });
    return response.status == 200 ? response.data.data : {};
  } catch (error) {
    return {};
  }
};

export const getMembershipTypeByMembershipTypeID = async (membershipTypeID) => {
  try {
    let response = await apiGet({ url: `membership/getMembershipTypeByMembershipTypeID/${membershipTypeID}`, responseType: "" });
    return response.status == 200 ? response.data.data : {};
  } catch (error) {
    return {};
  }
};



export const getAllMembershipType = async () => {
  try {
    let response = await apiGet({ url: `membership/getAllMembershipType`, responseType: "" });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

// Structure
// { id: "Unpaid", text: "Unpaid", color: "danger" },
// { id: "Partial", text: "Partial", color: "primary" },
// { id: "Paid", text: "Paid", color: "success" },
// { id: "Cancel", text: "Cancel", color: "warning" },
export const getMembershipTypeNameArray = async () => {
  let membershipTypeList = await getAllMembershipType();

  if (membershipTypeList && Array.isArray(membershipTypeList) && membershipTypeList.length >= 1) {
    let returnList = membershipTypeList.map((item, index) => {
      return {
        id: item.membershipTypeID,
        text: item.membershipTypeName,
        color: "primary"
      }
    })
    return returnList;
  } else {
    return [];
  }
};

export const getMembershipTypeSelect2 = async () => {
  let membershipTypeList = await getAllMembershipType();

  if (membershipTypeList && Array.isArray(membershipTypeList) && membershipTypeList.length >= 1) {
    let returnList = membershipTypeList.map((item, index) => {
      return {
        id: item.membershipTypeID,
        text: item.membershipTypeName,
        color: "primary",
        obj: item
      }
    })
    return returnList;
  } else {
    return [];
  }
};


// **************************************************
// POST

export const postNewMembership = async (data) => {

  let response = await apiPost({
    url: "membership/newMembership",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data.data.membershipID // return the new membershipID
    : response.status;
};


export const postNewMembershipType = async (data) => {

  let response = await apiPost({
    url: "membership/newMembershipType",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data.data.membershipTypeID // return the new membershipTypeID
    : response.status;
};



export const matchSingleMembershipToSingleInvoiceSubitem = async (membershipID, invoiceSubitemID) => {
  let data = {
    'membershipID': membershipID,
    'invoiceSubitemID': invoiceSubitemID
  }
  let response = await apiPost({
    url: "membership/matchSingleMembershipToSingleInvoiceSubitem",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data.data
    : response.status;
};


export const matchMembershipTypeWithGoodIDList = async (membershipTypeID, goodIDList) => {
  // goodIDArray: [
  //   {
  //       "goodID": "XXXXXX"
  //   },
  //   {
  //       "goodID": "YYYYYYY"
  //   }
  // ]
  let goodIDArray = [];

  if (goodIDList && Array.isArray(goodIDList) && goodIDList.length >= 1) {
    goodIDArray = goodIDList.map((item, index) => {
      return {'goodID': item}
    })
  } else {
    goodIDArray = [];
  }

  let data = {
    'membershipTypeID': membershipTypeID,
    'goodIDArray': goodIDArray
  }
  let response = await apiPost({
    url: "membership/matchMembershipTypeWithGoodIDList",
    data: data,
    responseType: "",
  });

  return response.status == 200
    ? response.data
    : response.status;
};


// **************************************************
// PUT

export const updateMembership = async (membershipID, customerID, membershipTypeID, effectiveDate, expiryDate) => {
  const dataToSubmit = {
    membershipID: membershipID,
    customerID: customerID,
    membershipTypeID: membershipTypeID,
    effectiveDate: effectiveDate,
    expiryDate: expiryDate
  }

  let response = await apiPut({
    url: "/membership/updateMembership",
    data: dataToSubmit,
    responseType: "",
  });

  return response.status == 200
    ? response.data
    : response.status;
};

export const updateMembershipType = async (membershipTypeID, membershipTypeName, membershipTypeDetail) => {
  const dataToSubmit = {
    membershipTypeID: membershipTypeID,
    membershipTypeName: membershipTypeName,
    membershipTypeDetail: membershipTypeDetail
  }

  let response = await apiPut({
    url: "/membership/updateMembershipType",
    data: dataToSubmit,
    responseType: "",
  });

  return response.status == 200
    ? response.data
    : response.status;
};



// **************************************************
// DELETE

export const deleteMembership = async (membershipID) => {
  let response = await apiDelete({
    url: "membership/deleteMembership/" + membershipID,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteMembershipType = async (membershipTypeID) => {
  let response = await apiDelete({
    url: "membership/deleteMembershipType/" + membershipTypeID,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};
