import React from "react";
// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import {
    getNickname,
    getUserId,
} from "../../../functions/Auth";
import SpinnerOverlay from "../components/SpinnerOverlay";
import PhotoUploadArea from "../components/PhotoUploadArea";
import { getBase64PhotoHeadRemoved } from "functions/Utility";
import { getImageType } from "functions/Utility";
import { isFileImage } from "functions/Utility";
import SubmitButtonInForm from "../components/SubmitButtonInForm";
import { getFrontendResourceImage } from "functions/FrontendResource";
import { uploadFrontendResourceImage } from "functions/FrontendResource";

function FrontendResourceLoginLogoEditModal(props) {
    const { t } = useTranslation();

    const fixedPhotoIDForThisModal = 'loginLogo' // loginLogo.png

    // Control
    const [isLoading, setIsLoading] = React.useState(false);
    const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

    const [targetPhoto1, setTargetPhoto1] = React.useState({
        photo1ID: '',
        photo1Base64Content: "",
        photo1Remark: "",
        photo1Order: "",
        photo1ImageUploadTime: Date(),
        photo1UploadUserID: "",
        photo1UploadUserNickName: ""
    });
    const { photo1ID, photo1Base64Content, photo1Remark, photo1Order, photo1ImageUploadTime, photo1UploadUserID, photo1UploadUserNickName } = targetPhoto1;

    const onChangePhoto1 = (photoIDToUpdate, photoBase64ContentToUpdate, photoRemark, photoOrder, photoImageUploadTime, photoImageUploadUserID, photoImageUploadUserNickName) => {
        setTargetPhoto1({
            photo1ID: photoIDToUpdate,
            photo1Base64Content: photoBase64ContentToUpdate,
            photo1Remark: photoRemark,
            photo1Order: photoOrder,
            photo1ImageUploadTime: photoImageUploadTime,
            photo1UploadUserID: photoImageUploadUserID,
            photo1UploadUserNickName: photoImageUploadUserNickName
        });
    };



    React.useEffect(() => {
        if (props.show) {
            const configResourceAsync = async () => {
                let logoPhotoFromServer = await getFrontendResourceImage(fixedPhotoIDForThisModal);

                onChangePhoto1(
                    logoPhotoFromServer.imageID,
                    logoPhotoFromServer.imageBase64Content,
                    logoPhotoFromServer.imageRemark,
                    logoPhotoFromServer.imageOrder,
                    logoPhotoFromServer.imageUploadTime,
                    "",
                    ""
                    //       logoPhotoFromServer.imageUploadUserData.userID,
                    //     logoPhotoFromServer.imageUploadUserData.nickName,
                )

            }
            configResourceAsync();


            setFinishSubmitBoo(false);
        }
    }, [props.show]);

    const onUploadLocalImageFile = async (photoDataToUploadWithHeader, photoRemark) => {
        if (!isFileImage(photoDataToUploadWithHeader)) {
            return "";
        } else {
            onChangePhoto1(
                fixedPhotoIDForThisModal,
                await getBase64PhotoHeadRemoved(photoDataToUploadWithHeader, false), // false means will not change file type // important
                photoRemark,
                "1",
                Date(),
                getUserId(),
                getNickname()
            )
        }
    }

    const uploadImgNowReturnPhotoID = async (imageID, imageBase64Content) => {
        setIsLoading(true);
        let responseFromServer = await uploadFrontendResourceImage(imageID, getImageType(imageBase64Content).ext, imageBase64Content);
        setIsLoading(false);
        return responseFromServer;

    };

    const downloadImg = async (photoID, photoBase64Data) => {
        try {
            var a = document.createElement("a");
            let imageTypeExt = getImageType(photoBase64Data).ext;
            a.href = `data:image/${imageTypeExt};base64,` + photoBase64Data;
            a.download = photoID + "." + imageTypeExt;
            a.click();

        } catch (error) {

        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let reponse = await uploadImgNowReturnPhotoID(photo1ID, photo1Base64Content);
        if (reponse.code == 200) {
            props.onHide();
        }
    };

    return (
        <>
            {isLoading ? <SpinnerOverlay /> : ""}
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader >
                        <h1>
                            <Trans>Login logo</Trans>
                        </h1>
                    </ModalHeader>
                    <ModalBody className="mt--4">
                        <Row>
                            <Col >
                                <PhotoUploadArea
                                    title={t("Photo Upload")}
                                    useTranslate={true}
                                    isShow={true}
                                    allowPhotoRemark={false}
                                    photoID={photo1ID}
                                    photoBase64Data={photo1Base64Content}
                                    photoRemark={photo1Remark}
                                    photoOrder={photo1Order}
                                    photoUserID={photo1UploadUserID}
                                    photoUserNickName={photo1UploadUserNickName}
                                    photoRecordDateTime={photo1ImageUploadTime}
                                    onUploadPhoto={async (photoDataToUploadWithHeader, photoRemark) => {
                                        onUploadLocalImageFile(photoDataToUploadWithHeader, photoRemark);

                                    }}
                                    onDownloadPhoto={(photoID, photoBase64Content) => {
                                        downloadImg(photoID, photoBase64Content);
                                    }}
                                    onDeletePhoto={(photoID) => {
                                        //   deleteImgNow(photoID);
                                        onChangePhoto1("", "", "", "1", Date(), "", "");
                                    }}
                                    onUpdatePhotoData={async (photoID, photoRemark) => {
                                        //   let returnPhotoID = await updateImgDataNowReturnPhotoID(photoID, photoRemark, "1");
                                    }}
                                />
                            </Col>

                        </Row>



                    </ModalBody>
                    <ModalFooter className="mt--4">

                        <SubmitButtonInForm
                            finishSubmitBoo={finishSubmitBoo}
                            buttonNormalText={"Save"} />

                        <Button color="default" type="button" onClick={props.onHide}>
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
}

export default FrontendResourceLoginLogoEditModal;
