import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Badge,
    Collapse
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getImageType, convertFileToBase64, resizeFile, getBase64PhotoHeadRemoved, getDateString } from "functions/Utility";
import { useEffect } from "react";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import moment from "moment";
import Notifications from "../components/Notifications";
import imageType from "image-type";
//props
//isShow
//title
//photoID
//photoBase64Data
//onUploadPhoto
//hideDownloadButton 
//onDownloadPhoto(photoID, photoBase64Data)
//onDeletePhoto
//useTranslate={true}


//badgeClassName : "statusBadge"/ "statusBadgeSmall"
function PhotoUploadArea(props) {

    const { t } = useTranslation();
    const hiddenFileInput3 = React.useRef(null);
    const [photoID, setPhotoID] = React.useState(null);
    const [photoBase64Data, setPhotoBase64Data] = React.useState(null);
    const [photoRemark, setPhotoRemark] = React.useState("");
    const [photoOrder, setPhotoOrder] = React.useState("");
    const [photoUserID, setPhotoUserID] = React.useState("");
    const [photoUserNickName, setPhotoUserNickName] = React.useState("");
    const [photoRecordDateTime, setPhotoRecordDateTime] = React.useState(Date());

    //For remark control
    const [showRemarkInput, setShowRemarkInput] = React.useState(false);
    const [remarkIsEdited, setRemarkIsEdited] = React.useState(false);

    const allowPhotoRemark = props.allowPhotoRemark;

    const handleClickUpload = (event) => {
        if (photoID !== undefined && photoID !== "") {
            Notifications({
                type: "advice",
                message: t('Cannot upload image. Plesae delete first.')
            });
        } else {
            hiddenFileInput3.current.click();
        }
    };

    // Delete Confirmation
    async function deleteConfirmation(photoID) {
        if (photoID === "" && photoBase64Data === "") return;
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: photoID,
            callback: callBackToDeletePhoto,
        };
        Notifications(alert);
    }

    // Delete API Call
    async function callBackToDeletePhoto(photoID) {
        props.onDeletePhoto(photoID);
    }



    React.useEffect(() => {
        setPhotoBase64Data(props.photoBase64Data);
    }, [props.photoBase64Data]);

    React.useEffect(() => {
        setPhotoID(props.photoID);
    }, [props.photoID]);

    React.useEffect(() => {
        setPhotoRemark(props.photoRemark);
    }, [props.photoRemark]);

    React.useEffect(() => {
        setPhotoOrder(props.photoOrder);
    }, [props.photoOrder]);

    React.useEffect(() => {
        setPhotoUserID(props.photoUserID);
    }, [props.photoUserID]);

    React.useEffect(() => {
        setPhotoRecordDateTime(props.photoRecordDateTime);
    }, [props.photoRecordDateTime]);

    React.useEffect(() => {
        setPhotoUserNickName(props.photoUserNickName);
    }, [props.photoUserNickName]);

    return (
        <>
            {props.isShow ? (
                <div className="mb-3">
                    <div
                        className="mt-3 p-3 d-flex justify-content-between"
                        style={{
                            backgroundImage: (photoBase64Data !== null && photoBase64Data) ? `url(data:image/${getImageType(photoBase64Data).ext};base64,${photoBase64Data})` : "none",
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            border: "3px dotted lightgray",
                            color: "gray",
                            height: "250px",
                            maxWidth: 1000 - 70, //500 -70
                        }}
                    >
                        <div >
                            <div className="h5 text-muted">
                                {props.title}
                            </div>
                            <div>
                                {photoID && <Badge color="info">{photoUserNickName === "" ? photoUserID : photoUserNickName}</Badge>}
                            </div>
                            <div>
                                {photoID && <Badge color="info">{getDateString(photoRecordDateTime, props.useTranslate)}</Badge>}
                            </div>
                            <div>
                                {photoID && <Badge color="info">{photoRemark}</Badge>}
                            </div>

                        </div>

                        <div className="d-flex flex-column justify-content-end">
                            <div className="mt-1 mb-1">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        if (allowPhotoRemark && !showRemarkInput) {
                                            //step 1: open the remark upload
                                            setShowRemarkInput(true);
                                            return;
                                        } else {
                                            //step 2: allow upload
                                            handleClickUpload();
                                        }
                                    }}
                                >
                                    <i className="fas fa-upload" />
                                </Button>
                                <input
                                    // the input is hidden, so the Button click action will trigger input.current.click
                                    multiple="multiple"
                                    type="file"
                                    ref={hiddenFileInput3}
                                    onChange={async (e) => {
                                        await props.onUploadPhoto(e.target?.files[0], photoRemark);
                                        setRemarkIsEdited(false); //coz the remark is uploaded now;
                                    }
                                    }
                                    style={{ display: "none" }}
                                />
                            </div>
                            <div
                                className="mt-1 mb-1"
                                style={{ display: props.hideDownloadButton ? "none" : "initial" }}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        props.onDownloadPhoto(photoID, photoBase64Data);
                                    }
                                    }
                                >
                                    <i className="fas fa-download" />
                                </Button>
                            </div>
                            <div className="mt-1 mb-1">
                                <Button
                                    color="danger"
                                    onClick={() => {
                                        deleteConfirmation(photoID);
                                    }}
                                >
                                    <i className="fas fa-trash" />
                                </Button>
                            </div>
                        </div>
                    </div>
                    {allowPhotoRemark && showRemarkInput &&
                        <div className="d-flex mt-1">
                            <div className="flex-grow-1">
                                <InputTypeTextForm
                                    label=""
                                    labelFor="remarkInput"
                                    inputName="remarkInput"
                                    inputId="remarkInput"
                                    inputPlaceholder={t("Remark")}
                                    value={photoRemark}
                                    handleChange={(e) => {
                                        e.preventDefault();
                                        setRemarkIsEdited(true);
                                        setPhotoRemark(e.target.value);
                                    }}
                                    readOnly={false}
                                />
                            </div>
                            {remarkIsEdited && photoID && photoID != "" && //if photoID === "", never show the update button 
                                <div>
                                    <Button
                                        style={{ height: "46px" }}
                                        color="success"
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            setRemarkIsEdited(false);
                                            await props.onUpdatePhotoData(photoID, photoRemark);
                                        }}>
                                        <i className="fas fa-check" />
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export default PhotoUploadArea;
