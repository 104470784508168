import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Label,
    Col,
    Badge
} from "reactstrap";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { openPDFLink } from "functions/Utility.js";

function PaymentMessageModal(props) {
    const { t } = useTranslation();
    const [RCURL, setRCURL] = React.useState("");
    const [paymentMessage, setPaymentMessage] = React.useState("");

    const [paymentMessageIncludeIN, setPaymentMessageIncludeIN] = React.useState(true); // IN = invoice 
    const [paymentMessageIncludePI, setPaymentMessageIncludePI] = React.useState(true); // PI = payment ref photo
    const [paymentMessageIncludeCompanyChop, setPaymnentMessageIncludeCompanyChop] = React.useState(true);

    const [actionCopyDone, setActionCopyDone] = React.useState(false);

    //props.show means this form is triggered by parent form
    React.useEffect(() => {
        if (props.show) {
            setPaymentMessage(props?.paymentData?.paymentMessage);
            setRCURL(props?.paymentData?.RCURL);
        } else {
            clearForm()
        }
    }, [props.show]);

    React.useEffect(() => {
        editInvoiceMessage(paymentMessageIncludeIN, paymentMessageIncludePI, paymentMessageIncludeCompanyChop);
        editInvoiceURL(paymentMessageIncludeIN, paymentMessageIncludePI, paymentMessageIncludeCompanyChop);
    }, [paymentMessageIncludeIN, paymentMessageIncludePI, paymentMessageIncludeCompanyChop]);


    const clearForm = () => {
        setPaymentMessageIncludeIN(true); // IN = invoice 
        setPaymentMessageIncludePI(true); // PI = payment ref photo
        setPaymnentMessageIncludeCompanyChop(true);
        setActionCopyDone(false);
    }

    const editInvoiceMessage = (INTrueFalse, PITrueFalse, chopTrueFalse) => {
        const chopRegex = /chop=[\w]+/g;
        const INRegex = /IN=[\w]+/g;
        const PIRegex = /PI=[\w]+/g;

        let paymentMessageToProcess = paymentMessage;

        let chopMatchResult = paymentMessageToProcess.match(chopRegex);
        let INMatchResult = paymentMessageToProcess.match(INRegex);
        let PIMatchResult = paymentMessageToProcess.match(PIRegex);
        if (!chopMatchResult || !INMatchResult || !PIMatchResult) {
            // Do nothing and leave
            return;
        }
        paymentMessageToProcess = paymentMessageToProcess.replace(INMatchResult[0], `IN=${INTrueFalse ? 'yes' : 'no'}`)
            .replace(PIMatchResult[0], `PI=${PITrueFalse ? 'yes' : 'no'}`)
            .replace(chopMatchResult[0], `chop=${chopTrueFalse ? 'yes' : 'no'}`);

        setPaymentMessage(paymentMessageToProcess);
    }
    const editInvoiceURL = (INTrueFalse, PITrueFalse, chopTrueFalse) => {
        const chopRegex = /chop=[\w]+/g;
        const INRegex = /IN=[\w]+/g;
        const PIRegex = /PI=[\w]+/g;

        let paymentMessageToProcess = RCURL;

        let chopMatchResult = paymentMessageToProcess.match(chopRegex);
        let INMatchResult = paymentMessageToProcess.match(INRegex);
        let PIMatchResult = paymentMessageToProcess.match(PIRegex);
        if (!chopMatchResult || !INMatchResult || !PIMatchResult) {
            // Do nothing and leave
            return;
        }
        paymentMessageToProcess = paymentMessageToProcess.replace(INMatchResult[0], `IN=${INTrueFalse ? 'yes' : 'no'}`)
            .replace(PIMatchResult[0], `PI=${PITrueFalse ? 'yes' : 'no'}`)
            .replace(chopMatchResult[0], `chop=${chopTrueFalse ? 'yes' : 'no'}`);

        setRCURL(paymentMessageToProcess);

    }
    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader >{props.title}</ModalHeader>
                <ModalBody className="mt--4">
                    <Col md={6} lg={6}>
                        <Label className="form-control-label mt-4">
                            {t("Payment PDF Message")}
                        </Label>
                    </Col>
                    <Col>
                        <InputTypeTextForm
                            label=""
                            labelFor="paymentMessage"
                            inputName="paymentMessage"
                            inputId="paymentMessage"
                            inputPlaceholder={t("Payment PDF Message")}
                            type="textarea"
                            value={paymentMessage}
                            handleChange={(e) => { setPaymentMessage(e.target.value) }}
                        />


                        <div className="d-flex mt-1">
                            <Button
                                className="flex-fill"
                                color={paymentMessageIncludeCompanyChop ? "success" : "danger"}
                                onClick={() => { setPaymnentMessageIncludeCompanyChop(!paymentMessageIncludeCompanyChop) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {paymentMessageIncludeCompanyChop ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Company Chop")}</span>
                            </Button>
                            <Button
                                className="flex-fill"
                                color={paymentMessageIncludeIN ? "success" : "danger"}
                                onClick={() => { setPaymentMessageIncludeIN(!paymentMessageIncludeIN) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {paymentMessageIncludeIN ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Invoice")}</span>
                            </Button>
                            <Button
                                className="flex-fill"
                                color={paymentMessageIncludePI ? "success" : "danger"}
                                onClick={() => { setPaymentMessageIncludePI(!paymentMessageIncludePI) }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    {paymentMessageIncludePI ?
                                        <i className="fas fa-check" />
                                        : <i className="fas fa-times" />}
                                </span>
                                <span className="btn-inner--text">{t("Payment photo")}</span>
                            </Button>
                        </div>

                        <div className="d-flex flex-column align-items-right mt-1" >
                            <Button
                                color={actionCopyDone ? "success" : "primary"}
                                onClick={() => {
                                    navigator.clipboard.writeText(paymentMessage);
                                    setActionCopyDone(true);
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="fas fa-copy" />
                                </span>
                                <span className="btn-inner--text">{actionCopyDone ? t("Copy success") : t("Copy")}</span>
                            </Button>
                        </div>
                    </Col>
                    <Col
                        className="d-flex flex-column align-items-center"
                    >
                        <QRCode
                            onClick={() =>
                                openPDFLink(RCURL)
                            }
                            style={{ cursor: "pointer" }}
                            className="mt-5"
                            value={RCURL ? RCURL : ""}
                            size={200}
                        />
                        <div
                            onClick={() =>
                                openPDFLink(RCURL)
                            }
                            style={{ cursor: "pointer" }}
                            className="text-sm text-underline mt-2"
                        >
                            {t("Click to view")}
                        </div>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" type="button" onClick={props.onHide}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default PaymentMessageModal;
