import React from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Form,
    Col,
    Label,
    Input,
    Row,
    FormGroup,
    Badge,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    UncontrolledTooltip
} from "reactstrap";
import { setUpInvoiceListToPayMessage } from "functions/Payment";
import InputTypeTextForm from "./InputTypeTextForm.js";
import { useTranslation } from "react-i18next";
import { openPDFLink } from "functions/Utility.js";
import { getMultipleInvoiceURL } from "functions/Invoice.js";



//props
//isSameCustomer
//customerDetail
//showReceiptURL
//receiptURL
//invoiceList

function InvoiceListToPayMessageArea(props) {

    const { t } = useTranslation();

    // For control
    const [expandFilter, setExpandFilter] = React.useState(false);
    const [invoiceListToPayMessage, setInvoiceListToPayMessage] = React.useState("");
    const [isInvoiceListValid, setIsInvoiceListValid] = React.useState(false);


    // For invoice list message option
    const [msgShowDeliveryAddressText, setMsgShowDeliveryAddressText] = React.useState(false); // default a false
    const [msgShowInvoiceAmount, setmsgShowInvoiceAmount] = React.useState(true); // default a true
    const [msgShowInvoiceStatus, setMsgShowInvoiceStatus] = React.useState(true); // default a true
    const [msgShowListPage, setMsgShowListPage] = React.useState(true); // default a true
    const [msgShowInvoicePage, setMsgShowInvoicePage] = React.useState(false); // default a false
    const [msgShowCompanyChop, setMsgShowCompanyChop] = React.useState(true); // default a true


    React.useEffect(() => {
        if (props.invoiceList && Array.isArray(props.invoiceList) && props.invoiceList.length >= 1) {
            let invoiceListToConfig = props.invoiceList;

            console.log(invoiceListToConfig);


            invoiceListToConfig.forEach(function (element) {
                element.amountToPayForMessage = element.paidAmount;
                element.unPaidAmountForMessage = element.unPaidAmount;
                element.invoicePrice = element.invoiceNetPrice;
            });

            let messageToSet = setUpInvoiceListToPayMessage(t, props.isSameCustomer, props.customerDetail, props.showReceiptURL, props.receiptURL, invoiceListToConfig
                , msgShowDeliveryAddressText, msgShowInvoiceStatus, msgShowInvoiceAmount
                , msgShowListPage, msgShowInvoicePage, msgShowCompanyChop);
            setInvoiceListToPayMessage(messageToSet);
        }
    }, [props.isSameCustomer, props.customerDetail, props.showReceiptURL, props.receiptURL, props.invoiceList
        , msgShowDeliveryAddressText, msgShowInvoiceStatus, msgShowInvoiceAmount
        , msgShowListPage, msgShowInvoicePage, msgShowCompanyChop
    ]);


    React.useEffect(() => {
        if (!msgShowListPage && !msgShowInvoicePage) {
            // if double false
            // true on other one
            setMsgShowListPage(true);
        }
    }, [msgShowInvoicePage]);


    React.useEffect(() => {
        if (!msgShowListPage && !msgShowInvoicePage) {
            // if double false
            // true on other one
            setMsgShowInvoicePage(true);
        }
    }, [msgShowListPage]);

    React.useEffect(() => {
        if (props.invoiceList && Array.isArray(props.invoiceList) && props.invoiceList.length >= 1) {
            setIsInvoiceListValid(true);
        } else {
            setIsInvoiceListValid(false);
        }
    }, [props.invoiceList]);



    return (
        <Card className="mb--2">
            <CardHeader
                role="tab"
                aria-expanded={expandFilter}
                className="">
                <Row>
                    <Col className="text-right d-flex align-items-begin justify-content-begin">
                        <Button
                            color={isInvoiceListValid ? "primary" : "danger"}
                            id="tooltip443412081"
                            onClick={(e) => {
                                e.preventDefault();
                                if (isInvoiceListValid) {
                                    setExpandFilter(!expandFilter);
                                } else {
                                    setExpandFilter(false);
                                }
                            }}
                            size="md">
                            {isInvoiceListValid ?
                                <>
                                    <span className="btn-inner--icon mr-1">
                                        <i className={expandFilter ? "fa fa-angle-up" : "fa fa-angle-down"} />
                                    </span>
                                    <span className="btn-inner--text">
                                        {t("Show")}
                                    </span>
                                </>
                                :
                                <>
                                    <span className="btn-inner--icon mr-1">
                                        <i className="fas fa-times" />
                                    </span>
                                    <span className="btn-inner--text">
                                        {t("No invoice")}
                                    </span>
                                </>}

                        </Button>
                        {isInvoiceListValid &&
                            <Button
                                id='tooltip443412089'
                                color="primary"
                                onClick={() => navigator.clipboard.writeText(invoiceListToPayMessage)}
                            >
                                <>
                                    <span className="btn-inner--icon mr-1"><i className="fas fa-copy" /></span>
                                    <span className="btn-inner--text">{t("Copy")}</span>
                                </>
                            </Button>
                        }

                        {isInvoiceListValid &&
                            <Button
                                id='tooltip443412090'
                                color="primary"
                                onClick={() => {
                                    let urlToShow = getMultipleInvoiceURL(props.invoiceList, msgShowListPage, msgShowInvoicePage, msgShowCompanyChop);
                                    if (urlToShow != '') {
                                        openPDFLink(urlToShow, "");
                                    }
                                }}
                            >
                                <span className="btn-inner--icon mr-1">
                                    <i className="ni ni-credit-card" />
                                </span>
                                <span className="btn-inner--text">{t("PDF")}</span>
                            </Button>
                        }
                    </Col>
                </Row>
            </CardHeader>
            <Collapse
                role="tabpanel"
                isOpen={expandFilter}
            >
                <CardBody className="">

                    <Row>
                        <Col>
                            <Button
                                className="mt-1"
                                onClick={() => setMsgShowDeliveryAddressText(!msgShowDeliveryAddressText)}
                                color={msgShowDeliveryAddressText ? "info" : "danger"}>
                                <i className={msgShowDeliveryAddressText ? "fas fa-check" : "fas fa-times"} />{" "}{t("Address")}
                            </Button>
                            <Button
                                className="mt-1"
                                onClick={() => setMsgShowInvoiceStatus(!msgShowInvoiceStatus)}
                                color={msgShowInvoiceStatus ? "info" : "danger"}>
                                <i className={msgShowInvoiceStatus ? "fas fa-check" : "fas fa-times"} />{" "}{t("Status")}
                            </Button>
                            <Button
                                className="mt-1"
                                onClick={() => setmsgShowInvoiceAmount(!msgShowInvoiceAmount)}
                                color={msgShowInvoiceAmount ? "info" : "danger"}>
                                <i className={msgShowInvoiceAmount ? "fas fa-check" : "fas fa-times"} />{" "}{t("Amount")}

                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                className="mt-1"
                                onClick={() => setMsgShowListPage(!msgShowListPage)}
                                color={msgShowListPage ? "info" : "danger"}>
                                <i className={msgShowListPage ? "fas fa-check" : "fas fa-times"} />{" "}{t("List")}
                            </Button>
                            <Button
                                className="mt-1"
                                onClick={() => setMsgShowInvoicePage(!msgShowInvoicePage)}
                                color={msgShowInvoicePage ? "info" : "danger"}>
                                <i className={msgShowInvoicePage ? "fas fa-check" : "fas fa-times"} />{" "}{t("Invoice")}
                            </Button>
                            <Button
                                className="mt-1"
                                onClick={() => setMsgShowCompanyChop(!msgShowCompanyChop)}
                                color={msgShowCompanyChop ? "info" : "danger"}>
                                <i className={msgShowCompanyChop ? "fas fa-check" : "fas fa-times"} />{" "}{t("Chop")}

                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <InputTypeTextForm
                                label={t("")}
                                labelFor="paymentMessage"
                                inputName="paymentMessage"
                                inputId="paymentMessage"
                                inputPlaceholder={t("Invoice List Message")}
                                type="textarea"
                                value={invoiceListToPayMessage}
                                handleChange={(e) => { setInvoiceListToPayMessage(e.target.value) }}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}

export default InvoiceListToPayMessageArea;
