import React, { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";
import { uuid4 } from "@sentry/utils";
import { useTranslation, Trans } from "react-i18next";
import Notifications from "../components/Notifications";
import SubmitButton from "../components/SubmitButton.js";

function CustomerModalPhoneCreate(props) {
  const { t } = useTranslation();

  const [newPhone, setNewPhone] = React.useState({
    phoneUserName: "",
    phoneNumber: "",
    phoneType: "whatsapp",
  });

  const { phoneUserName, phoneNumber, phoneType } = newPhone;

  const onInputChange = (e) => {
    setNewPhone({ ...newPhone, [e.target.name]: e.target.value });
  };

  const addPhone = (e) => {
    // check phone user name
    if (phoneUserName === "") {
      Notifications({
        type: "dataCheck",
        message: "Please input a user name"
      });
      return false;
    }

    // check phone phone number
    if (phoneNumber === "") {
      Notifications({
        type: "dataCheck",
        message: "Please input a phone number"
      });
      return false;
    }

    props.returnCustomerPhone(
      uuid4(),
      phoneUserName,
      phoneNumber,
      phoneType
    );


    clearForm();

    // quit the form
    props.onHide();

    return true;
  };


  const clearForm = () => {
    setNewPhone({
      phoneUserName: "",
      phoneNumber: "",
      phoneType: "whatsapp",
    });
  }

  // React.useEffect(() => {
  //   if (Object.values(props.customerPhonesData).length > 0) {
  //     setNewPhone({
  //       phoneNumberID: props.customerPhonesData.phoneNumberID,
  //       phoneUserName: props.customerPhonesData.phoneUserName,
  //       phoneNumber: props.customerPhonesData.phoneNumber,
  //       phoneType: props.customerPhonesData.phoneType,
  //     });
  //   }
  // }, [props.customerPhonesData]);

  return (
    <>
      <Modal
        isOpen={props.show}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <ModalHeader toggle={() => { props.onHide }}><Trans>{props.title}</Trans></ModalHeader>
        <ModalBody>
          <Form>

            <InputTypeTextForm
              label={`${t("Name")}${"("}${t("Required")}${")"}`}
              labelFor="phoneUserName"
              inputName="phoneUserName"
              inputId="phoneUserName"
              inputPlaceholder={t("Name")}
              value={phoneUserName}
              handleChange={(e) => onInputChange(e)}
              required={true}
            />
            <InputTypeTextForm
              type="number"
              label={`${t("Phone Number")}${"("}${t("Required")}${")"}`}
              labelFor="phoneNumber"
              inputName="phoneNumber"
              inputId="phoneNumber"
              inputPlaceholder={t("Phone Number")}
              value={phoneNumber}
              handleChange={(e) => onInputChange(e)}
              required={true}
            />
            <InputTypeSelect2Form
              useTranslate={true}
              data={[
                { id: "main", text: "Main" },
                { id: "company", text: "Company" },
                { id: "whatsapp", text: "Whatsapp" },
                { id: "wechat", text: "Wechat" },
                { id: "telegram", text: "Telegram" },
                { id: "messenger", text: "Messenger" },
                { id: "line", text: "Line" },
              ]}
              value={phoneType}
              nameSelect2="phoneType"
              idSelect2="phoneType"
              label={t("Type")}
              handleOnChangeSelect={(e) => onInputChange(e)}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Row className="w-100 d-flex justify-content-between">
            <div>
              <Button
                color="danger"
                type="button" // type="button" means the form will not submit
                onClick={(e) => {
                  e.preventDefault();
                  clearForm();
                }}
              >
                {t("Reset")}
              </Button>
            </div>
            <div>
              <SubmitButton
                handleSubmit={addPhone}
                buttonNormalText={"Save"}
              />
              <Button color="default" type="button" onClick={props.onHide}>
                {t("Cancel")}
              </Button>
            </div>
          </Row>

        </ModalFooter>
      </Modal>
    </>
  );
}

export default CustomerModalPhoneCreate;
