import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardText,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    CardFooter
} from "reactstrap";
import moment from "moment";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import InvoiceBadge from "../components/InvoiceBadge.js";
import PaymentBadge from "../components/PaymentBadge.js";
import { getDateString, getDateStringWithoutTime } from "functions/Utility";
import SalesAccountingBadge from "../components/SalesAccountingBadge.js";
import { getMultipleInvoiceURL } from "functions/Invoice.js";
import { openPDFLink } from "functions/Utility.js";
import { getInvoiceByDateRange } from "functions/Invoice.js";


//props:
//salesAccountSingleDateData
//onClickCard
//isSelectedCard
function SalesAccountingCard(props) {
    const { t, i18n } = useTranslation();
    const activeLang = i18n.language;
    const salesAccountSingleDateData = props.salesAccountSingleDateData;
    const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;

    const [showFullCard, setShowFullCard] = React.useState(props.showFullCard ? props.showFullCard : true);// default tshow full card

    React.useEffect(() => {
        setShowFullCard(props.showFullCard);
    }, [props.showFullCard]);


    return salesAccountSingleDateData ? (

        <Card
            style={{ cursor: "pointer" }}
            className={isSelectedCard ? "bg-gradient-success" : ""}
        >
            <CardHeader>
                <Row className="">
                    <Col className="d-flex">
                        <div className="flex-grow-1">
                            <Button
                                className="mb-1"
                                color="primary"
                                size="sm"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowFullCard(!showFullCard);
                                }}>
                                <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                            </Button>
                            <SalesAccountingBadge
                                salesAccountingSingleDateData={salesAccountSingleDateData}
                                badgeType="invoiceCount"
                                badgeClassName="statusBadge"
                            />
                            <SalesAccountingBadge
                                salesAccountingSingleDateData={salesAccountSingleDateData}
                                badgeType="deliveryCount"
                                badgeClassName="statusBadge"
                            />
                            <SalesAccountingBadge
                                salesAccountingSingleDateData={salesAccountSingleDateData}
                                badgeType="paymentCount"
                                badgeClassName="statusBadge"
                            />
                        </div>

                        <div className="cardIconButton">

                            <i className="ni ni-credit-card"
                                onClick={async (e) => {
                                    let invoiceListFromDB = await getInvoiceByDateRange(salesAccountSingleDateData.date, salesAccountSingleDateData.date);
                                    
                                    let urlToShow = getMultipleInvoiceURL(invoiceListFromDB, false);
                                    if (urlToShow != '') {
                                        openPDFLink(urlToShow, "");
                                    }

                                }}

                            />
                        </div>

                    </Col>

                </Row>
                <Row onClick={() => { props.onClickCard(salesAccountSingleDateData); }}>
                    <Col>
                        <div className="tableRowTitle">
                            {`$${salesAccountSingleDateData?.salesVolume}`}
                        </div>
                        <div className="font-weight-bold">
                            {`${getDateStringWithoutTime(salesAccountSingleDateData.date, activeLang === "cn")}`}
                        </div>
                        {/* <div className="tableRowRemark">
                            {salesAccountSingleDateData.paymentRefNumber !== "" ? ` ${salesAccountSingleDateData.paymentRefNumber}` : ""}{salesAccountSingleDateData.paymentRemark !== "" ? ` | ${salesAccountSingleDateData.paymentRemark}` : ""}
                        </div> */}

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div
                            style={{ fontSize: "12px" }}
                            className="text-muted mt-1"
                        >
                            {t("Invoice")}:
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {salesAccountSingleDateData.invoiceStatusArray.map((item, i) => (
                            <div
                                key={i}
                                className="d-flex justify-content-between"
                            >
                                <div className="mt-1 font-weight-bold"
                                    onClick={(e) => {
                                        props.onClickInvoiceStatus(item.date, item.date, item.invoiceStatus);
                                    }}>
                                    <SalesAccountingBadge
                                        salesAccountingSingleDateData={item}
                                        badgeType="invoiceStatusWithAmount"
                                        badgeClassName="statusBadge"
                                        clickable={true}
                                    />


                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </CardHeader>
            {showFullCard && salesAccountSingleDateData.deliveryStatusArray && salesAccountSingleDateData.deliveryStatusArray.length >= 1 &&
                <CardBody>
                    <Row>
                        <Col>
                            <div
                                style={{ fontSize: "12px" }}
                                className="text-muted mt--2"
                            >
                                {t("Delivery")}:
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {salesAccountSingleDateData.deliveryStatusArray.map((item, i) => (
                                <div
                                    key={i}
                                    className="d-flex justify-content-between"
                                >
                                    <div className="mt-1 font-weight-bold"
                                        onClick={(e) => {
                                            props.onClickDeliveryStatus(item.date, item.date, item.deliveryStatus);
                                        }}
                                    >
                                        <SalesAccountingBadge
                                            salesAccountingSingleDateData={item}
                                            badgeType="deliveryStatusWithAmount"
                                            badgeClassName="statusBadge"
                                            clickable={true}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </CardBody>
            }
            {showFullCard && (!salesAccountSingleDateData.deliveryStatusArray || salesAccountSingleDateData.deliveryStatusArray.length <= 0) &&
                <CardBody onClick={() => { props.onClickCard(salesAccountSingleDateData); }}>
                    <Row>
                        <Col>
                            <strong className="text">
                                {t("No Delivery Record")}
                            </strong>
                        </Col>
                    </Row>
                </CardBody>
            }
            {showFullCard && salesAccountSingleDateData.paymentMethodArray && salesAccountSingleDateData.paymentMethodArray.length >= 1 &&
                <CardFooter onClick={() => { props.onClickCard(salesAccountSingleDateData); }}>
                    <Row>
                        <Col>

                            <div
                                style={{ fontSize: "12px" }}
                                className="text-muted mt--2"
                            >
                                {t("Payment")}:
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {salesAccountSingleDateData.paymentMethodArray.map((item, i) => (
                                <div
                                    key={i}
                                    className="d-flex justify-content-between"
                                    onClick={(e) => {
                                        props.onClickPaymentMethod(item.date, item.date, item.paymentMethod);
                                    }}
                                >
                                    <div className="mt-1 font-weight-bold">
                                        <SalesAccountingBadge
                                            salesAccountingSingleDateData={item}
                                            badgeType="paymentMethodWithAmount"
                                            badgeClassName="statusBadge"
                                            clickable={true}
                                        />
                                    </div>
                                </div>
                            ))}
                        </Col>
                    </Row>


                </CardFooter>
            }
            {showFullCard && (!salesAccountSingleDateData.paymentMethodArray || salesAccountSingleDateData.paymentMethodArray.length <= 0) &&
                <CardFooter onClick={() => { props.onClickCard(salesAccountSingleDateData); }}>
                    <Row>
                        <Col>
                            <strong className="text">
                                {t("No Payment Record")}
                            </strong>
                        </Col>
                    </Row>
                </CardFooter>
            }
        </Card>
    ) : (
        <>
            <Col xs={12} sm={12} md={12} lg={12}>
                <Card>
                    <CardBody>
                        <strong className="text-info h1">
                            {t("No Data Found!")}
                        </strong>
                        <h3 className="text-info">
                            {t("Please Check Your Filter")}
                        </h3>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
}

export default SalesAccountingCard;
