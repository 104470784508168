import React from "react";
// reactstrap components
import {
    Button,
    Container,
    Row,
    Col,
    Label,
    UncontrolledTooltip,
    Card,
    CardHeader,
    CardBody,
    Input,
    Collapse,
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory } from "react-router-dom";
// core components
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import FilterBadge from "../components/FilterBadge.js";
import { addDays } from "../../../functions/Utility";
import InputDateGroupCombo from "../components/InputDateGroupCombo.js";

import { isAccessible } from "functions/SystemUserAccess.js";
import { getDriverSelect2 } from "functions/Driver.js";
import { object } from "prop-types";
import { getCarSelect2 } from "functions/Car.js";
import { getDeliveryStatusArray } from "functions/Delivery.js";
import { getInvoiceStatusArray } from "functions/Invoice.js";
import moment from "moment";
import { getAccessibleTagForSafeMode } from "functions/SystemUserAccess.js";
//props

// props.initDeliveryRecordData
// prpos.returnShowCard
// props.returnFilteredSortedDeliveryRecordData

function DeliveryRecordSearchBar(props) {
    const { t } = useTranslation();

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [filteredSortedDeliveryRecordData, setFilteredSortedDeliveryRecordData] = React.useState([]);
    const [initDeliveryRecordData, setinitDeliveryRecordData] = React.useState([]);

    // GUI
    const [showFullCard, setShowFullCard] = React.useState(false);
    const [showSortingButton, setShowSortingButton] = React.useState(false);

    // Sorting
    const [useSortPrice, setUseSortPrice] = React.useState(false);
    const [useSortDate, setUseSortDate] = React.useState(false);
    const [useSortDriver, setUseSortDriver] = React.useState(false);
    const [useSortCarID, setUseSortCarId] = React.useState(false);
    const [useSortDeliveryStatus, setUseSortDeliveryStatus] = React.useState(false);
    const [useSortInvoiceStatus, setUseSortInvoiceStatus] = React.useState(false);
    const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
    const [sortDateSmall, setSortDateSmall] = React.useState(true);
    const [sortDriverSmall, setSortDriverSmall] = React.useState(false);
    const [sortCarIDSmall, setSortCarIDSmall] = React.useState(false);
    const [sortDeliveryStatusSmall, setSortDeliveryStatusSmall] = React.useState(false);
    const [sortInvoiceStatusSmall, setSortInvoiceStatusSmall] = React.useState(false);

    // For filter string
    const [searchString, setSearchString] = React.useState("");

    // For filter driver
    // In a structure [{id:"TBC",text:"小明",systemUser:{Object},isChecked:true/false}]
    const [driverChooseData, setDriverChooseData] = React.useState([]);

    // For filter carID
    // In a structure [{id:"SH5721",text:"SH5721",isChecked:true/false}]
    const [carIDChooseData, setCarIDChooseData] = React.useState([]);

    // For filter deliveryStatusArray
    // In a structure [{id:"pending",text:"pending",isChecked:true/false}]
    const [deliveryStatusChooseData, setDeliveryStatusChooseData] = React.useState([]);

    // For filter invoiceStatusArray
    // In a structure [{id:"paid",text:"paid",isChecked:true/false}]
    const [invoiceStatusChooseData, setInvoiceStatusChooseData] = React.useState([]);

    // For filter search date
    const dataIntervalInteger = 10;
    const [useStartDate, setUseStartDate] = React.useState(true);
    const [useEndDate, setUseEndDate] = React.useState(true);
    const [searchStartDate, setSearchStartDate] = React.useState(new Date());
    const [searchEndDate, setSearchEndDate] = React.useState(new Date());
    const [dataRangeStartDate, setDataRangeStartDate] = React.useState(moment(new Date()).add(dataIntervalInteger * -1, 'days').toDate());
    const [dataRangeEndDate, setDataRangeEndDate] = React.useState(moment(new Date()).add(dataIntervalInteger, 'days').toDate());


    // For filter control show
    const [showFilterSearch, setShowFilterSearch] = React.useState(false);
    const [showFilterProposedDateTimeRange, setShowFilterProposedDateTimeRange] = React.useState(false);
    const [showFilterDriver, setShowFilterDriver] = React.useState(false);
    const [showFilterCarID, setShowFilterCarID] = React.useState(false);
    const [showFilterDeliveryStatusArray, setShowFilterDeliveryStatusArray] = React.useState(false);
    const [showFilterInvoiceStatusArray, setShowFilterInvoiceStatusArray] = React.useState(false);

    // For control
    const [expandFilter, setExpandFilter] = React.useState(false);

    React.useEffect(() => {
        const getDriverDataFromDB = async () => {
            let driverData = await getDriverSelect2();
            // Add a value isChecked
            if (driverData && Object.values(driverData).length >= 1) {
                driverData.forEach((item, index) => {
                    item.isChecked = true; // default a true
                })
                setDriverChooseData(driverData);
            } else {
                setDriverChooseData([]);
            }
        };
        const getCarIDDataFromDB = async () => {
            let carIDData = await getCarSelect2();
            // Add a value isChecked
            if (carIDData && Object.values(carIDData).length >= 1) {
                carIDData.forEach((item, index) => {
                    item.isChecked = true; // default a true
                })
                setCarIDChooseData(carIDData);
            } else {
                setCarIDChooseData([]);
            }
        };
        const getDeliveryStatusChooseDataFromFunction = async () => {
            let deliveryStatusArray = getDeliveryStatusArray();
            // Add a value isChecked
            if (deliveryStatusArray && Object.values(deliveryStatusArray).length >= 1) {
                deliveryStatusArray.forEach((item, index) => {
                    item.isChecked = true; // default a true
                })
                setDeliveryStatusChooseData(deliveryStatusArray);
            } else {
                setDeliveryStatusChooseData([]);
            }
        };
        const getInvoiceStatusArrayFromFunction = async () => {
            let invoiceStatusArray = getInvoiceStatusArray();
            // Add a value isChecked
            if (invoiceStatusArray && Object.values(invoiceStatusArray).length >= 1) {
                invoiceStatusArray.forEach((item, index) => {
                    item.isChecked = true; // default a true
                })
                setInvoiceStatusChooseData(invoiceStatusArray);
            } else {
                setInvoiceStatusChooseData([]);
            }
        };

        // Only do once
        getDriverDataFromDB();
        getCarIDDataFromDB();
        getDeliveryStatusChooseDataFromFunction();
        getInvoiceStatusArrayFromFunction();
    }, []);



    // showFilterDriver , showFilterCarID ... etc. are multually exclusive
    // Process in same useEffect function
    React.useEffect(() => {
        const enableBgColor = showFilterDriver || showFilterCarID || showFilterDeliveryStatusArray || showFilterInvoiceStatusArray || showFilterProposedDateTimeRange;
        props.changeCardColor(enableBgColor, showFilterDriver, showFilterCarID, showFilterDeliveryStatusArray, showFilterInvoiceStatusArray, showFilterProposedDateTimeRange);
    }, [showFilterDriver, showFilterCarID, showFilterDeliveryStatusArray, showFilterInvoiceStatusArray, showFilterProposedDateTimeRange]);

    React.useEffect(() => {
        // do an inital sort
        searchDataMain("", "");
    }, [initDeliveryRecordData, useStartDate, useEndDate, refreshTable]);

    React.useEffect(() => {
        setDataRangeStartDate(props.dataRangeStartDate);
        setDataRangeEndDate(props.dataRangeEndDate);
    }, [props.dataRangeStartDate, props.dataRangeEndDate]);

    // Date is very important in getting data from the server
    // Have to tackle them specifically
    React.useEffect(() => {
        if (!useStartDate || !useEndDate) {
            // any one off 
            // must trigger reload the initial data from the server
            // will ignore the date range in the server
            props.returnStartDateEndDate(searchStartDate, searchEndDate, useStartDate, useEndDate);
        }
        if (useStartDate && useEndDate) {
            if (searchStartDate > searchEndDate) {
                // error case
                return;
            }
            if (dataRangeStartDate > dataRangeEndDate) {
                // error case
                return;
            }
            // a logic to compare calendarDate and dataDate
            if (dataRangeStartDate <= searchStartDate && searchEndDate <= dataRangeEndDate) {
                // save, go on
                // just do not trigger the data fetching from server
                return;
            }
            if (dataRangeStartDate > searchStartDate || searchEndDate > dataRangeEndDate) {
                // extend the data date range 
                props.returnStartDateEndDate(moment(searchStartDate).add(dataIntervalInteger * -1, 'days').toDate(), (moment(searchEndDate).add(dataIntervalInteger, 'days').toDate()), useStartDate, useEndDate);
            }
        }
    }, [searchStartDate, searchEndDate, useStartDate, useEndDate, dataRangeStartDate, dataRangeEndDate]);


    React.useEffect(() => {
        setinitDeliveryRecordData(props.initDeliveryRecordData);
    }, [props.initDeliveryRecordData]);

    React.useEffect(() => {
        // this is the key return
        props.returnFilteredSortedDeliveryRecordData(filteredSortedDeliveryRecordData);
    }, [filteredSortedDeliveryRecordData, refreshTable]);

    React.useEffect(() => {
        // this is the key return
        props.returnShowFullCard(showFullCard);
    }, [showFullCard]);


    const closeAllFilterControl = () => {
        setShowFilterSearch(false);
        setShowFilterProposedDateTimeRange(false);
        setShowFilterDriver(false);
        setShowFilterCarID(false);
        setShowFilterDeliveryStatusArray(false);
        setShowFilterInvoiceStatusArray(false);
    }

    const countDriverStatusOn = () => {
        let counter = 0;
        if (driverChooseData && driverChooseData.length >= 1 && Object.values(driverChooseData).length >= 1) {
            driverChooseData.forEach((item) => {
                if (item.isChecked) {
                    counter++;
                }
            })
        }
        return counter;
    }
    const countCarIDStatusOn = () => {
        let counter = 0;
        if (carIDChooseData && carIDChooseData.length >= 1 && Object.values(carIDChooseData).length >= 1) {
            carIDChooseData.forEach((item) => {
                if (item.isChecked) {
                    counter++;
                }
            })
        }
        return counter;
    }

    const countInvoiceStatusOn = () => {
        try {
            let counter = 0;
            if (invoiceStatusChooseData && invoiceStatusChooseData.length >= 1 && Object.values(invoiceStatusChooseData).length >= 1) {
                invoiceStatusChooseData.forEach((item) => {
                    if (item.isChecked) {
                        counter++;
                    }
                })
            }
            return counter;
        } catch (error) {

            return 0;
        }

    }

    const countDeliveryStatusOn = () => {
        let counter = 0;
        if (deliveryStatusChooseData && deliveryStatusChooseData.length >= 1 && Object.values(deliveryStatusChooseData).length >= 1) {
            deliveryStatusChooseData.forEach((item) => {
                if (item.isChecked) {
                    counter++;
                }
            })
        }
        return counter;
    }

    const resetFilter = () => {
        setSearchString("");

        setUseStartDate(true);
        setUseEndDate(true);
        setSearchStartDate(new Date());
        setSearchEndDate(new Date());

        resetAllUseSort();
        resetAllSortOrder();

        try {
            let tempDriverDate = driverChooseData;
            tempDriverDate.forEach(item => item.isChecked = true);
            setDriverChooseData([...tempDriverDate]);
        } catch (error) {
        }

        try {
            let tempCarIDData = carIDChooseData;
            tempCarIDData.forEach(item => item.isChecked = true);
            setCarIDChooseData([...tempCarIDData]);
        } catch (error) {
        }

        try {
            let tempData_3 = deliveryStatusChooseData;
            tempData_3.forEach(item => item.isChecked = true);
            setDeliveryStatusChooseData([...tempData_3]);
        } catch (error) {
        }

        try {
            let tempData_4 = invoiceStatusChooseData;
            tempData_4.forEach(item => item.isChecked = true);
            setInvoiceStatusChooseData([...tempData_4]);
        } catch (error) {
        }

        setRefreshTable(refreshTable + 1);
    };

    // This is the main filter
    const searchDataMain = (searchType, searchValue, searchValue2 = null) => {
        const value = searchValue;
        const value2 = searchValue2;
        let res = [];
        let searchString_ = searchString;
        let searchStartDate_ = searchStartDate;
        let searchEndDate_ = searchEndDate;
        if (searchType === "search") {
            setSearchString(value);
            searchString_ = value;
        } else if (searchType === "startDate") {
            setSearchStartDate(value);
            searchStartDate_ = value;
        } else if (searchType === "endDate") {
            setSearchEndDate(value);
            searchEndDate_ = value;
        } else if (searchType === "period") {
            setSearchStartDate(value);
            searchStartDate_ = value;
            setSearchEndDate(value2);
            searchEndDate_ = value2;
        } else {

        }

        const filterFuncString = (data) => {
            if (searchString_ !== "") {
                const address =
                    data?.DeliveryAddress?.addressDetailStreet +
                    ", " +
                    data?.DeliveryAddress?.addressDetailBuilding +
                    ", " +
                    data?.DeliveryAddress?.addressDetailStreet +
                    ", " +
                    data?.DeliveryAddress?.addressDetailDistrict +
                    ", " +
                    data?.DeliveryAddress?.addressDetailMainDistrict +
                    ", " +
                    data?.DeliveryAddress?.addressDetailCity;
                return (
                    data.invoiceID.includes(searchString_) ||
                    data?.Invoice?.Customer?.customerName.includes(searchString_) ||
                    address.includes(searchString_) ||
                    data.driverID.includes(searchString_) ||
                    data.carID.includes(searchString_)
                );
            } else {
                return true;
            }
        };
        const filterFuncStartDate = (data) => {
            if (searchStartDate_ !== "") {
                const extDate = new Date(data.proposedDateTime);
                const filterDate = new Date(searchStartDate_);
                filterDate.setHours(0, 0, 0, 0);
                return extDate >= filterDate;
            } else {
                return true;
            }
        };
        const filterFuncEndDate = (data) => {
            if (searchEndDate_ !== "") {
                const extDate = new Date(data.proposedDateTime);
                const filterDate = new Date(searchEndDate_);
                filterDate.setHours(23, 59, 59, 999);
                return extDate <= filterDate;
            } else {
                return true;
            }
        };

        const filterFuncDriverUserLogin = (data) => {
            if (isAccessible('deliveryScreenShowAllDriver')) {
                // got the token
                // skip the filter driver
                return true;
            } else {
                // only allow the current user to check himself
                let userID = localStorage.getItem('userID');
                return data?.driverID === userID;
            }

        };

        const filterFuncDriverData = (data) => {
            try {
                // Cross check with driverChooseData
                return driverChooseData.findIndex((item) => { return (item.id === data?.driverID) && (item.isChecked) }) != -1;
            } catch (error) {
                return true;
            }
        };

        const filterFuncCarIDData = (data) => {
            try {
                // Cross check with carIDChooseData
                return carIDChooseData.findIndex((item) => { return (item.id === data?.carID) && (item.isChecked) }) != -1;
            } catch (error) {
                return true;
            }
        };

        const filterFuncDeliveryStatusArray = (data) => {
            try {
                // Cross check with deliveryStatusArray
                return deliveryStatusChooseData.findIndex((item) => { return (item.id === data?.deliveryStatus) && (item.isChecked) }) != -1;
            } catch (error) {
                return true;
            }
        };

        const filterFuncInvoiceStatusArray = (data) => {
            try {
                // Cross check with invoiceStatusArray
                return invoiceStatusChooseData.findIndex((item) => { return (item.id === data?.Invoice.invoiceStatus) && (item.isChecked) }) != -1;
            } catch (error) {
                return true;
            }
        };

        res = initDeliveryRecordData.filter(filterFuncString);
        if (useStartDate) {
            res = res.filter(filterFuncStartDate);
        }
        if (useEndDate) {
            res = res.filter(filterFuncEndDate);
        }

        res = res.filter(filterFuncDriverUserLogin);
        res = res.filter(filterFuncDriverData);
        res = res.filter(filterFuncCarIDData);
        res = res.filter(filterFuncDeliveryStatusArray);
        res = res.filter(filterFuncInvoiceStatusArray);


        if (useSortPrice) {
            res = sortPriceWithOutput(res);
        } else if (useSortDate) {
            res = sortDateWithOutput(res);
        } else if (useSortDriver) {
            res = sortDriverWithOutput(res);
        } else if (useSortCarID) {
            res = sortCarIDWithOutput(res);
        } else if (useSortDeliveryStatus) {
            res = sortDeliveryStatusWithOutput(res);
        } else if (useSortInvoiceStatus) {
            res = sortInvoiceStatusWithOutput(res);
        }

        setFilteredSortedDeliveryRecordData(res);
    };

    const resetAllUseSort = () => {
        setUseSortPrice(false);
        setUseSortDate(false);
        setUseSortDriver(false);
        setUseSortCarId(false);
        setUseSortDeliveryStatus(false);
        setUseSortInvoiceStatus(false);
    }

    const resetAllSortOrder = () => {
        setSortDateSmall(false);
        setSortPriceSmall(false);
        setSortDriverSmall(false);
        setSortCarIDSmall(false);
        setSortDeliveryStatusSmall(false);
        setSortInvoiceStatusSmall(false);
    }

    const sortPrice = (refreshBoo) => {
        resetAllUseSort();
        setUseSortPrice(true);
        setSortPriceSmall(!sortPriceSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    const sortDate = (refreshBoo) => {
        resetAllUseSort();
        setUseSortDate(true);
        setSortDateSmall(!sortDateSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };


    const sortDriver = (refreshBoo) => {
        resetAllUseSort(); resetAllUseSort
        setUseSortDriver(true);
        setSortDriverSmall(!sortDriverSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    const sortCarID = (refreshBoo) => {
        resetAllUseSort();
        setUseSortCarId(true);
        setSortCarIDSmall(!sortCarIDSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    const sortDeliveryStatus = (refreshBoo) => {
        resetAllUseSort();
        setUseSortDeliveryStatus(true);
        setSortDeliveryStatusSmall(!sortDeliveryStatusSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };


    const sortInvoiceStatus = (refreshBoo) => {
        resetAllUseSort();
        setUseSortInvoiceStatus(true);
        setSortInvoiceStatusSmall(!sortInvoiceStatusSmall);
        if (refreshBoo) {
            setRefreshTable(refreshTable + 1);
        }
    };

    // take in data, sort and return
    const sortDateWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }

        // setSortPriceSmall(true);
        let res = [];
        if (!sortDateSmall) {
            res = dataToSort.sort(
                (a, b) => {
                    return new Date(a.proposedDateTime) - new Date(b.proposedDateTime)
                }
            );
        } else {
            res = dataToSort.sort(
                (a, b) => {
                    return new Date(b.proposedDateTime) - new Date(a.proposedDateTime)
                });
        }
        return res;
    };

    // take in data, sort and return
    const sortPriceWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        //setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = dataToSort.sort(
                (a, b) => a?.Invoice?.invoiceNetPrice - b?.Invoice?.invoiceNetPrice
            );
        } else {
            res = dataToSort.sort(
                (a, b) => b?.Invoice?.invoiceNetPrice - a?.Invoice?.invoiceNetPrice
            );
        }
        return res;
    };

    // take in data, sort and return
    const sortDriverWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        let res = [];
        const smallBigControl = sortDriverSmall ? 1 : -1;
        res = dataToSort.sort(
            (a, b) => {
                const nameA = a.driverID.toUpperCase(); // ignore upper and lowercase
                const nameB = b.driverID.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1 * smallBigControl;
                }
                if (nameA > nameB) {
                    return 1 * smallBigControl;
                }
                // names must be equal
                return 0;
            }
        );
        return res;
    };

    // take in data, sort and return
    const sortCarIDWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        let res = [];
        const smallBigControl = sortCarIDSmall ? 1 : -1;
        res = dataToSort.sort(
            (a, b) => {
                const nameA = a.carID.toUpperCase(); // ignore upper and lowercase
                const nameB = b.carID.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1 * smallBigControl;
                }
                if (nameA > nameB) {
                    return 1 * smallBigControl;
                }
                // names must be equal
                return 0;
            }
        );
        return res;
    };

    // take in data, sort and return
    const sortDeliveryStatusWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        let res = [];
        const smallBigControl = sortDeliveryStatusSmall ? 1 : -1;
        res = dataToSort.sort(
            (a, b) => {
                const nameA = a.deliveryStatus.toUpperCase(); // ignore upper and lowercase
                const nameB = b.deliveryStatus.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1 * smallBigControl;
                }
                if (nameA > nameB) {
                    return 1 * smallBigControl;
                }
                // names must be equal
                return 0;
            }
        );
        return res;
    };

    // take in data, sort and return
    const sortInvoiceStatusWithOutput = (dataToSort) => {
        if (!dataToSort) {
            return []; //return a empty array
        }
        let res = [];
        const smallBigControl = sortInvoiceStatusSmall ? 1 : -1;
        res = dataToSort.sort(
            (a, b) => {
                const nameA = a.Invoice.invoiceStatus.toUpperCase(); // ignore upper and lowercase
                const nameB = b.Invoice.invoiceStatus.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1 * smallBigControl;
                }
                if (nameA > nameB) {
                    return 1 * smallBigControl;
                }
                // names must be equal
                return 0;
            }
        );
        return res;
    };

    return (
        <Card className="mb--2">
            <CardHeader
                role="tab"
                aria-expanded={expandFilter}>
                <Row>
                    <Col
                        className=" align-items-begin justify-content-begin" >

                        <Row>
                            <Col className="col-auto mt-1 pr-0" >
                                <span>
                                    {getAccessibleTagForSafeMode('deliveryScreenShowAllDriver')}
                                </span>
                                <Button
                                    onClick={() => {
                                        if (showFilterSearch && expandFilter) {
                                            setShowFilterSearch(!showFilterSearch);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterSearch && !expandFilter) {
                                            setShowFilterSearch(!showFilterSearch);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterSearch && expandFilter) {
                                            //Close the other
                                            closeAllFilterControl();
                                            setShowFilterSearch(true);
                                            // setExpandFilter(true);
                                        }
                                    }}
                                    className="mt-1"
                                    size=""
                                    color={expandFilter && showFilterSearch ? "default" : "primary"}>
                                    <i className={`fa fa-angle-${expandFilter && showFilterSearch ? "up" : "down"}`} />{" "}{t("Search")}
                                    {searchString && searchString !== "" && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
                                </Button>

                                <Button
                                    onClick={() => {
                                        if (showFilterDriver && expandFilter) {
                                            setShowFilterDriver(false);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterDriver && !expandFilter) {
                                            setShowFilterDriver(true);
                                            sortDriver(true);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterDriver && expandFilter) {
                                            //Close the other
                                            closeAllFilterControl();
                                            setShowFilterDriver(true);
                                            sortDriver(true);
                                        }
                                    }}
                                    className="mt-1"
                                    color={expandFilter && showFilterDriver ? "default" : "primary"}>
                                    <i className={`fa fa-angle-${expandFilter && showFilterDriver ? "up" : "down"}`} />{countDriverStatusOn() != 0 || " "}{t("Driver")}
                                    {countDriverStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countDriverStatusOn()}</Badge>}
                                </Button>

                                <Button
                                    onClick={() => {
                                        if (showFilterCarID && expandFilter) {
                                            setShowFilterCarID(false);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterCarID && !expandFilter) {
                                            setShowFilterCarID(true);
                                            sortCarID(true); // auto follow a sort
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterCarID && expandFilter) {
                                            //Close the other
                                            closeAllFilterControl();
                                            setShowFilterCarID(true);
                                            sortCarID(true); // auto follow a sort
                                            // setExpandFilter(true);
                                        }
                                    }}
                                    className="mt-1"
                                    color={expandFilter && showFilterCarID ? "default" : "primary"}>
                                    <i className={`fa fa-angle-${expandFilter && showFilterCarID ? "up" : "down"}`} />{countCarIDStatusOn() != 0 || " "}{t("CarID")}
                                    {countCarIDStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countCarIDStatusOn()}</Badge>}
                                </Button>

                                <Button
                                    onClick={() => {
                                        if (showFilterDeliveryStatusArray && expandFilter) {
                                            setShowFilterDeliveryStatusArray(false);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterDeliveryStatusArray && !expandFilter) {
                                            setShowFilterDeliveryStatusArray(true);
                                            sortDeliveryStatus(true); // auto follow a sort
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterDeliveryStatusArray && expandFilter) {
                                            //Close the other
                                            closeAllFilterControl();
                                            setShowFilterDeliveryStatusArray(true);
                                            sortDeliveryStatus(true); // auto follow a sort
                                            // setExpandFilter(true);
                                        }
                                    }}
                                    className="mt-1"
                                    color={expandFilter && showFilterDeliveryStatusArray ? "default" : "primary"}>
                                    <i className={`fa fa-angle-${expandFilter && showFilterDeliveryStatusArray ? "up" : "down"}`} />{countDeliveryStatusOn() != 0 || " "}{t("Status")}
                                    {countDeliveryStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countDeliveryStatusOn()}</Badge>}
                                </Button>
                                <Button
                                    onClick={() => {
                                        if (showFilterInvoiceStatusArray && expandFilter) {
                                            setShowFilterInvoiceStatusArray(false);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterInvoiceStatusArray && !expandFilter) {
                                            setShowFilterInvoiceStatusArray(true);
                                            sortInvoiceStatus(true); // auto follow a sort
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterInvoiceStatusArray && expandFilter) {
                                            //Close the other
                                            closeAllFilterControl();
                                            setShowFilterInvoiceStatusArray(true);
                                            sortInvoiceStatus(true); // auto follow a sort
                                            // setExpandFilter(true);
                                        }
                                    }}
                                    className="mt-1"
                                    color={(expandFilter && showFilterInvoiceStatusArray) ? "default" : "primary"}
                                >
                                    <i className={`fa fa-angle-${expandFilter && showFilterInvoiceStatusArray ? "up" : "down"}`} />{countInvoiceStatusOn() == 0 && " "}{t("Invoice")}
                                    {
                                        countInvoiceStatusOn() != 0 && <Badge color="success" className="badge-md badge-circle badge-floating border-white">{countInvoiceStatusOn()}</Badge>
                                    }
                                </Button>


                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (showFilterProposedDateTimeRange && expandFilter) {
                                            // Close it
                                            setShowFilterProposedDateTimeRange(false);
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterProposedDateTimeRange && !expandFilter) {
                                            setShowFilterProposedDateTimeRange(true);
                                            sortDate(true); // auto follow a sort
                                            setExpandFilter(!expandFilter);
                                        }
                                        if (!showFilterProposedDateTimeRange && expandFilter) {
                                            //Close the other
                                            closeAllFilterControl();
                                            setShowFilterProposedDateTimeRange(true);
                                            sortDate(true); // auto follow a sort
                                            // setExpandFilter(true);
                                        }
                                    }}
                                    className="mt-1"
                                    color={expandFilter && showFilterProposedDateTimeRange ? "default" : "primary"}>
                                    <i className={`fa fa-angle-${expandFilter && showFilterProposedDateTimeRange ? "up" : "down"}`} />{(useStartDate || useEndDate) || " "}{t("Date")}
                                    {(useStartDate || useEndDate) && <Badge color="success" className="badge-md badge-circle badge-floating border-white"><i className="fas fa-check" /></Badge>}
                                </Button>

                            </Col>
                            <Col className="col-auto mt-1  pr-0" >
                                <Button
                                    onClick={() => { setShowFullCard(!showFullCard) }}
                                    className="mt-1"
                                    color="info">
                                    <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                                </Button>


                                <Button
                                    className="mt-1"
                                    onClick={() => { setShowSortingButton(!showSortingButton) }}
                                    color="info">
                                    <i className={`fas fa-sort-numeric-${showSortingButton ? "up" : "down"}`} />{t("Sort")}
                                </Button>


                                <Button
                                    className="mt-1"
                                    color="danger"
                                    id="tooltip443412080"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        resetFilter();
                                    }}
                                >
                                    <span className="btn-inner--text">{t("Reset Filter")}</span>
                                </Button>
                                <UncontrolledTooltip delay={1} target="tooltip443412080">
                                    {t("Reset Filter")}{"/"}{t("Clear Sorting")}
                                </UncontrolledTooltip>

                            </Col>
                        </Row>
                        {showSortingButton &&
                            <Row>
                                <Col className="col-auto mt-1 pr-0" >

                                    <Button
                                        className="mt-1"
                                        onClick={() => sortDriver(true)}
                                        color={useSortDriver ? "default" : "info"}>
                                        <i className={`fas fa-sort-numeric-${sortDriverSmall ? "up" : "down"}`} />{t("Driver")}
                                    </Button>

                                    <Button
                                        className="mt-1"
                                        onClick={() => sortCarID(true)}
                                        color={useSortCarID ? "default" : "info"}>
                                        <i className={`fas fa-sort-numeric-${sortCarIDSmall ? "up" : "down"}`} />{t("CarID")}
                                    </Button>

                                    <Button
                                        className="mt-1"
                                        onClick={() => sortDeliveryStatus(true)}
                                        color={useSortDeliveryStatus ? "default" : "info"}>
                                        <i className={`fas fa-sort-numeric-${sortDeliveryStatusSmall ? "up" : "down"}`} />{t("Status")}
                                    </Button>


                                    <Button
                                        className="mt-1"
                                        onClick={() => sortInvoiceStatus(true)}
                                        color={useSortInvoiceStatus ? "default" : "info"}>
                                        <i className={`fas fa-sort-numeric-${sortInvoiceStatusSmall ? "up" : "down"}`} />{t("Invoice")}
                                    </Button>

                                    <Button
                                        onClick={() => sortPrice(true)}
                                        className="mt-1"
                                        color={useSortPrice ? "default" : "info"}>
                                        <i className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"}`} />{t("Price")}
                                    </Button>

                                    <Button
                                        className="mt-1"
                                        onClick={() => sortDate(true)}
                                        color={useSortDate ? "default" : "info"}>
                                        <i className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"}`} />{t("Date")}
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </CardHeader>
            <Collapse role="tabpanel"
                isOpen={expandFilter}>
                <CardBody>
                    <Row className="">
                        <Col xs={12} sm={12} md={12} lg={12}>

                            {showFilterSearch &&
                                <Row className="mt-2">
                                    <Col
                                        className="mt-1"
                                        lg={12} md={12} xs={12}
                                    >
                                        <InputTypeTextForm
                                            label={t("Search")}
                                            labelFor="searchText"
                                            inputName="searchText"
                                            inputId="searchText"
                                            inputPlaceholder={t("Search (Invoice number/Customer/Address/Driver/CarID)")}
                                            value={searchString}
                                            handleChange={(e) => {
                                                e.preventDefault();
                                                searchDataMain("search", e?.target?.value);
                                            }}
                                            readOnly={false}
                                        />

                                    </Col>
                                </Row>
                            }

                            {showFilterProposedDateTimeRange &&
                                <>
                                    <InputDateGroupCombo
                                        labelFor="proposedDateTime"
                                        inputName="proposedDateTime"
                                        inputId="proposedDateTime"
                                        useStartDate={useStartDate}
                                        useEndDate={useEndDate}
                                        changeUseStartDate={(boo) => { setUseStartDate(boo); }}
                                        changeUseEndDate={(boo) => { setUseEndDate(boo); }}
                                        startDate={searchStartDate}
                                        endDate={searchEndDate}
                                        changeStartDate={(date) => { searchDataMain("startDate", date); }}
                                        changeEndDate={(date) => { searchDataMain("endDate", date); }}
                                        changePeriod={(startDate, endDate) => {
                                            searchDataMain("period", startDate, endDate);
                                        }}
                                    >
                                    </InputDateGroupCombo>

                                </>
                            }




                            {showFilterDriver &&
                                <Row className="mt-2 ml-1">
                                    {driverChooseData.map((driverItem, index) => (
                                        <FilterBadge
                                            key={index}
                                            textToShow={driverItem.text}
                                            isChecked={driverItem.isChecked}
                                            setIsChecked={(isChecked) => {
                                                let selectedItemIndex = driverChooseData.findIndex((item) => { return item.id === driverItem.id });
                                                if (selectedItemIndex != -1) {
                                                    try {
                                                        driverChooseData[selectedItemIndex].isChecked = isChecked;
                                                        setDriverChooseData([...driverChooseData]);
                                                    } catch (error) {
                                                    }
                                                }
                                                searchDataMain("", "");
                                            }}
                                        />
                                    )
                                    )
                                    }
                                </Row>
                            }
                            {showFilterCarID &&
                                <Row className="mt-2 ml-1">
                                    {carIDChooseData.map((carIDItem, index) => (
                                        <FilterBadge
                                            key={index}
                                            textToShow={carIDItem.text}
                                            isChecked={carIDItem.isChecked}
                                            setIsChecked={(isChecked) => {
                                                let selectedItemIndex = carIDChooseData.findIndex((item) => { return item.id === carIDItem.id });
                                                if (selectedItemIndex != -1) {
                                                    try {
                                                        carIDChooseData[selectedItemIndex].isChecked = isChecked;
                                                        setCarIDChooseData([...carIDChooseData]);
                                                    } catch (error) {
                                                    }
                                                }
                                                searchDataMain("", "");
                                            }}
                                        />
                                    )
                                    )
                                    }
                                </Row>
                            }
                            {showFilterDeliveryStatusArray &&
                                <Row className="mt-2 ml-1">
                                    {deliveryStatusChooseData.map((deliveryStatusItem, index) => (
                                        <FilterBadge
                                            key={index}
                                            textToShow={t(deliveryStatusItem.text)}
                                            isChecked={deliveryStatusItem.isChecked}
                                            setIsChecked={(isChecked) => {
                                                let selectedItemIndex = deliveryStatusChooseData.findIndex((item) => { return item.id === deliveryStatusItem.id });
                                                if (selectedItemIndex != -1) {
                                                    try {
                                                        deliveryStatusChooseData[selectedItemIndex].isChecked = isChecked;
                                                        setDeliveryStatusChooseData([...deliveryStatusChooseData]);
                                                    } catch (error) {
                                                    }
                                                }
                                                searchDataMain("", "");
                                            }}
                                        />
                                    )
                                    )
                                    }
                                </Row>
                            }
                            {showFilterInvoiceStatusArray &&
                                <Row className="mt-2 ml-1">
                                    {invoiceStatusChooseData.map((invoiceStatusItem, index) => (
                                        <FilterBadge
                                            key={index}
                                            textToShow={t(invoiceStatusItem.text)}
                                            isChecked={invoiceStatusItem.isChecked}
                                            setIsChecked={(isChecked) => {
                                                let selectedItemIndex = invoiceStatusChooseData.findIndex((item) => { return item.id === invoiceStatusItem.id });
                                                if (selectedItemIndex != -1) {
                                                    try {
                                                        invoiceStatusChooseData[selectedItemIndex].isChecked = isChecked;
                                                        setInvoiceStatusChooseData([...invoiceStatusChooseData]);
                                                    } catch (error) {
                                                    }
                                                }
                                                searchDataMain("", "");
                                            }}
                                        />
                                    )
                                    )
                                    }
                                </Row>
                            }
                        </Col>

                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}

export default DeliveryRecordSearchBar;
