const getDescendantProp = function (obj, desc = undefined) {
  if (desc) {
    var arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }
  return obj;
};

export default getDescendantProp;
