import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    UncontrolledTooltip,
    CardFooter,
    ListGroup,
    ListGroupItem,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { uuid4 } from "@sentry/utils";
// core components
import { isLoggedIn } from "functions/Auth.js";
import { storeInvoice } from "functions/Invoice.js";
import { getUserLocalSettingItemValue } from "functions/LocalSetting.js";
import { getStoreSelect2 } from "functions/Store";
import { getCustomerByID } from "functions/Customer";
import InvoiceAddProductModal from "./InvoiceAddProductModal.js";
import Notifications from "../components/Notifications.js";
import InvoiceEditSubitemModal from "./InvoiceEditSubitemModal.js";
import { updateObjectInvoiceSubitems } from "functions/Invoice.js";
import InputDateTimeGroupForm from "../components/InputDateTimeGroupForm.js";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { getDefaultInvoiceStatus } from "functions/Invoice.js";

import InputTypeSelect2 from "../components/InputTypeSelect2Form";

import InvoiceSubitemTable from "../components/InvoiceSubitemTable.js";
import CustomerDisplayCard from "../customer/CustomerDisplayCard.js";
import DropdownControl from "../components/DropdownControl.js";

import CustomerSelectInput from "../components/CustomerSelectInput";
import QuotationMessageModal from "../quotation/QuotationMessageModal.js";
import UseQuotationModal from "../quotation/UseQuotationModal.js";
import DeliveryAddressSelectInput from "../components/DeliveryAddressSelectInput.js";
import { getDriverSelect2 } from "functions/Driver.js";
import { getCarSelect2 } from "functions/Car.js";
import { getDefaultCarID } from "functions/Car.js";
import { getDefaultDriverID } from "functions/Driver.js";
import { getDefaultDeliveryStatus, storeMultipleDeliveryRecord } from "functions/Delivery.js";
import AddressModalCreate from "../address/AddressModalCreate.js";
import CustomerModalCreate from "../customer/CustomerModalCreate.js";
import { setQuotationToLocalStorage } from "functions/Quotation.js";
import { getQuotationListFromLocalStorage } from "functions/Quotation.js";
import SubmitButton from "../components/SubmitButton.js";
import { getDefaultStoreID } from "functions/Store.js";
import { isInvoiceDelivery } from "functions/Invoice.js";

const isDriver = localStorage.getItem("rights") === "driver";


// props
// preSelectedCustomer


// To do list
// search address in google button


function InvoiceCreateModal(props) {
    const { t } = useTranslation();

    // Initialize Data
    const [storeSelect2Data, setStoreSelect2Data] = React.useState([]);
    const [driverChooseSelect2Data, setDriverChooseSelect2Data] = React.useState([]);
    const [carChooseSelect2Data, setCarChooseSelect2Data] = React.useState([]);


    const [customerSelectedData, setCustomerSelectedData] = React.useState([]);
    const [isInvoiceCustomerDisabled, setIsInvoiceCustomerDisabled] =
        React.useState(false);
    const [selectedInvoicesSubitemsData, setSelectedInvoicesSubitemsData] =
        React.useState([]);
    const [deleteSelectedInvoicesSubitemID, setDeleteSelectedInvoicesSubitemID] =
        React.useState("");
    const [refreshTable, setRefreshTable] = React.useState(0);

    //For modal 
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalEditIsOpen, setModalEditIsOpen] = React.useState(false);
    const [modalQuotationMessageIsOpen, setModalQuotationMessageIsOpen] = React.useState(false);
    const [modalUseQuotationIsOpen, setModalUseQuotationIsOpen] = React.useState(false);
    const [modalCreateDeliveryAddressIsOpen, setModalCreateDeliveryAddressIsOpen] = React.useState(false);
    const [modalCustomerCreate, setModalCustomerCreate] = React.useState(false);


    const [reloadCustomerSelect, setReloadCustomerSelect] = React.useState(false);


    //Select2 Option
    //const [invoiceStatusValue, setInvoiceStatusValue] = React.useState(1);

    // POST State
    const [invoiceNetPrice, setInvoiceNetPrice] = React.useState(0);
    const [invoiceStatus, setInvoiceStatus] = React.useState(getDefaultInvoiceStatus());
    const [invoiceRemark, setInvoiceRemark] = React.useState("");
    const [invoiceCustomerID, setInvoiceCustomerID] = React.useState("");
    const [storeIDSelected, setStoreIDSelected] = React.useState("");
    const [invoicesSubitemList, setInvoicesSubitemList] = React.useState([]);

    // Delivery Info Management

    // In a structure of 
    // [{localDeliveryRecordShoppingCartID: uuid(), 
    //  showDetailGUI: true/false, 
    //  deliveryAddressID: XXX, 
    //  DeliveryAddress: {}, 
    //  driverID: YYY, 
    //  carID: HHH, 
    //  proposedDateTime: KKK}
    //  , ...]
    const [deliveryRecordShoppingCartArrayToAdd, setDeliveryRecordShoppingCartArrayToAdd] = React.useState([]);

    const [selectedLocalDeliveryRecordShoppingCartID, setSelectedLocalDeliveryRecordShoppingCartID] = React.useState("");
    const [additionalAddressDataArray, setAdditionalAddressDataArray] = React.useState([]);


    React.useEffect(() => {
        const getAllStoreData = async () => {
            let storeData = await getStoreSelect2();
            setStoreSelect2Data(storeData);
            setStoreIDSelected(getDefaultStoreID(storeData));

        };

        const getDriver = async () => {
            let driver = await getDriverSelect2();
            setDriverChooseSelect2Data(driver);
        };
        const getCar = async () => {
            let car = await getCarSelect2();
            setCarChooseSelect2Data(car);
        };
        if (props.show) {
            getDriver();
            getCar();
            getAllStoreData();

        }
    }, [props.show]);

    // This is the initial entry
    React.useEffect(() => {
        if (!props.show) return;

        // From other page or component which carries a state with the invoice to clone
        // make sure invoiceToClone is valid and not empty
        if (props.invoiceToClone !== undefined && props.invoiceToClone !== null && Object.values(props.invoiceToClone).length >= 1) {

            // Set Customer
            setInvoiceCustomerID(props.invoiceToClone.Customer?.customerID);
            setCustomerSelectedData(props.invoiceToClone.Customer);
            setIsInvoiceCustomerDisabled(false); //still allow change customer
            // invoice detail
            setInvoiceNetPrice(props.invoiceToClone.invoiceNetPrice);
            setInvoiceStatus(props.invoiceToClone.invoiceStatus);
            setInvoiceRemark(props.invoiceToClone.invoiceRemark);
            // invoiceSubitem
            replaceInvoiceSubitemList(props.invoiceToClone.InvoiceSubitems);

            // set delivery shopping cart

            if (isInvoiceDelivery(props.invoiceToClone)) {
                // if (props.invoiceToClone.DeliveryRecords && Array.isArray(props.invoiceToClone.DeliveryRecords) && props.invoiceToClone.DeliveryRecords.length >= 1) {
                addExistingDeliveryRecordShoppingCartFromDB(props.invoiceToClone.DeliveryRecords, false, true, true, true);
            }

        } else {
            // From other page or component which carries a state with a preSelectedCustomer
            if (props.preSelectedCustomer && props.preSelectedCustomer !== undefined && props.preSelectedCustomer !== null) {
                // Set Customer
                setInvoiceCustomerID(props.preSelectedCustomer?.customerID);
                setCustomerSelectedData(props.preSelectedCustomer);
                setIsInvoiceCustomerDisabled(true);
            }

            // From other page
            if (props.preSelectedInvoiceRemark && props.preSelectedInvoiceRemark !== undefined && props.preSelectedInvoiceRemark !== null) {
                setInvoiceRemark(props.preSelectedInvoiceRemark);
            }

            // From other page
            if (props.preSelectedInvoiceSubitemList && Array.isArray(props.preSelectedInvoiceSubitemList)) {
                replaceInvoiceSubitemList(props.preSelectedInvoiceSubitemList);
            }
            // From other page
            if (props.preSelectedDeliveryRecordList && Array.isArray(props.preSelectedDeliveryRecordList)) {
                addExistingDeliveryRecordShoppingCartFromDB(props.preSelectedDeliveryRecordList);
            }
        }


    }, [props.show, props.preSelectedCustomer, props.preSelectedInvoiceRemark, props.preSelectedInvoiceSubitemList, props.preSelectedDeliveryRecordList]);

    React.useEffect(() => {
        const getCustomerDetails = async () => {
            let customer = await getCustomerByID(invoiceCustomerID);
            setCustomerSelectedData(customer);
        };
        if (invoiceCustomerID != "") {
            getCustomerDetails();
        }
    }, [invoiceCustomerID]);

    React.useEffect(() => {
        let totalPrice = invoicesSubitemList.reduce(
            (total, obj) => (obj.unitPrice * obj.quantity) + total,
            0
        );
        try {
            setInvoiceNetPrice(totalPrice);
        } catch (error) {

        }

    }, [invoicesSubitemList]);

    React.useEffect(() => {

        const deleteInvoicesSubitems = () => {
            setInvoicesSubitemList(
                invoicesSubitemList.filter(
                    (data) => data.localSubitemID !== deleteSelectedInvoicesSubitemID
                )
            );
            setDeleteSelectedInvoicesSubitemID("");
        };

        if (deleteSelectedInvoicesSubitemID !== "") {
            deleteInvoicesSubitems();
        }
    }, [deleteSelectedInvoicesSubitemID]);


    const clearForm = () => {
        // Initialize Data
        //setStoreDataArray([]); // no need to clear the choice
        setCustomerSelectedData([]);
        setIsInvoiceCustomerDisabled(false);
        setSelectedInvoicesSubitemsData([]);
        setDeleteSelectedInvoicesSubitemID("");

        // POST State
        setInvoiceNetPrice(0);
        setInvoiceStatus(getDefaultInvoiceStatus());
        setInvoiceRemark("");
        setInvoiceCustomerID("");
        setStoreIDSelected(getUserLocalSettingItemValue("defaultStoreID")); // just choose the default store
        setInvoicesSubitemList([]);

        setDeliveryRecordShoppingCartArrayToAdd([]);
    }

    async function handleInvoiceRemarks(e) {
        let value = e.target.value;
        setInvoiceRemark(value);
    }

    async function handleOnChangeCustomer(customerID) {
        setInvoiceCustomerID(customerID);
    }

    async function updateSubitems(
        localSubitemID,
        quantity,
        unitPrice,
        invoiceSubitemRemark,
        good
    ) {
        let updatedList = updateObjectInvoiceSubitems(localSubitemID, invoicesSubitemList, {
            localSubitemID,
            quantity,
            unitPrice,
            invoiceSubitemRemark,
            good: good
        });
        setInvoicesSubitemList(updatedList);
    }

    function replaceInvoiceSubitemList(listToAdd) {
        let subitemListToConstruct = [];
        if (listToAdd && listToAdd.length >= 1) {
            listToAdd.forEach((item) => {
                const localSubitemID = uuid4();
                subitemListToConstruct.push({
                    localSubitemID: localSubitemID,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    invoiceSubitemRemark: item.invoiceSubitemRemark,
                    good: item.Good ? item.Good : item.good
                });
                setInvoicesSubitemList(subitemListToConstruct);
            })
        }
    }

    function addNewItemToInvoiceSubitemList(
        quantity,
        unitPrice,
        invoiceSubitemRemark,
        good
    ) {
        const localSubitemID = uuid4();
        setInvoicesSubitemList([
            ...invoicesSubitemList,
            { localSubitemID, quantity, unitPrice, invoiceSubitemRemark, good: good },
        ]);

    }

    const closeThisModal = (storeQuotationToLocalBoo, returnCreatedInvoiceID = '') => {
        try {
            if (storeQuotationToLocalBoo) {
                setQuotationToLocalStorage(invoiceCustomerID, customerSelectedData, invoiceRemark, invoicesSubitemList, deliveryRecordShoppingCartArrayToAdd);
            }

            props.onHide(returnCreatedInvoiceID);
        } catch (error) {

        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Data check
        // customer ID
        if (invoiceCustomerID === "") {
            Notifications({
                type: "dataCheck",
                message: "Please choose a customer"
            });
            return;
        }

        // store ID
        if (storeIDSelected === "") {
            Notifications({
                type: "dataCheck",
                message: "Please choose a store"
            });
            return;
        }

        // Data check
        // Subitem list
        // props.allowEmptySubitemList
        if (!invoicesSubitemList || !Array.isArray(invoicesSubitemList)) {
            // Error case
            Notifications({
                type: "dataCheck",
                message: "Please choose a good"
            });
            return;
        }
        if (invoicesSubitemList && Array.isArray(invoicesSubitemList) && invoicesSubitemList.length == 0) {
            if (props.allowEmptySubitemList) {
                // let go
            } else {
                Notifications({
                    type: "dataCheck",
                    message: "Please choose a good"
                });
                return;
            }
        }

        // delivery shopping cart
        let returnFlag = false; // false means keep going // true means stop
        if (deliveryRecordShoppingCartArrayToAdd) { // if no delivery shopping cart // no need to check
            if (deliveryRecordShoppingCartArrayToAdd.length >= 1) { // if no delivery shopping cart // no need to check
                deliveryRecordShoppingCartArrayToAdd.forEach((item, index) => {
                    if (item.deliveryAddressID === "") {
                        // Empty addressID
                        Notifications({
                            type: "dataCheck",
                            message: "Please choose an address"
                        });
                        returnFlag = true;
                        return;
                    }
                    // check carID
                    if (item.carID === "") {
                        Notifications({
                            type: "dataCheck",
                            message: t("Please choose a car")
                        });
                        returnFlag = true;
                        return;
                    }

                    // check driverID
                    if (item.driverID === "") {
                        Notifications({
                            type: "dataCheck",
                            message: t("Please choose a driver")
                        });
                        returnFlag = true;
                        return;
                    }

                })
            }
        }

        // empty addressID
        // Stop the function
        if (returnFlag) return;

        try {
            try {
                // Setup the invoiceSubitems to submit
                invoicesSubitemList.forEach((element, index) => {
                    element.goodID = element.good?.goodID;
                    element.Good = element.good;
                });
            } catch (error) {

            }

            let dataInvoice = {
                invoiceNetPrice: invoiceNetPrice,
                invoiceStatus: invoiceStatus,
                invoiceRemark: invoiceRemark,
                invoiceCustomerID: invoiceCustomerID,
                storeID: storeIDSelected,
                invoicesSubitems: invoicesSubitemList,
            };

            let resultInvoiceID = await storeInvoice(dataInvoice);
            if (resultInvoiceID && resultInvoiceID !== "") {

                // successfully added invoice
                await createDeliveryRecordInDB(resultInvoiceID);
                clearForm();
                closeThisModal(false, resultInvoiceID);
                return true;
            } else {
                return false;
            }

        } catch (err) {
            Notifications();
        }
    };

    const createDeliveryRecordInDB = async (invoiceID) => {
        if (deliveryRecordShoppingCartArrayToAdd) { // if no delivery shopping cart 
            if (deliveryRecordShoppingCartArrayToAdd.length >= 1) {

                let deliveryRecordSubitems = [];

                // The logic is
                // When there is only one delivery just add all the goods into the delivery
                if (deliveryRecordShoppingCartArrayToAdd.length === 1) {
                    // Transform the invoiceSubitem to deliverySubitem
                    if (invoicesSubitemList) {
                        for (let i = 0; i < invoicesSubitemList.length; i += 1) {
                            const obj = {
                                goodID: invoicesSubitemList[i].good.goodID,
                                deliverySubitemQuantity: invoicesSubitemList[i].quantity,
                                deliverySubitemRemark: invoicesSubitemList[i].invoiceSubitemRemark,
                            };
                            deliveryRecordSubitems.push(obj);
                        }
                    }
                }

                let dataSubmitArray = [];
                deliveryRecordShoppingCartArrayToAdd.forEach((DNitem, index) => {
                    if (DNitem.deliveryAddressID !== undefined && DNitem.deliveryAddressID !== "" && DNitem.driverID !== "" && DNitem.carID !== "") {
                        let dataSubmit = {
                            invoiceID: invoiceID,
                            proposedDateTime: DNitem.proposedDateTime,
                            deliveryStatus: getDefaultDeliveryStatus(),
                            driverID: DNitem.driverID,
                            deliveryAddressID: DNitem.deliveryAddressID,
                            deliveryRemark: "",
                            carID: DNitem.carID,
                            deliveryRecordSubitems: deliveryRecordSubitems,
                        };
                        dataSubmitArray.push(dataSubmit);
                    }
                })
                let result = await storeMultipleDeliveryRecord(dataSubmitArray);
            }
        }

    }


    const openQuotationModal = () => {
        // Check data 
        if (!customerSelectedData || Object.values(customerSelectedData).length <= 0) {
            Notifications({
                type: "dataCheck",
                message: t("Please choose a customer")
            });
            return;
        }
        if (!invoicesSubitemList || Object.values(invoicesSubitemList).length <= 0) {
            Notifications({
                type: "dataCheck",
                message: t("Please choose some good")
            });
            return;
        }

        // OK
        setModalQuotationMessageIsOpen(true);
    }

    const addSingleEmptyDeliveryItem = () => {
        if (deliveryRecordShoppingCartArrayToAdd) {
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd, {
                localDeliveryRecordShoppingCartID: uuid4(),
                showDetailGUI: false, // default a false to close the control
                deliveryAddressID: '',
                driverID: getDefaultDriverID(driverChooseSelect2Data), //default
                carID: getDefaultCarID(carChooseSelect2Data), //default
                proposedDateTime: new Date(),
                DeliveryAddress: {}
            }])
        }
    }

    const addExistingDeliveryRecordShoppingCartFromDB = (existingDeliveryRecordShoppingCartArray, resetAddressID = false, resetDriver = false, resetCarID = false, resetProposedDateTime = false) => {

        let resultArray = [];
        if (existingDeliveryRecordShoppingCartArray && Array.isArray(existingDeliveryRecordShoppingCartArray) && existingDeliveryRecordShoppingCartArray.length >= 1) {

            existingDeliveryRecordShoppingCartArray.forEach((item) => {
                try {

                    let deliveryAddressIDToSet = '', driverIDToSet = getDefaultDriverID(driverChooseSelect2Data), carIDToSet = getDefaultCarID(carChooseSelect2Data), proposedDateTimeToSet = Date.now();

                    if (!resetAddressID) {
                        deliveryAddressIDToSet = item.deliveryAddressID;
                    }
                    if (!resetDriver) {
                        driverIDToSet = item.driverID;
                    }
                    if (!resetCarID) {
                        carIDToSet = item.carID;
                    }
                    if (!resetProposedDateTime) {
                        proposedDateTimeToSet = item.proposedDateTime;
                    }

                    resultArray.push({
                        localDeliveryRecordShoppingCartID: uuid4(),
                        showDetailGUI: false, // default a false to close the control
                        deliveryAddressID: deliveryAddressIDToSet,
                        driverID: driverIDToSet,
                        carID: carIDToSet,
                        proposedDateTime: proposedDateTimeToSet,
                        DeliveryAddress: {}
                    })
                } catch (error) {
                    //   console.log(error);
                }

            })
        }
        setDeliveryRecordShoppingCartArrayToAdd([...resultArray]);

    }

    const flipShowDetailToSingleItem = (localDeliveryRecordShoppingCartIDToDelete) => {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 1 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
            deliveryRecordShoppingCartArrayToAdd.forEach((item) => {
                if (item.localDeliveryRecordShoppingCartID === localDeliveryRecordShoppingCartIDToDelete) {
                    item.showDetailGUI = !item.showDetailGUI;
                } else {
                    item.showDetailGUI = false; // Close all others
                }
            })
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd]);
        }
    }

    const updateSingleItemDriverID = (localDeliveryRecordShoppingCartIDToUpdate, driverID) => {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 1 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
            deliveryRecordShoppingCartArrayToAdd.forEach((item) => {
                if (item.localDeliveryRecordShoppingCartID === localDeliveryRecordShoppingCartIDToUpdate) {
                    item.driverID = driverID;
                }
            })
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd]);
        }
    }
    const updateSingleItemCarID = (localDeliveryRecordShoppingCartIDToUpdate, carID) => {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 1 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
            deliveryRecordShoppingCartArrayToAdd.forEach((item) => {
                if (item.localDeliveryRecordShoppingCartID === localDeliveryRecordShoppingCartIDToUpdate) {
                    item.carID = carID;
                }
            })
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd]);
        }
    }

    const updateSingleItemProposedDateTime = (localDeliveryRecordShoppingCartIDToUpdate, proposedDateTime) => {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 1 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
            deliveryRecordShoppingCartArrayToAdd.forEach((item) => {
                if (item.localDeliveryRecordShoppingCartID === localDeliveryRecordShoppingCartIDToUpdate) {
                    item.proposedDateTime = proposedDateTime;
                }
            })
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd]);
        }
    }

    const updateSingleItemDeliveryAddressID = (localDeliveryRecordShoppingCartIDToUpdate, deliveryAddressID) => {
        //  if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 0 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 0) {
            deliveryRecordShoppingCartArrayToAdd.forEach((item) => {
                if (item.localDeliveryRecordShoppingCartID === localDeliveryRecordShoppingCartIDToUpdate) {
                    item.deliveryAddressID = deliveryAddressID;
                }
            })
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd]);
        }
    }

    const updateSingleItemDeliveryAddressData = (localDeliveryRecordShoppingCartIDToUpdate, deliveryAddressData) => {
        //  if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 0 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 0) {
            deliveryRecordShoppingCartArrayToAdd.forEach((item) => {
                if (item.localDeliveryRecordShoppingCartID === localDeliveryRecordShoppingCartIDToUpdate) {
                    item.DeliveryAddress = deliveryAddressData;
                }
            })
            setDeliveryRecordShoppingCartArrayToAdd([...deliveryRecordShoppingCartArrayToAdd]);
        }
    }

    const deleteSingleDeliveryItem = (localDeliveryRecordShoppingCartIDToDelete) => {
        if (deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 1 && Object.values(deliveryRecordShoppingCartArrayToAdd).length >= 1) {
            let resultArray = deliveryRecordShoppingCartArrayToAdd.filter((item) => { return item.localDeliveryRecordShoppingCartID != localDeliveryRecordShoppingCartIDToDelete })
            setDeliveryRecordShoppingCartArrayToAdd([...resultArray]);
        }
    }

    // Delete Confirmation
    async function deleteConfirmation(localDeliveryRecordShoppingCartIDToDelete) {
        let alert = {
            type: "deleteConfirmation",
            title: "Are you Sure?",
            message: "Data Will Be Deleted",
            elementID: localDeliveryRecordShoppingCartIDToDelete,
            callback: deleteSingleDeliveryItem,
        };
        Notifications(alert);
    }

    //className="mt--6" is different from className="mt-6"
    return isLoggedIn() ? (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={() => { closeThisModal(true) }}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <ModalHeader className="mt-2 ml-2 mr-2" toggle={() => { closeThisModal(true) }} >
                        <div>
                            <div>{t("Create Invoice")}</div>
                            <div className="mt-1">
                                <Button
                                    className="ml--1"
                                    color="primary"
                                    size="sm"
                                    onClick={() => {
                                        openQuotationModal();
                                    }}>
                                    <Trans>Quotation PDF</Trans>
                                </Button>
                                <Button
                                    className="ml--1"
                                    color="primary"
                                    size="sm"
                                    onClick={() => {
                                        setModalUseQuotationIsOpen(true);
                                    }}>
                                    <Trans>Use quotation</Trans>
                                </Button>
                            </div>
                        </div>

                    </ModalHeader>

                    <ModalBody>
                        <>
                            {/* Customer Data */}
                            <Row>
                                <Col sm="12" lg="12">
                                    <div className="card-wrapper">
                                        <Card className="mb-4">
                                            <CardHeader>
                                                <h3 className="mb-0">{t("Customer Name")}</h3>
                                            </CardHeader>
                                            <CardBody>
                                                <Row className="p-1">
                                                    <Col >
                                                        <CustomerSelectInput
                                                            preSelectCustomerID={invoiceCustomerID}
                                                            selectCustomerID={(customerID) => {
                                                                handleOnChangeCustomer(customerID);
                                                            }}
                                                            disabled={isInvoiceCustomerDisabled}
                                                            showCreateButton={!isInvoiceCustomerDisabled}
                                                            onCreateNewCustomer={() => {
                                                                setModalCustomerCreate(true);
                                                            }}
                                                            reloadMe={reloadCustomerSelect}
                                                        />
                                                    </Col>
                                                </Row>


                                                {Object.keys(customerSelectedData).length > 0 ? (
                                                    <>
                                                        <Row className="p-1">
                                                            <Col >
                                                                <CustomerDisplayCard
                                                                    customerData={customerSelectedData}
                                                                    hideCardHeader={true} />
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                            {/* Invoice subitem table */}
                            <Row>
                                <Col sm="12" lg="12">
                                    <div className="card-wrapper">
                                        {/* Table Invoice Subitems Items */}
                                        <Card className="mt-2">
                                            <CardHeader className="mb--2">
                                                <div className="d-flex justify-content-between">
                                                    <div className="ml-2 mt-1">
                                                        <h1>{`$${invoiceNetPrice}`}</h1>
                                                    </div>
                                                    <div className="mt-1">
                                                        {!props.disableAddProduct &&
                                                            <Button
                                                                color="primary"
                                                                id="tooltip443412080"
                                                                onClick={() => setModalIsOpen(true)}
                                                                size="sm"
                                                            >
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="fas fa-plus" />
                                                                </span>
                                                                <span className="btn-inner--text">
                                                                    {t("Add")}
                                                                </span>
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="ml--3 mr--3 mt--4 pt--2">
                                                <Row className="">
                                                    <InvoiceSubitemTable
                                                        invoiceSubitemsTable={invoicesSubitemList}
                                                        isDriver={isDriver}
                                                        selectSubitem={(invoiceSubitemItem) => {
                                                            setSelectedInvoicesSubitemsData(invoiceSubitemItem);
                                                            setModalEditIsOpen(true);
                                                            // setInvoiceDetailEdited(true);
                                                        }}
                                                        deleteSubitem={(subItemID) => {
                                                            setDeleteSelectedInvoicesSubitemID(subItemID);
                                                            // setInvoiceDetailEdited(true);
                                                        }}
                                                        disableDeleteGood={props.disableDeleteGood}
                                                    />
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <DropdownControl
                                                            disabled={false}
                                                            defaultColor="primary"
                                                            dropdownName={t("Store")}
                                                            useTranslate={false}
                                                            // arrayIDTextFormat={[{id: "xxxx", text: "yyy"}]}
                                                            arrayIDTextFormat={storeSelect2Data}
                                                            valueID={storeIDSelected}
                                                            onSelectValueID={(valueID) => {
                                                                setStoreIDSelected(valueID);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col>
                                                        <InputTypeTextForm
                                                            label={""}
                                                            labelFor="invoiceRemark"
                                                            inputName="invoiceRemark"
                                                            inputId="invoiceRemark"
                                                            inputPlaceholder={t("Invoice Remark")}
                                                            value={invoiceRemark}
                                                            handleChange={handleInvoiceRemarks}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>

                                        </Card>
                                    </div>
                                </Col>
                            </Row>

                            {/* Delivery Record Data */}

                            {!props.disableDelivery &&
                                <Row>
                                    <Col sm="12" lg="12">
                                        <div className="card-wrapper">
                                            <Card>

                                                <CardHeader>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="ml-2 mt-1">
                                                            <h3 className="mb-0">
                                                                {t("Delivery info")}
                                                            </h3>
                                                        </div>
                                                        <div className="mt-1">
                                                            <Button color="primary"
                                                                id="tooltip443412080"
                                                                onClick={() => { addSingleEmptyDeliveryItem() }}
                                                                size="sm">
                                                                <span className="btn-inner--icon mr-1">
                                                                    <i className="fas fa-plus" />
                                                                </span>
                                                                <span className="btn-inner--text">
                                                                    {t("Add")}
                                                                </span>
                                                            </Button>
                                                            <UncontrolledTooltip
                                                                delay={1}
                                                                target="tooltip443412080"
                                                            >
                                                                {t("Add")}
                                                            </UncontrolledTooltip>

                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                {deliveryRecordShoppingCartArrayToAdd && deliveryRecordShoppingCartArrayToAdd.length >= 1 &&
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs={12} sm={12} md={12} lg={12}>

                                                                <Row className="mb-4">
                                                                    <Col>
                                                                        {deliveryRecordShoppingCartArrayToAdd &&
                                                                            deliveryRecordShoppingCartArrayToAdd.map((deliveryRecordShoppingCartItem, index) => (
                                                                                <div key={index}>
                                                                                    <div className="d-flex" >
                                                                                    <div className="p-1 flex-grow-1 text-truncate">
                                                                                            <DeliveryAddressSelectInput
                                                                                                additionalAddressDataArray={additionalAddressDataArray}
                                                                                                preSelectAddressID={deliveryRecordShoppingCartItem.deliveryAddressID}
                                                                                                returnSelectedAddressID={(deliveryAddressID) => {
                                                                                                    updateSingleItemDeliveryAddressID(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID, deliveryAddressID)
                                                                                                }}
                                                                                                returnSelectedAddressData={(deliveryAddressData) => {
                                                                                                    updateSingleItemDeliveryAddressData(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID, deliveryAddressData);
                                                                                                }}
                                                                                                showLabel={false}
                                                                                            />
                                                                                        </div>
                                                                                        {deliveryRecordShoppingCartItem.showDetailGUI &&
                                                                                            <div className="p-1"><Button color="success" onClick={() => {
                                                                                                setModalCreateDeliveryAddressIsOpen(true);
                                                                                                setSelectedLocalDeliveryRecordShoppingCartID(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID);
                                                                                            }}><i className="fas fa-plus" /></Button></div>
                                                                                        }
                                                                                        {deliveryRecordShoppingCartItem.showDetailGUI &&
                                                                                            <div className="p-1" ><Button color="danger" onClick={() => { deleteConfirmation(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID) }}><i className="fas fa-trash" /></Button></div>

                                                                                        }
                                                                                        <div className="p-1"><Button color={deliveryRecordShoppingCartItem.showDetailGUI ? "primary" : "primary"} onClick={() => { flipShowDetailToSingleItem(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID) }}><i className={`fa fa-angle-${deliveryRecordShoppingCartItem.showDetailGUI ? "up" : "down"}`} /></Button></div>
                                                                                    </div>

                                                                                    {deliveryRecordShoppingCartItem.showDetailGUI &&
                                                                                        <>
                                                                                            <div className="d-flex mb-1">
                                                                                                <DropdownControl
                                                                                                    disabled={false}
                                                                                                    defaultColor="primary"
                                                                                                    dropdownName={t("Car")}
                                                                                                    useTranslate={false}
                                                                                                    arrayIDTextFormat={carChooseSelect2Data}
                                                                                                    valueID={deliveryRecordShoppingCartItem.carID}
                                                                                                    onSelectValueID={(valueID) => {
                                                                                                        updateSingleItemCarID(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID, valueID);
                                                                                                    }}
                                                                                                />
                                                                                                <DropdownControl
                                                                                                    disabled={false}
                                                                                                    defaultColor="primary"
                                                                                                    dropdownName={t("Driver")}
                                                                                                    useTranslate={false}
                                                                                                    arrayIDTextFormat={driverChooseSelect2Data}
                                                                                                    valueID={deliveryRecordShoppingCartItem.driverID}
                                                                                                    onSelectValueID={(valueID) => {
                                                                                                        updateSingleItemDriverID(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID, valueID);
                                                                                                    }}
                                                                                                />

                                                                                            </div>
                                                                                            <div className="mt-2 mb-1">
                                                                                                <InputDateTimeGroupForm
                                                                                                    label=""
                                                                                                    labelFor="proposedDateTime"
                                                                                                    inputName="proposedDateTime"
                                                                                                    inputId="proposedDateTime"
                                                                                                    inputPlaceholder={t("Estimated Time")}
                                                                                                    value={deliveryRecordShoppingCartItem.proposedDateTime}
                                                                                                    onDateChange={(date) => {
                                                                                                        // setproposedDateTime(date);
                                                                                                        // setDeliveryDetailEdited(true);
                                                                                                        updateSingleItemProposedDateTime(deliveryRecordShoppingCartItem.localDeliveryRecordShoppingCartID, date);
                                                                                                    }}
                                                                                                    defaultDate={deliveryRecordShoppingCartItem.proposedDateTime}
                                                                                                    readOnly={false}
                                                                                                    // open={!isDriver}
                                                                                                    showTimeBoo={true}
                                                                                                    showCustomDateTimeButton={true}
                                                                                                    specialButtonColor={"primary"}
                                                                                                />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>

                                                                            ))
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                }
                                            </Card>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Row className="w-100 d-flex justify-content-between">
                            <div>
                                {!props.disableResetButton &&
                                    <Button
                                        color="danger"
                                        type="button" // type="button" means the form will not submit
                                        onClick={(e) => {
                                            e.preventDefault();
                                            clearForm();
                                        }}
                                    >
                                        {t("Reset")}
                                    </Button>
                                }
                            </div>
                            <div>
                                <SubmitButton
                                    handleSubmit={handleSubmit}
                                    buttonNormalText={"Create Invoice"}
                                />

                                <Button
                                    color="default"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeThisModal(true);
                                    }}
                                >
                                    {t("Back")}
                                </Button>
                            </div>
                        </Row>
                    </ModalFooter>
                </Form>

            </Modal>
            <InvoiceAddProductModal
                show={modalIsOpen}
                onHide={() => setModalIsOpen(false)}
                title={t("Add Invoice Items")}
                button={t("Save")}
                addInvoicesSubitems={addNewItemToInvoiceSubitemList}
            />
            <InvoiceEditSubitemModal
                show={modalEditIsOpen}
                onHide={() => setModalEditIsOpen(false)}
                title={t("Edit Invoice Items")}
                button={t("Update")}
                invoicesSubitemsData={selectedInvoicesSubitemsData}
                updateInvoicesSubitems={(localSubitemID,
                    quantity,
                    unitPrice,
                    invoiceSubitemRemark,
                    good) => {
                    updateSubitems(localSubitemID,
                        quantity,
                        unitPrice,
                        invoiceSubitemRemark,
                        good);
                }}
                disableGoodSelection={props.disableChangeGood}
            />
            <QuotationMessageModal
                show={modalQuotationMessageIsOpen}
                onHide={() => setModalQuotationMessageIsOpen(false)}
                title={t("Quotation Message")}
                refreshTable={() => { }}
                customerData={customerSelectedData}
                invoiceRemark={invoiceRemark}
                invoiceSubitemList={invoicesSubitemList}
                deliveryRecordShoppingCartList={deliveryRecordShoppingCartArrayToAdd}
            />
            <UseQuotationModal
                show={modalUseQuotationIsOpen}
                onHide={() => setModalUseQuotationIsOpen(false)}
                title={t("Use quotation")}
                refreshTable={() => { }}
                getBackData={(customerID, invoiceRemark, invoiceSubitemList, deliveryRecordList) => {
                    //    clearForm(); // claer all data
                    setInvoiceCustomerID(customerID);
                    setInvoiceRemark(invoiceRemark);
                    replaceInvoiceSubitemList(invoiceSubitemList);
                    addExistingDeliveryRecordShoppingCartFromDB(deliveryRecordList);
                }}
            />
            <AddressModalCreate
                show={modalCreateDeliveryAddressIsOpen}
                onHide={(e) => {
                    setModalCreateDeliveryAddressIsOpen(false);
                }}
                title={t("Create new address")}
                button={t("Add")}
                refreshTable={() => { }}
                deliveryAddressToClone={{}}
                getNewAddressData={(newAddressData) => {

                    setAdditionalAddressDataArray([newAddressData])
                    updateSingleItemDeliveryAddressID(selectedLocalDeliveryRecordShoppingCartID, newAddressData.deliveryAddressID);
                }}
            />
            <CustomerModalCreate
                show={modalCustomerCreate}
                onHide={() => setModalCustomerCreate(false)}
                title={t("Add Customer")}
                button={t("Save")}
                returnNewCustomerCreated={(newCustomerData) => {
                    try {
                        setInvoiceCustomerID(newCustomerData?.customerID);
                        setReloadCustomerSelect(true);
                    } catch (error) {

                    }
                }
                }
            />

        </>
    ) : (
        <Redirect to="/work/signIn" />
    );
}

export default InvoiceCreateModal;
