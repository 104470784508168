import React, { useRef, useState } from "react";
import {
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Button,
  Col,
  Label,
} from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import zhTW from "date-fns/locale/zh-TW";
import { isMobile } from "react-device-detect";
import { addDays } from "../../../functions/Utility";

import "../components/SearchDateRangeBar.css";

function SearchDateRangeBar(props) {
  const { defaultStartDate, defaultEndDate, minDateForPicker } = props;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDatePickerRef = useRef(null);
  const endDatePickerRef = useRef(null);
  const InputGroups = useRef(null);
  const [startDate, setStartDate] = useState(defaultStartDate || today);
  const [endDate, setEndDate] = useState(defaultEndDate || today);

  function onStartDateChange(date) {
    setStartDate(date);
    props.onStartDateChange(date);
  }

  function onEndDateChange(date) {
    setEndDate(date);
    props.onEndDateChange(date);
  }

  React.useEffect(() => {
    if (isMobile && startDatePickerRef.current !== null) {
      startDatePickerRef.current.input.readOnly = true;
    }
    if (isMobile && endDatePickerRef.current !== null) {
      endDatePickerRef.current.input.readOnly = true;
    }
  }, [startDatePickerRef, endDatePickerRef]);

  return (
    <Container>
      <Row className="mt-2">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Label for={props.labelFor} className="form-control-label">
            {props.label}
          </Label>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <InputGroup style={{ flexWrap: "nowrap" }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>From</InputGroupText>
            </InputGroupAddon>

            <InputGroupAddon addonType="append">
              <DatePicker
                ref={startDatePickerRef}
                selected={startDate}
                onChange={onStartDateChange}
                dateFormat="yyyy-MM-dd HH:mm:ss"
                minDate={minDateForPicker}
              />
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
              <Button
                onClick={() => {
                  onStartDateChange(addDays(startDate, -1));
                }}
              >
                <i className="fa fa-minus" />
              </Button>
              <Button
                onClick={() => {
                  onStartDateChange(addDays(startDate, 1));
                }}
              >
                <i className="fa fa-plus" />
              </Button>
            </InputGroupAddon>
          </InputGroup>

          <InputGroup style={{ flexWrap: "nowrap" }}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>To</InputGroupText>
            </InputGroupAddon>

            <InputGroupAddon addonType="append">
              <DatePicker
                ref={endDatePickerRef}
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="yyyy-MM-dd HH:mm:ss"
                minDate={minDateForPicker}
              />
            </InputGroupAddon>

            <InputGroupAddon addonType="append">
              <Button
                onClick={() => {
                  onEndDateChange(addDays(endDate, -1));
                }}
              >
                <i className="fa fa-minus" />
              </Button>
              <Button
                onClick={() => {
                  onEndDateChange(addDays(endDate, 1));
                }}
              >
                <i className="fa fa-plus" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchDateRangeBar;
