import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//deliveryRecordListData
//deliveryStatus :string
// hideIfZero
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
function DeliveryCountBadge(props) {

    const { t } = useTranslation();
    const deliveryRecordList = props.deliveryRecordListData;
    const deliveryStatus = props.deliveryStatus;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";


    function textToShow() {
        if (!deliveryRecordList || Object.values(deliveryRecordList).length === 0) {
            return "";
        }

        let filteredList = deliveryRecordList.filter((deliveryItem) => {
            return deliveryItem.deliveryStatus.toLowerCase() === deliveryStatus.toLowerCase()
        });
        if (!filteredList) {
            return "";
        }
        if (props.hideIfZero && filteredList.length === 0) {
            return "";
        }

        return `${filteredList.length}${" "}${t(deliveryStatus)}`
    }

    function badgeColor() {
        switch (deliveryStatus) {
            case 'pending':
                return "danger";
            case 'progress':
                return "warning";
            case 'finish':
                return "success";
            case 'cancel':
                return "seceondary";
            default:
                return "seceondary";
        }
    };

    return (
        <Badge
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
        >
            {textToShow()}
        </Badge>
    );
}

export default DeliveryCountBadge;
