import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
//props
//textToShow
//isChecked
//function: 

function FilterBadge(props) {

    const { t } = useTranslation();
    const textToShow = props.textToShow;
    const isChecked = props.isChecked;


    function textToShowfcn() {
        return textToShow;
    }


    function badgeColor() {
        return isChecked
            ? "success"
            : "danger";
    };

    return (
        <>
            <Badge
                style={{ cursor: "pointer" }}
                color={badgeColor()}
                className="filterBadge badge-md border-primary"
                pill={true}
                onClick={() => { props.setIsChecked(!isChecked); }}
            >
                <i class={isChecked ? "fa fa-check-circle" : "fa fa-times-circle"} />
                {"  "}
                {textToShowfcn()}
            </Badge>
        </>
    );


};
export default FilterBadge;
