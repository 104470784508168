import React from "react";
import {
    Badge
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { getInvoiceStatusColor } from "functions/Invoice";
import { getDeliveryStatusColor } from "functions/Delivery";
import { getPaymentMethodColor } from "functions/Payment";
//props
//salesAccountingSingleDateData
//badgeType :string
// clickable
//badgeClassName : "statusBadge"/ "statusBadgeSmall"
function SalesAccountingBadge(props) {

    const { t } = useTranslation();
    const salesAccountingSingleDateData = props.salesAccountingSingleDateData;
    const badgeType = props.badgeType;
    const badgeClassName = props.badgeClassName;
    const badgeClassNameDefault = "statusBadge";
    const clickable = props.clickable;


    function textToShow() {

        try {
            switch (badgeType) {
                case 'invoiceCount':
                    return `${salesAccountingSingleDateData.invoiceCount + ' ' + t('invoice')}`;
                case 'invoiceStatusWithAmount':
                    return `${salesAccountingSingleDateData.invoiceCount + ' ' + t(salesAccountingSingleDateData.invoiceStatus ? salesAccountingSingleDateData.invoiceStatus : 'N/A') + ' (' + t('Total') + '$' + String(salesAccountingSingleDateData.salesVolume) + ')'}`;
                case 'deliveryCount':
                    return `${salesAccountingSingleDateData.deliveryCount + ' ' + t('delivery')}`;
                case 'deliveryStatusWithAmount':
                    return `${salesAccountingSingleDateData.deliveryCount + ' ' + t(salesAccountingSingleDateData.deliveryStatus ? salesAccountingSingleDateData.deliveryStatus : 'N/A')}`;
                case 'paymentCount':
                    return `${salesAccountingSingleDateData.paymentCount + ' ' + t('payment')}`;
                case 'paymentMethodWithAmount':
                    return `${salesAccountingSingleDateData.paymentCount + ' ' + t(salesAccountingSingleDateData.paymentMethod ? salesAccountingSingleDateData.paymentMethod : 'N/A') + ' (' + t('Total') + '$' + String(salesAccountingSingleDateData.paymentVolume) + ')'}`;
                default:
                    return 'N/A';
            }
        } catch (error) {
            return 'N/A';
        }

    };

    function badgeColor() {
        switch (badgeType) {
            case 'deliveryCount':
                if (salesAccountingSingleDateData.deliveryCount > 0) {
                    return "primary"
                } else {
                    return "info"
                }
            case 'deliveryStatusWithAmount':
                if (salesAccountingSingleDateData.deliveryStatus) {
                    return getDeliveryStatusColor(salesAccountingSingleDateData.deliveryStatus);
                } else {
                    return "default";
                }
            case 'invoiceCount':
                if (salesAccountingSingleDateData.invoiceCount > 0) {
                    return "primary"
                } else {
                    return "info"
                }
            case 'invoiceStatusWithAmount':
                if (salesAccountingSingleDateData.invoiceStatus) {
                    return getInvoiceStatusColor(salesAccountingSingleDateData.invoiceStatus);
                } else {
                    return "default";
                }
            case 'paymentCount':
                if (salesAccountingSingleDateData.paymentCount > 0) {
                    return "primary"
                } else {
                    return "info"
                }
                case 'paymentMethodWithAmount':
                    if (salesAccountingSingleDateData.paymentMethod) {
                        return getPaymentMethodColor(salesAccountingSingleDateData.paymentMethod);
                    } else {
                        return "default";
                    }
            default:
                return "info";
        }
    };

    return (
        <Badge
            color={badgeColor()}
            className={badgeClassName ? badgeClassName : badgeClassNameDefault}
        >
            {textToShow()}
            {clickable && " "}       
            {clickable && <i className="fas fa-hand-pointer" />}       
        </Badge>
    );
}

export default SalesAccountingBadge;
