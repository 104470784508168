import React from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InputTypeTextForm from "../components/InputTypeTextForm.js";
import { storeDriver } from "functions/Driver.js";
import { getUserSelect2 } from "functions/Auth";
import InputTypeSelect2Form from "../components/InputTypeSelect2Form";

import Notifications from "../components/Notifications";
import SubmitButtonInForm from "../components/SubmitButtonInForm.js";

function DriverModalCreate(props) {
  const { t } = useTranslation();
  const [userID, setUserID] = React.useState("");
  const [userList, setUserList] = React.useState([]);
  const [jobRemark, setJobRemark] = React.useState("");

  // Control
  const [finishSubmitBoo, setFinishSubmitBoo] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check 
    if (userID === "") {
      Notifications({
        type: "dataCheck",
        message: "Please input a user"
      });
      return;
    }

    let dataDriver = {
      userID: userID,
      jobRemark: jobRemark,
    };

    let store = await storeDriver(dataDriver);
    if (store.code == 200) {
      props.refreshTable()
      setFinishSubmitBoo(true);
      clearForm();
      props.onHide();
    }

  };

  async function handleChangeUser(e) {
    if (e.target.selectedIndex != -1) {
      let value = e.target.value;
      let text = e.target.options[e.target.selectedIndex].text;
      setUserID(value);
    }
  }

  const clearForm = () => {
    setUserID("");
    setJobRemark("");
    
    setFinishSubmitBoo(false);
  };

  React.useEffect(() => {
    const getUserList = async () => {
      let getUser = await getUserSelect2();
      setUserList(getUser);
    };
    if (props.show) {
      getUserList();
    }
  }, [props.show]);

  return (
    <>
      <Modal
        isOpen={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
         centered
        toggle={props.onHide}
        autoFocus={true}
        backdrop={'static'}
        trapFocus={false}
        keyboard={false}
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader >{props.title}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <InputTypeSelect2Form
                  data={userList}
                  placeholder={t("--Select User--")}
                  value={userID}
                  nameSelect2="userID"
                  idSelect2="userID"
                  label={t("Name")}
                  handleOnChangeSelect={(e) => {
                    handleChangeUser(e);
                  }}
                />
                {/* <InputTypeTextForm
                  label={t("Nickname")}
                  labelFor="nickname"
                  inputName="nickname"
                  inputId="nickname"
                  inputPlaceholder={t("Nickname")}
                  readOnly={true}
                  value={nickName}
                  handleChange={(e) => {
                    e.preventDefault();
                  }}
                />
                <InputTypeTextForm
                  label= {t("Contact Number")}
                  labelFor="phoneNumber"
                  inputName="phoneNumber"
                  inputId="phoneNumber"
                  inputPlaceholder={t("Contact Number")}
                  readOnly={true}
                  value={phoneNumber}
                  handleChange={(e) => {
                    e.preventDefault();
                  }}
                /> */}
                <InputTypeTextForm
                  label={t("Remark")}
                  labelFor="jobRemark"
                  inputName="jobRemark"
                  inputId="jobRemark"
                  inputPlaceholder={t("Remark")}
                  value={jobRemark}
                  type="textarea"
                  handleChange={(e) => setJobRemark(e.target.value)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="w-100 d-flex justify-content-between">
              <div>
                <Button
                  color="danger"
                  type="button" // type="button" means the form will not submit
                  onClick={(e) => {
                    e.preventDefault();
                    clearForm();
                  }}
                >
                  {t("Reset")}
                </Button>
              </div>
              <div>
                <SubmitButtonInForm
                  finishSubmitBoo={finishSubmitBoo}
                  buttonNormalText={"Add"} />
                <Button color="default" type="button" onClick={props.onHide}>
                  {t("Cancel")}
                </Button>
              </div>

            </Row>

          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default DriverModalCreate;
