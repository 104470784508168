import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";

export const getAllTruckWorker = async () => {
  let response = await apiGet({ url: "truckWorker/getTruckWorkers", responseType: "" });
  return response.status == 200 ? response.data.data.rows : [];
};

export const getTruckWorkerSelect2 = async () => {
  try {
    let response = await apiGet({ url: "truckWorker/getTruckWorkers", responseType: "" });
    if (response.status == 200) {
      const data = response.data.data.rows;
      let res = [];
      for (let i = 0; i < data.length; i += 1) {
        const obj = {
          id: data[i].truckWorkerID,
          text: data[i].SystemUser.nickName,
          SystemUser: data[i].SystemUser,
        };
        res.push(obj);
      }
      return res ?? [];
    }
  } catch (e) {
    return [];
  }
  
};

