import React, { useRef } from "react";
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";


import "./SignatureCanvas.css";

function SignatureCanvas(props) {
  const { t } = useTranslation();

  const { canvasWidth, canvasHeight, strokeColor, strokeWidth, onSubmit } =
    props;

  const canvasRef = useRef(null);

  const clearCanvas = () => {
    canvasRef.current.width = canvasRef.current.width;
    initCanvas(); //  Re-init canvas
  };
  const onClearClick = () => {
    clearCanvas();
  };
  const onSubmitClick = () => {
    const dataUrl = canvasRef.current.toDataURL();
    onSubmit(dataUrl);
  };
  const initCanvas = () => {
    // Get a regular interval for drawing to the screen
    window.requestAnimFrame = (function (callback) {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimaitonFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();
    // Set up the canvas
    const canvas = document.getElementById("sig-canvas");
    const ctx = canvas.getContext("2d");
    ctx.strokeStyle = strokeColor ?? "#222";
    ctx.lineWidth = strokeWidth ?? 3;
    // Set up mouse events for drawing
    let drawing = false;
    let mousePos = { x: 0, y: 0 };
    let lastPos = mousePos;
    canvas.addEventListener(
      "mousedown",
      function (e) {
        drawing = true;
        lastPos = getMousePos(canvas, e);
      },
      false
    );
    canvas.addEventListener(
      "mouseup",
      function (e) {
        drawing = false;
      },
      false
    );
    canvas.addEventListener(
      "mousemove",
      function (e) {
        mousePos = getMousePos(canvas, e);
      },
      false
    );
    // Set up touch events for mobile, etc
    canvas.addEventListener(
      "touchstart",
      function (e) {
        mousePos = getTouchPos(canvas, e);
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvas.dispatchEvent(mouseEvent);
      },
      false
    );
    canvas.addEventListener(
      "touchend",
      function (e) {
        var mouseEvent = new MouseEvent("mouseup", {});
        canvas.dispatchEvent(mouseEvent);
      },
      false
    );
    canvas.addEventListener(
      "touchmove",
      function (e) {
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousemove", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvas.dispatchEvent(mouseEvent);
      },
      false
    );
    // Prevent scrolling when touching the canvas
    document.body.addEventListener(
      "touchstart",
      function (e) {
        if (e.target == canvas) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      "touchend",
      function (e) {
        if (e.target == canvas) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      "touchmove",
      function (e) {
        // eslint-disable-next-line
        if (e.target == canvas) {
          e.preventDefault();
        }
      },
      false
    );
    // Get the position of the mouse relative to the canvas
    function getMousePos(canvasDom, mouseEvent) {
      const rect = canvasDom.getBoundingClientRect();
      return {
        x: mouseEvent.clientX - rect.left,
        y: mouseEvent.clientY - rect.top,
      };
    }
    // Get the position of a touch relative to the canvas
    function getTouchPos(canvasDom, touchEvent) {
      const rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    }
    // Draw to the canvas
    function renderCanvas() {
      if (drawing) {
        ctx.moveTo(lastPos.x, lastPos.y);
        ctx.lineTo(mousePos.x, mousePos.y);
        ctx.stroke();
        lastPos = mousePos;
      }
    }
    // Allow for animation
    (function drawLoop() {
      requestAnimationFrame(drawLoop);
      renderCanvas();
    })();
  };

  //  Initialize canvas
  React.useEffect(initCanvas, [strokeColor, strokeWidth]);

  return (
    <Container id="sig-canvas-container">
      <canvas
        id="sig-canvas"
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
      >
        Your device/browser does not support the signature function
      </canvas>
      <div className="mt-2 mb-2 d-flex justify-content-between">
        <Button color="secondary" onClick={onClearClick}>
          <i className="fas fa-eraser" /> {t("Clear")}
        </Button>
        <Button color="success" onClick={onSubmitClick}>
          <i className="fas fa-check" />  {t("Submit")}
        </Button>
      </div> 
    </Container>
  );
}

export default SignatureCanvas;
