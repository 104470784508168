import React from "react";
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Button,
  Col,
  Row
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datetime/css/react-datetime.css";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { Trans } from "react-i18next";


// props
// UseCheckButton
// props.checkButtonClick
function InputDateTimeGroupForm(props) {


  const [currentDateTime, setCurrentDateTime] = React.useState(new Date());

  const [openCustomDateTimeButton, setOpenCustomDateTimeButton] = React.useState(false);

  const controlCustomDateTimeButton = (e) => {
    setOpenCustomDateTimeButton(!openCustomDateTimeButton);
  }

  const onChangeDateLocal = (date) => {
    props.onDateChange(new Date(date));
  }


  React.useEffect(() => {
    setCurrentDateTime(new Date(props.value));
  }, [props.value]);

  return (
    <>
      {props.label !== "" ?
        <Label for={props.labelFor} className="form-control-label">
          {props.label}
        </Label>
        : <></>}
      <div className="flex-grow-1">
        <div className="d-flex">
          <InputGroup className="d-flex input-group-alternative">
            {false &&
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
            }

            {props.useCheckButton &&
              <InputGroupAddon addonType="prepend">
                <Button color="success"
                  onClick={() => {
                    props.checkButtonClick();
                  }}>
                  <i className="fas fa-check" />
                </Button>
              </InputGroupAddon>
            }

            <InputGroupAddon className="flex-grow-1" addonType="prepend">
              <ReactDatetime
                className="flex-grow-1"
                inputProps={{
                  placeholder: props.label,
                  id: props.inputId,
                  name: props.inputName,
                  disabled: props.readOnly,
                   className: "form-control form-control-date-time-picker"
                }}
                dateFormat={props.dateFormat ? props.dateFormat : "YYYY-MM-DD ddd"}
                timeFormat={props.showTimeBoo}
                value={currentDateTime}
                onChange={(date) => {
                  onChangeDateLocal(date);
                }}
                open={props.open}
                closeOnSelect={true}
                initialViewMode='time'
                updateOnView="days"
                input={true}
              />
            </InputGroupAddon>


            {!props.readOnly && props.showCustomDateTimeButton &&
              <InputGroupAddon addonType="append">
                <Button color={props.specialButtonColor ? props.specialButtonColor : "primary"}
                  onClick={controlCustomDateTimeButton}
                >
                  <i className={openCustomDateTimeButton ? "fa fa-angle-up" : "fa fa-angle-down"} />
                </Button>
              </InputGroupAddon>
            }
          </InputGroup>

        </div>
        {openCustomDateTimeButton &&
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="mt-2">
              <Button
                className="ml-1"
                color="primary"
                onClick={(e) => {
                  onChangeDateLocal(moment(currentDateTime).add(-1, 'days').toDate())
                }
                }>
                <i className="fas fa-angle-left" />
              </Button>
              <Button
                className="ml-1"
                color="primary"
                onClick={(e) => {
                  const timeToEdit = moment(currentDateTime);
                  const timeNow = moment();
                  timeToEdit.set('year', timeNow.year());
                  timeToEdit.set('month', timeNow.month()); //moment month is zero based 
                  timeToEdit.set('date', timeNow.date()); // moment.day() is referring to weekday
                  onChangeDateLocal(timeToEdit.toDate());
                }
                }>
                <Trans>Today</Trans>
              </Button>
              <Button
                className="ml-1"
                color="primary"
                onClick={(e) => {
                  onChangeDateLocal(moment(currentDateTime).add(1, 'days').toDate())
                }
                }>
                <i className="fas fa-angle-right" />
              </Button>
            </Col>
            {props.showTimeBoo &&
              <Col xs={12} sm={12} md={12} lg={12} className="mt-2">
                <Button
                  className="ml-1"
                  color="primary"
                  onClick={(e) => {
                    const timeToEdit = moment(currentDateTime);
                    timeToEdit.set('hour', 8);
                    timeToEdit.set('minute', 0);
                    timeToEdit.set('second', 0);
                    onChangeDateLocal(timeToEdit.toDate())
                  }
                  }>
                  <Trans>Morning</Trans>
                </Button>
                <Button
                  className="ml-1"
                  color="primary"
                  onClick={(e) => {
                    const timeToEdit = moment(currentDateTime);
                    const timeNow = moment();
                    timeToEdit.set('hour', timeNow.hour());
                    timeToEdit.set('minute', timeNow.minute());
                    timeToEdit.set('second', timeNow.second());
                    onChangeDateLocal(timeToEdit.toDate());
                  }
                  }>
                  <Trans>Now</Trans>
                </Button>
                <Button
                  className="ml-1"
                  color="primary"
                  onClick={(e) => {
                    const timeToEdit = moment(currentDateTime);
                    timeToEdit.set('hour', 13);
                    timeToEdit.set('minute', 0);
                    timeToEdit.set('second', 0);
                    onChangeDateLocal(timeToEdit.toDate())
                  }
                  }>
                  <Trans>Afternoon</Trans>
                </Button>
              </Col>
            }
            {props.showTimeBoo &&
              <Col xs={12} sm={12} md={12} lg={12} className="mt-2">
                <Button
                  className="ml-1"
                  size="sm"
                  color="primary"
                  onClick={(e) => {
                    const timeToEdit = moment(currentDateTime);
                    const remainder = - (timeToEdit.minute() % 30);
                    if (remainder == 0) { onChangeDateLocal(moment(currentDateTime).add(-30, 'minutes').toDate()) };
                    if (remainder != 0) { onChangeDateLocal(moment(currentDateTime).add(remainder, 'minutes').toDate()) };
                  }
                  }>
                  -30<Trans>m</Trans>
                </Button>
                <Button
                  className="ml-1"
                  size="sm"
                  color="primary"
                  onClick={(e) => {
                    onChangeDateLocal(moment(currentDateTime).startOf('hour').add(-1, 'hours').toDate())
                  }
                  }>
                  -1<Trans>h</Trans>
                </Button>
                <Button
                  className="ml-1"
                  size="sm"
                  color="primary"
                  onClick={(e) => {
                    onChangeDateLocal(moment(currentDateTime).startOf('hour').add(1, 'hours').toDate())
                  }
                  }>
                  +1<Trans>h</Trans>
                </Button>
                <Button
                  className="ml-1"
                  size="sm"
                  color="primary"
                  onClick={(e) => {
                    const timeToEdit = moment(currentDateTime);
                    const remainder = 30 - (timeToEdit.minute() % 30);
                    onChangeDateLocal(moment(currentDateTime).add(remainder, 'minutes').toDate())
                  }
                  }>
                  +30<Trans>m</Trans>
                </Button>

              </Col>
            }
          </Row>

        }

      </div>
    </>
  );
}

export default InputDateTimeGroupForm;
