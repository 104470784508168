import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  CardFooter
} from "reactstrap";
import moment from "moment";
import Notifications from "../components/Notifications";
import { combineAddress } from "../../../functions/Delivery.js"
import { Link, Redirect, useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { getDateStringWithoutTime } from "functions/Utility";

import InvoiceBadge from "../components/InvoiceBadge.js";
import DeliveryBadge from "../components/DeliveryBadge";
import MembershipBadge from "../components/MembershipBadge";

//props:
//membershipData
//setClickViewInvoiceButton
//onClickCard
//isSelectedCard
//isMutedCard : cannot edit
//deleteThisMembership

// styleSkipBottomSpaceBoo
function MembershipCard(props) {


  const { t, i18n } = useTranslation();
  const activeLang = i18n.language;

  const membershipData = props.membershipData;
  const isSelectedCard = props.isSelectedCard ? props.isSelectedCard : false;

  const styleSkipBottomSpaceBoo = props.styleSkipBottomSpaceBoo;

  const [showFullCard, setShowFullCard] = React.useState(props.showFullCard ? props.showFullCard : true);// default tshow full card
  const [whiteText, setWhiteText] = React.useState("");

  React.useEffect(() => {
    setShowFullCard(props.showFullCard);
  }, [props.showFullCard]);

  React.useEffect(() => {
    setWhiteText(" " + (props.enableCardBgColor ? "cardWhiteText" : ""));
  }, [props.enableCardBgColor]);


  return membershipData ? (
    <Card
      className={styleSkipBottomSpaceBoo ? "mb-0" : ""} //naturally will have mb-4
      style={{ cursor: "pointer" }}
    >
      <CardHeader
        className={isSelectedCard ? "bg-gradient-success" : (props.enableCardBgColor ? props.cardBgColor : "")}>
        <div className={"d-flex justify-content-between" + whiteText}>
          <div className="ml--1">
            <Button
              className="mb-1"
              color="primary"
              size="sm"
              onClick={() => { setShowFullCard(!showFullCard) }}>
              <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
            </Button>
            {props.isMutedCard &&
              <Badge color="danger" className="statusBadge">
                {t("Not editable")}
              </Badge>
            }
            <MembershipBadge
              membershipData={membershipData}
              badgeType="invoiceSubitemConnection"
              badgeClassName="statusBadge"
            />

            <MembershipBadge
              membershipData={membershipData}
              badgeType="invoiceStatus"
              badgeClassName="statusBadge"
            />

          </div>
          <div className="cardIconButton">

            <span>&nbsp;&nbsp;&nbsp;</span>
            <i className="ni ni-credit-card"
              id="tooltip443412012"
              onClick={(e) => {
                if (!membershipData.MembershipInvoiceSubitemMatch) {
                  Notifications({
                    type: "dataCheck",
                    message: t("No invoice connected")
                  });
                  return;
                }

                if (membershipData.MembershipInvoiceSubitemMatch && membershipData?.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice) {
                  props.setClickViewInvoiceButton(membershipData?.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.Invoice);
                }
              }} />
            {/* <UncontrolledTooltip
              delay={1}
              target="tooltip443412012">
              {t("invoice")}
            </UncontrolledTooltip> */}
            <span>&nbsp;&nbsp;&nbsp;</span>
            <i className="fas fa-trash"
              onClick={(e) => {
                props.deleteThisMembership(membershipData.membershipID);
              }} />
          </div>
        </div>
        <Row
          onClick={(e) => {
            e.preventDefault();
            props.onClickCard(membershipData);
          }}>
          <Col xs={12} >
            <div className={"tableRowTitle" + whiteText}>
              <Trans>{membershipData.MembershipType.membershipTypeName}</Trans>
            </div>
            <div className={"font-weight-bold" + whiteText}>
              {`${t('Eff')}: ${getDateStringWithoutTime(membershipData.effectiveDate, activeLang === "cn")}`}
            </div>
            <div className={"font-weight-bold" + whiteText}>
              {`${t('Exp')}: ${getDateStringWithoutTime(membershipData.expiryDate, activeLang === "cn")}`}
              
            </div>
            <div className={"font-weight-bold tableRowRemark" + whiteText}>

              {membershipData.MembershipInvoiceSubitemMatch ? `$${membershipData.MembershipInvoiceSubitemMatch?.InvoiceSubitem?.unitPrice}` : t('No invoice connected')}
            </div>
            {/* <div className={"text-muted" + whiteText}>
              {`${membershipData.Customer.customerName}`}
            </div> */}
          </Col>

        </Row>
        <Row
          className={whiteText}
          onClick={(e) => {
            e.preventDefault();
            props.onClickCard(membershipData);
          }}>
          <Col>
            <div className="tableRowSecondTitle">
              {membershipData?.Customer?.customerName === undefined
                ? "-"
                : membershipData?.Customer.customerName}
            </div>
            <div className="font-weight-bold">
              {(membershipData?.Customer && membershipData?.Customer?.CustomerPhones && membershipData?.Customer?.CustomerPhones.length >= 1)
                ? `${membershipData?.Customer?.CustomerPhones[0]?.phoneUserName}:${membershipData?.Customer?.CustomerPhones[0]?.phoneNumber}`
                : t("No contact")}
            </div>
          </Col>
        </Row>
      </CardHeader>
      {/* 

      {showFullCard &&
        <CardBody
          className={isSelectedCard ? "bg-gradient-success" : (props.enableCardBgColor ? props.cardBgColor : "")}>

          {(membershipData?.InvoiceSubitems) && (Object.values(membershipData?.InvoiceSubitems).length > 0) &&
            <Row
              className={whiteText}>
              <Col onClick={(e) => {
                e.preventDefault();
                props.onClickCard(membershipData);
              }}  >
                {membershipData?.InvoiceSubitems?.map((invoiceSubitem, index) => (

                  <div key={index}>
                    <span>
                      <Badge
                        color={"warning"}
                        className="statusBadge"
                      >
                        {invoiceSubitem?.quantity}{invoiceSubitem?.Good?.goodUnit}{invoiceSubitem?.Good?.goodNameShortForm}
                      </Badge>
                    </span>
                    <span>
                      &nbsp;
                    </span>
                    <Badge
                      color={"default"}
                      className="statusBadge"
                    >
                      {`$${invoiceSubitem?.unitPrice * invoiceSubitem?.quantity}`}
                    </Badge>
                    <span>
                      &nbsp;
                    </span>
                    <Badge
                      color={"info"}
                      className="statusBadge"
                    >
                      {`$${invoiceSubitem?.unitPrice} / ${invoiceSubitem?.Good?.goodUnit}`}
                    </Badge>


                  </div>

                ))}

              </Col>
            </Row>
          }
          {(membershipData?.InvoiceSubitems) && (Object.values(membershipData?.InvoiceSubitems).length <= 0) &&
            <Row
              className={whiteText}>
              <Col onClick={(e) => {
                e.preventDefault();
                props.onClickCard(membershipData);
              }}  >
                <div className="tableRowSecondTitle">
                  <span>{t("Empty Invoice Items!")}</span>
                </div>


              </Col>
            </Row>
          }
        </CardBody>
      }

      {showFullCard && (membershipData?.DeliveryRecords) && (Object.values(membershipData?.DeliveryRecords).length > 0) &&
        <CardFooter
          className={isSelectedCard ? "bg-gradient-success" : (props.enableCardBgColor ? props.cardBgColor : "")}>
          <Row
            className={whiteText}>
            <Col onClick={(e) => {
              e.preventDefault();
              props.onClickCard(membershipData);
            }}  >
              {membershipData?.DeliveryRecords?.map((deliveryRecordItem, index) => (
                <div key={index}>
                  <div key={index}>
                    {combineAddress(deliveryRecordItem?.DeliveryAddress)}
                  </div>
                  <div>


                    <DeliveryBadge
                      deliveryRecordData={deliveryRecordItem}
                      badgeType="deliveryStatus"
                      badgeClassName="statusBadgeSmall"
                    />
                    <DeliveryBadge
                      deliveryRecordData={deliveryRecordItem}
                      badgeType="carID"
                      badgeClassName="statusBadgeSmall"
                    />
                    <DeliveryBadge
                      deliveryRecordData={deliveryRecordItem}
                      badgeType="driverID"
                      badgeClassName="statusBadgeSmall"
                    />



                  </div>
                </div>
              ))}

            </Col>

          </Row>
        </CardFooter>
      } 
      
      */}
    </Card>
  ) : (
    <Col xs={12} sm={12} md={12} lg={12}>
      <Card>
        <CardBody>
          <strong className="text-info h1"><Trans>No Data Found</Trans></strong>
          <h3 className="text-info"><Trans>Please Check Your Filter</Trans></h3>
        </CardBody>
      </Card>
    </Col>
  );
}

export default MembershipCard;
