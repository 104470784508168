import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";
import renameKeys from "./RenameKey.js";
import Notifications from "views/pages/components/Notifications";
import { getIntegerDecodeFromString, getRandomBgColor } from "./Utility";

export const getAllDriver = async () => {
  try {
    let response = await apiGet({ url: "drivers/getDrivers", responseType: "" });
    return response.status == 200 ? response.data.data : [];
  } catch (error) {
    return [];
  }
};

export const getDriverDatatable = async () => {
  try {
    let response = await apiGet({ url: "drivers/getDrivers", responseType: "" });
    return response.status == 200 ? response.data.data.rows : [];
  } catch (error) {
    return [];
  }
};

export const getDriverSelect2 = async () => {
  try {
    let response = await apiGet({ url: "drivers/getDrivers", responseType: "" });
    if (response.status == 200) {
      const data = response.data.data.rows;
      let res = [];
      for (let i = 0; i < data.length; i += 1) {
        const obj = {
          id: data[i].driverID,
          text: data[i].SystemUser.nickName,
          SystemUser: data[i].SystemUser,
          // color: getRandomBgColor(getIntegerDecodeFromString(data[i].driverID))
        };
        res.push(obj);
      }
      return res ?? [];
    }
  } catch (e) {
    return [];
  }

};

export const storeDriver = async (data) => {
  let response = await apiPost({
    url: "drivers/registerDriver",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const updateDriver = async (data) => {
  let response = await apiPut({
    url: "drivers/updateDriver",
    data: data,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};

export const deleteDriverByID = async (id) => {
  let response = await apiDelete({
    url: "drivers/unregisterDriver/" + id,
    responseType: "",
  });
  return response.status == 200
    ? response.data
    : response.status;
};


export const getDefaultDriverID = (driverChooseSelect2Data = []) => {
  try {
    if (driverChooseSelect2Data && Array.isArray(driverChooseSelect2Data) && driverChooseSelect2Data.length == 1) {
      return driverChooseSelect2Data[0].id;
    } else {
      return "TBCDRIVER";
    }
  } catch (error) {
    return '';
  }
};
