import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { getRights, isLoggedIn } from "../../../functions/Auth";
import { getAllInvoice } from "functions/Invoice";
// core components
import InvoiceHeader from "components/Headers/InvoiceHeader.js";
import InvoiceCard from "./InvoiceCard";

import CustomerActionModal from "../customer/CustomerActionModal";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";

import { deleteInvoiceByID } from "functions/Invoice";
import Notifications from "../components/Notifications";
import { useTranslation } from "react-i18next";

import InvoiceMessageModal from "./InvoiceMessageModal";

import InvoiceEditModal from "./InvoiceEditModal";
import InvoiceSearchBar from "../components/InvoiceSearchBar";

import SpinnerOverlay from "../components/SpinnerOverlay";
import ScreenPullToRefresh from "../components/ScreenPullToRefresh";
import { isAccessible } from "functions/SystemUserAccess";
import { getIntegerDecodeFromString, getRandomBgColor, lockScrolling } from "../../../functions/Utility";
import moment from "moment";
import { getInvoiceByDateRange } from "functions/Invoice";
import InvoiceListToPayMessageArea from "../components/InvoiceListToPayMessageArea";

function InvoiceScreen(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [filteredSortedInvoiceData, setFilteredSortedInvoiceData] = React.useState([]);
  const [initInvoiceData, setinitInvoiceData] = React.useState([]);


  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshTable, setRefreshTable] = React.useState(0);

  const [invoiceSelectedDataForInvoiceEdit, setInvoiceSelectedDataForInvoiceEdit] = React.useState([]);
  const [invoiceSelectedDataForInvoiceClone, setInvoiceSelectedDataForInvoiceClone] = React.useState({});
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const [selectedCustomerData, setSelectedCustomerData] = React.useState({});

  // GUI
  const [pullToRefreshDisable, setPullToRefreshDisable] = React.useState(false);
  const [enableCardBgColor, setEnableCardBgColor] = React.useState(false);
  const [useInvoiceStatusColor, setUseInvoiceStatusColor] = React.useState(false);
  const [useInvoiceDateTimeRangeColor, setUseInvoiceDateTimeRangeColor] = React.useState(false);
  const [useInvoiceIsDeliveryColor, setUseInvoiceIsDeliveryColor] = React.useState(false);
  
  //  modal
  const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
  const [modalCustomerActionIsOpen, setModalCustomerActionIsOpen] = React.useState(false);
  const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);
  const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);


  // data control
  const dataIntervalInteger = 10;
  const [useFilterStartDate, setUseFilterStartDate] = React.useState(true);
  const [useFilterEndDate, setUseFilterEndDate] = React.useState(true);
  const [dataRangeStartDate, setDataRangeStartDate] = React.useState(moment(new Date()).add(dataIntervalInteger * -1, 'days').toDate()); // default 10 day
  const [dataRangeEndDate, setDataRangeEndDate] = React.useState(moment(new Date()).add(dataIntervalInteger, 'days').toDate()); // default 10 day

  // For filter control show
  const [showFullCard, setShowFullCard] = React.useState(false);

  const getInvoiceData = async () => {
    setIsLoading(true);
    let invoiceData = [];
    if (useFilterStartDate && useFilterEndDate) {
      // get data from server using the date option
      if (dataRangeStartDate && dataRangeEndDate) {
        // Get data according to the date range
        invoiceData = await getInvoiceByDateRange(dataRangeStartDate, dataRangeEndDate);
      } else {
        // error case
        // dont do anything
      }
    } else {
      // force to get all data from server
      invoiceData = await getAllInvoice();
    }

    setinitInvoiceData(invoiceData);
    setIsLoading(false);
  };

  React.useEffect(() => { // everytime refresh from outside
    getInvoiceData();
  }, [refreshTable]);

  React.useEffect(() => {
    // Pull to refresh must be disabled if opened some medal
    // Otherwise will lock the screen of the modal
    setPullToRefreshDisable(modalInvoiceMessageIsOpen || modalCustomerActionIsOpen || modalInvoiceEditIsOpen || modalInvoiceCreateIsOpen);
    lockScrolling(modalInvoiceMessageIsOpen || modalCustomerActionIsOpen || modalInvoiceEditIsOpen || modalInvoiceCreateIsOpen);
  }, [modalInvoiceMessageIsOpen, modalCustomerActionIsOpen, modalInvoiceEditIsOpen, modalInvoiceCreateIsOpen]);

  async function deleteConfirmation(id) {
    let alert = {
      type: "deleteConfirmation",
      title: "Are you Sure?",
      message: "Data Will Be Deleted",
      elementID: id,
      callback: deleteInvoice,
    };
    Notifications(alert);
  }

  const refreshTableData = () => {
    setRefreshTable(refreshTable + 1);
  };

  // Delete API Call
  async function deleteInvoice(id) {
    setIsLoading(true);
    let deleteResponse = await deleteInvoiceByID(id);
    deleteResponse.code == 200 ? refreshTableData() : true;
    setIsLoading(false);
  }

  const getCardBgColor = (invoiceStatus, invoiceRecordDateString, invoiceIsDeliveryString) => {
    // This control the card Bg color 
    if (enableCardBgColor) {
      // priority 
      // carID color second // Only if these are all true at the same time 
      if (useInvoiceStatusColor) {
        return getRandomBgColor(getIntegerDecodeFromString(invoiceStatus));
      } else if (useInvoiceDateTimeRangeColor) {
        return getRandomBgColor(getIntegerDecodeFromString(invoiceRecordDateString));
      } else if (useInvoiceIsDeliveryColor) {
          return getRandomBgColor(getIntegerDecodeFromString(invoiceIsDeliveryString));
      } else {
        return "";
      }
    }
  }

  return (isLoggedIn() && isAccessible(props.accessID)) ? (
    <>
      {isLoading ? <SpinnerOverlay /> : ""}

      <ScreenPullToRefresh
        onRefresh={async () => {
          await getInvoiceData();
        }}
        disabled={pullToRefreshDisable}>
        <InvoiceHeader
          name={t("Invoice")} // Name of this form
          icon="fas fa-home"
          invoiceData={filteredSortedInvoiceData}
          goBackLastPage={() => { history.goBack() }}
        />
      </ScreenPullToRefresh>
      {/* <Container style={{ marginTop: "-6rem" }} fluid> */}
      <Container style={{ marginTop: "-1rem" }} fluid>
        <InvoiceSearchBar
          initInvoiceData={initInvoiceData}
          dataRangeStartDate={dataRangeStartDate}
          dataRangeEndDate={dataRangeEndDate}
          changeCardColor={(enableBgColor, useInvoiceStatusColor, useInvoiceDateTimeRangeColor, useInvoiceIsDeliveryColor) => {
            setEnableCardBgColor(enableBgColor);
            setUseInvoiceStatusColor(useInvoiceStatusColor);
            setUseInvoiceDateTimeRangeColor(useInvoiceDateTimeRangeColor);
            setUseInvoiceIsDeliveryColor(useInvoiceIsDeliveryColor);
          }}
          returnShowFullCard={(showFullCard) => { setShowFullCard(showFullCard); }}
          returnFilteredSortedInvoiceData={(filteredSortedInvoiceData) => {
            setFilteredSortedInvoiceData(filteredSortedInvoiceData);
          }}
          createInvoice={() => {
            setModalInvoiceCreateIsOpen(true)
          }}
          refreshInitialData={(filterStartDateRequestByUser, filterEndDateRequestByUser, useFilterStartDateRequestByUser, useFilterEndDateRequestByUser) => {
            if (moment(filterStartDateRequestByUser).diff(moment(dataRangeStartDate), 'seconds') == 0 &&
              moment(filterEndDateRequestByUser).diff(moment(dataRangeEndDate), 'seconds') == 0 &&
              useFilterStartDateRequestByUser == useFilterStartDate &&
              useFilterEndDateRequestByUser == useFilterEndDate) {
              // values are the same 
              // prevent multiple fetch from the server
            } else {
              setUseFilterStartDate(useFilterStartDateRequestByUser);
              setUseFilterEndDate(useFilterEndDateRequestByUser);
              setDataRangeStartDate(moment(filterStartDateRequestByUser).add(dataIntervalInteger * -1, 'days').toDate());
              setDataRangeEndDate(moment(filterEndDateRequestByUser).add(dataIntervalInteger * 1, 'days').toDate());
              refreshTableData();
            }
          }}

        >
        </InvoiceSearchBar>



        <Row className="mt-4">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              {filteredSortedInvoiceData.map((invoiceItem, index) => (
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  data-id={invoiceItem.invoiceID}
                  key={invoiceItem.invoiceID}
                >
                  <InvoiceCard
                    showFullCard={showFullCard}
                    enableCardBgColor={enableCardBgColor}
                    cardBgColor={getCardBgColor(invoiceItem.invoiceStatus, moment(invoiceItem.invoiceRecordDateTime).format("yyyy-MM-DD").toString())}
                    invoiceData={invoiceItem}
                    setClickViewInvoiceButton={(invoice) => {
                      setSelectedInvoice(invoice);
                      setModalInvoiceMessageIsOpen(true);
                    }}
                    onClickCard={(invoice) => {
                      setInvoiceSelectedDataForInvoiceEdit(invoice);
                      setModalInvoiceEditIsOpen(true);
                    }}
                    isSelectedCard={false}
                    cloneInvoice={(invoice) => {
                      setInvoiceSelectedDataForInvoiceClone(invoice);
                      setModalInvoiceCreateIsOpen(true);
                    }}
                    accessCustomerAction={true}
                    customerAction={(customer) => {
                      setSelectedCustomerData(customer);
                      setModalCustomerActionIsOpen(true);
                    }}
                    accessCloneInvoice={true}
                  />
                </Col>
              ))}
              {filteredSortedInvoiceData.length === 0 && (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardBody>
                      <strong className="text-info h1">
                        {t("No Invoice Found!")}
                      </strong>
                      <h3 className="text-info">
                        {t("Please Check Your Filter")} </h3>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Col>
        </Row >

        <InvoiceMessageModal
          show={modalInvoiceMessageIsOpen}
          onHide={() => setModalInvoiceMessageIsOpen(false)}
          title={t("Invoice Message")}
          refreshTable={() => { }}
          invoiceData={selectedInvoice}
        />
        <CustomerActionModal
          show={modalCustomerActionIsOpen}
          onHide={() => setModalCustomerActionIsOpen(false)}
          title={t("Choose Action")}
          button={t("Update")}
          customerData={selectedCustomerData}
          refreshTable={refreshTableData}
        />
        <InvoiceEditModal
          show={modalInvoiceEditIsOpen}
          onHide={() => {
            setModalInvoiceEditIsOpen(false);
            refreshTableData();
          }}
          invoiceData={invoiceSelectedDataForInvoiceEdit}
        />
        <InvoiceCreateModal
          invoiceToClone={invoiceSelectedDataForInvoiceClone}
          show={modalInvoiceCreateIsOpen}
          onHide={(returnCreatedInvoiceID) => {
            setModalInvoiceCreateIsOpen(false);
            refreshTableData();
          }}
          allowEmptySubitemList={true}
        />


      </Container >
    </>
  ) : (
    <Redirect to="/work/signIn" />
  );
}

export default InvoiceScreen;
