import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Form,
    Container,
    Row,
    Col,
    Table,
    Button,
    Badge,
    UncontrolledTooltip,
    ListGroup,
    ListGroupItem,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Collapse,
    CardTitle
} from "reactstrap";
import QRCode from "react-qr-code";
import { uuid4 } from "@sentry/utils";
import { Trans, useTranslation } from "react-i18next";

// core components
import Dropzone from "dropzone";
import InvoiceCard from "../invoice/InvoiceCard";
import InvoiceMessageModal from "../invoice/InvoiceMessageModal";
import { getInvoiceByCustomerID } from "functions/Invoice";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import InvoiceEditModal from "../invoice/InvoiceEditModal";

import InvoiceHeader from "components/Headers/InvoiceHeader.js";
import InvoiceStatCard from "../components/InvoiceStatCard";
import InvoiceCreateModal from "../invoice/InvoiceCreateModal";

import PaymentCreateModal from "../payment/PaymentCreateModal";
import { getInvoiceByDateRange } from "functions/Invoice";
import { getDateStringWithoutTime } from "functions/Utility";
import { getInvoiceStatusColor } from "functions/Invoice";
import InvoiceListToPayMessageArea from "../components/InvoiceListToPayMessageArea";



Dropzone.autoDiscover = false;


// props.startDate
// props.endDate
// props.invoiceStatus

function SalesAccountingInvoiceListModal(props) {

    const history = useHistory();
    const startDate = props.startDate;
    const endDate = props.endDate;
    const invoiceStatus = props.invoiceStatus;


    const { t } = useTranslation();
    //Initialize Get Set

    const [refreshTable, setRefreshTable] = React.useState(0);

    const [showAll, setShowAll] = React.useState(isMobile ? false : true);
    const [invoiceData, setInvoiceData] = React.useState([]);

    const [initInvoiceData, setinitInvoiceData] = React.useState([]);

    const [sortPriceSmall, setSortPriceSmall] = React.useState(true);
    const [sortDateSmall, setSortDateSmall] = React.useState(true);

    // for modal 
    const [modalInvoiceMessageIsOpen, setModalInvoiceMessageIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceMessage, setInvoiceSelectedDataForInvoiceMessage] = React.useState([]);
    const [modalInvoiceEditIsOpen, setModalInvoiceEditIsOpen] = React.useState(false);
    const [invoiceSelectedDataForInvoiceEdit, setInvoiceSelectedDataForInvoiceEdit] = React.useState([]);
    const [invoiceSelectedDataForCloneInvoice, setInvoiceSelectedDataForCloneInvoice] = React.useState(null);

    const [modalInvoiceCreateIsOpen, setModalInvoiceCreateIsOpen] = React.useState(false);

    const [modalPaymentCreateIsOpen, setModalPaymentCreateIsOpen] = React.useState(false);

    // For filter control show
    const [showFullCard, setShowFullCard] = React.useState(false);

    React.useEffect(() => {
        // setShowCustomer(showAll);
    }, [showAll]);

    /*     React.useEffect(() => {
            setInvoiceData(initInvoiceData);
        }, [initInvoiceData]);
     */

    //Initialize Effect
    React.useEffect(() => {
        const getInvoiceData = async () => {
            let invoiceData = await getInvoiceByDateRange(startDate, endDate, invoiceStatus);
            setinitInvoiceData(invoiceData);
            setInvoiceData(invoiceData);
        };

        if (props.show) {
            getInvoiceData();
        }
    }, [props.show, refreshTable]);


    const refreshTableData = () => {
        setRefreshTable(refreshTable + 1);
    };

    const sortPrice = () => {
        setSortDateSmall(true);
        let res = [];
        if (!sortPriceSmall) {
            res = invoiceData.sort((a, b) => a.invoiceNetPrice - b.invoiceNetPrice);
        } else {
            res = invoiceData.sort((a, b) => b.invoiceNetPrice - a.invoiceNetPrice);
        }
        setInvoiceData(res);
        setSortPriceSmall(!sortPriceSmall);
    };

    const sortDate = () => {
        setSortPriceSmall(true);
        let res = [];
        if (!sortDateSmall) {
            res = invoiceData.sort(
                (a, b) =>
                    new Date(a.invoiceRecordDateTime) - new Date(b.invoiceRecordDateTime)
            );
        } else {
            res = invoiceData.sort(
                (a, b) =>
                    new Date(b.invoiceRecordDateTime) - new Date(a.invoiceRecordDateTime)
            );
        }
        setInvoiceData(res);
        setSortDateSmall(!sortDateSmall);
    };

    return (
        <>
            <Modal
                isOpen={props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                toggle={props.onHide}
                autoFocus={true}
                backdrop={'static'}
                trapFocus={false}
                keyboard={false}
            >
                <ModalHeader className="mt-2 ml-2 mr-2" toggle={props.onHide} >
                    <div>
                        <span> {t("Invoice Record")}</span>
                    </div>
                    <div className="mt-1">
                        <Badge color={getInvoiceStatusColor(invoiceStatus)} className="statusBadge"><Trans>{invoiceStatus}</Trans></Badge>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge"><Trans>Start</Trans>:{getDateStringWithoutTime(startDate, true)}</Badge>
                    </div>
                    <div className="mt-1">
                        <Badge color="primary" className="statusBadge"><Trans>End</Trans>:{getDateStringWithoutTime(endDate, true)}</Badge>
                    </div>
                </ModalHeader>
                <ModalBody>



                    {/* Stat */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InvoiceStatCard
                                invoiceListData={invoiceData}
                            />
                        </Col>

                    </Row>
                    {/* filter Card */}
                    <Row>
                        <Col>
                            <Card className="card-stats">
                                <CardHeader
                                    role="tab">
                                    <Row>
                                        <Col className="col-auto pr-0">
                                            <Button
                                                onClick={() => { setShowFullCard(!showFullCard) }}
                                                className="mt-1"
                                                color="primary">
                                                <i className={`fa fa-angle-${showFullCard ? "up" : "down"}`} />
                                            </Button>

                                            <Button onClick={sortPrice} className="mt-1" color="primary">
                                                {t("Price")}{" "}
                                                <i
                                                    className={`fas fa-sort-numeric-${sortPriceSmall ? "up" : "down"
                                                        }`}
                                                />
                                            </Button>

                                            <Button onClick={sortDate} className="mt-1" color="primary">
                                                {t("Date")}{" "}
                                                <i
                                                    className={`fas fa-sort-numeric-${sortDateSmall ? "up" : "down"
                                                        }`}
                                                />
                                            </Button>
                                        </Col>

                                    </Row>
                                </CardHeader>

                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <InvoiceListToPayMessageArea
                                isSameCustomer={false}
                                customerDetail={{}}
                                showReceiptURL={false}
                                receiptURL={""}
                                invoiceList={invoiceData}
                            >
                            </InvoiceListToPayMessageArea>
                        </Col>
                    </Row>


                    {/* Invoice list */}
                    <Row>
                        {invoiceData && invoiceData.length != 0 && invoiceData.map((invoice, index) => (
                            <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                data-id={invoice.invoiceID}
                                key={invoice.invoiceID}
                            >
                                <InvoiceCard
                                    showFullCard={showFullCard}
                                    invoiceData={invoice}
                                    setClickViewInvoiceButton={(invoice) => {
                                        setInvoiceSelectedDataForInvoiceMessage(invoice);
                                        setModalInvoiceMessageIsOpen(true);
                                    }}
                                    onClickCard={(invoice) => {
                                        setInvoiceSelectedDataForInvoiceEdit(invoice);
                                        setModalInvoiceEditIsOpen(true);
                                    }}
                                    isSelectedCard={false}
                                    cloneInvoice={(invoice) => {
                                        setInvoiceSelectedDataForCloneInvoice(invoice);
                                        setModalInvoiceCreateIsOpen(true);
                                    }}
                                    accessCustomerAction={false}
                                    customerAction={(customer) => {
                                        //  setSelectedCustomerData(customer);
                                        // setModalCustomerActionIsOpen(true);
                                    }}
                                    accessCloneInvoice={true}
                                />
                            </Col>
                        ))}
                        {invoiceData && invoiceData.length === 0 && (
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Card>
                                    <CardBody>
                                        <strong className="text-info h1">
                                            {t("No Invoice Found!")}
                                        </strong>
                                        <h3 className="text-info">
                                            {t("Please Check Your Filter")} </h3>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>

                    {/* End of Invoice list */}

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="default"
                        type="button"
                        onClick={() => {
                            props.onHide();
                        }}
                    >
                        {t("Back")}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* <CustomerActionModal
                show={modalCustomerActionIsOpen}
                onHide={() => setModalCustomerActionIsOpen(false)}
                title={t("Choose Action")}
                button={t("Update")}
                customerData={customerSelectedDataForCustomerAction}
                refreshTable={() => { setRefreshTable(refreshTable + 1) }}
            /> */}
            <InvoiceMessageModal
                show={modalInvoiceMessageIsOpen}
                onHide={() => setModalInvoiceMessageIsOpen(false)}
                title={t("Invoice Message")}
                refreshTable={() => { }}
                invoiceData={invoiceSelectedDataForInvoiceMessage}
            />
            <InvoiceEditModal
                show={modalInvoiceEditIsOpen}
                onHide={() => {
                    setModalInvoiceEditIsOpen(false);
                    refreshTableData();
                }}
                invoiceData={invoiceSelectedDataForInvoiceEdit}
            />
            <InvoiceCreateModal
                preSelectedCustomer={props.customerData}
                invoiceToClone={invoiceSelectedDataForCloneInvoice}
                show={modalInvoiceCreateIsOpen}
                onHide={(returnCreatedInvoiceID) => {
                    setModalInvoiceCreateIsOpen(false);
                    refreshTableData();
                }}
                allowEmptySubitemList={true}
            />
        </>
    );
}

export default SalesAccountingInvoiceListModal;
