import React from "react";

import { useTranslation } from "react-i18next";
//props
//text
//translateBoo
function TranslateItem(props) {
    

        const { t } = useTranslation();
        return (
            <>
               {t(props.text)}
            </>
        );

    
}

export default TranslateItem;
