
import renameKeys from "./RenameKey";
import Notifications from "views/pages/components/Notifications";
import api, { apiDelete, apiGet, apiPost, apiPut } from "./Api";


export const configLocalUserJobList = async (userID) => {

  let userJobList;
  let response = await apiGet({
      url: "systemuserjob/getJobByUserID/" + userID,
      responseType: "",
  });

  if (response.status == 200) {
      userJobList = response.data.data.rows;
  } else {
      userJobList = [];
  }
  
  localStorage.removeItem("userJobList");
  localStorage.setItem("userJobList", JSON.stringify(userJobList));

}



export const getAllUserJobSelect2 = async () => {
    try {
        let response = await apiGet({ url: "/systemuserjob/getAllJob", responseType: "" });
        if (response.status == 200) {

            let resultArray = [];

            // to create an array like: [{id: xxx, text: yyy}, {id: hh, text; kk}]
            response.data.data.rows.forEach((obj) => {
                resultArray.push({
                    id: obj.job,
                    text: obj.job,
                    obj: obj
                });
            }
            );
            return resultArray;
        }
    } catch (e) {
        return [];
    }
};

export const getAllUserJob = async () => {
    try {
        const response = await apiGet({
            url: "systemuserjob/getAllJob",
            responseType: "",
        });

        return response.data.data.rows;
    } catch (error) {
        return [];
    }

};


export const addNewJob = async (data) => {
    let response = await apiPost({
      url: "/systemuserjob/addJob",
      data: data,
      responseType: "",
    });
    return response.status == 200
      ? response.data.data
      : response.status;
  };


export const deleteUserJob = async (job) => {
    let response = await apiDelete({
      url: "/systemuserjob/deleteJob/" + job,
      responseType: "",
    });
    return response.status == 200
      ? response.data
      : response.status;
  };
  


  export const updateUserJob = async (data) => {
    let response = await apiPut({
      url: "/systemuserjob/updateJob",
      data: data,
      responseType: "",
    });

    return response.status == 200
    ? response.data
    : response.status;
};
  


export const updateAccessIDArrayToJob = async (data) => {
    let response = await apiPost({
      url: "/systemuserjob/grantAccessIDArrayToSingleJob",
      data: data,
      responseType: "",
    });
  
    return response.status == 200
      ? response.data
      : response.status;
  };



  export const updateUserIDArrayToJob = async (data) => {

    console.log(data);
    let response = await apiPost({
      url: "/systemuserjob/grantUserIDArrayToSingleJob",
      data: data,
      responseType: "",
    });
  
    return response.status == 200
      ? response.data
      : response.status;
  };
  

export const getJobTypeArray = () => {
  return [
    { id: "fixed", text: "fixed", color: "danger" },
    { id: "normal", text: "normal", color: "default" },
  ];
};
